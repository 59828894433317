import { withI18n } from "react-i18next";
import { withStyles } from '@material-ui/core/styles';
import { withRouter } from 'react-router-dom';
import {compose} from "recompose";

import {CheckCircle, Cancel, Person, PersonAdd} from '@material-ui/icons';
import {Avatar, Button, Card, CardContent, CardHeader, CircularProgress, Grid, IconButton, InputBase, List, ListItem, ListItemText, ListItemAvatar, ListItemSecondaryAction, Paper, Typography} from '@material-ui/core';
import * as COLORS from "../../constants/colors";
import {connect} from "react-redux";
import { acceptFollowingRequest, declineFollowingRequest, fetchFollowingRequests,fetchFollower, fetchFollowers, fetchFollowings, sendFollowRequest,  removeFollowInfoListeners, unFollow} from "../../actions";
import UserAvatar from "../UserAvatar";
import {PRIMARY} from "../../constants/colors";
import {filterObjectInArray} from "../../util/functions";
import {USER_SEARCH_LIMIT} from "../../constants/common";

const React = require('react');

const styles = theme => ({
    mainView: {
        width: '100%',
        display: 'contents'
    },
    progressRoot: {
        flexGrow: 1,
        spacing: '40'
    },
    progress: {
        margin: theme.spacing(2),
        color: COLORS.LOADING_MASK
    },
    pageActions : {
        paddingTop : 0,
        color: COLORS.PRIMARY
    },
    card: {
        width: '100%',
    },
    bullet: {
        display: 'inline-block',
        margin: '0 2px',
        transform: 'scale(0.8)',
    },
    actions: {
        display: 'inherit',
    },
    media: {
        paddingTop: '75.25%', // 16:9
    },
    title: {
        textTransform: 'uppercase'
    },
    pos: {
        marginBottom: 12,
    },
    expand: {
        transform: 'rotate(0deg)',
        marginLeft: 'auto',
        transition: theme.transitions.create('transform', {
            duration: theme.transitions.duration.shortest,
        }),
    },
    expandOpen: {
        transform: 'rotate(180deg)',
    },
    avatar: {
        backgroundColor: COLORS.SECONDARY_ICON,
    },
    accept: {
        color: COLORS.ACCEPT,
    },
    decline: {
        color: COLORS.DECLINE,
    },
    buttonAccept: {
        backgroundColor: COLORS.ACCEPT,
        color: 'white'
    },
    buttonDecline: {
        backgroundColor: COLORS.DECLINE,
        color: 'white'
    },
    description : {
        whiteSpace: 'pre-wrap'
    },
    gray : {
        color: 'gray'
    },
    inputRoot: {
        padding: '2px 4px',
        display: 'flex',
        alignItems: 'center',
        maxWidth: 400,
    },
    input: {
        // marginLeft: theme.spacing(1),
        flex: 1,
        borderColor: PRIMARY,
        borderWidth: 2,
    }
});

class FriendsView extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            loading: true,
            friends: [],
            friendsExpand: false,
            followRequestsLoading: true,
            followRequests: [],
            followRequestsExpand: false,
            followersLoading: true,
            followers: [],
            followersExpand: false,
            followingLoading: true,
            followingList: [],
            followingExpand: false,
            snackShow: false,
            snackMessage: '',
            snackVariant: '',
            searchValue: '',
            hasMore:false
        };

        this.setOpen = this.setOpen.bind(this);
        this.handleActionsClose = this.handleActionsClose.bind(this);
        this.displaySnack = this.displaySnack.bind(this);
        this.filterRequestType = this.filterRequestType.bind(this);
        this.loadRequestType = this.loadRequestType.bind(this);
        this.loadMore = this.loadMore.bind(this);
        this.fetchFollowCallback = this.fetchFollowCallback.bind(this);


    }

    componentDidMount() {
        this.loadRequestType();
    }

    loadRequestType() {
        const userId = this.props.userInfo.id;
        this.setState({loading:false, currentRequestType: this.props.requestType});
        if("following" === this.props.requestType) {
            if(!this.props.followings[userId]) {
                this.props.fetchFollowings({uid: userId, Limit:USER_SEARCH_LIMIT, callback:this.fetchFollowCallback});
            }
        } else if("follower" === this.props.requestType) {
            if(!this.props.followers[userId]) {
                this.props.fetchFollowers({uid:userId, Limit:USER_SEARCH_LIMIT, callback:this.fetchFollowCallback});
            }
        } else if("followingRequests" === this.props.requestType) {
           // this.props.fetchFollowingRequests({uid:userId});
        }
    }
    componentWillUpdate(nextProps, nextState, nextContext) {
        if(nextProps.requestType !== this.state.currentRequestType) {
            this.loadRequestType();
        }
    }
    componentWillUnmount() {
        this.props.removeFollowInfoListeners(this.props.userInfo.id);
    }

    fetchFollowCallback(count) {
        var hasMore = count === USER_SEARCH_LIMIT;
        this.setState({hasMore:hasMore});

    }
    loadMore = () => {
        debugger;
        const userId = this.props.userInfo.id;
        const {followings, followers, followingRequests, requestType} =  this.props;
        var followCollection = "following" === requestType ? followings[userId] : 'followingRequests' === requestType ?
            followingRequests[userId] :followers[userId];
        var last = followCollection ? followCollection[followCollection.length - 1] : null;
        if(last) {
            if ("following" === requestType) {
                this.props.fetchFollowings({uid: userId, username: this.state.searchValue, last: last.username, limit:USER_SEARCH_LIMIT, callback:this.fetchFollowCallback()});
            } else if ("follower" === requestType) {
                this.props.fetchFollowers({uid: userId, username: this.state.searchValue, last: last.username, limit:USER_SEARCH_LIMIT, callback:this.fetchFollowCallback()});
            } else if ("followingRequests" === requestType) {
                this.props.fetchFollowingRequests({uid: userId, username: this.state.searchValue, last: last.username, limit:USER_SEARCH_LIMIT, callback:this.fetchFollowCallback()});
            }
        } else {
            this.setState({hasMore:false});
        }
    };
    handleActionsClose = () => {
        this.setState({ actionAnchorEl: null });
    };

    setOpen(open) {
        this.setState({snackShow: open});
    }

    displaySnack(snackInfo) {
        this.setState(snackInfo);
    }
    onSearchValueChange=(event) =>{
        this.filterRequestType(event.target.value.trim());
        this.setState({searchValue:event.target.value.trim()});
    };

    filterRequestType(searchValue) {
        const {followings, followers, requestType, userInfo} =  this.props;
        var userId = userInfo.id;
        var followCollection = "following" === requestType ? followings[userId] : followers[userId];
        var allLoaded = followCollection ? "following" === requestType ? userInfo.followersCount === followCollection.length : userInfo.followingsCount === followCollection.length : false;
        if(!allLoaded) {
            if ("following" === requestType) {
                this.props.fetchFollowings({
                    uid: userId,
                    username: searchValue,
                    limit: USER_SEARCH_LIMIT,
                    callback: this.fetchFollowCallback()
                });
            } else if ("follower" === requestType) {
                this.props.fetchFollowers({
                    uid: userId,
                    username: searchValue,
                    limit: USER_SEARCH_LIMIT,
                    callback: this.fetchFollowCallback()
                });
            } else if ("followingRequests" === requestType) {
                this.props.fetchFollowingRequests({
                    uid: userId,
                    username: searchValue,
                    limit: USER_SEARCH_LIMIT,
                    callback: this.fetchFollowCallback()
                });
            }
        }
    }
    render() {

        const {t, classes, followRequests, followers, followings, userInfo, loading} = this.props;
        const {searchValue} =  this.state;
        const requestList = this.props.requestType === "followingRequests" ? followRequests :
            this.props.requestType === "follower" ? filterObjectInArray(followers[userInfo.id], 'username', searchValue) : "following" === this.props.requestType ? filterObjectInArray(followings[userInfo.id], 'username', searchValue) : [];
        return (
                <div align="center" class="momentumScroll">
                        <Grid container className={classes.mainView} justify="center">
                            <Grid item>
                                <Card className={classes.card}>
                                    <CardHeader
                                        title={<Paper className={classes.inputRoot}><InputBase
                                            className={classes.input}
                                            placeholder={t('filter list')}
                                            inputProps={{ 'aria-label': t('filter list') }}
                                            value={searchValue}
                                            onChange={this.onSearchValueChange}
                                        /></Paper>
                                        }
                                    />
                                    <CardContent>
                                        {loading ? <div align="center" className={classes.progressRoot}><CircularProgress
                                                className={classes.progress} size={30} thickness={3}/></div> :
                                            requestList && requestList.length > 0 ? <List dense className={classes.root}>
                                                    {requestList.map(element => {
                                                        return (
                                                            <ListItem key={element.uid} button onClick={() => { this.props.history.push(`/user/${element.uid}`);}}>
                                                                <ListItemAvatar>
                                                                    <UserAvatar username={element.username}
                                                                                userId={element.uid}
                                                                                imageUrl={element.profileImageUrl}/>
                                                                </ListItemAvatar>
                                                                <ListItemText id={element.uid}
                                                                              primary={element.username}/>
                                                            </ListItem>
                                                        );
                                                    })}
                                                </List> :
                                                <Typography variant={"subheading"} className={classes.gray}>
                                                    {t('no results')}
                                                </Typography>
                                        }
                                    </CardContent>
                                </Card>
                            </Grid>
                        </Grid>
                </div>
        )
    }

}
const mapStateToProps = ({followingRequests, followers, followings, user}) => {
        return {user:user, followRequests:followingRequests, followers:followers, followings: followings};
};

export default compose (
    withRouter,
    withStyles(styles),
    withI18n(),
    connect(mapStateToProps, {acceptFollowingRequest, declineFollowingRequest, fetchFollowingRequests,fetchFollower, fetchFollowers, fetchFollowings,  sendFollowRequest, removeFollowInfoListeners, unFollow})
)(FriendsView)
