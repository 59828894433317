import { withI18n } from "react-i18next";
import { withStyles } from '@material-ui/core/styles';
import { withRouter } from 'react-router-dom';
import {compose} from "recompose";
import {Send,  } from '@material-ui/icons';
import {Card, CardContent,CardMedia, Grid, LinearProgress, List, ListItem, ListItemText, Paper, InputBase, IconButton}  from '@material-ui/core';
import {sendMessage, doUploadBase64File, doUploadPostImage, getFileDownloadUrl, openSnack} from "../../actions";
import {MEDIA_LOCATION, MEDIA_TYPE} from "../../constants/common";
import FileUpload from "../FileUpload"
import {connect} from "react-redux";
import * as COLORS from "../../constants/colors";
import {PRIMARY} from "../../constants/colors";
import {getYoutubeVideoId} from "../../util/functions";
var linkify = require('linkify-it')();
const React = require('react');

const styles = theme => ({
    root: {
        padding:0,
    },
    card: {
        width: '100%',
        margin: theme.spacing(.2)
    },
    cardHeader: {
        marginTop:0,
        marginBottom:0,
        paddingTop:0,
        paddingBottom: '0px !important',
        padding:0
    },
    cardContent: {
        marginTop:0,
        marginBottom:0,
        paddingTop:0,
        paddingBottom:'0px !important',
        padding:0,
    },
    cardContentLastChild: {
        paddingBottom: 0
    },
    cardMedia: {
        height: 0,
        paddingTop: '56.25%', // 16:9
    },
    postContent : {
        paddingTop: 0,
        paddingBottom: 0
    },
    listPadding : {
        paddingTop: 0,
        paddingBottom: 0
    },
    hidden: {
        display: 'none'
    },
    mediaPreview: {
        width: 40,
        height: 30,
        // padding: theme.spacing.unit * 2,
        backgroundColor: COLORS.MAIN_ICON,
    },
    bullet: {
        display: 'inline-block',
        margin: '0 2px',
        transform: 'scale(0.8)',
    },
    actions: {
        display: 'inherit',
        textAlign: 'center',
        padding: 0
    },
    title: {
        textTransform: 'uppercase'
    },
    pos: {
        marginBottom: 12,
    },
    inputRoot: {
        padding: '6px 4px 0px',
        display: 'flex',
        // alignItems: 'center',
        // width: 400,
    },
    input: {
        // marginLeft: theme.spacing(1),
        flex: 1,
        //borderWidth: 2,
        border: '1px solid #ddd;',
        boxShadow: 'inset 0 1px 8px -3px #ababab',
        width: '100%',
        minHeight: '70px'
    },
    icon: {
        color: PRIMARY,
        cursor: 'pointer'
    },
});

const INITIAL_STATE = {
    saving: false,
    media: null,
    mediaType: null,
    mediaName: null,
    firstLink: null,
};


const INITIAL_MESSAGE_STATE = {
    saving: false,
    media: null,
    mediaName: null,
    mediaUrl: null,
    medialLocation: null,
    firstLink: null,
    localFileAvailable:false,
    content: '',
    contentPreview: null,
    id:'newMessage'

};
class Message extends React.Component {

    constructor(props) {
        super(props);

        var readOnly = props.readOnly;
        if(props.message) {
            this.state = {content:props.message.content, mediaName: props.message.mediaName, imageUrl:props.message.mediaUrl, videoUrl:props.message.videoUrl, id:props.message.id, ...INITIAL_STATE, readOnly:readOnly}
        } else {
            this.state = {...INITIAL_MESSAGE_STATE, readOnly:readOnly};
        }
        this.deleteMessage = this.deleteMessage.bind(this);
        this.sendMessage = this.sendMessage.bind(this);
        this.sendMessageCallback = this.sendMessageCallback.bind(this);
        this.submitMessage = this.submitMessage.bind(this);
        this.onChange = this.onChange.bind(this);
        this.getUrlContentType = this.getUrlContentType.bind(this);
        this.contentChange = this.contentChange.bind(this);
        this.onUploadChange = this.onUploadChange.bind(this);
        this.loading = this.loading.bind(this);
    }

    submit = event => {
        event.preventDefault();
        this.setState({saving:true});
        if(this.props.onBeforeSend)
        {
            //create chat if it does not exists
            this.props.onBeforeSend(this.submitMessage);
        } else {
            this.submitMessage(true);
        }
    };

    submitMessage(create) {
        if(create) {
            if (this.state.media) {
                this.uploadMessageMedia()
            } else {
                this.sendMessage()
            }
        } else {
            this.setState({saving:false});
        }
    };



    sendMessage() {
        const {content, mediaUrl, mediaType, filename, mediaLocation} = this.state;
        const {currentUser, participant} = this.props;
        const currentDate = Date.now();
        const newMessage = {senderUsername: currentUser.username, senderId:currentUser.id, dateCreated:currentDate, receiverUsername:participant.username,
            receiverId:participant.userId};
        var messagePreview = content;
        if(content) {
            newMessage.content = content;
        }
        if(mediaUrl){
            newMessage.mediaUrl = mediaUrl;
            newMessage.mediaType = mediaType;
            messagePreview = mediaType + ' sent';
        }
        if(filename) {
            newMessage.mediaName = filename;
        }
        if(mediaLocation) {
            newMessage.mediaLocation = mediaLocation;
        }
        if(participant.profileImageUrl) {
            newMessage.receiverProfileImageUrl = participant.profileImageUrl;
        }
        if(currentUser.profileImageUrl) {
            newMessage.senderProfileImageUrl = currentUser.profileImageUrl;
        }

        this.props.sendMessage({message:newMessage, messagePreview:messagePreview, callback:this.sendMessageCallback});
    };

    sendMessageCallback(success) {
        this.setState({saving:false});
        if(success) {
            this.setState(INITIAL_MESSAGE_STATE)
            if(this.props.onSendComplete) {
                this.props.onSendComplete();
            }
        }
    }
    deleteMessage() {

    }


    uploadMessageMedia() {
        const {media, mediaName, mediaType, mediaInfo} = this.state;
        //const metadata = {name:media.name, size:media.size, type:media.type};
        const {currentUser} = this.props;
        const currentTime = Date.now();
        const that = this;
        const path = 'media/messages/'+currentUser.id;
        const filename = currentTime.toString() + mediaName;
        const fullPath =  path + '/' + filename;
        const dataUrlStringToReplace = "data:"+mediaInfo.type+";base64,";
        const mediaContent = mediaType === MEDIA_TYPE.IMAGE ? media.replace(dataUrlStringToReplace, "") : media.replace(dataUrlStringToReplace,"");
        const uploadTask = doUploadBase64File({fullPath: fullPath, file:mediaContent});
        uploadTask.on('state_changed',
            (snapshot) => {

            },
            (error) => {
                that.setState({ 'saving': false });
                that.props.openSnack({open:true,messageText:error.message,variant:'error'});
            },
            () => {
                uploadTask.snapshot.ref.getDownloadURL().then(
                    url => {
                        that.setState({'mediaUrl': url, 'filename': filename});
                        that.sendMessage();
                    }
                )
            }
        )
    }

    updatePostCallback(success) {
        this.setState({saving:false});
        if(success) {
            this.setState({readOnly:true});
        }
    }
    onChange = prop => event => {
        if(prop === 'content' && !this.state.localFileAvailable) {
            this.contentChange(event.target.value );
        }
        this.setState({ [prop]: event.target.value });
    };

    contentChange = (content) => {
        var links = linkify.match(content);
        if(links && links.length > 0) {
            if(this.state.firstLink){
                var found = links.filter(match => (match.url === this.state.firstLink));
                if(found && found.length > 0) {
                    return;
                }
                this.setState({firstLink:null, mediaType: null, mediaUrl:null, mediaLocation: null});
            }

            var i;
            for (i = 0; i < links.length; i++) {
                var link = links[i].url;
                var mediaUrl = null;
                var mediaType = null;
                var videoId = getYoutubeVideoId(link);
                if (videoId && videoId !== 'error') {
                    mediaUrl = 'https://www.youtube.com/embed/' + videoId;
                    mediaType = MEDIA_TYPE.VIDEO;
                    this.setState({firstLink:link, mediaType: mediaType, mediaUrl:mediaUrl, mediaLocation: MEDIA_LOCATION.YOU_TUBE, media:null});
                    return;
                } else {
                    this.getUrlContentType(link).then(result => console.log('found content type', result)).catch(error => console.log('failed', error));
                }
            }

        }
    };

    async getUrlContentType(url){
        try {
            const proxyurl = "https://cors-anywhere.herokuapp.com/";
            const response = await fetch(proxyurl + url);
            console.log(response);
            this.setState({});
            var contentType = response.headers.get('Content-Type');
            var mediaType = null;
            if(contentType.indexOf('image/') === 0) {
                mediaType = MEDIA_TYPE.IMAGE;
            } else if(contentType.indexOf('video/') === 0) {
                mediaType = MEDIA_TYPE.VIDEO;
            } else {
                return;
            }

            this.setState({firstLink:url, mediaType: mediaType, mediaUrl:url, mediaLocation: MEDIA_LOCATION.UNKNOWN});

        }catch(error) {
            debugger;
            console.log(error);
        }
    }

    onUploadChange = (fileObject) => {
        if(fileObject) {
            var mediaType = fileObject.info.type.indexOf('image/') === 0 ? MEDIA_TYPE.IMAGE : MEDIA_TYPE.VIDEO;
            this.setState({mediaInfo:fileObject.info, mediaName:fileObject.info.name, mediaType:mediaType, media:fileObject.src, mediaLocation: MEDIA_LOCATION.SOCIPOD_FIREBASE, firstLink:null, localFileAvailable:true});
        } else {
            this.setState({mediaInfo:null, mediaName:null, mediaType:null, media:null, mediaLocation:null, localFileAvailable:false, firstLink:null});
            this.contentChange(this.state.content);
        }
    };

    loading(isLoading) {
        this.setState({saving:isLoading})
    }

    render() {
        const {t, classes, message, messageId, participant, messageClass } = this.props;
        const {media, mediaUrl, mediaType, saving, readOnly, content, mediaName, mediaLocation} = this.state;
        const isInvalid = (content === '' && media === null) || participant === null || saving;
        return (
            <div>
                {readOnly ? <Card className={messageClass ? messageClass : classes.card}>

                        {message.mediaUrl && message.mediaType === MEDIA_TYPE.IMAGE &&
                        <CardMedia
                            component="img"
                            className={classes.media}
                            image={message.mediaUrl}
                        />
                        }
                        {message.mediaUrl && message.mediaType === MEDIA_TYPE.VIDEO &&
                        <span>
                                {message.mediaLocation ?
                                    <iframe title={message.mediaUrl}
                                        allowFullScreen
                                        className={classes.cardMediaYoutube}
                                        class="MuiCardMedia-root MuiCardMedia-media"
                                        width="100%"
                                        src={message.mediaUrl}
                                        controls
                                    />
                                    :
                                    <CardMedia
                                        component={"video"}
                                        controls
                                        allowfullscreen
                                        className={classes.cardMedia}
                                        src={message.mediaUrl}
                                    />
                                }
                            </span>
                        }
                        <CardContent classes={{root: classes.cardContent}}>
                            <Grid>
                                <Grid container>
                                    <Grid item xs={12}>
                                        <List classes={{padding: classes.listPadding}}>
                                            <ListItem classes={{root : classes.postContent}}>
                                                {message.text && <ListItemText primary={message.text}/>}
                                                {message.content && <ListItemText primary={message.content}/>}
                                            </ListItem>
                                        </List>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </CardContent>
                    </Card> :
                    <Card className={classes.cardEdit}>
                        {(media || mediaUrl) && mediaType === MEDIA_TYPE.IMAGE &&
                        <CardMedia
                            component="img"
                            className={classes.media}
                            image={media ? media : mediaUrl}
                        />
                        }
                        {(media || mediaUrl) && mediaType === MEDIA_TYPE.VIDEO &&
                        <span>
                                {mediaLocation ?
                                    <iframe title={media ? media.name : mediaUrl}
                                        allowFullScreen
                                        className={classes.cardMediaYoutube}
                                        class="MuiCardMedia-root MuiCardMedia-media"
                                        width="100%"
                                        height="250px"
                                        src={media ? media : mediaUrl}
                                        controls
                                    />
                                    :
                                    <CardMedia
                                        component={"video"}
                                        controls
                                        allowfullscreen
                                        className={classes.cardMedia}
                                        src={media ? media : mediaUrl}
                                    />
                                }
                            </span>
                        }
                        <CardContent classes={{root: classes.cardContent}}>
                            <Paper className={classes.inputRoot}>
                                <div style={{width: '100%'}}>
                                    <InputBase
                                        className={classes.input}
                                        placeholder={t('create message')}
                                        inputProps={{ 'aria-label': t('create message') }}
                                        value={content}
                                        multiline
                                        margin="normal"
                                        onChange={this.onChange('content')}
                                    />
                                    <FileUpload id={`${messageId}-file-input`} onChange={this.onUploadChange}
                                                loading={this.loading} isSaving={saving}
                                                accept="video/*, image/*" displayFileName={mediaName}/>
                                </div>
                                <IconButton edge="end" aria-label={t('buttons.send')}
                                            aria-hidden={false}
                                            disabled={isInvalid}
                                            onClick={this.submit}>
                                    <Send className={classes.icon}/>
                                </IconButton>
                            </Paper>
                        </CardContent>
                        {saving &&
                        <div className={classes.uploadProgress}>
                            <LinearProgress classes={{
                                colorPrimary: classes.linearColorPrimary,
                                barColorPrimary: classes.linearBarColorPrimary,
                            }}/>
                        </div>
                        }
                    </Card>
                }
            </div>


        )
    }

}

// function mapStateToProps({user}) {
//     return {user};
// }
export default compose (
    withRouter,
    withStyles(styles),
    withI18n(),
    connect(null, { sendMessage, doUploadBase64File, doUploadPostImage, getFileDownloadUrl, openSnack})
)(Message)