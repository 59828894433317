export const PRIMARY = '#1976d2'; //red
export const SECONDARY = '#0EAF33'; //green
export const TERTIARY = "#F7F72D"; //yellow
export const QUATERNARY = "black"; //black
export const QUINARY  = '#1976d2'; //blue
export const LOADING_MASK="#1976d2";
export const LINARY_LOADING_MASK='#1976d2';
export const LINARY_LOADING_MASK_BAR= '#0EAF33';
export const SWITCH = '#0EAF33'; //yellow
export const APP_BAR= "#1976d2";
export const APP_BAR_FONT= "#ffffff";
export const SUB_APP_BAR="#696969";  // green : subtab
export const MAIN_ICON="white"; // white
export const DRAWER_ICON="#1976d2"; // white
export const STAR_ICON="gold"; // white
export const SECONDARY_ICON="black"; // black
export const ACCEPT = "#0EAF33"; //green
export const SEND = "#1976d2"; //blue
export const DECLINE = "#f44336";  // red
export const CHIP = "#1976d2";  // blue
export const CHIP_LIGHT = "#a6bdea";  // red
export const MESSAGE_CURRENT_USER = "#2784db";  // green
export const MESSAGE_OTHER_USER = "#fdfdfd";  // red
export const HEADER = "#1976d2";  // blue
export const FOOTER = "#1976d2";  // blue

//badges
export const BADGE_ADMIN="#0EAF33";
export const BADGE_PROFESSOR="#c9962e";
export const BADGE_PARENT="#d914b8";
export const BADGE_STUDENT="#1976d2";


//Custom Editor

export const EDITOR_BORDER = "#696969";
export const EDITOR_BUTTONS_BACKGROUND = "#A9A9A9";

export const GREEN = "#0EAF33";
export const WHITE = "#ffffff";
export const RED = "#f44336";
export const BLUE = "#1976d2";
export const INFO = "#1976d2";
export const WARNING = "#ffcc00";
export const LOCK = "#ffd359";
export const ORANGE = "#c9962e";
export const RADIO_BUTTON_SELECT =  "#0EAF33";
export const LOW_GRADE = "#ffcc00";
export const RATINGS_FILLED = '#ff6d75';
export const RATINGS_HOVER = '#ff3d47';
