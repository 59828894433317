import { withI18n } from "react-i18next";
import { withStyles } from '@material-ui/core/styles';
import { Info, Edit } from '@material-ui/icons';
import {compose} from "recompose";
import {Avatar, Card, CardMedia, Grid, List, ListItem, ListItemIcon, ListItemSecondaryAction, ListItemText, Tooltip} from '@material-ui/core';
import * as COLORS from "../../constants/colors";
import UploadCropImage from "../UploadCropImage";
import UserAvatar from "../UserAvatar";
import {connect} from "react-redux";
import { withRouter } from 'react-router-dom';
import {RIEInput, RIENumber, RIETextArea, RIESelect, RIEToggle, RIETags} from 'riek'
import {updateDocumentAttribute} from '../../actions'
import {
    DIFFICULTY_LEVEL,
    GRADE_LEVEL,
    IMAGE_CROP_TYPE, SKILLS,
    SUBJECT
} from "../../constants/common";
import TimeAgo from 'react-timeago';
import {getJSDate} from "../../util/functions";

const React = require('react');

const styles = theme => ({
    root: {
        flexGrow: 1,
        spacing: '40',
        backgroundColor: theme.palette.background.paper,
    },
    progressRoot: {
        flexGrow: 1,
        spacing: '40'
    },
    details: {
        width: '100%',
        maxWidth: 600,
        backgroundColor: theme.palette.background.paper,
    },
    progress: {
        margin: theme.spacing(2),
        color: COLORS.LOADING_MASK
    },
    chipsRoot : {
        display: 'flex',
        justifyContent: 'center',
        flexWrap: 'wrap',
    },
    chip: {
        margin: theme.spacing(1),
    },
    actions: {
        display: 'inherit',
    },
    media: {
        height: 100,
        paddingTop: '75.25%', // 16:9
    },
    listLabel: {
        textTransform: 'capitalize',
        width: 150,
        display: 'block',
        textAlign : 'right'

    },
    listText: {
        marginLeft: theme.spacing(.5)
    },
    avatar: {
        backgroundColor: COLORS.MAIN_ICON,
    },
    infoAvatar: {
        color: COLORS.INFO,
    },
    verifiedAvatar: {
        color: COLORS.GREEN,
    },
    unverifiedAvatar: {
        color: COLORS.ORANGE,
    },
    success : {
        color: 'green'
    }
});

class LessonInfo extends React.Component {
    constructor(props) {
        super(props);
        let subjects = [];
        let gradeLevels = [];
        let difficultyLevels = [];
        let skills = [];
        if (props.canEdit) {
            SUBJECT.map(subject =>
                subjects.push({id:subject, text:props.t('subject.'+subject)})
            )
            GRADE_LEVEL.map(level =>
                gradeLevels.push({id:level, text:props.t('grade.'+level)})
            )

            DIFFICULTY_LEVEL.map(level =>
                difficultyLevels.push({id:level, text:props.t(level)})
            )
            SKILLS[this.props.lesson.gradeLevel].map(skill =>
                skills.push({id:skill, text:this.props.t(skill)})
            )
        }
        this.state = {
            loading: true,
            imageHash: Date.now(),
            subjects:subjects,
            gradeLevels: gradeLevels,
            difficultyLevels: difficultyLevels,
            skills:skills,
        };
        this.dataChanged = this.dataChanged.bind(this);
        this.validateText = this.validateText.bind(this);
        this.updateDocumentAttributeCallback = this.updateDocumentAttributeCallback.bind(this);
        this.updateSkill = this.updateSkill.bind(this);

    }

    componentDidMount() {
        this.setState({loading: true});
        this.setState({imageCropTitle: this.props.t('profile image crop title')});
        this.setState({imageCropDescription: this.props.t('profile image crop description')});
        this.handleClickOpen = this.handleClickOpen.bind(this);
        this.updateHash = this.updateHash.bind(this);
        this.setState({loading: false});

    }

    handleClickOpen = () => {
        console.log("handleClickOpen clicked");
        this.setState({ imageCropOpen: true });
    };
    dataChanged(data) {
        const {path, currentUser} = this.props;
        this.props.updateDocumentAttribute({path: path, ...data, currentUsername:currentUser.username, currentUserId:currentUser.id, callback:this.updateDocumentAttributeCallback});
    }

    updateDocumentAttributeCallback(success, data) {
        if(success) {
            if(!this.updateSkill(data) &&  this.props.updateSuccess){
                this.props.updateSuccess();
            }
        }
    }
    updateHash(){
        if(!this.props.lesson.profileImageUrl && this.props.updateSuccess) {
            this.props.updateSuccess();
        };
        this.setState({imageHash: Date.now()});
    }
    validateText(text, limit) {
        return (text.length > 0 && text.length < limit);
    }
    updateSkill(data) {
        if(data.attribute == 'gradeLevel') {
            let skills = [];
            let gradeSkills = SKILLS[data.value];
            gradeSkills.map(skill =>
                skills.push({id:skill, text:this.props.t(skill)})
            )

            if(this.props.lesson.skill && !gradeSkills.includes(this.props.lesson.skill)){
                this.dataChanged({value:skills[0].id, attribute:"skill"});
                this.setState({skills:skills})
                return true;
            } else {
                this.setState({skills:skills})
                return false;
            }
        }
        return false;
    }
    validateLexile(value) {
        if(value == 0 || value == null) {
            return true;
        }
        if(value >= 5 && value <= 2000 && value % 5 === 0 ) {
            return true;
        }

        return false;
    }
    updateHash = () => {
        this.setState({imageHash: Date.now()});
    };
    render() {
        const {lesson, currentUser, t, classes, canEdit} = this.props;
        const {imageHash} = this.state;
        return (<Grid  item container spacing={2}>
                    <Grid item xs={12} md={6}>
                        {lesson &&
                            <div className={classes.root}>
                                {canEdit ?
                                    <List className={classes.details}>
                                        <ListItem>
                                            <ListItemIcon className={classes.listLabel}>
                                                {t('fields.avatar')}:
                                            </ListItemIcon>
                                            <ListItemText id="avatar" className={classes.listText}><Avatar
                                                src={lesson && lesson.profileImageUrl}/></ListItemText>
                                            <ListItemSecondaryAction>
                                                <UploadCropImage currentUser={currentUser} icon={<Edit/>}
                                                                 title={t('image crop title')}
                                                                 description={t('image crop description')}
                                                                 typeId={lesson.id} type={IMAGE_CROP_TYPE.LESSON}
                                                                 isNew={lesson && lesson.profileImageUrl ? false : true}
                                                                 updateHash={this.updateHash}
                                                                 dataPath={this.props.path}
                                                />
                                            </ListItemSecondaryAction>
                                        </ListItem>
                                        <ListItem>
                                            <ListItemIcon className={classes.listLabel}>
                                                {t('fields.title')}:
                                            </ListItemIcon>
                                            <ListItemText id="lesson-title" className={classes.listText}>
                                                <RIEInput
                                                    ref="titleEdit"
                                                    maxLength={80}
                                                    validate={(text) => this.validateText(text, 80)}
                                                    activeClassName="editing"
                                                    value={lesson.title}
                                                    propName="title"
                                                    change={(data) => this.dataChanged({
                                                        value: data.title,
                                                        attribute: "title"
                                                    })}
                                                />
                                            </ListItemText>
                                            <ListItemSecondaryAction>
                                                <Tooltip title={t('inline edit instruction')}
                                                         aria-label={t('inline edit instruction')}>
                                                    <Info className={classes.infoAvatar}/>
                                                </Tooltip>
                                            </ListItemSecondaryAction>
                                        </ListItem>
                                        <ListItem>
                                            <ListItemIcon className={classes.listLabel}>
                                                {t('fields.description')}:
                                            </ListItemIcon>
                                            <ListItemText id="lesson-description" className={classes.listText}>
                                                <RIETextArea
                                                    ref="descriptionEdit"
                                                    maxLength={200}
                                                    validate={(text) => this.validateText(text, 200)}
                                                    value={lesson.description}
                                                    propName="description"
                                                    change={(data) => this.dataChanged({
                                                        value: data.description,
                                                        attribute: "description"
                                                    })}

                                                />
                                                <ListItemSecondaryAction>
                                                    <Tooltip title={t('inline edit instruction')}
                                                             aria-label={t('inline edit instruction')}>
                                                        <Info className={classes.infoAvatar}/>
                                                    </Tooltip>
                                                </ListItemSecondaryAction>
                                            </ListItemText>
                                        </ListItem>
                                        <ListItem>
                                            <ListItemIcon className={classes.listLabel}>
                                                {t('fields.subject')}:
                                            </ListItemIcon>
                                            <ListItemText id="lesson-edit-subject" className={classes.listText}>
                                                <RIESelect
                                                    ref="subjectEdit"
                                                    activeClassName="editing"
                                                    value={{id:lesson.subject, text:t('subject.'+lesson.subject)}}
                                                    propName="subject"
                                                    change={(data) => this.dataChanged({
                                                        value: data.subject.id,
                                                        attribute: "subject"
                                                    })}
                                                    options={this.state.subjects}
                                                />
                                            </ListItemText>
                                            <ListItemSecondaryAction>
                                                <Tooltip title={t('inline edit instruction')}
                                                         aria-label={t('inline edit instruction')}>
                                                    <Info className={classes.infoAvatar}/>
                                                </Tooltip>
                                            </ListItemSecondaryAction>
                                        </ListItem>
                                        <ListItem>
                                            <ListItemIcon className={classes.listLabel}>
                                                {t('fields.topic')}:
                                            </ListItemIcon>
                                            <ListItemText id="lesson-info-edit-topic" className={classes.listText}>
                                                <RIEInput
                                                    ref="topicEdit"
                                                    maxLength={40}
                                                    validate={(text) => this.validateText(text, 40)}
                                                    activeClassName="editing"
                                                    value={lesson.topic ? lesson.topic : t('enter value')}
                                                    propName="topic"
                                                    change={(data) => this.dataChanged({
                                                        value: data.topic,
                                                        attribute: "topic"
                                                    })}
                                                />
                                            </ListItemText>
                                            <ListItemSecondaryAction>
                                                <Tooltip title={t('inline edit instruction')}
                                                         aria-label={t('inline edit instruction')}>
                                                    <Info className={classes.infoAvatar}/>
                                                </Tooltip>
                                            </ListItemSecondaryAction>
                                        </ListItem>
                                        <ListItem>
                                            <ListItemIcon className={classes.listLabel}>
                                                {t('fields.grade level')}:
                                            </ListItemIcon>
                                            <ListItemText id="lesson-info-edit-grade-level" className={classes.listText}>
                                                <RIESelect
                                                    ref="subjectEdit"
                                                    activeClassName="editing"
                                                    value={{id:lesson.gradeLevel, text:t('grade.'+lesson.gradeLevel)}}
                                                    propName="gradeLevel"
                                                    change={(data) => this.dataChanged({
                                                        value: data.gradeLevel.id,
                                                        attribute: "gradeLevel"
                                                    })}
                                                    options={this.state.gradeLevels}
                                                />
                                            </ListItemText>
                                            <ListItemSecondaryAction>
                                                <Tooltip title={t('inline edit instruction')}
                                                         aria-label={t('inline edit instruction')}>
                                                    <Info className={classes.infoAvatar}/>
                                                </Tooltip>
                                            </ListItemSecondaryAction>
                                        </ListItem>

                                        <ListItem>
                                            <ListItemIcon className={classes.listLabel}>
                                                {t('fields.skill')}:
                                            </ListItemIcon>
                                            <ListItemText id="lesson-info-edit-skill" className={classes.listText}>
                                                <RIESelect
                                                    ref="skillEdit"
                                                    activeClassName="editing"
                                                    value={{id:lesson.skill ? lesson.skill : 0 , text: lesson.skill ? t(lesson.skill) : t('select value')}}
                                                    propName="skill"
                                                    change={(data) => this.dataChanged({
                                                        value: data.skill.id,
                                                        attribute: "skill"
                                                    })}
                                                    options={this.state.skills}
                                                />
                                            </ListItemText>
                                            <ListItemSecondaryAction>
                                                <Tooltip title={t('inline edit instruction')}
                                                         aria-label={t('inline edit instruction')}>
                                                    <Info className={classes.infoAvatar}/>
                                                </Tooltip>
                                            </ListItemSecondaryAction>
                                        </ListItem>
                                        <ListItem>
                                            <ListItemIcon className={classes.listLabel}>
                                                {t('fields.sub skill')}:
                                            </ListItemIcon>
                                            <ListItemText id="lesson-info-edit-sub-skill" className={classes.listText}>
                                                <RIEInput
                                                    ref="subSkillEdit"
                                                    maxLength={40}
                                                    validate={(text) => this.validateText(text, 40)}
                                                    activeClassName="editing"
                                                    value={lesson.topic ? lesson.topic : t('enter value')}
                                                    propName="subSkill"
                                                    change={(data) => this.dataChanged({
                                                        value: data.subSkill,
                                                        attribute: "subSkill"
                                                    })}
                                                />
                                            </ListItemText>
                                            <ListItemSecondaryAction>
                                                <Tooltip title={t('inline edit instruction')}
                                                         aria-label={t('inline edit instruction')}>
                                                    <Info className={classes.infoAvatar}/>
                                                </Tooltip>
                                            </ListItemSecondaryAction>
                                        </ListItem>
                                        <ListItem>
                                            <ListItemIcon className={classes.listLabel}>
                                                {t('fields.reading lexile level')}:
                                            </ListItemIcon>
                                            <ListItemText id="lesson-info-edit-reading-level" className={classes.listText}>
                                                <RIENumber
                                                    ref="lexileLevelEdit"
                                                    validate={this.validateLexile}
                                                    activeClassName="editing"
                                                    value={lesson.readingLexileLevel ? lesson.readingLexileLevel+"L" : t('enter value')}
                                                    propName="readingLexileLevel"
                                                    change={(data) => this.dataChanged({
                                                        value: data.readingLexileLevel,
                                                        attribute: "readingLexileLevel"
                                                    })}
                                                />
                                            </ListItemText>
                                            <ListItemSecondaryAction>
                                                <Tooltip title={t('inline edit instruction')}
                                                         aria-label={t('inline edit instruction')}>
                                                    <Info className={classes.infoAvatar}/>
                                                </Tooltip>
                                            </ListItemSecondaryAction>
                                        </ListItem>
                                        <ListItem>
                                            <ListItemIcon className={classes.listLabel}>
                                                {t('fields.difficulty')}:
                                            </ListItemIcon>
                                            <ListItemText id="lesson-edit-difficultyLevel" className={classes.listText}>
                                                <RIESelect
                                                    ref="difficultyLevelEdit"
                                                    activeClassName="editing"
                                                    value={{id:lesson.difficultyLevel, text:t(lesson.difficultyLevel)}}
                                                    propName="difficultyLevel"
                                                    change={(data) => this.dataChanged({
                                                        value: data.difficultyLevel.id,
                                                        attribute: "difficultyLevel"
                                                    })}
                                                    options={this.state.difficultyLevels}
                                                />
                                            </ListItemText>
                                            <ListItemSecondaryAction>
                                                <Tooltip title={t('inline edit instruction')}
                                                         aria-label={t('inline edit instruction')}>
                                                    <Info className={classes.infoAvatar}/>
                                                </Tooltip>
                                            </ListItemSecondaryAction>
                                        </ListItem>
                                        <ListItem>
                                            <ListItemIcon className={classes.listLabel}>
                                                {t('fields.private')}:
                                            </ListItemIcon>
                                            <ListItemText id="lesson-info-private" className={classes.listText}>
                                                <RIEToggle
                                                    value={lesson.isPrivate}
                                                    className={this.state.highlight ? "editable" : ""}
                                                    change={(data) => this.dataChanged({
                                                        value: data.isPrivate,
                                                        attribute: "isPrivate"
                                                    })}
                                                    propName="isPrivate"
                                                    textTrue={t('fields.true')}
                                                    textFalse={t('fields.false')}
                                                   />
                                            </ListItemText>
                                            <ListItemSecondaryAction>
                                                <Tooltip title={t('inline edit instruction')}
                                                         aria-label={t('inline edit instruction')}>
                                                    <Info className={classes.infoAvatar}/>
                                                </Tooltip>
                                            </ListItemSecondaryAction>
                                        </ListItem>
                                        {/*<ListItem>*/}
                                            {/*<ListItemIcon className={classes.listLabel}>*/}
                                                {/*{t('tags')}:*/}
                                            {/*</ListItemIcon>*/}
                                            {/*<ListItemText id="lesson-info-edit-tags" className={classes.listText}>*/}
                                                {/*<RIETags*/}
                                                    {/*value={lesson.tags? lesson.tags : [t('subject.'+lesson.subject)]}*/}
                                                    {/*change={(data) => this.dataChanged({*/}
                                                        {/*value: data.tags,*/}
                                                        {/*attribute: "tags"*/}
                                                    {/*})}*/}
                                                    {/*maxTags={10}*/}
                                                    {/*minTags={1}*/}
                                                    {/*propName="tags"*/}
                                                    {/*className={this.state.highlight ? "tags editable" : "tags"}*/}
                                                    {/*/>*/}
                                            {/*</ListItemText>*/}
                                            {/*<ListItemSecondaryAction>*/}
                                                {/*<Tooltip title={t('inline edit instruction')}*/}
                                                         {/*aria-label={t('inline edit instruction')}>*/}
                                                    {/*<Info className={classes.infoAvatar}/>*/}
                                                {/*</Tooltip>*/}
                                            {/*</ListItemSecondaryAction>*/}
                                        {/*</ListItem>*/}
                                        <ListItem>
                                            <ListItemIcon className={classes.listLabel}>
                                                {t('fields.published')}:
                                            </ListItemIcon>
                                            <ListItemText id="lesson-info-edit-published"
                                                          primary={lesson.published ? t('fields.true') : t('fields.false')}
                                                          className={classes.listText}/>
                                        </ListItem>
                                        <ListItem>
                                            <ListItemIcon className={classes.listLabel}>
                                                {t('fields.created by')}:
                                            </ListItemIcon>
                                            <ListItemText id="lesson-info-edit-created-by" primary={lesson.createdByUsername}
                                                          className={classes.listText}/>
                                            <ListItemSecondaryAction>
                                                <UserAvatar username={lesson.createdByUsername}
                                                            userId={lesson.createdById}/>
                                            </ListItemSecondaryAction>
                                        </ListItem>
                                        <ListItem>
                                            <ListItemIcon className={classes.listLabel}>
                                                {t('created')}:
                                            </ListItemIcon>
                                            <ListItemText id="lesson-info-edit-date-created" primary={<TimeAgo date={getJSDate(lesson.dateCreated)}/>}
                                                          className={classes.listText}/>
                                        </ListItem>
                                        {lesson.updatedById && <ListItem>
                                            <ListItemIcon className={classes.listLabel}>
                                                {t('updated by')}:
                                            </ListItemIcon>
                                            <ListItemText id="lesson-info-updated-by" primary={lesson.updatedByUsername}
                                                          className={classes.listText}/>
                                            <ListItemSecondaryAction>
                                                <UserAvatar username={lesson.updatedByUsername}
                                                            userId={lesson.updatedById}/>
                                            </ListItemSecondaryAction>
                                        </ListItem>
                                        }
                                        <ListItem>
                                            <ListItemIcon className={classes.listLabel}>
                                                {t('updated')}:
                                            </ListItemIcon>
                                            <ListItemText id="lesson-info-edit-date-updated" primary={<TimeAgo date={getJSDate(lesson.dateUpdated)}/>}
                                                          className={classes.listText}/>
                                        </ListItem>
                                    </List>
                                    :
                                    <List className={classes.details}>
                                        <ListItem>
                                            <ListItemIcon className={classes.listLabel}>
                                                {t('fields.title')}:
                                            </ListItemIcon>
                                            <ListItemText className={classes.listText} id="lesson-info-title"
                                                          primary={lesson.title}/>
                                        </ListItem>
                                        <ListItem>
                                            <ListItemIcon className={classes.listLabel}>
                                                {t('fields.description')}:
                                            </ListItemIcon>
                                            <ListItemText id="lesson-info-description" primary={lesson.description}
                                                          className={classes.listText}/>
                                        </ListItem>
                                        <ListItem>
                                            <ListItemIcon className={classes.listLabel}>
                                                {t('fields.subject')}:
                                            </ListItemIcon>
                                            <ListItemText id="lesson-info-subject" primary={t('subject.'+lesson.subject)}
                                                          className={classes.listText}/>
                                        </ListItem>
                                        <ListItem>
                                            <ListItemIcon className={classes.listLabel}>
                                                {t('fields.topic')}:
                                            </ListItemIcon>
                                            <ListItemText id="lesson-info-topic" primary={lesson.topic}
                                                          className={classes.listText}/>
                                        </ListItem>
                                        <ListItem>
                                            <ListItemIcon className={classes.listLabel}>
                                                {t('fields.grade level')}:
                                            </ListItemIcon>
                                            <ListItemText id="lesson-info-grade-level" primary={t('grade.'+lesson.gradeLevel)}
                                                          className={classes.listText}/>
                                        </ListItem>
                                        <ListItem>
                                            <ListItemIcon className={classes.listLabel}>
                                                {t('fields.skill')}:
                                            </ListItemIcon>
                                            <ListItemText id="lesson-skill" primary={t(lesson.skill)}
                                                          className={classes.listText}/>
                                        </ListItem>
                                        <ListItem>
                                            <ListItemIcon className={classes.listLabel}>
                                                {t('fields.sub skill')}:
                                            </ListItemIcon>
                                            <ListItemText id="lesson-sub-skill" primary={t(lesson.subSkill)}
                                                          className={classes.listText}/>
                                        </ListItem>
                                        <ListItem>
                                            <ListItemIcon className={classes.listLabel}>
                                                {t('fields.reading lexile level')}:
                                            </ListItemIcon>
                                            <ListItemText id="lesson-reading-level" primary={lesson.readingLexileLevel ? lesson.readingLexileLevel+"L" : ""}
                                                          className={classes.listText}/>
                                        </ListItem>
                                        <ListItem>
                                            <ListItemIcon className={classes.listLabel}>
                                                {t('fields.difficulty')}:
                                            </ListItemIcon>
                                            <ListItemText id="lesson-info-difficulty" primary={t(lesson.difficultyLevel)}
                                                          className={classes.listText}/>
                                        </ListItem>
                                        <ListItem>
                                            <ListItemIcon className={classes.listLabel}>
                                                {t('fields.private')}:
                                            </ListItemIcon>
                                            <ListItemText id="lesson-info-private"
                                                          primary={lesson.isPrivate ? t('fields.true') : t('fields.false')}
                                                          className={classes.listText}/>
                                        </ListItem>
                                        <ListItem>
                                            <ListItemIcon className={classes.listLabel}>
                                                {t('fields.published')}:
                                            </ListItemIcon>
                                            <ListItemText id="lesson-info-published"
                                                          primary={lesson.published ? t('fields.true') : t('fields.false')}
                                                          className={classes.listText}/>
                                        </ListItem>
                                        <ListItem>
                                            <ListItemIcon className={classes.listLabel}>
                                                {t('fields.created by')}:
                                            </ListItemIcon>
                                            <ListItemText id="lesson-info-created-by" primary={lesson.createdByUsername}
                                                          className={classes.listText}/>
                                            <ListItemSecondaryAction>
                                                <UserAvatar username={lesson.createdByUsername}
                                                            userId={lesson.createdById}/>
                                            </ListItemSecondaryAction>
                                        </ListItem>
                                        <ListItem>
                                            <ListItemIcon className={classes.listLabel}>
                                                {t('created')}:
                                            </ListItemIcon>
                                            <ListItemText id="lesson-info-date-created" primary={<TimeAgo date={getJSDate(lesson.dateCreated)}/>}
                                                          className={classes.listText}/>
                                        </ListItem>
                                        {lesson.updatedById && <ListItem>
                                            <ListItemIcon className={classes.listLabel}>
                                                {t('updated by')}:
                                            </ListItemIcon>
                                            <ListItemText id="lesson-info-updated-by" primary={lesson.updatedByUsername}
                                                          className={classes.listText}/>
                                            <ListItemSecondaryAction>
                                                <UserAvatar username={lesson.updatedByUsername}
                                                            userId={lesson.updatedById}/>
                                            </ListItemSecondaryAction>
                                        </ListItem>
                                        }
                                        <ListItem>
                                            <ListItemIcon className={classes.listLabel}>
                                                {t('updated')}:
                                            </ListItemIcon>
                                            <ListItemText id="lesson-info-date-updated" primary={<TimeAgo date={getJSDate(lesson.dateUpdated)}/>}
                                                          className={classes.listText}/>
                                        </ListItem>
                                    </List>
                                }
                            </div>
                        }
                    </Grid>
                <Grid item xs={12} md={3}>
                    {lesson && lesson.profileImageUrl && <Card>
                        <CardMedia
                            className={classes.media}
                            image={`${lesson.profileImageUrl}/${imageHash}`}
                        />
                    </Card> }
                </Grid>
            </Grid>
        )
    }
}
const mapStateToProps = ({user}) => {
    return {currentUser:user}
};

export default compose (
    withStyles(styles),
    withI18n(),
    withRouter,
    connect(mapStateToProps, {updateDocumentAttribute})
)(LessonInfo)