import { withI18n } from "react-i18next";
import { withStyles } from '@material-ui/core/styles';
import { withRouter } from 'react-router-dom';
import { ReactSVG } from 'react-svg'
import {compose} from "recompose";
import {GridList, GridListTile, GridListTileBar, IconButton, ListSubheader, Typography, CircularProgress, ListItem, ListItemText, ListItemSecondaryAction} from '@material-ui/core';
import {InfoIcon, CheckBox, CloudUpload} from '@material-ui/icons';
import {connect} from "react-redux";
import TimeAgo from 'react-timeago';
import InfiniteScroll from 'react-infinite-scroller';
import {doUploadBase64File, fetchImages, dispathAction, doUploadDataUrl} from "../../actions";
import FileUpload from "../FileUpload";
import {MEDIA_LOCATION, MEDIA_TYPE, QUERY_IMAGE_LIMIT} from "../../constants/common";
import * as ACTIONS from "../../constants/action-types";
import {PRIMARY} from "../../constants/colors";


const React = require('react');

const styles = theme => ({
    root: {
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'space-around',
        overflow: 'hidden',
        backgroundColor: theme.palette.background.paper,
    },
    gridList: {
        width: 500,
        height: 450,
    },
    icon: {
        color: PRIMARY,
        cursor: 'pointer'
    },
});



class ImageGrid extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            loading: false,
            saving: false,
            hasMore: false,
            loaded: false,
            nextPageToken: null,
            mediaInfo:null,
            mediaName:null,
            mediaType:null,
            media:null,
            mediaLocation: null

        };
        this.loadMore = this.loadMore.bind(this);
        this.fetchImagesCallback = this.fetchImagesCallback.bind(this);
        this.uploadMedia = this.uploadMedia.bind(this);
        this.onUploadChange = this.onUploadChange.bind(this);
    }

    componentDidMount() {
        const {images} =  this.props;
        var imageList = images && images[this.props.folderId];
        if(imageList) {
            this.setState({loaded: true});
        } else {
            this.props.fetchImages({path:this.props.path, limit:QUERY_IMAGE_LIMIT, id: this.props.folderId, callback:this.fetchImagesCallback});
        }

    }

    loadMore = () => {
        this.setState({hasMore: false});
        const {images, folderId, path} =  this.props;
        var imageList = images[folderId];
        var last = imageList ? imageList[imageList.length - 1] : null;
        if(last) {
            this.props.fetchImages({path:path, limit:QUERY_IMAGE_LIMIT, nextPageToken: this.state.nextPageToken, id:folderId, callback:this.fetchImagesCallback});
        }
    };
    onUploadChange = (fileObject) => {
        debugger;
        if(fileObject) {
            var mediaType = fileObject.info.type.indexOf('image/') === 0 ? MEDIA_TYPE.IMAGE : MEDIA_TYPE.VIDEO;
            this.setState({mediaInfo:fileObject.info, mediaName:fileObject.info.name, mediaType:mediaType, media:fileObject.src, mediaLocation: MEDIA_LOCATION.SOCIPOD_FIREBASE});
        } else {
            this.setState({mediaInfo:null, mediaName:null, mediaType:null, media:null, mediaLocation: null});
            this.contentChange(this.state.content);
        }
    };
    uploadMedia() {
        debugger;
        const {media, mediaName, mediaType, mediaInfo} = this.state;
        const {path, folderId} = this.props;
        //const metadata = {name:media.name, size:media.size, type:media.type};
        const currentTime = Date.now();
        const that = this;
        const filename = mediaName;
        const fullPath =  path + '/'+ folderId + '/' + filename;
        const dataUrlStringToReplace = "data:"+mediaInfo.type+";base64,";
      //  const mediaContent = mediaType === MEDIA_TYPE.IMAGE ? media.replace(dataUrlStringToReplace, "") : media.replace(dataUrlStringToReplace,"");
        const uploadTask = doUploadDataUrl({fullPath: fullPath, file:media});
        uploadTask.on('state_changed',
            (snapshot) => {

            },
            (error) => {
                that.setState({ 'saving': false });
                that.props.openSnack({open:true,messageText:error.message,variant:'error'});
            },
            () => {
                uploadTask.snapshot.ref.getDownloadURL().then(
                    url => {
                        that.props.onImageSelected({'mediaLink': url, name:filename, 'name': filename});
                        debugger;
                        that.props.dispathAction({
                            type: ACTIONS.ADD_IMAGES,
                            payload: [{mediaLink:url, id:filename, name:filename}],
                            top:true
                        })
                    }
                )
            }
        )
    }
    fetchImagesCallback = (count, nextPageToken) =>{
        var hasMore =  count === QUERY_IMAGE_LIMIT;
        this.setState({hasMore: hasMore, loaded: true, nextPageToken:nextPageToken});
    }
    render() {
        const {t, classes, images, folderId, loading} = this.props;
        const imageDataList = images[folderId] ? images[folderId] : [];
        return (loading ? <div align="center" className={classes.progressRoot}> <CircularProgress className={classes.progress} size={30} thickness={3} /></div>
                :
                <div align="center" class="momentumScroll">
                    <ListItem key="upload-line-item">
                        <ListItemText id="upload-line-item-text">
                            <FileUpload id={`file-input`} onChange={this.onUploadChange}
                                        isSaving={this.state.saving}
                                        accept="image/svg" displayFileName={null} displayInput={true}/>
                        </ListItemText>
                        <ListItemSecondaryAction>
                            <IconButton onClick={this.uploadMedia} disabled={this.state.media === null}>
                                <CloudUpload />
                            </IconButton>
                        </ListItemSecondaryAction>
                    </ListItem>
                    <GridList  key="Subheader" cols={2} style={{ height: 'auto'}}>
                        <GridListTile key="Subheader" cols={2} style={{ height: 'auto' }}>
                            <ListSubheader component="div">{t('current uploads')}</ListSubheader>
                        </GridListTile>
                        {imageDataList.length == 0 && <Typography>{t('no results')}</Typography> }
                        {imageDataList.map(imageData => (
                            <GridListTile key={imageData.img}>
                                <img src={imageData.mediaLink} alt={imageData.name}/>
                                <GridListTileBar
                                    title={imageData.name}
                                    actionIcon={
                                        <IconButton aria-label={t('select')} className={classes.icon} onClick={()=>this.props.onImageSelected(imageData)}>
                                            <CheckBox />
                                        </IconButton>
                                    }
                                />
                            </GridListTile>
                        ))}
                    </GridList>
                </div>
        )
    }
}

const mapStateToProps = ({images}) => {
    return {images:images};
};



export default compose (
    withRouter,
    withStyles(styles),
    withI18n(),
    connect(mapStateToProps, {fetchImages, dispathAction, doUploadBase64File, doUploadDataUrl})
)(ImageGrid)
