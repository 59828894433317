import { withI18n } from "react-i18next";
import { withStyles } from '@material-ui/core/styles';
import { Link, withRouter } from 'react-router-dom';
import {compose} from "recompose";

import {Add, Settings, Search} from '@material-ui/icons';
import {Button, CircularProgress, FormControl, InputLabel, Grid, IconButton, Menu, MenuItem, Select, Typography} from '@material-ui/core';
import * as ROLES from "../../constants/roles";
import * as COLORS from "../../constants/colors";
import {CREATE_LESSON} from '../../constants/routes';
import {connect} from "react-redux";
import {fetchLessons, removeLessonsListeners} from "../../actions";
import LessonTable from "../LessonTable";
import {
    ACCESS_LEVEL,
    ALL,
    PATHS,
    SUBJECT,
    GRADE_LEVEL,
    DIFFICULTY_LEVEL,
    QUERY_LIMIT,
    LIMITS
} from "../../constants/common";

const React = require('react');

const styles = theme => ({
    container: {
        flexGrow: 1,
    },
    dataTable : {
        color:'inherit',
        backgroundColor: 'inhert'
    },
    progressRoot: {
        flexGrow: 1,
        spacing: '40'
    },
    progress: {
        margin: theme.spacing(2),
        color: COLORS.LOADING_MASK
    },
    pageActions : {
        paddingTop : 0,
        color: COLORS.PRIMARY
    },
    subActions : {
        color: COLORS.WHITE,
        backgroundColor:COLORS.PRIMARY,
    },
    card: {
        width: 300,
        margin: theme.spacing(2),
    },
    bullet: {
        display: 'inline-block',
        margin: '0 2px',
        transform: 'scale(0.8)',
    },
    actions: {
        display: 'inherit',
    },
    media: {
        paddingTop: '75.25%', // 16:9
    },
    title: {
        textTransform: 'uppercase'
    },
    capital: {
        textTransform: "capitalize"
    },
    pos: {
        marginBottom: 12,
    },
    expand: {
        transform: 'rotate(0deg)',
        marginLeft: 'auto',
        transition: theme.transitions.create('transform', {
            duration: theme.transitions.duration.shortest,
        }),
    },
    expandOpen: {
        transform: 'rotate(180deg)',
    },
    avatar: {
        backgroundColor: COLORS.SECONDARY_ICON,
    },
    accept: {
        color: COLORS.ACCEPT,
    },
    decline: {
        color: COLORS.DECLINE,
    },
    buttonAccept: {
        backgroundColor: COLORS.ACCEPT,
        color: 'white'
    },
    buttonDecline: {
        backgroundColor: COLORS.DECLINE,
        color: 'white'
    },
    description : {
        whiteSpace: 'pre-wrap'
    },
    gray : {
        color: 'gray'
    },
    lessons : {
        width: '90%'
    },
    formControl: {
        margin: theme.spacing(1),
        minWidth: 120,
    },
});

class Library extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            loading:true,
            actionAnchorEl: null,
            hasMore:false,
            path:PATHS.NON_ORG_LESSONS,
            orgToSearch: ACCESS_LEVEL.PUBLIC,
            orgLabel: props.t('public'),
            subject: ALL,
            gradeLevel: ALL,
            difficultyLevel: ALL,
            searching:false,
            collectionId:ACCESS_LEVEL.PUBLIC
        };

        this.handleActionsClick = this.handleActionsClick.bind(this);
        this.setOpen = this.setOpen.bind(this);
        this.handleActionsClose = this.handleActionsClose.bind(this);
        this.onFilterChange = this.onFilterChange.bind(this);
        this.onFilterSubmitted = this.onFilterSubmitted.bind(this);
        this.getCollectionId = this.getCollectionId.bind(this);
        this.fetchLessonsCallback = this.fetchLessonsCallback.bind(this);
        this.loadMore = this.loadMore.bind(this);

    }

    componentDidMount() {
        const currentUserId = this.props.authenticated.uid;
        this.setState({loading:false});
        debugger;
        this.props.fetchLessons({path:PATHS.NON_ORG_LESSONS, currentUserId:currentUserId, LIMIT: QUERY_LIMIT, collectionId:ACCESS_LEVEL.PUBLIC, callback:this.fetchLessonsCallback});
    }

    onFilterChange = event => {
        debugger;
        if(event.target.name === 'orgToSearch' ) {
            var path = PATHS.NON_ORG_LESSONS;
            if(event.target.value !== PATHS.PUBLIC_BASE.toUpperCase()) {
                path = PATHS.ORGANIZATION_BASE + '/' + event.target.value +'/lessons';
            }
            this.setState({[event.target.name]: event.target.value, path:path, hasMore:false});
        } else {
            this.setState({[event.target.name]: event.target.value, hasMore:false});
        }
    };

    onFilterSubmitted = () => {
        const {path, subject, gradeLevel, difficultyLevel, orgToSearch, hasMore} = this.state;
        var collectionId = this.getCollectionId({orgToSearch:orgToSearch, subject:subject, gradeLevel:gradeLevel, difficultyLevel:difficultyLevel})
        var searching = false;
        var hasMoreUpdate = hasMore;
        var label = orgToSearch === ACCESS_LEVEL.PUBLIC ? this.props.t('public') : this.props.user.organizations[orgToSearch].name;
        if(!this.props.lessons[collectionId]) {
            this.props.fetchLessons({
                path: path,
                currentUserId: this.props.authenticated.uid,
                subject: subject,
                gradeLevel: gradeLevel,
                difficultyLevel: difficultyLevel,
                collectionId: collectionId,
                LIMIT: QUERY_LIMIT,
                callback:this.fetchLessonsCallback
            });
        } else {
            hasMoreUpdate = true;
        }
        this.setState({collectionId:collectionId, searching:searching, orgLabel:label, hasMore:hasMoreUpdate});
    };
    loadMore(){
        const {hasMore, subject, gradeLevel, difficultyLevel, collectionId, path} = this.state;
        if(hasMore) {
            var currentLessons = this.props.lessons[collectionId];
            var last = currentLessons ? currentLessons[currentLessons.length -1] : null;
            var requestInput = {
                path: path,
                currentUserId: this.props.authenticated.uid,
                limit: QUERY_LIMIT,
                collectionId: collectionId,
                subject:subject,
                gradeLevel:gradeLevel,
                difficultyLevel:difficultyLevel,
                callback: this.fetchLessonsCallback
            };
            if(last) {
                requestInput.last = last.dateUpdated;
            }

            this.props.fetchLessons(requestInput);
        }
    }
    fetchLessonsCallback(count) {
        debugger;
        var hasMore = count === QUERY_LIMIT;
        this.setState({loading:false, searching:false, hasMore:hasMore})
    }
    getCollectionId =(filter)=>{
        debugger;
        var collectionId = filter.orgToSearch;
        if(filter.subject !== ALL) {
            collectionId = `${collectionId}_${filter.subject}`;
        }
        if(filter.gradeLevel !== ALL) {
            collectionId = `${collectionId}_${filter.gradeLevel}`;
        }
        if(filter.difficultyLevel !== ALL) {
            collectionId = `${collectionId}_${filter.difficultyLevel}`;
        }
        return collectionId;
    }

    handleActionsClick = event => {
        this.setState({ actionAnchorEl: event.currentTarget });
    };

    handleActionsClose = () => {
        this.setState({ actionAnchorEl: null });
    };

    setOpen(open) {
        this.setState({snackShow: open});
    }

    render() {

        const {t, classes, lessons, user} = this.props;
        const {loading, actionAnchorEl, orgToSearch, subject, gradeLevel, difficultyLevel, collectionId} = this.state;

        const openActions = Boolean(actionAnchorEl);
        const lessonsToDisplay = lessons && lessons[collectionId] ? lessons[collectionId] : [];
        const canExport = user && user.isApproved && (user.designation === ROLES.ADMINISTRATION || user.designation === ROLES.PROFESSOR || user.isAdmin) && lessonsToDisplay && lessonsToDisplay.length < LIMITS.EXPORT;
        return (
            loading ? <div align="center" className={classes.progressRoot}> <CircularProgress className={classes.progress} size={100} thickness={6} /></div> :
                <div align="center">
                    <Grid container className={classes.container} spacing={1}>
                        <Grid item key="title" xs={9} md={9}>
                                <Grid>
                                    <Typography  variant={"title"} className={classes.title}>
                                        {t('application.library')}
                                    </Typography>
                                </Grid>
                        </Grid>

                        {user && (user.designation === ROLES.ADMINISTRATION || user.designation === ROLES.PROFESSOR || user.isAdmin) &&
                        <Grid item key="actions" xs={3} md={3}>
                            <IconButton aria-label={t('buttons.actions')}
                                        aria-owns={openActions ? 'actions-menu' : undefined}
                                        aria-haspopup="true" onClick={this.handleActionsClick}
                                        className={classes.pageActions}>
                                <Settings/>
                            </IconButton>
                            <Menu id="actions-menu" anchorEl={actionAnchorEl} open={openActions}
                                  onClose={this.handleActionsClose}>
                                <MenuItem component={Link} to={CREATE_LESSON} onClick={this.handleActionsClose}>
                                    <Add className={classes.icon} />
                                    {t('application.create lesson')}
                                </MenuItem>

                            </Menu>
                        </Grid>
                        }

                        <br/>
                        <Grid item xs={12} md={12}>
                            <Grid container spacing={4} justify={"center"}>
                                <FormControl className={classes.formControl}>
                                    <InputLabel htmlFor="organization-select"  shrink={true}><Typography className={classes.capital}> {t('fields.organization')}</Typography></InputLabel>
                                    <Select
                                        className={classes.capital}
                                        value={orgToSearch}
                                        onChange={this.onFilterChange}
                                        inputProps={{
                                            name: 'orgToSearch',
                                            id: 'orgToSearch-select',
                                        }}
                                        native
                                    >

                                        <option value={ACCESS_LEVEL.PUBLIC}>{t('public').toUpperCase()}</option>
                                        {user && user.verifiedOrganizations && user.verifiedOrganizations.map(organization =>
                                            <option value={organization.id} title={organization.name} key={organization.id} className={classes.capital}>{organization.name}</option>
                                        )}
                                    </Select>
                                </FormControl>
                                <FormControl className={classes.formControl}>
                                    <InputLabel htmlFor="subject-select"  shrink={true}><Typography className={classes.capital}> {t('fields.subject')}</Typography></InputLabel>
                                    <Select
                                        value={subject}
                                        onChange={this.onFilterChange}
                                        inputProps={{
                                            name: 'subject',
                                            id: 'subjectToSearch-select',
                                        }}
                                        native
                                    >

                                        <option value={ALL}>{t('all').toUpperCase()}</option>
                                        {SUBJECT.map(subject =>
                                            <option value={subject} className={classes.capital}>{t('subject.'+subject)}</option>
                                        )}
                                    </Select>
                                </FormControl>
                                <FormControl className={classes.formControl}>
                                    <InputLabel htmlFor="gradeLevel-select"  shrink={true}><Typography className={classes.capital}> {t('fields.grade level')}</Typography></InputLabel>
                                    <Select
                                        value={gradeLevel}
                                        onChange={this.onFilterChange}
                                        inputProps={{
                                            name: 'gradeLevel',
                                            id: 'gradeLevel-select',
                                        }}
                                        native
                                    >

                                        <option value={ALL}>{t('all').toUpperCase()}</option>
                                        {GRADE_LEVEL.map(grade =>
                                            <option value={grade}>{t('grade.'+grade)}</option>
                                        )}
                                    </Select>
                                </FormControl>
                                <FormControl className={classes.formControl}>
                                    <InputLabel htmlFor="difficultyLevel-select"  shrink={true}><Typography className={classes.capital}> {t('difficulty').toUpperCase()}</Typography></InputLabel>
                                    <Select
                                        value={difficultyLevel}
                                        onChange={this.onFilterChange}
                                        inputProps={{
                                            name: 'difficultyLevel',
                                            id: 'difficultyLevel-select',
                                        }}
                                        native
                                    >

                                        <option value={ALL}>{t('all').toUpperCase()}</option>
                                        {DIFFICULTY_LEVEL.map(difficulty =>
                                            <option value={difficulty}>{t(difficulty)}</option>
                                        )}
                                    </Select>
                                </FormControl>
                                <div>
                                    <IconButton aria-label={t('buttons.filter')}
                                                    aria-haspopup="true" onClick={this.onFilterSubmitted}
                                                variant="contained"
                                                size="medium"
                                                className={classes.subActions}>
                                            <Search/>
                                    </IconButton>
                                </div>
                            </Grid>
                        </Grid>
                    </Grid>
                    <br/>
                    <Grid item xs={12}>
                        {lessonsToDisplay ? <div className={classes.lessons}> <LessonTable title={`${t('application.lessons')} - ${this.state.orgLabel}`} data={lessonsToDisplay} export={canExport} currentUser= {user} classes={classes}
                                                                                           loadMore={this.loadMore} hasMore={this.state.hasMore}/> </div> :
                            <div align="center"> {t('no results')}</div>}
                    </Grid>
                </div>
        )
    }

}
const mapStateToProps = ({user, lessons}) => {
        return {user:user, lessons:lessons};
};

export default compose (
    withRouter,
    withStyles(styles),
    withI18n(),
    connect(mapStateToProps, {fetchLessons, removeLessonsListeners})
)(Library)
