import { withI18n } from "react-i18next";
import { withStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
import { Link, withRouter } from 'react-router-dom';
import {compose} from "recompose";
import * as ROUTES from '../../constants/routes';

import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import { connect } from "react-redux";
import { doSignInWithEmailAndPassword } from "../../actions";
import PropTypes from "prop-types";
import {LINARY_LOADING_MASK, LINARY_LOADING_MASK_BAR} from "../../constants/colors";

const React = require('react');

const styles = {
    card: {
        minWidth: 275,
        maxWidth: 400,
        paddingBottom: 10
    },
    bullet: {
        display: 'inline-block',
        margin: '0 2px',
        transform: 'scale(0.8)',
    },
    actions: {
        display: 'inherit',
    },
    title: {
        textTransform: 'uppercase'
    },
    pos: {
        marginBottom: 12,
    },
    uploadProgress : {
        flexGrow: 1,
    },
    linearColorPrimary : {
        backgroundColor: LINARY_LOADING_MASK,
    },
    linearBarColorPrimary : {
        backgroundColor: LINARY_LOADING_MASK_BAR,
    },
};

const INITIAL_STATE = {
    email: '',
    password: '',
    showPassword:false,
    error: null,
    loading: false
};

class SignIn extends React.Component {

    constructor(props) {
        super(props);

        this.state = { ...INITIAL_STATE };
        this.onSubmit = this.onSubmit.bind(this);
        this.onChange = this.onChange.bind(this);
        this.signInCallback = this.signInCallback.bind(this);
    }

    static contextTypes = {
        router: PropTypes.object
    };

    signInCallback(success) {
        if (success) {
            this.props.history.push('/home');
        } else {
            this.setState({loading:false});
        }
    }



    onSubmit = event => {
        const { email, password } = this.state;
        this.setState({loading:true});
        event.preventDefault();
        this.props
            .doSignInWithEmailAndPassword(email, password, this.signInCallback);

    };
    onChange = prop => event => {
        this.setState({ [prop]: event.target.value });
    };


    render() {
        const {t, classes, auth} = this.props;
        const { email, password, showPassword, error, loading } = this.state;
        const isInvalid = password === '' || email === '' || loading;
        debugger;

        return (
            <div align="center">
                {!auth && <span>
                <Card className={classes.card}>
                    <form onSubmit={this.onSubmit}>
                        <CardContent>
                            <CardHeader
                                title={<Typography className={classes.title} color="textSecondary" gutterBottom>
                                    {t('application.log in')}
                                </Typography>
                                }
                            />
                            <div>
                                <Grid>
                                    <Grid item>
                                        <TextField value={email} type="email" onChange={this.onChange('email')}
                                                   id="input-email" label={t('fields.email')}/>
                                    </Grid>
                                    <TextField
                                        id="input-password"
                                        className={classNames(classes.margin, classes.textField)}
                                        variant="filled"
                                        type={showPassword ? 'text' : 'password'}
                                        label={t('fields.password')}
                                        value={password}
                                        onChange={this.onChange('password')}
                                    />
                                </Grid>
                            </div>

                        </CardContent>
                        <CardActions className={classes.actions}>
                            <Button size="small" color="primary" type="submit"
                                    disabled={isInvalid}> {t('application.log in')} </Button>
                        </CardActions>
                        <Typography color="error">
                            {error && <p>{error.message}</p>}
                        </Typography>
                        <Typography className={classes.title} color="primary">
                            <Link to={ROUTES.PASSWORD_FORGET}>{t('forgot password?')} </Link>
                        </Typography>
                    </form>
                </Card>
                    < Typography color="textSecondary">
                    {t('no account?')} <Link to={ROUTES.SIGN_UP}>{t('application.sign up')} </Link>
                        </Typography>
                </span>
                }

            </div>


        )
    }

}

function mapStateToProps({ auth }) {
    return { auth };
}
export default compose (
    withRouter,
    withStyles(styles),
    withI18n(),
    connect(mapStateToProps, { doSignInWithEmailAndPassword })
)(SignIn)