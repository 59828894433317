import { ADD_MY_CHATS, FETCH_MY_CHATS} from "../constants/action-types";
import {combineArrays} from "../util/functions";

export default (state = [], action) => {
    switch (action.type) {
        case ADD_MY_CHATS :
            return action.payload.top ?
                combineArrays(action.payload.data, state)
                : combineArrays(state, action.payload.data)
        case FETCH_MY_CHATS:
            return action.payload || null;
        default:
            return state;
    }
};