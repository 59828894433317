import { withI18n } from "react-i18next";
import { withStyles } from '@material-ui/core/styles';
import { Link, withRouter } from 'react-router-dom';
import {compose} from "recompose";
import { Email,  Phone } from '@material-ui/icons';

import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import Avatar from '@material-ui/core/Avatar';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import * as COLORS from "../../constants/colors";

const React = require('react');

const styles = theme => ({
    root: {
        flexGrow: 1,
    },
    card: {
        // minWidth: 150,
        // maxWidth: 300,
        margin: theme.spacing(2),
    },
    bullet: {
        display: 'inline-block',
        margin: '0 2px',
        transform: 'scale(0.8)',
    },
    actions: {
        display: 'inherit',
    },
    textIcon : {
        color: COLORS.PRIMARY,
    },
    media: {
        height: 0,
        paddingTop: '56.25%', // 16:9
    },
    title: {
        textTransform: 'uppercase'
    },
    pos: {
        marginBottom: 12,
    },
    expand: {
        transform: 'rotate(0deg)',
        marginLeft: 'auto',
        transition: theme.transitions.create('transform', {
            duration: theme.transitions.duration.shortest,
        }),
    },
    expandOpen: {
        transform: 'rotate(180deg)',
    },
    avatar: {
        backgroundColor: COLORS.PRIMARY,
    },
    icons : {
        backgroundColor: 'white',
    },
    chip: {
        margin: theme.spacing.unit,
    }
});

class ContactUs extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            loading: false,
            expanded: false,
        };

    }

    render() {
        const {t, classes} = this.props;
        return (
            <div>
                <div  align="center">
                    <Typography  className={classes.title}>
                        {t('application.contact us')}
                    </Typography>
                </div>
                <Grid container className={classes.root}>
                    <Grid item  xs={12} sm={6} md={5} lg={3}>
                        <Card className={classes.card}>
                            <CardHeader
                                avatar={
                                    <Avatar aria-label={t('fields.email')} className={classes.avatar}>
                                        <Email />
                                    </Avatar>
                                }
                                title={t('fields.email')}
                            />
                            <CardContent>
                                <Typography component="p">
                                    <a href="mailto:info@socipod.com">info@socipod.com</a>
                                </Typography>
                            </CardContent>
                        </Card>
                    </Grid>

                    <Grid item  xs={12} sm={6} md={5} lg={3}>
                        <Card className={classes.card}>
                            <CardHeader
                                avatar={
                                    <Avatar aria-label={t('fields.phone')} className={classes.avatar}>
                                        <Phone />
                                    </Avatar>
                                }
                                title={t('fields.phone')}
                            />
                            <CardContent>
                                <Typography component="p">
                                    <a href="tel:786 274 9087">786 274 9087</a>
                                </Typography>
                            </CardContent>
                        </Card>
                    </Grid>
                    {/*<Grid item xs={12} sm={6} md={5} lg={3}>*/}
                        {/*<Card className={classes.card}>*/}
                            {/*<CardHeader*/}
                                {/*avatar={*/}
                                    {/*<Avatar aria-label={t('facebook')}  src="../../images/icons8-facebook-24.png" className={classes.icons} />*/}

                                {/*}*/}
                                {/*title={t('facebook')}*/}
                            {/*/>*/}
                            {/*<CardContent>*/}
                                {/*<Typography component="p">*/}
                                    {/*<a href="https://www.facebook.com/socipod/" target="_blank">Socipod</a>*/}
                                {/*</Typography>*/}
                            {/*</CardContent>*/}
                        {/*</Card>*/}
                    {/*</Grid>*/}
                    {/*<Grid item xs={12} sm={6} md={5} lg={3}>*/}
                        {/*<Card className={classes.card}>*/}
                            {/*<CardHeader*/}
                                {/*avatar={*/}
                                    {/*<Avatar aria-label={t('instagram')}  src="../../images/icons8-instagram-48.png" className={classes.icons} />*/}
                                {/*}*/}
                                {/*title={t('instagram')}*/}
                            {/*/>*/}
                            {/*<CardContent>*/}
                                {/*<Typography component="p">*/}
                                    {/*<a href="https://www.instagram.com/socipod/" target="_blank">@socipod</a>*/}
                                {/*</Typography>*/}
                            {/*</CardContent>*/}
                        {/*</Card>*/}
                    {/*</Grid>*/}
                </Grid>

                <Grid item xs={12} sm={7} md={6} lg={4}>
                    <Card className={classes.card}>
                        <CardHeader
                            avatar={
                                <Avatar aria-label="King Rayon" className={classes.avatar}>
                                    KR
                                </Avatar>
                            }
                            title={<Typography variant={"title"}>
                                {t('site design and creation by')}
                            </Typography>}
                            // subheader={<Typography variant={"subheading"}>
                            //     Rayon Watson
                            // </Typography>}
                        />
                        <CardContent>
                            <Typography variant={"subheading"}>
                                <a href="tel:347 720 7058"><Phone className={classes.textIcon}/> 347 720 7058</a>
                            </Typography >
                            <Typography variant={"subheading"}>
                                <a href="mailto:rayon.watson@gmail.com"><Email className={classes.textIcon}/><span >rayon.watson@gmail.com</span></a>
                            </Typography >
                        </CardContent>
                    </Card>
                </Grid>
            </div>

        )
    }

}

export default compose (
    withRouter,
    withStyles(styles),
    withI18n()
)(ContactUs)
