import {
    ADD_ORGANIZATION_MEMBER_REQUESTS,
    FETCH_ORGANIZATION_MEMBER_REQUESTS,
    REMOVE_MEMBER_REQUESTS
} from "../constants/action-types";
import {combineArrays, removeObjectInArrayByKey} from "../util/functions";

export default (state = {}, action) => {
    switch (action.type) {
        case ADD_ORGANIZATION_MEMBER_REQUESTS:
            return {...state, [action.payload.id] : action.payload.data.top ?
                    combineArrays(action.payload.data, state[action.payload.id])
                    : combineArrays(state[action.payload.id], action.payload.data)};
        case FETCH_ORGANIZATION_MEMBER_REQUESTS:
            return {...state, [action.payload.id] : action.payload.data};
        case REMOVE_MEMBER_REQUESTS:
            debugger;
            return {...state, [action.payload.id] : removeObjectInArrayByKey(state[action.payload.id], 'uid', action.payload.memberId)};
        default:
            return state;
    }
};