import {ADD_QUESTION, FETCH_QUESTIONS, UPDATE_QUESTION} from "../constants/action-types";
import {updateObjectInArrayById} from "../util/functions";

export default (state = null, action) => {
    switch (action.type) {
        case FETCH_QUESTIONS:
            return action.payload ||  [];
        case ADD_QUESTION:
            return [...state, action.payload];
        case UPDATE_QUESTION:
            debugger;
            return updateObjectInArrayById(state, action.payload.data);
        default:
            return state;
    }
};