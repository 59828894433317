import { withI18n } from "react-i18next";
import { withStyles } from '@material-ui/core/styles';
import { withRouter } from 'react-router-dom';
import {compose} from "recompose";
import {Avatar, Card, CardContent, CardHeader, Divider, Grid, IconButton, Typography, CircularProgress, List, ListItem, ListItemText, ListItemIcon, ListItemSecondaryAction} from '@material-ui/core';
import {connect} from "react-redux";
import TimeAgo from 'react-timeago';
import InfiniteScroll from 'react-infinite-scroller';
import {fetchPosts, removePostsListener, fetchLessonAssignments} from "../../actions";
import {ASSIGNMENT_STATUS, PATHS, POST_LIMIT, POST_TYPE, AVATAR_TYPE} from "../../constants/common";
import Post from "../Post"
import {GREEN, LOW_GRADE, RED} from "../../constants/colors";
import {getJSDate} from "../../util/functions";
import UserAvatar from "../UserAvatar"

const React = require('react');

const styles = {
    card: {
        minWidth: 275,
        maxWidth: 400,
    },
    bullet: {
        display: 'inline-block',
        margin: '0 2px',
        transform: 'scale(0.8)',
    },
    actions: {
        display: 'inherit',
    },
    title: {
        textTransform: 'uppercase'
    },
    pos: {
        marginBottom: 12,
    },
    vScroll: {
        // overflowY:'auto',
        // maxHeight:'75vh'
    },
    cardHeader: {
        padddingTop:0,
        paddingBottom: 0
    },
    failAvatar : {
        backgroundColor: RED,
        width: 50,
        height: 50
    },
    passAvatar : {
        backgroundColor: GREEN,
        width: 50,
        height: 50
    },
    lowAvatar : {
        backgroundColor: LOW_GRADE,
        width: 50,
        height: 50
    },
    scrollContainer: {
        height:'700px',
        overflow:'auto'
    }
};

const INITIAL_STATE = {
    saving: false,
    postText: '',
    postImages : [],
    postVidoes : []
};


class HomePage extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            loading: false,
            saving: false,
            hasMorePosts: false,
            postLoaded: false

        };
        this.onChange = this.onChange.bind(this);
        this.loadMorePosts = this.loadMorePosts.bind(this);
        this.fetchPostCallback = this.fetchPostCallback.bind(this);
    }

    componentDidMount() {
        const {posts} =  this.props;
        var feedPosts = posts && posts['feeds'];
        if(feedPosts) {
            this.setState({postLoaded: true});
        } else {
            this.props.fetchPosts({type: POST_TYPE.PERSONAL, limit:POST_LIMIT, userId:this.props.authenticated.uid, callback:this.fetchPostCallback});
        }

    }

    loadMorePosts = () => {
        this.setState({hasMorePosts: false});
        const {posts} =  this.props;
        var feedPosts = posts['feeds'];
        var lastPost = feedPosts ? feedPosts[feedPosts.length - 1] : null;
        if(lastPost) {
            this.props.fetchPosts({type: POST_TYPE.PERSONAL, limit:POST_LIMIT, last: lastPost.dateUpdated, userId:this.props.authenticated.uid, callback:this.fetchPostCallback});
        }
    };
    fetchPostCallback = (count) =>{
        var hasMorePosts =  count === POST_LIMIT;
        this.setState({hasMorePosts: hasMorePosts, postLoaded: true});
    }


    componentWillUnmount() {
        this.props.removePostsListener();
    }

    onChange = prop => event => {
        this.setState({ [prop]: event.target.value });
    };
    openLesson = (lessonId, organizationId) => {
        if(organizationId) {
            this.props.history.push(`/lessons/${organizationId}/${lessonId}`);
        } else {
            this.props.history.push(`/lessons/${PATHS.PUBLIC_BASE}/${lessonId}`);
        }
    };

    render() {
        const {t, classes, posts, authenticated, user, myLessons} = this.props;
        const {loading, hasMorePosts, postLoaded} = this.state;
        const todos = myLessons && myLessons[ASSIGNMENT_STATUS.ASSIGNED] !== null ? myLessons[ASSIGNMENT_STATUS.ASSIGNED] : [];
        const toRedos = myLessons && myLessons[ASSIGNMENT_STATUS.REASSIGNED] !== null ? myLessons[ASSIGNMENT_STATUS.REASSIGNED] : [];
        const submitted = myLessons && myLessons[ASSIGNMENT_STATUS.SUBMITTED] !== null ? myLessons[ASSIGNMENT_STATUS.SUBMITTED] : [];
        const completed = myLessons && myLessons[ASSIGNMENT_STATUS.COMPLETED] !== null ? myLessons[ASSIGNMENT_STATUS.COMPLETED] : [];
        const feeds = posts ? posts['feeds'] : [];

        const todoCount = todos ? todos.length: 0;
        const toRedoCount = toRedos ? toRedos.length: 0;
        const submittedCount = submitted ? submitted.length: 0;
        const completedCount = completed ? completed.length: 0;
        const totalTodo = todoCount + toRedoCount;
        const totalCompleted = submittedCount + completedCount;
        debugger;
        return (loading ? <div align="center" className={classes.progressRoot}> <CircularProgress className={classes.progress} size={100} thickness={6} /></div>
                :
                <div align="center" class="momentumScroll">
                    <Grid  item container className={classes.root} spacing={2}>
                        <Grid item xs={12} md={3} >
                            <Grid id="leftPanel">
                                <Card>
                                    <CardHeader className={classes.cardHeader}
                                        title={ <Typography className={classes.title} color="textSecondary" gutterBottom>
                                            {t('to dos')} {`(${totalTodo})`}
                                        </Typography>
                                        }
                                    />
                                    <CardContent className={classes.cardContent}>
                                        {todos && todos.length > 0 && <List>
                                            {todos.map(assignment =>
                                                <ListItem key={assignment.id} button
                                                          onClick={() => this.openLesson(assignment.id, assignment.organizationId)}>
                                                    <ListItemIcon className={classes.listLabel}>
                                                        <UserAvatar username={assignment.lessonTitle} userId={assignment.id}
                                                                    imageUrl={assignment.lessonProfileImageUrl}
                                                        type={AVATAR_TYPE.LESSON}/>
                                                    </ListItemIcon>
                                                    <ListItemText id={assignment.id}
                                                                  primary={assignment.lessonTitle}
                                                                  secondary={
                                                                      <span>{t('status.assigned').toUpperCase()}: <TimeAgo
                                                                          date={getJSDate(assignment.dateCreated)}/></span>}/>
                                                </ListItem>
                                            )}
                                        </List>
                                        }

                                        {toRedos && toRedos.length > 0 && <List>
                                            {toRedos.map(assignment =>
                                                <ListItem key={assignment.id} button
                                                          onClick={() => this.openLesson(assignment.id, assignment.organizationId)}>
                                                    <ListItemIcon className={classes.listLabel}>
                                                        <UserAvatar username={assignment.lessonTitle} userId={assignment.id}
                                                                    imageUrl={assignment.lessonProfileImageUrl}
                                                                    type={AVATAR_TYPE.LESSON}/>
                                                    </ListItemIcon>
                                                    <ListItemText id={assignment.id}
                                                                  primary={assignment.lessonTitle}
                                                                  secondary={
                                                                      <span>{t('status.reassigned').toUpperCase()}: <TimeAgo
                                                                          date={getJSDate(assignment.dateUpdated)}/></span>}/>
                                                    <ListItemSecondaryAction>

                                                    </ListItemSecondaryAction>
                                                </ListItem>
                                            )}
                                        </List>
                                        }
                                    </CardContent>
                                </Card>

                                <br />
                                <Card>
                                    <CardHeader className={classes.cardContent}
                                        title={ <Typography className={classes.title} color="textSecondary" gutterBottom>
                                            {t('status.completed')} {`(${totalCompleted})`}
                                        </Typography>
                                        }
                                    />
                                    <CardContent>
                                        {submitted && submitted.length > 0 && <List subheader={ <Typography> {t('status.submitted')} {`(${submittedCount})`}</Typography>}>
                                            {submitted.map(assignment =>
                                                <ListItem key={assignment.id} button
                                                          onClick={() => this.openLesson(assignment.id, assignment.organizationId)}>
                                                    <ListItemText id={assignment.id}
                                                                  primary={assignment.lessonTitle}
                                                                  secondary={<span>{t('status.submitted').toUpperCase()}: <TimeAgo
                                                                      date={getJSDate(assignment.dateUpdated)}/></span>}/>
                                                    <ListItemSecondaryAction>


                                                    </ListItemSecondaryAction>
                                                </ListItem>
                                            )}
                                        </List>
                                        }
                                        {completed && completed.length > 0 &&
                                        <List>
                                            {completed.map(assignment =>
                                                <ListItem key={assignment.id} button
                                                          onClick={() => this.openLesson(assignment.id, assignment.organizationId)}>
                                                    <ListItemText id={assignment.id}
                                                                  primary={assignment.lessonTitle}
                                                                  secondary={
                                                                      <span>{t('status.completed').toUpperCase()}: <TimeAgo
                                                                          date={getJSDate(assignment.dateSubmitted)}/></span>}/>
                                                    <ListItemSecondaryAction>
                                                        <IconButton edge="end" aria-label={t('buttons.grade')}
                                                                    aria-hidden={false}>
                                                            <Avatar
                                                                className={assignment.gradePercentage < 45 ? classes.failAvatar :
                                                                    assignment.gradePercentage < 70 ? classes.lowAvatar : classes.passAvatar}
                                                            > {assignment.gradePercentage}</Avatar>
                                                        </IconButton>
                                                    </ListItemSecondaryAction>
                                                </ListItem>
                                            )}
                                        </List>
                                        }
                                    </CardContent>
                                </Card>
                            </Grid>
                        </Grid>
                        <Grid item xs={12} md={6} className={classes.vScroll}>
                            <Grid>
                                <Post postId="newPost" readOnly={false} type={POST_TYPE.PERSONAL} canEdit={true} profileImageUrl={user.profileImageUrl} username={user.username} userId={authenticated.uid} />
                                <br/>
                                <Divider/>
                                <br/>
                                {!postLoaded && <div align="center" className={classes.progressRoot}> <CircularProgress className={classes.progress} size={20} thickness={3} /></div>}
                                {feeds && feeds.length > 0 &&
                                    <div className={classes.scrollContainer}>
                                        <InfiniteScroll
                                            pageStart={0}
                                            loadMore={this.loadMorePosts}
                                            hasMore={hasMorePosts}
                                            useWindow={false}
                                            loader={<div align="center" className={classes.progressRoot}> <CircularProgress className={classes.progress} size={20} thickness={3} /></div>}
                                        >
                                        {feeds.map((post,index) =>(
                                            <span key={`post-${index}`}>
                                                <div key={post.id} >
                                                <Post postId={post.id} readOnly={true} type={POST_TYPE.PERSONAL} canEdit={authenticated.uid === post.createdById} profileImageUrl={user.profileImageUrl} username={user.username} userId={authenticated.uid} post={post}/>
                                                </div>
                                                </span>
                                        ))}

                                    </InfiniteScroll>
                                    </div>
                                }
                            </Grid>
                        </Grid>
                        <Grid item xs={12} md={3}>

                        </Grid>
                    </Grid>
                </div>
        )
    }
}

const mapStateToProps = ({posts, user, myFollowings, myLessons}) => {
    return {posts:posts, user:user, myFollowings:myFollowings, myLessons:myLessons};
};


export default compose (
    withRouter,
    withStyles(styles),
    withI18n(),
    connect(mapStateToProps, {fetchPosts, removePostsListener, fetchLessonAssignments})
)(HomePage)
