import {FETCH_LESSON_ASSIGNMENT,ADD_LESSON_ASSIGNMENT} from "../constants/action-types";

export default (state = null, action) => {
    switch (action.type) {
        case FETCH_LESSON_ASSIGNMENT:
            return action.payload ||  null;
        case ADD_LESSON_ASSIGNMENT:
            return action.payload ||  null;
        default:
            return state;
    }
};