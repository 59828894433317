import { withI18n } from "react-i18next";
import { withRouter } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
import {compose} from "recompose";
import {List, ListItem, ListItemIcon, ListItemText, ListSubheader, ListItemSecondaryAction, Tooltip, Divider} from '@material-ui/core';
import {VerifiedUser} from '@material-ui/icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUserMinus } from '@fortawesome/free-solid-svg-icons';
import * as COLORS from "../../constants/colors";
import {AVATAR_TYPE, BADGE_TYPE} from "../../constants/common";
import UserAvatar from "../UserAvatar";


const React = require('react');

const styles = theme => ({
    root: {
        flexGrow: 1,
        spacing: '40',
        backgroundColor: theme.palette.background.paper,
    },
    progressRoot: {
        flexGrow: 1,
        spacing: '40'
    },
    details: {
        width: '100%',
        //maxWidth: 400,
        backgroundColor: theme.palette.background.paper,
    },
    status : {
        textTransform: 'uppercase',
    },
    progress: {
        margin: theme.spacing(2),
        color: COLORS.LOADING_MASK
    },
    chipsRoot : {
        display: 'flex',
        justifyContent: 'center',
        flexWrap: 'wrap',
    },
    chip: {
        margin: theme.spacing(1),
    },
    actions: {
        display: 'inherit',
    },
    media: {
        height: 100,
        paddingTop: '75.25%', // 16:9
    },
    listLabel: {
        textTransform: 'capitalize',
        width: 150,
        display: 'block',
        textAlign : 'right'

    },
    listText: {
        marginLeft: theme.spacing(.5)
    },
    avatar: {
        backgroundColor: COLORS.MAIN_ICON,
    },
    infoAvatar: {
        color: COLORS.INFO,
    },
    verifiedAvatar: {
        color: COLORS.GREEN,
    },
    unverifiedAvatar: {
        color: COLORS.ORANGE,
    },
    success : {
        color: 'green'
    }
});

class ProfileView extends React.Component {
    constructor(props) {
        super(props);
        this.state = {

        };
    }
    render() {
        const {userInfo, t, classes, canViewRelations} = this.props;
        return (
                <div className={classes.root}>
                    { userInfo &&
                        <div>
                            <List subheader={<ListSubheader> {t('profile details')}</ListSubheader>}
                                  className={classes.details}>
                                <ListItem>
                                    <ListItemIcon className={classes.listLabel}>
                                        {t('fields.username')}:
                                    </ListItemIcon>
                                    <ListItemText className={classes.listText} id="username" primary={userInfo.username}/>
                                </ListItem>
                                <ListItem>
                                    <ListItemIcon className={classes.listLabel}>
                                        {t('fields.firstname')}:
                                    </ListItemIcon>
                                    <ListItemText id="firstname" className={classes.listText} primary={userInfo.firstname} />
                                </ListItem>
                                <ListItem>
                                    <ListItemIcon className={classes.listLabel}>
                                        {t('fields.lastname')}:
                                    </ListItemIcon>
                                    <ListItemText id="lastname" className={classes.listText} primary={userInfo.lastname}/>
                                </ListItem>
                                <ListItem>
                                    <ListItemIcon className={classes.listLabel}>
                                        {t('fields.designation')}:
                                    </ListItemIcon>
                                    <ListItemText id="designation" className={classes.listText} primary={t(userInfo.designation).toUpperCase()}/>
                                </ListItem>
                                <ListItem>
                                    <ListItemIcon className={classes.listLabel}>
                                        {t('points')}:
                                    </ListItemIcon>
                                    <ListItemText id="points" className={classes.listText} primary={t(userInfo.points).toUpperCase()}/>
                                </ListItem>
                            </List>

                            {userInfo.organizationList &&
                                <List subheader={<ListSubheader> {t('Organizations')}</ListSubheader>}
                                      className={classes.details}>
                                    {userInfo.organizationList.map(organization =>
                                        <span>
                                        <Divider variant="inset" component="li"/>
                                        <ListItem key={organization.id}>
                                            <ListItemIcon className={classes.listLabel}>
                                                <UserAvatar username={organization.name} userId={organization.id}
                                                            imageUrl={organization.profileImageUrl} type={AVATAR_TYPE.ORGANIZATION}/>
                                            </ListItemIcon>
                                            <ListItemText className={classes.listText} id={organization.id}
                                                          primary={organization.name}
                                                          secondary={<span>
                                                                      {canViewRelations && organization.parentList && organization.parentList.map(parent =>
                                                                          <UserAvatar size="small" username={parent.username} userId={parent.id}
                                                                                      imageUrl={parent.profileImageUrl} badge={BADGE_TYPE.PARENT}/>
                                                                      )}
                                                              {canViewRelations && organization.childrenList && organization.childrenList.map(child =>
                                                                  <UserAvatar size="small" username={child.username} userId={child.id}
                                                                              imageUrl={child.profileImageUrl} badge={BADGE_TYPE.CHILD}/>
                                                              )}
                                                                  </span>}/>
                                            <ListItemSecondaryAction>
                                                { organization.isApproved ?
                                                    <Tooltip title={t('status.approved')} aria-label={t('status.approved')}>
                                                        <VerifiedUser className={classes.verifiedAvatar}/>
                                                    </Tooltip>
                                                    :
                                                    <Tooltip title={ t('status.pending')} aria-label={ t('status.pending')}>
                                                        <FontAwesomeIcon icon={faUserMinus} className={classes.unverifiedAvatar} title={ t('status.pending')}/>
                                                    </Tooltip>
                                                }
                                            </ListItemSecondaryAction>
                                        </ListItem>
                                        </span>
                                    )}
                                </List>
                            }
                        </div>
                    }
                </div>
        )
    }
}
export default compose (
    withStyles(styles),
    withRouter,
    withI18n(),
)(ProfileView)