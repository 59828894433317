import { withI18n } from "react-i18next";
import { withStyles } from '@material-ui/core/styles';
import {compose} from "recompose";
import {RATINGS_FILLED, RATINGS_HOVER,} from "../../constants/colors";
import {Favorite} from '@material-ui/icons';
import {Rating} from '@material-ui/lab';
import {Box} from '@material-ui/core';
import {fetchRatingForLesson,fetchUserRatingForLesson, setLessonRating} from "../../actions";
import {connect} from "react-redux";


const React = require('react');

const labels = {
    0.5: 'useless',
    1: 'useless plus+',
    1.5: 'poor',
    2: 'poor plus',
    2.5: 'ok',
    3: 'ok plus',
    3.5: 'good',
    4: 'good plus',
    4.5: 'excellent',
    5: 'excellent pluss',
};


const HeartRating = withStyles({
    iconFilled: {
        color: RATINGS_FILLED,
    },
    iconHover: {
        color: RATINGS_HOVER,
    },
})(Rating);

const styles = theme => ({
    root: {
        width: 'max-content',
        display: 'flex',
        alignItems: 'center'
    },
    noMargin: {
        marginLeft: '0 !important'
    }
});


class Ratings extends React.Component {
    constructor(props) {
        super(props);
        const rating = props.rating ? props.rating : 0;
        const numRatings = props.numRatings ? props.numRatings : 0;
        this.state =  {loading:true, rating:rating, ratings:[], numRatings:numRatings, hover:-1};
        this.fetchRatings = this.fetchRatings.bind(this);
        this.fetchRatingForLessonCallBack = this.fetchRatingForLessonCallBack.bind(this);
        this.fetchUserRatingForLessonCallBack = this.fetchUserRatingForLessonCallBack.bind(this);
        this.setLessionRatingCallback = this.setLessionRatingCallback.bind(this);
    }

    componentDidMount() {
        this.fetchRatings();
    }

    setValue(newRating){
        this.setState({rating:newRating});
        this.props.setLessonRating(this.props.user.id, this.props.lessonId, this.props.organizationId, newRating, this.setLessionRatingCallback);

    }
    setHover(newHover){
        this.setState({hover:newHover});
    }
    fetchRatings(){
        if(this.props.readOnly) {
            //this.props.fetchRatingForLesson(this.props.user.id, this.props.lessonId, this.fetchRatingForLessonCallBack);
            this.setState({loading:false});
        } else {
            this.props.fetchUserRatingForLesson(this.props.user.id, this.props.lessonId, this.fetchUserRatingForLessonCallBack);
        }
    }
    fetchUserRatingForLessonCallBack(rating) {
        if(rating) {
            this.setState({rating: rating.value, loading:false});
        } else {
            this.setState({loading:false});
        }
    }
    fetchRatingForLessonCallBack(result) {

        if(result && result.ratings && result.ratings.length > 0) {
            const ratings = result.ratings.map(v => v.value)
            var overallRating = ratings.length ? ratings.reduce((total, val) => total + val) / result.ratings.length : 0
            this.setState({rating: overallRating, loading:false, ratings:result.ratings, userRating: result.userRating});
        } else {
            this.setState({loading:false});
        }
    }
    setLessionRatingCallback(success) {
        if(success && this.props.updateSuccess) {
            //refresh overallRating
            this.props.updateSuccess();
        }
    }

    render() {
        const {t, classes, readOnly, name, size, showTotal} = this.props;
        const {rating, hover, numRatings} = this.state;

        return (  <div className={classes.root}><HeartRating
                        name={name}
                        value={this.state.rating}
                        readOnly={readOnly}
                        precision={0.5}
                        icon={<Favorite fontSize="inherit" />}
                        onChange={(event, newValue) => {
                            this.setValue(newValue);
                        }}
                        size={size}
                        onChangeActive={(event, newHover) => {
                            this.setHover(newHover);
                        }}
                    />
                {showTotal && <Box m={1}>({numRatings})</Box>}
                {!readOnly && rating !== null && <Box ml={1}>{hover !== -1 ? t("ratings."+hover) : t("lesson rating."+rating)}</Box>}

                </div>
        )
    }
}

const mapStateToProps = ({user}) => {
    return {user:user};
};

export default compose (
    withStyles(styles),
    withI18n(),
    connect(mapStateToProps, {fetchRatingForLesson,fetchUserRatingForLesson,setLessonRating})
)(Ratings)