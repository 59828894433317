import { withI18n } from "react-i18next";
import { withStyles } from '@material-ui/core/styles';
import {withRouter } from 'react-router-dom';
import {compose} from "recompose";
import {PhotoCamera, Image, Videocam} from '@material-ui/icons'
import {Chip, Typography}  from '@material-ui/core';
import 'react-image-crop-component/style.css'
import {MAX_VIDEO_POST_SIZE_BTYE} from "../../constants/common";
import {openSnack} from "../../actions";
import {connect} from "react-redux";
import {BLUE, WHITE,CHIP} from "../../constants/colors";

const React = require('react');

const styles = theme => ({
    icon: {
        margin: theme.spacing(),
        color: BLUE,
    },
    chipIcon: {
        color: WHITE,
    },
    hidden: {
        width: '0.1px',
        height: '0.1px',
        opacity: 0,
        overflow: 'hidden',
        position: 'absolute',
        zIndex: -1
    },
    show: {

    },
    upload: {
       // backgroundColor: SECONDARY_ICON,
        cursor: 'pointer'
    },
    chip: {
        backgroundColor: CHIP,
        color: WHITE,
        fontSize: '.8em'
    },
});

const INITIAL_STATE = {
    fileSrc: null,
    fileInfo: null
};
class FileUpload extends React.Component {

    constructor(props) {
        super(props);
        this.state = { ...INITIAL_STATE};
        this.onFileChange = this.onFileChange.bind(this);
        this.onFileLoadEnded = this.onFileLoadEnded.bind(this);
        this.onFileLoadStarted = this.onFileLoadStarted.bind(this);
        this.deleteFile = this.deleteFile.bind(this);
    }
    onFileChange = (event) => {
        var file = event.target.files[0];
        if(file) {
            if (file.type.indexOf('video/') === 0 || file.type.indexOf('image/') === 0) {

                if (file.size > MAX_VIDEO_POST_SIZE_BTYE) {
                    this.props.openSnack({open: true, messageText: 'file to large to upload', variant: 'error'});
                    return;
                }
                if (file.size < 1) {
                    this.props.openSnack({open: true, messageText: 'not valid upload', variant: 'error'});
                    return;
                }
                var reader = new FileReader();
                reader.onloadstart = this.onFileLoadStarted;
                reader.onloadend = this.onFileLoadEnded;
                this.setState({fileInfo: file});
                reader.readAsDataURL(file);
            } else {
                this.props.openSnack({open: true, messageText: 'invalid file', variant: 'warning'});
            }
        }
    };
    onFileLoadStarted = (e) => {
        if(this.props.loading) {
            this.props.loading(true);
        }
    }
    onFileLoadEnded = (e) => {
        try {
            var fileSrc =  e.target.result;
            this.setState({ fileSrc: fileSrc});
            var fileObject = {src:fileSrc, info:this.state.fileInfo};
            this.props.onChange(fileObject);
        } catch(x) {
            this.props.openSnack( {open:true,messageText:'failed to read file', variant:'error'});
        }
        if(this.props.loading) {
            this.props.loading(false);
        }
    }
    deleteFile() {
        if(!this.props.isSaving) {
            this.setState({fileInfo: null, fileSrc: null});
            this.props.onChange(null);
        }
    }

    render() {
        const {accept, id, classes, isSaving, displayFileName, displayInput} = this.props;
        const {fileInfo} = this.state;
        return (
            <div>
                <label htmlFor={id} className={classes.upload}>
                    {!displayInput &&
                    <div class="MuiButtonBase-root MuiIconButton-root" style={{color: BLUE}}>
                        <PhotoCamera/>
                    </div>
                    }
                    {displayFileName &&
                        <Chip
                            size="small"
                            clickable={isSaving}
                            avatar={fileInfo.type.indexOf('image/') === 0 ? <Image className={classes.chipIcon}/> : <Videocam className={classes.chipIcon}/>}
                            label={displayFileName.length > 18 ? displayFileName.substr(0, 7)+'...'+displayFileName.substr(displayFileName.length - 7) : displayFileName}
                            onDelete={this.deleteFile}
                            className={classes.chip}
                        />
                    }
                </label>
                {isSaving ? <input
                        id={id}
                        type="file"
                        accept={accept}
                        onChange={this.onFileChange}
                        className={classes.hidden}
                        disabled
                    /> :
                    <input
                        id={id}
                        type="file"
                        accept={accept}
                        onChange={this.onFileChange}
                        className={displayInput ?  classes.show : classes.hidden}
                    />
                }
            </div>
        )
    }

}

export default compose (
    withRouter,
    withStyles(styles),
    withI18n(),
    connect(null, {openSnack})
)(FileUpload)
