import React, {Component, Fragment} from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Link, withRouter } from 'react-router-dom';
import {
    AppBar, Avatar, CssBaseline, Divider, Drawer, Toolbar, IconButton, Typography, Hidden, MenuList, MenuItem
} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { Menu,  Home, Description, Contacts, Message, Person, Group, LocalLibrary } from '@material-ui/icons';
import { compose } from 'recompose';
import { withNamespaces } from 'react-i18next';
import * as ROUTES from '../../constants/routes';
import UserMenu from './UserMenu';
import Footer from './Footer';
import {connect} from "react-redux";
import CustomSnackBar from '../CustomSnackBar'
import {APP_BAR, APP_BAR_FONT, DRAWER_ICON, MAIN_ICON, STAR_ICON} from "../../constants/colors";


const drawerWidth = 240;


const styles = theme => ({
    root: {
        flexGrow: 1,
        // height: 430,
        zIndex: 1,
        overflow: 'hidden',
        position: 'relative',
        display: 'flex',
        width: '100%',
    },
    paper: {
        marginRight: theme.spacing(3),
    },
    appBar: {
        zIndex: theme.zIndex.drawer + 1,
        backgroundColor: `${APP_BAR} !important`
    },
    navIconHide: {
        [theme.breakpoints.up('md')]: {
            display: 'none !important',
        }
    },
    navIconDisplay: {
        marginRight: theme.spacing(6)
    },
    toolbar: theme.mixins.toolbar,
    drawerPaper: {
        width: drawerWidth,
        [theme.breakpoints.up('md')]: {
            position: 'relative',
        },
        minHeight: '100vh'
    },
    palette : {
        primary : {
            main : '#000000'
        }
    },
    content: {
        flexGrow: 1,
        backgroundColor: theme.palette.background.default,
        minHeight: '100vh',
        overflowX: 'auto',
        // backgroundRepeat: 'no-repeat',
        // backgroundImage: `url(${Image})`,

    },
    pageContent: {
        flexGrow: 1,
        // backgroundColor: theme.palette.background.default,
        padding: theme.spacing(0),
        paddingTop: theme.spacing(3),
        paddingBottom: theme.spacing(3),
        marginBottom: theme.spacing(1),
        height: '88vh',
        //overflow: 'auto',
        width: '100%',
        //backgroundColor: '#ffffff',
       // border: 1px solid black;
       // opacity: 0.9,
       // filter: alpha(opacity=60)
    },
    footer : {
        flexGrow: 1,
        backgroundColor: `${APP_BAR} !important`,
        // padding: theme.spacing() * 3,
        bottom:0
    },
    nested: {
        paddingLeft: theme.spacing(4),
    },
    avatar: {
        backgroundColor : '#000'
    },
    bigAvatar: {
        width: 50,
        height: 50,
        backgroundColor : '#000',
    },
    smallAvatar: {
        width: 40,
        height: 30,
        backgroundColor : '#000'
    },
    sectionDesktop: {
        display: 'none',
        [theme.breakpoints.up('md')]: {
            display: 'flex',
        },
    },
    sectionMobile: {
        display: 'flex',
        [theme.breakpoints.up('md')]: {
            display: 'none',
        },
    },
    icon: {
        margin: theme.spacing(),
        //backgroundColor: red[500],
        color: MAIN_ICON,
        cursor: 'pointer'
    },

    iconInDrawMenu: {
        margin: theme.spacing(),
        //backgroundColor: red[500],
        color: DRAWER_ICON,
    },

    iconStar: {
        margin: theme.spacing(),
        //backgroundColor: red[500],
        color: STAR_ICON,
    },

    iconActive: {
        margin: theme.spacing(),
        //backgroundColor: red[500],
        color: 'white',
    },
    actions : {
        position: 'sticky',
        display: 'flex',
        marginRight: theme.spacing(.25),
        left: '100%',
    },
    appTitle : {
       // position: 'sticky',
        left: '45%',
        display: 'flex',
        position: 'sticky'
    },
    title : {
        marginTop: theme.spacing(1)
    }
});


class DrawerLayout extends Component {
    constructor(props) {
        super(props);
        this.state = {
            mobileOpen: false,
            userMenuOpen: false,
            openLanguageSelection: false,
            profileImageHash: Date.now()
        };
        this.handleDrawerToggle = this.handleDrawerToggle.bind(this);
        this.handleClick = this.handleClick.bind(this);
        this.handleLanguageSelectionClick = this.handleLanguageSelectionClick.bind(this);
        this.handleUserMenuToggle = this.handleUserMenuToggle.bind(this);
        this.handleUserMenuClose = this.handleUserMenuClose.bind(this);
    }

    handleClick = () => {
        this.setState(state => ({ open: !state.open }));
    };

    handleLanguageSelectionClick = () => {
        this.setState((prevState, props) => ({ openLanguageSelection: !prevState.openLanguageSelection }));
    };


    handleDrawerToggle = () => {
        this.setState(state => ({ mobileOpen: !state.mobileOpen }));
    };

    handleUserMenuToggle = () => {
        this.setState(state => ({ userMenuOpen: !state.userMenuOpen }));
    };

    handleUserMenuClose = event => {
        if (this.anchorEl.contains(event.target)) {
            return;
        }

        this.setState({ userMenuOpen: false });
    };

    updateProfileImageHash = () => {
        this.setState({profileImageHash: Date.now()});
    }

    render() {
        const { classes, location:{pathname}, children, t} = this.props;
        const {mobileOpen} = this.state;

        const mainMenuAuth = (
            <div>
                <Hidden smDown>
                    <Link to={ROUTES.HOME} title={t('application.home')}><Home className={classes.icon} /></Link>
                    <Link to={ROUTES.LIBRARY} title={t('application.library')}><LocalLibrary className={classes.icon} /></Link>
                    <Link to={ROUTES.FRIENDS} title={t('application.friends')}><Person className={classes.icon} /></Link>
                    <Link to={ROUTES.MESSAGES} title={t('application.messages')} ><Message className={classes.icon} /></Link>
                    <Link to={ROUTES.GROUPS} title={t('application.groups')}><Group className={classes.icon} /></Link>
                </Hidden>
                <Hidden mdUp>
                    <MenuList>
                        <MenuItem component={Link} to={ROUTES.HOME} selected={ROUTES.HOME === pathname} onClick={this.handleDrawerToggle}>
                            <Home className={classes.iconInDrawMenu} />
                            {t('application.home')}
                        </MenuItem>
                        <MenuItem component={Link} to={ROUTES.LIBRARY} selected={ROUTES.LIBRARY === pathname} onClick={this.handleDrawerToggle}>
                            <LocalLibrary className={classes.iconInDrawMenu} />
                            {t('application.library')}
                        </MenuItem>
                        <MenuItem component={Link} to={ROUTES.FRIENDS} selected={ROUTES.FRIENDS === pathname} onClick={this.handleDrawerToggle}>
                            <Person className={classes.iconInDrawMenu} />
                            {t('application.friends')}
                        </MenuItem>
                        <MenuItem component={Link} to={ROUTES.MESSAGES} selected={'/messages' === pathname} onClick={this.handleDrawerToggle}>
                            <Message className={classes.iconInDrawMenu} />
                            {t('application.messages')}
                        </MenuItem>
                        <MenuItem component={Link} to={ROUTES.GROUPS} selected={'/groups' === pathname} onClick={this.handleDrawerToggle}>
                            <Group className={classes.iconInDrawMenu} />
                            {t('application.groups')}
                        </MenuItem>
                        <MenuItem component={Link} to={ROUTES.ABOUT_US} selected={ROUTES.ABOUT_US === pathname} onClick={this.handleDrawerToggle}>
                            <Description className={classes.iconInDrawMenu} />
                            {t('application.about us')}
                        </MenuItem>
                        <MenuItem component={Link} to={ROUTES.CONTACT_US} selected={ROUTES.CONTACT_US === pathname} onClick={this.handleDrawerToggle}>
                            <Contacts className={classes.iconInDrawMenu} />
                            {t('application.contact us')}
                        </MenuItem>
                        <Divider />
                    </MenuList>
                </Hidden>
            </div>
        );

        const mainMenuNonAuth = (
            <div>
                <Hidden smDown>
                    <Link to={ROUTES.LANDING}><Home className={classes.icon} /></Link>
                </Hidden>
                <Hidden mdUp>
                    <MenuList>
                        <MenuItem component={Link} to={"/"} selected={'/'=== pathname} onClick={this.handleDrawerToggle}>
                            <Home className={classes.iconInDrawMenu} />
                            {t('application.home')}
                        </MenuItem>
                    </MenuList>
                </Hidden>
                <Divider />
            </div>
        );


        const drawer  = (
            <div>
                    {
                        this.props.authUser ? mainMenuAuth : mainMenuNonAuth
                    }
            </div>
        );

        const userMenuDisplay = (

            <div style={{cursor:'pointer'}}>
                        <UserMenu authUser={this.props.authUser} currentUser={this.props.currentUser} imageHash={this.props.profileImageHash} doSignOut={this.props.doSignOut} openLanguageSelection={this.state.openLanguageSelection} handleLanguageSelectionClick={this.handleLanguageSelectionClick} classes={classes}/>
            </div>

        );
       return <Fragment>
            <CssBaseline />
           <div className={classes.root}>
            <AppBar position='absolute' className={classes.appBar}>
                <Toolbar >
                    <IconButton
                        style={{color:APP_BAR_FONT}}
                        aria-label="Open drawer"
                        onClick={this.handleDrawerToggle}
                        className={classNames(classes.navIconHide, classes.navIconDisplay)}
                    >
                        <Menu />
                    </IconButton>
                        <Avatar
                            alt={t('application.title')}
                            src="../../logoOnly2.jpg"
                            aria-label={t('application.title')}
                            className={classNames(classes.bigAvatar)}
                            color={APP_BAR_FONT}

                        />
                    <Typography variant="title" color={APP_BAR_FONT} noWrap={true}>
                            <label style={{color:APP_BAR_FONT}}>{t('application.title')}</label>
                        </Typography>

                    <div className={classes.actions}>
                        <Hidden smDown implementation="css">
                            {drawer}
                        </Hidden>
                        {userMenuDisplay}
                    </div>
                </Toolbar>
            </AppBar>

            <Hidden mdUp>
                <Drawer
                    variant="temporary"
                    open={mobileOpen}
                    onClose={this.handleDrawerToggle}
                    classes={{
                        paper: classes.drawerPaper,
                    }}
                    ModalProps={{
                        keepMounted: true, // Better open performance on mobile.
                    }}
                >
                    {drawer}
                </Drawer>
            </Hidden>
            <main className={classes.content}>
                <div className={classes.toolbar} />
                <div className={classes.pageContent} class="momentumScroll">
                <CustomSnackBar />
                {children}
                </div>
                <br/>
                <br/>
                <Footer />
            </main>
        </div>
       </Fragment>
    }
}

DrawerLayout.propTypes = {
    classes: PropTypes.object.isRequired
};

const mapStateToProps = ({auth, user, profileImageHash}) => {
    return { authUser: auth, currentUser:user, profileImageHash:profileImageHash?profileImageHash:Date.now()};
};


export default compose (
    withRouter,
    withStyles(styles),
    withNamespaces(),
    connect(mapStateToProps)
)(DrawerLayout)
