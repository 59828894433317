import { FETCH_ORGANIZATION_INFO, FETCH_ORGANIZATION_LINK_CODES} from "../constants/action-types";

export default (state = null, action) => {
    switch (action.type) {
        case FETCH_ORGANIZATION_INFO:
            return action.payload || null;
        case FETCH_ORGANIZATION_LINK_CODES:
            return {...state, linkCodes:action.payload.linkCodes}
        default:
            return state;
    }
};