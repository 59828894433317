import React, { Component } from "react";
import PropTypes from "prop-types";
import { Chip} from "@material-ui/core";
import DraftsIcon from '@material-ui/icons/Drafts';

export class FriendInviteItem extends Component {
  render() {
    const { email, t } = this.props;
    return (
      <Chip onDelete={this.props.delFriend.bind(this, email)} icon={<DraftsIcon />} label={email} />
    );
  }
}

// PropTypes
FriendInviteItem.propTypes = {
  email: PropTypes.string.isRequired,
  delFriend: PropTypes.func.isRequired,
};

export default FriendInviteItem;
