import { combineReducers } from "redux";

import assignment from "./assignmentReducer";
import auth from "./authReducer";
import data from "./dataReducer";
import chatMessages from "./chatMessagesReducer";
import friends from "./friendsReducer";
import friendRequests from "./friendRequestsReducer";
import followers from "./followersReducer";
import followings from "./followingsReducer";
import followingRequests from "./followerRequestsReducer";
import group from "./groupReducer";
import groups from "./groupsReducer";
import images from "./imagesReducer";
import myChats from "./myChatsReducer";
import myFollowings from "./myFollowingsReducer";
import myFollowerRequests from "./myFollowerRequestsReducer";
import myFollowers from "./myFollowersReducer";
import mySentFollowingRequests from "./mySentFollowingRequestsReducer";
import myGroups from "./myGroupsReducer";
import myLessons from "./myLessonsReducer";
import lessons from "./lessonsReducer";
import lesson from "./lessonReducer";
import lessonAssignees from "./lessonAssigneesReducer";
import organizationInfo from "./organizationInfoReducer"
import organizationMemberRequests from "./organizationMemberRequestsReducer"
import organizationMembers from "./organizationMembersReducer"
import posts from "./postsReducer";
import profileImageHash from "./profileImageHashReducer";
import question from "./questionReducer";
import questions from "./questionsReducer";
import snack from "./snackReducer";
import user from "./userReducer";
import users from "./usersReducer";


const appReducer =  combineReducers({
    assignment,
    auth,
    chatMessages,
    data,
    friends,
    friendRequests,
    followers,
    followings,
    followingRequests,
    group,
    groups,
    images,
    myChats,
    myGroups,
    myFollowers,
    myFollowings,
    myFollowerRequests,
    myLessons,
    mySentFollowingRequests,
    lesson,
    lessons,
    lessonAssignees,
    organizationInfo,
    organizationMemberRequests,
    organizationMembers,
    posts,
    profileImageHash,
    question,
    questions,
    snack,
    user,
    users

});


const rootReducer = (state, action) => {
    if (action.type === 'USER_LOGOUT') {
        debugger;
        state = undefined
    }

    return appReducer(state, action)
}

export default rootReducer;