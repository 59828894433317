import { withI18n } from "react-i18next";
import { withStyles } from '@material-ui/core/styles';
import { Info, Edit } from '@material-ui/icons';
import {compose} from "recompose";
import {Avatar, CircularProgress, List, ListItem, ListItemIcon, ListItemSecondaryAction, ListItemText, ListSubheader, Divider, Tooltip, Typography} from '@material-ui/core';
import * as COLORS from "../../constants/colors";
import UploadCropImage from "../UploadCropImage";
import UserAvatar from "../UserAvatar";
import {connect} from "react-redux";
import { withRouter } from 'react-router-dom';
import {RIEInput, RIETextArea} from 'riek'
import {updateDocumentAttribute} from '../../actions'
import {VerifiedUser} from '@material-ui/icons';
import {AVATAR_TYPE, BADGE_TYPE, IMAGE_CROP_TYPE, LOGO} from "../../constants/common";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {faUserMinus} from "@fortawesome/free-solid-svg-icons/index";


const React = require('react');

const styles = theme => ({
    root: {
        flexGrow: 1,
        spacing: '40',
        backgroundColor: theme.palette.background.paper,
    },
    progressRoot: {
        flexGrow: 1,
        spacing: '40'
    },
    details: {
        width: '100%',
        maxWidth: 400,
        backgroundColor: theme.palette.background.paper,
    },
    orgDetails: {
        width: '100%',
       // maxWidth: 400,
        backgroundColor: theme.palette.background.paper,
    },
    progress: {
        margin: theme.spacing(2),
        color: COLORS.LOADING_MASK
    },
    chipsRoot : {
        display: 'flex',
        justifyContent: 'center',
        flexWrap: 'wrap',
    },
    chip: {
        margin: theme.spacing(1),
    },
    actions: {
        display: 'inherit',
    },
    media: {
        height: 100,
        paddingTop: '75.25%', // 16:9
    },
    listLabel: {
        textTransform: 'capitalize',
        width: 150,
        display: 'block',
        textAlign : 'right'

    },
    listText: {
        marginLeft: theme.spacing(.5)
    },
    avatar: {
        backgroundColor: COLORS.MAIN_ICON,
    },
    infoAvatar: {
        color: COLORS.INFO,
    },
    verifiedAvatar: {
        color: COLORS.GREEN,
    },
    unverifiedAvatar: {
        color: COLORS.ORANGE,
    },
    success : {
        color: 'green'
    }
});

class ProfileEdit extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            imageCropOpen: false,
            imageCropType: 'profile',
            imageCropTitle: '',
            imageCropDescription: '',
            imageHash: props.profileImageHash,
            editFirstname: false,
            editlastname: false


        };
        this.dataChanged = this.dataChanged.bind(this);
        this.validateText = this.validateText.bind(this);
        this.validateBio = this.validateBio.bind(this);
    }

    componentDidMount() {
        this.setState({loading: true});
        this.setState({imageCropTitle: this.props.t('profile image crop title')});
        this.setState({imageCropDescription: this.props.t('profile image crop description')});
        this.handleClickOpen = this.handleClickOpen.bind(this);
        this.updateHash = this.updateHash.bind(this);
        this.setState({loading: false});

    }

    handleClickOpen = () => {
        console.log("handleClickOpen clicked");
        this.setState({ imageCropOpen: true });
    };
    dataChanged(data) {
        this.props.updateDocumentAttribute({path:`users/${this.props.user.id}`, ...data, currentUsername: this.props.user.username, currentUserId:this.props.user.id});
    }

    toggleFirstNameEdit() {
        this.setState({editFirstname: !this.state.editFirstname})
    }

    toggleLastNameEdit() {
        this.setState({editLastname: !this.state.editLastname})
    }
    validateText(text) {
        const  {t} = this.props;
        const defaultFirstname = t('enter last name');
        const defaultLastname = t('enter first name');
        return (text.length > 0 && text.length < 57 && text !== defaultFirstname && text !== defaultLastname);
    }
    validateBio(text) {
        const  {t} = this.props;
        const bioDefault = t('enter bio');
        return (text.length > 0 && text.length < 201 && text !== bioDefault);
    }
    updateHash = () => {
        this.setState({imageHash: Date.now()});
    };
    render() {
        const {loading, imageCropTitle, imageCropDescription} = this.state;
        const {authUser, user, t, profileImageHash, classes} = this.props;
        const imageHash = profileImageHash ? profileImageHash: Date.now();
        return (
            (loading ? <div align="center" className={classes.progressRoot}> <CircularProgress className={classes.progress} size={100} thickness={6} /></div> :
                    <div className={classes.root}>
                        { user ?
                            <div>
                                <List subheader={<ListSubheader> {t('profile details')}</ListSubheader>}
                                      className={classes.details}>
                                    <ListItem>
                                        <ListItemIcon className={classes.listLabel}>
                                            {t('fields.avatar')}:
                                        </ListItemIcon>
                                        <ListItemText id="avatar" className={classes.listText}><Avatar src={authUser && authUser.photoURL ? `${authUser.photoURL}?${imageHash}` : "../../"+LOGO}/></ListItemText>
                                        <ListItemSecondaryAction>
                                            <UploadCropImage currentUser={user} icon={<Edit />} title={t('image crop title')} description={t('image crop description')}  typeId={authUser.uid} type={IMAGE_CROP_TYPE.PROFILE} isNew={authUser && authUser.photoURL? false : true}/>
                                        </ListItemSecondaryAction>
                                    </ListItem>
                                    <ListItem>
                                        <ListItemIcon className={classes.listLabel}>
                                            {t('fields.username')}:
                                        </ListItemIcon>
                                        <ListItemText className={classes.listText} id="username" primary={user.username}/>
                                    </ListItem>
                                    <ListItem>
                                        <ListItemIcon className={classes.listLabel}>
                                            {t('fields.email')}:
                                        </ListItemIcon>
                                        <ListItemText id="email" primary={user.email} className={classes.listText}/>
                                    </ListItem>
                                    <ListItem>
                                        <ListItemIcon className={classes.listLabel}>
                                            {t('fields.firstname')}:
                                        </ListItemIcon>
                                        <ListItemText id="firstname" className={classes.listText}>
                                            <RIEInput
                                                ref="firstnameEdit"
                                                maxLength={56}
                                                validate={this.validateText}
                                                activeClassName="editing"
                                                value={user.firstname ? user.firstname : t('enter first name')}
                                                propName="firstname"
                                                change={(data) => this.dataChanged({ attribute: "firstname", value:data.firstname})}
                                            />
                                        </ListItemText>
                                        <ListItemSecondaryAction>
                                                <Tooltip  title={t('inline edit instruction')} aria-label={t('inline edit instruction')}>
                                                    <Info className={classes.infoAvatar}/>
                                                </Tooltip>
                                        </ListItemSecondaryAction>
                                    </ListItem>
                                    <ListItem>
                                        <ListItemIcon className={classes.listLabel}>
                                            {t('fields.lastname')}:
                                        </ListItemIcon>
                                        <ListItemText id="lastname" className={classes.listText}>
                                            <RIEInput
                                                ref="lastnameEdit"
                                                maxLength={56}
                                                validate={this.validateText}
                                                value={user.lastname ? user.lastname : t('enter last name')}
                                                propName="lastname"
                                                change={(data) => this.dataChanged({attribute: "lastname", value:data.lastname})}

                                            />
                                        </ListItemText>
                                        <ListItemSecondaryAction>
                                            <Tooltip title={t('inline edit instruction')} aria-label={t('inline edit instruction')}>
                                                    <Info className={classes.infoAvatar}/>
                                            </Tooltip>
                                        </ListItemSecondaryAction>
                                    </ListItem>
                                    <ListItem>
                                        <ListItemIcon className={classes.listLabel}>
                                            {t('fields.designation')}:
                                        </ListItemIcon>
                                        <ListItemText id="designation" className={classes.listText} primary={t(user.designation).toUpperCase()}/>
                                    </ListItem>
                                    <ListItem>
                                        <ListItemIcon className={classes.listLabel}>
                                            {t('fields.bio')}:
                                        </ListItemIcon>
                                        <ListItemText id="bio" className={classes.listText}>
                                            <RIETextArea
                                                ref="bioEdit"
                                                maxLength={200}
                                                validate={this.validateBio}
                                                value={user.bio ? user.bio : t('enter bio')}
                                                propName="bio"
                                                change={(data) => this.dataChanged({attribute: "bio", value:data.bio})}

                                            />
                                        </ListItemText>
                                        <ListItemSecondaryAction>
                                            <Tooltip title={t('inline edit instruction')} aria-label={t('inline edit instruction')}>
                                                <Info className={classes.infoAvatar}/>
                                            </Tooltip>
                                        </ListItemSecondaryAction>
                                    </ListItem>
                                </List>
                                <Divider />
                                {user.organizationList &&
                                <List subheader={<ListSubheader> {t('Organizations')}</ListSubheader>}
                                      className={classes.orgDetails}>
                                    {user.organizationList.map(organization =>
                                        <span>
                                            <Divider variant="inset" component="li"/>
                                                <ListItem key={organization.id} >
                                                    <ListItemIcon className={classes.listLabel}>
                                                        <UserAvatar username={organization.name} userId={organization.id}
                                                                    imageUrl={organization.profileImageUrl} type={AVATAR_TYPE.ORGANIZATION}/>
                                                    </ListItemIcon>
                                                    <ListItemText className={classes.listText} id={organization.id}
                                                                  primary={organization.name}
                                                                  secondary={<span>
                                                                      {organization.parentList && organization.parentList.map(parent =>
                                                                          <UserAvatar size="small" username={parent.username} userId={parent.id}
                                                                                      imageUrl={parent.profileImageUrl} badge={BADGE_TYPE.PARENT}/>
                                                                      )}
                                                                      {organization.childrenList && organization.childrenList.map(child =>
                                                                          <UserAvatar size="small" username={child.username} userId={child.id}
                                                                                      imageUrl={child.profileImageUrl} badge={BADGE_TYPE.CHILD}/>
                                                                      )}
                                                                  </span>}/>
                                                    <ListItemSecondaryAction>
                                                         { organization.isApproved ?
                                                             <Tooltip title={t('status.approved')} aria-label={t('status.approved')}>
                                                                 <VerifiedUser className={classes.verifiedAvatar}/>
                                                             </Tooltip>
                                                             :
                                                             <Tooltip title={ t('status.pending')} aria-label={ t('status.pending')}>
                                                                 <FontAwesomeIcon icon={faUserMinus} className={classes.unverifiedAvatar} title={ t('status.pending')}/>
                                                             </Tooltip>
                                                         }
                                                    </ListItemSecondaryAction>
                                                </ListItem>
                                        </span>
                                    )}
                                </List>
                                }
                            </div> :
                            <div align="center" className={classes.progressRoot}><CircularProgress
                                className={classes.progress} size={30} thickness={4}/></div>
                        }
                    </div>
        )   )
    }
}
const mapStateToProps = ({auth, user, profileImageHash}) => {
    if(profileImageHash) {
        return {authUser:auth, user:user, profileImageHash:profileImageHash};
    } else {
        return {authUser:auth, user:user};
    }
};

export default compose (
    withStyles(styles),
    withI18n(),
    withRouter,
    connect(mapStateToProps, {updateDocumentAttribute})
)(ProfileEdit)