import { withI18n } from "react-i18next";
import { withStyles } from '@material-ui/core/styles';
import {compose} from "recompose";
import {QUESTION_TYPE} from "../../constants/common";
import {Close } from '@material-ui/icons';
import {Avatar, Button, Card, CardHeader, CardContent, Checkbox, Dialog, DialogActions, DialogContent, DialogTitle, Grid, IconButton, LinearProgress, List, ListItem, ListItemIcon, ListItemText, TextField, Typography} from'@material-ui/core';
import {submitGrade, openSnack} from "../../actions";
import UserAvatar from "../UserAvatar";
import {connect} from "react-redux";
import {GREEN, LINARY_LOADING_MASK, LINARY_LOADING_MASK_BAR, PRIMARY, RED, LOW_GRADE} from "../../constants/colors";

const React = require('react');

const styles = theme => ({
    container: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500],
    },
    scoreAvatar: {
        width: 50,
        height: 50,
    },
    formControl: {
        margin: 5,
        minWidth: 120,
    },
    icon: {
        margin: theme.spacing(),
        color: PRIMARY,
    },
    uploadProgress : {
        flexGrow: 1,
    },
    linearColorPrimary : {
        backgroundColor: LINARY_LOADING_MASK,
    },
    linearBarColorPrimary : {
        backgroundColor: LINARY_LOADING_MASK_BAR,
    },
    correct : {
        color: GREEN,
        '&$checked': {
            color: GREEN +'!important',
        },
        secondary: GREEN
    },
    incorrect : {
        color: RED,
        '&$checked': {
            color: RED,
        },
    },
    failAvatar : {
        backgroundColor: RED,
        width: 50,
        height: 50
    },
    passAvatar : {
        backgroundColor: GREEN,
        width: 50,
        height: 50
    },
    lowAvatar : {
        backgroundColor: LOW_GRADE,
        width: 50,
        height: 50
    }


});

const INITIAL_STATE = {
    saving: false,
    submission: null,
    submittedAnswers: null
};
class QuestionsGrade extends React.Component {

    constructor(props) {
        super(props);

        this.state = { ...INITIAL_STATE};
        this.handleClose = this.handleClose.bind(this);
        this.areSetsEqual = this.areSetsEqual.bind(this);
        this.onChange = this.onChange.bind(this);
        this.onQuestionCorrectCheck = this.onQuestionCorrectCheck.bind(this);
        this.onQuestionIncorrectCheck = this.onQuestionIncorrectCheck.bind(this);

    }

    componentWillUpdate(nextProps) {
        if (nextProps.assignee && this.state.submission === null) {
            var submission = nextProps.assignee.lastSubmission;
            var submittedAnswers = {};
            const answerKeys = Object.keys(submission.answers);
            for(const key of answerKeys){
               if(submission.answers[key].answers){
                   submittedAnswers[key] = {answers : new Set(submission.answers[key].answers)};
               } else {
                   submittedAnswers[key] = {answer: submission.answers[key].answer};
                }
            }
            var newSubmission = this.calculateGrade(nextProps.questions, submission, nextProps.assignee.lastGraded);
            this.setState({submission:newSubmission, submittedAnswers:submittedAnswers});
        }

    }

    handleClose = () => {
        this.setState({...INITIAL_STATE});
        this.props.close();
    };

    handleSubmit = () => {
        this.setState({ 'saving': true });
        const {currentUserId, lessonId, assignee, organizationId, currentUsername} = this.props;
        debugger;
        const submission = this.state.submission;
        var answers = {};
        const answerKeys = Object.keys(submission.answers);
        for(const key of answerKeys){
            if(submission.answers[key].answers) {
                answers[key] = {isCorrect:submission.answers[key].isCorrect};
            }
        }
        this.props.submitGrade({organizationId:organizationId, lessonId:lessonId, currentUserId:currentUserId, currentUsername:currentUsername, answers, assigneeId:assignee.id, callback:this.saveCompleted});
    };

    saveCompleted = (success) => {
        this.setState({ 'saving': false });
        if(success && this.props.onGradeComplete) {
            this.props.onGradeComplete();
        }
    };

    onChange = prop => event => {
        this.setState({ [prop]: event.target.value });
    };

    calculateGrade = (questions, submission, lastGrade) => {
        var totalCorrect = 0;
        var questionsGraded = new Set();
        var that = this;
        var newSubmission = { answers: {}};
        questions.forEach(function (question) {
            var answerSubmitted = submission.answers[question.id];
            if(answerSubmitted.answers) {
                newSubmission.answers[question.id] = {answers : answerSubmitted.answers.slice()};
            } else {
                newSubmission.answers[question.id]= {answer : answerSubmitted.answer};
            }
            if(answerSubmitted.isCorrect !== undefined) {
                newSubmission.answers[question.id].isCorrect = answerSubmitted.isCorrect;
                questionsGraded.add(question.id);
                totalCorrect = submission.answers[question.id].isCorrect ? totalCorrect+1 : totalCorrect;
            } else if(question.type === QUESTION_TYPE.MULTIPLE_CHOICE) {
                var correctAnswers = new Set();
                question.answers.forEach(function (answer, index) {
                    if(answer.isCorrect) {
                        correctAnswers.add(index)
                    }
                });
                if(that.areSetsEqual(correctAnswers, submission.answers[question.id].answer)){
                    totalCorrect++;
                    newSubmission.answers[question.id].isCorrect = true;
                } else {
                    newSubmission.answers[question.id].isCorrect = false;
                }
                questionsGraded.add(question.id);
            }
        });
        newSubmission.totalQuestions = questions.length;
        newSubmission.totalCorrect = totalCorrect;
        newSubmission.questionsGraded = questionsGraded;
        return newSubmission;

    };
    areSetsEqual(setA, SetB){
        if (setA.size !== SetB.size) {
            return false;
        }
        for (var a of setA) {
            if (!SetB.has(a)) {
                return false;
            }
        }
        return true;
    };

    onQuestionCorrectCheck = (questionId) => event => {
        debugger;
        const submission = this.state.submission;
        if(event.target.checked) {
            if(!submission.answers[questionId].isCorrect) {
                submission.totalCorrect = submission.totalCorrect+1;
            }
            submission.answers[questionId].isCorrect = true;
            submission.questionsGraded.add(questionId);
        } else {
            submission.answers[questionId].isCorrect = false;
            submission.totalCorrect = submission.totalCorrect-1;
            submission.questionsGraded.delete(questionId);
        }
        this.setState({submission:submission});
    }

    onQuestionIncorrectCheck = (questionId) => event => {
        const submission = this.state.submission;
        if(event.target.checked) {
            if(submission.answers[questionId].isCorrect) {
                submission.totalCorrect = submission.totalCorrect-1;
            }
            submission.answers[questionId].isCorrect = false;
            submission.questionsGraded.add(questionId);
        } else {
                submission.answers[questionId].isCorrect = undefined;
            submission.questionsGraded.delete(questionId);
        }
        this.setState({submission:submission})
    };
    render() {
        const {t, classes, questions, assignee} = this.props;
        const {
            saving,
            submission,
        } = this.state;
        const isInvalid = (submission && submission.questionsGraded.size !== submission.totalQuestions) || saving ;
        return (
            <div align="center">
                {assignee && submission
                && <Dialog
                    open={this.props.open}
                    onClose={this.handleClose}
                    aria-labelledby="form-dialog-title"
                    fullWidth={true}
                    maxWidth={'md'}
                >
                    <DialogTitle id="form-dialog-title">
                        <Typography variant="h6" className={classes.title}>
                            <ListItem dense>
                                <ListItemIcon>
                                    <UserAvatar username={assignee.username} userId={assignee.id}
                                                imageUrl={assignee.profileImageUrl}/>
                                </ListItemIcon>
                                <ListItemText primary={assignee.username}/>
                                <ListItemIcon>
                                    <Avatar className={(submission.totalCorrect/submission.totalQuestions * 100) < 45 ? classes.failAvatar :
                                        (submission.totalCorrect/submission.totalQuestions * 100) < 70 ? classes.lowAvatar : classes.passAvatar}
                                        > {Math.ceil(submission.totalCorrect/submission.totalQuestions * 100)}</Avatar>
                                </ListItemIcon>
                            </ListItem>
                        </Typography>
                        <IconButton aria-label="Close" className={classes.closeButton} onClick={this.handleClose}>
                            <Close/>
                        </IconButton>
                    </DialogTitle>
                    <DialogContent dividers>
                        <Grid container spacing={2}>
                            {questions.map(question => (
                                <Grid item key={question.id} xs={12} md={12} lg={12}>
                                    <Card className={classes.card}>
                                        <CardHeader
                                            title={question.question}
                                            action={
                                                <div>
                                                    <Checkbox
                                                        style={{color:GREEN}}
                                                        checked={submission.answers[question.id].isCorrect && submission.answers[question.id].isCorrect === true}
                                                        inputProps={t('correct')}
                                                        onClick={this.onQuestionCorrectCheck(question.id)}
                                                    />
                                                    <Checkbox
                                                        style={{color:RED}}
                                                        checked={submission.answers[question.id].isCorrect !== undefined && submission.answers[question.id].isCorrect === false}
                                                        inputProps={t('incorrect')}
                                                        onClick={this.onQuestionIncorrectCheck(question.id)}
                                                        indeterminate={submission.answers[question.id].isCorrect !== undefined && submission.answers[question.id].isCorrect === false}
                                                    />
                                                </div>
                                            }
                                        />
                                        <CardContent>
                                            {question.type === QUESTION_TYPE.MULTIPLE_CHOICE ?
                                                <List className={classes.root}>
                                                    {question.answers.map(answer => (

                                                        <ListItem
                                                            key={'answer-' + question.id + '-' + answer.id}
                                                            role={undefined} dense>
                                                            <ListItemIcon>
                                                                {answer.isCorrect ?

                                                                     <Checkbox
                                                                         style={{color:GREEN}}
                                                                        checked={submission.answers[question.id].answers.includes(answer.id)}
                                                                        inputProps={t('field.submited Answer')}
                                                                        classes={{
                                                                            root: classes.correct,
                                                                            checked: classes.correct,
                                                                        }}
                                                                    />

                                                                    :

                                                                     <Checkbox
                                                                            checked={submission.answers[question.id].answers.includes(answer.id)}
                                                                            style={{color:RED}}
                                                                            inputProps={t('field.submited Answer')}
                                                                            classes={{
                                                                                root: classes.incorrect,
                                                                                checked: classes.incorrect,
                                                                            }}
                                                                        />

                                                                }


                                                            </ListItemIcon>
                                                            <ListItemText style={answer.isCorrect? {color:GREEN} : {color:RED}}
                                                                id={'answer-value-' + question.id + '-' + answer.id}
                                                                primary={answer.value}/>
                                                        </ListItem>

                                                    ))}
                                                </List> :
                                                <TextField disabled={true}
                                                           multiline
                                                           rows="2"
                                                           value={submission.answers[question.id].answer}
                                                           required type="text" label={t('fields.answer')}/>
                                            }
                                        </CardContent>
                                    </Card>
                                </Grid>
                            ))}
                        </Grid>

                    </DialogContent>
                    {saving &&
                    <div className={classes.uploadProgress}>
                        <LinearProgress classes={{
                            colorPrimary: classes.linearColorPrimary,
                            barColorPrimary: classes.linearBarColorPrimary,
                        }}/>
                    </div>
                    }
                    <DialogActions>
                        <Button onClick={this.handleClose} color="primary">
                            {t('buttons.cancel')}
                        </Button>
                        <Button onClick={this.handleSubmit} color="primary" disabled={isInvalid}>
                            {t('buttons.grade')}
                        </Button>
                    </DialogActions>
                </Dialog>
                }
            </div>
        );
    }

}

export default compose (
    withStyles(styles),
    withI18n(),
    connect(null, {submitGrade, openSnack})
)(QuestionsGrade)
