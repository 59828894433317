import { withI18n } from "react-i18next";
import { withStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
import {withRouter } from 'react-router-dom';
import {compose} from "recompose";
import PDFViewer from 'pdf-viewer-reactjs'
import * as ROUTES from '../../constants/routes';
import * as ROLES from '../../constants/roles';
import {Button, Card, CardActions, CardContent, CardHeader, Checkbox, Dialog, DialogActions, DialogContent, InputLabel, FormControl, FormControlLabel, Grid, Link, Select, TextField, Typography, LinearProgress} from '@material-ui/core';
import { connect } from "react-redux";
import { openSnack} from "../../actions";
import PropTypes from "prop-types";
import {LINARY_LOADING_MASK_BAR, RADIO_BUTTON_SELECT} from "../../constants/colors";
import {LINARY_LOADING_MASK} from "../../constants/colors";
import {fbFunctions} from "../Firebase/firebase";
import termsAndConsPdf from "../../SocipodTerms&Condition.pdf"

const React = require('react');

const styles = {
    card: {
        minWidth: 275,
        maxWidth: 400,
    },
    bullet: {
        display: 'inline-block',
        margin: '0 2px',
        transform: 'scale(0.8)',
    },
    actions: {
        display: 'inherit',
    },
    formControl: {
        margin: 5,
        minWidth: 120,
    },
    pos: {
        marginBottom: 12,
    },
    title: {
        textTransform: 'uppercase'
    },
    uploadProgress : {
        flexGrow: 1,
    },
    linearColorPrimary : {
        backgroundColor: LINARY_LOADING_MASK,
    },
    linearBarColorPrimary : {
        backgroundColor: LINARY_LOADING_MASK_BAR,
    },
    content : {
        width: '90%',
        height: '90%',
        minWidth:'400'
    },
};

const INITIAL_STATE = {
    username: '',
    email: '',
    designation: ROLES.STUDENT,
    passwordOne: '',
    passwordTwo: '',
    showPasswordOne: false,
    showPasswordTwo: false,
    termsAndConditionsAccepted:false,
    openTermsAndConditions: false,
    isAcceptTermsInvalid: true,
    documentNavigation:{pages:null, page:1},
    numPages: null,
    pageNumber: 1,
    saving:false,
    pdfFile:termsAndConsPdf
};
class SignUp extends React.Component {

    constructor(props) {
        super(props);

        this.state = { ...INITIAL_STATE};
        this.onSubmit = this.onSubmit.bind(this);
        this.onChange = this.onChange.bind(this);
        this.toggleTermsDisplay = this.toggleTermsDisplay.bind(this);
        this.onCheckChange = this.onCheckChange.bind(this);
        this.onNext = this.onNext.bind(this);
        this.handleAccept = this.handleAccept.bind(this);

    }

    static contextTypes = {
        router: PropTypes.object
    };

    componentWillUpdate(nextProps, nextState, nextContext) {
        if (nextProps.auth) {
            this.props.history.push(ROUTES.HOME);
        }
    }

    onNext = (page) => {
        debugger;
        if(page === 34) {
            this.setState({ isAcceptTermsInvalid:false });
        }
    }
    onPrevious = ( page, pages) => {
        debugger;

        this.setState({ pages });
    }

    handleAccept = () => {
        debugger;
        this.setState({openTermsAndConditions:false, termsAndConditionsAccepted:true });
    }

    toggleTermsDisplay = () => {
        debugger;
        this.setState({openTermsAndConditions:!this.state.openTermsAndConditions})
    }
    onCheckChange = prop => event => {
        debugger;
        this.setState({ [prop]: event.target.checked });
    };

    onSubmit = event => {
        event.preventDefault();

        const {username, email, designation, passwordOne, termsAndConditionsAccepted } = this.state;
        this.setState({saving:true});
        var that = this;
        var registerUser = fbFunctions.httpsCallable('registerUser');
        registerUser({email:email, password:passwordOne, username:username, designation:designation,termsAndConditionsAccepted:termsAndConditionsAccepted}).then(function (result) {
            that.props.openSnack({open:true, messageText: 'messages.registration success', variant:'success'});
            that.props.history.push(ROUTES.LANDING);
        }).catch(error => {
            that.setState({saving:false});
            that.props.openSnack({open:true, messageText: error.message, variant:'error'});
        });
    };
    onChange = prop => event => {
        var cleanValue = event.target.value;
        if(cleanValue){
            cleanValue = cleanValue.replace(/\s/g, '');
        }
        this.setState({ [prop]: cleanValue });
    };

    render() {
        const {t, classes} = this.props;
        const {
            username,
            email,
            designation,
            passwordOne,
            passwordTwo,
            termsAndConditionsAccepted,
            saving,
            openTermsAndConditions,
            isAcceptTermsInvalid,
            documentNavigation,
            pageNumber,
            numPages,
            pdfFile
        } = this.state;

        const isInvalid =
            passwordOne !== passwordTwo ||
            passwordOne === '' ||
            designation === '' ||
            email === '' ||
            username === '' || saving || !termsAndConditionsAccepted;
        return (
            <div align="center">
                <Dialog
                    open={openTermsAndConditions?openTermsAndConditions:false}
                    onClose={this.toggleTermsDisplay}
                >
                    <DialogContent>
                            <PDFViewer
                                document={{uri:pdfFile}}
                                // file={pdfFile}
                                onNextBtnClick={this.onNext}
                                hideRotation
                            >
                            </PDFViewer>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={this.toggleTermsDisplay} color="primary">
                            {t('buttons.cancel')}
                        </Button>
                        <Button onClick={this.handleAccept} color="primary" disabled={isAcceptTermsInvalid}>
                            {t('buttons.accept')}
                        </Button>
                    </DialogActions>
                </Dialog>
                <Card className={classes.card}>
                    <form onSubmit={this.onSubmit}>
                        <CardHeader
                                    title={ <Typography  className={classes.title}>
                                                    {t('application.sign up')}
                                        </Typography>
                                    }
                        />
                        <CardContent>
                                <div>
                                    <Grid>
                                        <Grid item>
                                            <TextField value={email} type="text" onChange={this.onChange('email')} id="input-email" label={t('fields.email')} />
                                        </Grid>
                                        <Grid item>
                                            <TextField value={username} type="text" onChange={this.onChange('username')} id="input-username" label={t('fields.username')} />
                                        </Grid>
                                        <Grid item>
                                            <FormControl className={classes.formControl}>
                                                <InputLabel htmlFor="designation-select" required shrink={true}><Typography > {t('fields.designation')}</Typography></InputLabel>
                                                <Select
                                                    value={designation}
                                                    onChange={this.onChange('designation')}
                                                    inputProps={{
                                                        name: 'designationId',
                                                        id: 'designation-select',
                                                    }}
                                                    native
                                                >

                                                    <option value={ROLES.ADMINISTRATION}>{t('administration')}</option>
                                                    <option value={ROLES.PARENT}>{t('parent')}</option>
                                                    <option value={ROLES.PROFESSOR}>{t('professor')}</option>
                                                    <option value={ROLES.STUDENT}>{t('student')}</option>
                                                    ))}
                                                </Select>
                                            </FormControl>
                                        </Grid>
                                        <Grid item>
                                            <TextField
                                                id="input-password-one"
                                                className={classNames(classes.margin, classes.textField)}
                                                variant="filled"
                                                type={this.state.showPasswordOne ? 'text' : 'password'}
                                                label={t('fields.password')}
                                                value={this.state.passwordOne}
                                                onChange={this.onChange('passwordOne')}
                                            />
                                        </Grid>
                                        <Grid item>
                                            <TextField
                                                id="input-password-two"
                                                className={classNames(classes.margin, classes.textField)}
                                                variant="filled"
                                                type={this.state.showPasswordOne ? 'text' : 'password'}
                                                label={t('fields.password confirm')}
                                                value={this.state.passwordTwo}
                                                onChange={this.onChange('passwordTwo')}
                                            />
                                        </Grid>
                                        <Grid item >
                                            <FormControl required className={classes.formControl}>
                                                <FormControlLabel
                                                control={<Checkbox
                                                    checked={termsAndConditionsAccepted}
                                                    value={termsAndConditionsAccepted}
                                                    onChange={this.onCheckChange('termsAndConditionsAccepted')}
                                                    // color="primary"
                                                    style={{color:RADIO_BUTTON_SELECT}}
                                                    disabled={isAcceptTermsInvalid}


                                                />}
                                                label={<Typography> I accept the <Link onClick={this.toggleTermsDisplay}>terms and conditions</Link> of socipod. </Typography>}
                                                />
                                            </FormControl>
                                        </Grid>
                                    </Grid>
                                </div>

                        </CardContent>
                        {saving &&
                        <div className={classes.uploadProgress}>
                            <LinearProgress classes={{
                                colorPrimary: classes.linearColorPrimary,
                                barColorPrimary: classes.linearBarColorPrimary,
                            }}/>
                        </div>
                        }
                        <CardActions  className={classes.actions}>
                            <Button size="small" color="primary" type="submit" disabled={isInvalid}>{t('application.sign up')}</Button>
                        </CardActions>
                    </form>
                </Card>
                <Typography color="textSecondary">
                    {t('already have an account?')} <Link to={ROUTES.SIGN_IN} className={classes.link}>{t('application.log in')} </Link>
                </Typography>

            </div>


        )
    }

}
function mapStateToProps({ auth }) {
    return { auth };
}
export default compose (
    withRouter,
    withStyles(styles),
    withI18n(),
    connect(mapStateToProps, { openSnack })
)(SignUp)
