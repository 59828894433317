import { withI18n } from "react-i18next";
import { withStyles } from '@material-ui/core/styles';
import {withRouter } from 'react-router-dom';
import {compose} from "recompose";
import * as COLORS from "../../constants/colors";
import CustomReactSelect from '../CustomReactSelect'
import 'react-image-crop-component/style.css'
import { Dialog, DialogContent, DialogTitle, IconButton, Grid, LinearProgress, Typography} from'@material-ui/core';
import { connect } from "react-redux";
import {createChat, openSnack} from "../../actions";
import Message from "../Message";
import {ASSIGNEE_TYPE, USER_SEARCH_TYPE} from "../../constants/common";

const React = require('react');

const styles = theme => ({
    container: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    colorSwitchBase: {
        color: COLORS.SWITCH,
        '&$colorChecked': {
            color: COLORS.SWITCH,
            '& + $colorBar': {
                backgroundColor: COLORS.SWITCH,
            },
        },
    },
    content : {
        minWidth: 300,
    },
    colorBar: {},
    colorChecked: {},
    uploadProgress : {
        flexGrow: 1,
    },
    linearColorPrimary : {
        backgroundColor: COLORS.LINARY_LOADING_MASK,
    },
    linearBarColorPrimary : {
        backgroundColor: COLORS.LINARY_LOADING_MASK_BAR,
    },
    actions: {
        display: 'inherit',
    },
    Avatar: {
        width: 70,
        height: 70,
        // padding: theme.spacing.unit * 2,
        backgroundColor: COLORS.MAIN_ICON,
    },
    Post: {
        width: 304,
        height: 171,
        //height: 0,
        //paddingTop: '56.25%', // 16:9
    },
    icon: {
        margin: theme.spacing.unit,
        color: COLORS.BLUE,
    },
});

const INITIAL_STATE = {
    content:null,
    participant: null,
    error: null,
    saving: false,
};
class ChatCreate extends React.Component {

    constructor(props) {
        super(props);


        this.state = { ...INITIAL_STATE, open:props.open, type:props.type};
        this.handleClickOpen = this.handleClickOpen.bind(this);
        this.handleClose = this.handleClose.bind(this);
        this.handleCreate = this.handleCreate.bind(this);
        this.onChange = this.onChange.bind(this);
        this.setSelectedUser = this.setSelectedUser.bind(this);
        this.sendMessageComplete = this.sendMessageComplete.bind(this);
        this.createChatCallback = this.createChatCallback.bind(this);


    }

    handleClickOpen = () => {
        this.setState({ open: true});
    };

    handleClose = () => {
        this.setState({ open: false});
    };

    handleCreate = (callbackCompleted) => {
        this.setState({'onbeforeMessageCallbackCompleted': callbackCompleted});
        const {participant} = this.state;
        const {currentUser} = this.props;
        this.props.createChat({participant:participant, currentUser:currentUser, callback:this.createChatCallback});

    };
    createChatCallback (success, chat) {
        if(success) {
            this.setState({chat:chat});
        }
        this.state.onbeforeMessageCallbackCompleted(success);
    }
    sendMessageComplete() {
        debugger;
        this.handleClose();
        this.props.onComplete(this.state.chat);
    }
    onChange = prop => event => {
        this.setState({ [prop]: event.target.value });
    };

    setSelectedUser(value) {
        if(value === null) {
            this.setState({participant: null});
        } else {
            var participant = {userId: value.value, username:value.label};
            if(value.profileImageUrl) {
                participant.profileImageUrl = value.profileImageUrl;
            }
            this.setState({participant: participant});
        }

    };

    render() {
        const {
            participant,
            saving,
            open
        } = this.state;
        const {t, classes, icon, currentUser} = this.props;
        return (
            <div>
                <IconButton aria-label={t('buttons.new')}
                            onClick={() => this.handleClickOpen()}
                          >
                    {icon}
                </IconButton>
                <Dialog
                    open={open?open:false}
                    onClose={this.handleClose}
                    aria-labelledby="form-dialog-title"
                >
                    <DialogTitle id="form-dialog-title"> <Typography  className={classes.title}>
                        {t('new message')}
                    </Typography></DialogTitle>
                    <DialogContent className={classes.content}>
                        <div>
                            <Grid>
                                <Grid item xs={12}>
                                    <CustomReactSelect type={USER_SEARCH_TYPE.MESSAGE} onChange={this.setSelectedUser} isMulti={false} selectionType={ASSIGNEE_TYPE.USER}/>
                                </Grid>
                                <Grid item xs={12}>
                                    <Message onCancel={this.handleClose} participant={participant} currentUser={currentUser} onSendComplete={this.sendMessageComplete} onBeforeSend={this.handleCreate} readOnly={false} messageId={'newMessage'}/>
                                </Grid>
                            </Grid>
                        </div>
                    </DialogContent>
                    {saving &&
                    <div className={classes.uploadProgress}>
                        <LinearProgress classes={{
                            colorPrimary: classes.linearColorPrimary,
                            barColorPrimary: classes.linearBarColorPrimary,
                        }}/>
                    </div>
                    }
                    {/*<DialogActions>*/}
                        {/*<Button onClick={this.handleClose} color="primary">*/}
                            {/*Cancel*/}
                        {/*</Button>*/}
                        {/*<Button onClick={this.handleSave} color="primary" disabled={isInvalid}>*/}
                            {/*{t('buttons.send')}*/}
                        {/*</Button>*/}
                    {/*</DialogActions>*/}
                </Dialog>
            </div>
        )
    }

}

export default compose (
    withRouter,
    withStyles(styles),
    withI18n(),
    connect(null, {createChat, openSnack })
)(ChatCreate)
