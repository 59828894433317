import React from 'react';
import { withI18n } from "react-i18next";
import {compose} from "recompose";
import {doSignOut} from "../../actions";
import MenuItem from '@material-ui/core/MenuItem';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSignOutAlt } from '@fortawesome/free-solid-svg-icons'
import {connect} from "react-redux";

const SignOutButton = ({ classes, t, doSignOut}) => (
    <MenuItem onClick={doSignOut}>
        <FontAwesomeIcon icon={faSignOutAlt} className={classes.iconInDrawMenu}/>{t('application.sign out')}
    </MenuItem>
);

export default compose (
    withI18n(),
    connect(null, { doSignOut })
)(SignOutButton)
