import React from "react";
import PropTypes from "prop-types";
import { FriendInviteItem } from "./FriendInviteItem";
import { Grid } from "@material-ui/core";

class FriendInvites extends React.Component {
  render() {
    return (
      <Grid
        container
        spacing={1}
        direction="column"
        justify="center"
        verticalalign="center"
      >
      {this.props.emails.map(
        (email) => (
        <Grid container item>
          <FriendInviteItem
            key={email}
            email={email}
            delFriend={this.props.delFriend}
          />
        </Grid>
        ))}
      </Grid>
    );
  }
}

// PropTypes
FriendInvites.propTypes = {
  emails: PropTypes.array.isRequired,
  delFriend: PropTypes.func.isRequired,
};

export default FriendInvites;
