import { withI18n } from "react-i18next";
import { Link } from 'react-router-dom';
import React, {Component} from 'react';
import {AppBar, Grid, Typography} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { Email, Phone, Contacts } from '@material-ui/icons';
import {compose} from "recompose";
import * as ROUTES from "../../constants/routes";
import {APP_BAR, PRIMARY} from "../../constants/colors";

const styles = theme => ({
    root: {
        flexGrow: 1,
    },
    footerContainer: {
        display: 'flex',
        flexDirection: 'column',
        backgroundColor: `${APP_BAR} !important`,
        color: 'white',
        width: '100%',
        position: 'fixed',
        bottom: 0

    },
    text : {
        color: 'white',
        paddingLeft: theme.spacing(2),
    },
    avatar: {
        backgroundColor: PRIMARY,
    },
    icon : {
        color: 'white',
        height:24,
        width: 24
    },
    icons : {
        backgroundColor: 'white',
        marginRight: 0
    },
});

class Footer extends Component {
    render() {
        const {classes, t} = this.props;
        return (

            <AppBar position="static" className={classes.footerContainer}>
                <Grid container>
                    <Grid item xs={12}>
                        <Grid container justify="center" >
                            <Grid value={1} item>
                                <Link to={ROUTES.ABOUT_US} className={classes.text} title={t('application.about us')}>About Us</Link> |
                            </Grid>
                            <Grid value={2} item>
                                <Link to={ROUTES.CONTACT_US} className={classes.text} title={t('application.contact us')}>Contact Us</Link> |
                            </Grid>
                            <Grid value={3} item>
                                <Link target="_blank" className={classes.text} to={'/SocipodTerms&Condition.pdf'} title={t('terms')}>{t('terms')}</Link> |
                            </Grid>
                            <Grid value={4} item>
                                <Link target="_blank" className={classes.text} to={'/SocipodPrivatePolicy.pdf'} title={t('privacy')}>{t('privacy')}</Link>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </AppBar>
        );
    }
}

export default compose (
    withStyles(styles),
    withI18n()
)(Footer)