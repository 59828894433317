import { withI18n } from "react-i18next";
import { withStyles } from '@material-ui/core/styles';
import {withRouter} from 'react-router-dom';
import {compose} from "recompose";
import {AppBar, CircularProgress, Button, Divider, Grid, Tabs, Tab, Card, CardActions, CardHeader, CardContent, Typography, List,  ListSubheader, ListItem, ListItemSecondaryAction, ListItemText, ListItemIcon, Tooltip} from '@material-ui/core';
import {Description, Note, Person, Edit,Info, Lock, LockOpen} from '@material-ui/icons';
import {connect} from "react-redux";
import PropTypes from 'prop-types';
import InfiniteScroll from 'react-infinite-scroller';
import {fetchOrganizationInfo, fetchOrganizationLinkCodes, fetchPosts, generateLinkCode, removePostsListener, sendJoinOrgRequest, updateDocumentAttribute, verifyOrganizationAttribute} from "../../actions";
import OrganizationMembersView from "../OrganizationMembersView";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUserShield} from '@fortawesome/free-solid-svg-icons'
import Post from "../Post";
import UploadCropImage from "../UploadCropImage";
import UserAvatar from "../UserAvatar";
import {AVATAR_TYPE, IMAGE_CROP_TYPE, POST_TYPE, LINK_CODES_TYPE, BADGE_TYPE, POST_LIMIT} from "../../constants/common";
import {RIEInput, RIETextArea, RIENumber} from 'riek'
import {
    getUserOrganizationById,
    isApprovedMemberOfOrganization,
    isUnapprovedMemberOfOrganization
} from "../../util/functions";
import {ACCEPT, INFO, LOCK, SEND} from "../../constants/colors";
import {ADMINISTRATION} from "../../constants/roles";


const React = require('react');

const styles = theme => ({
    container: {
        width: '100%',
        //flexGrow: 1,

    },
    infoDisplay : {
        flexGrow: 1,
        spacing: '40',
    },
    mainRoot: {
        flexGrow: 1,
        width: '100%',
        backgroundColor: theme.palette.background.paper,
    },
    card: {
        width: '100%',
        margin: theme.spacing(.2),
    },
    cardActions: {
        padding:0
    },
    buttonAccept: {
        margin: theme.spacing(.2),
        backgroundColor: ACCEPT,
        color: 'white'
    },
    buttonSend: {
        margin: theme.spacing(.2),
        backgroundColor: SEND,
        color: 'white'
    },
    details: {
        width: '100%',
      //  maxWidth: 400,
        backgroundColor: theme.palette.background.paper,
    },
    listLabel: {
        textTransform: 'capitalize',
        width: 150,
        display: 'block',
        textAlign : 'right'

    },
    infoAvatar: {
        color: INFO,
    },
    lockAvatar: {
        color: LOCK,
    },
    listText: {
        marginLeft: theme.spacing(2)
    },
    scrollContainer: {
        height:'700px',
        overflow:'auto'
    }
});


function TabContainer(props) {
    return (
        <Typography component="div" style={{ paddingTop: 2 * 2 }}>
            {props.children}
        </Typography>
    );
}

TabContainer.propTypes = {
    children: PropTypes.node.isRequired,
};

class Organization extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            loading: false,
            loadingRequest:false,
            postLoaded:false,
            hasMorePosts:false,
            linkCodesLoaded: false,
            tabValue: 0,
            saving: false,
            isApprovedMember: false,
            isPendingMember: false,
            membershipInfo: null,
            imageHash : Date.now(),
            organizationId: this.props.match.params.organizationId
        };
        this.fetchOrganizationInfoCallback = this.fetchOrganizationInfoCallback.bind(this);
        this.fetchOrganizationPostCallback = this.fetchOrganizationPostCallback.bind(this);
        this.handleTabChange = this.handleTabChange.bind(this);
        this.goToGroup = this.goToGroup.bind(this);
        this.updateUserInfo = this.updateUserInfo.bind(this);
        this.handleVerifyClick = this.handleVerifyClick.bind(this);
        this.handleJoinClick = this.handleJoinClick.bind(this);
        this.loadCompleted = this.loadCompleted.bind(this);
        this.updateHash = this.updateHash.bind(this);
        this.validateDescription = this.validateDescription.bind(this);
        this.validateText = this.validateText.bind(this);
        this.dataChanged = this.dataChanged.bind(this);
    }

    componentDidMount() {
        if(this.state.organizationId) {
            this.setState({loading:true});
            this.updateUserInfo();
            this.props.fetchOrganizationInfo({organizationId:this.state.organizationId, callback:this.fetchOrganizationInfoCallback})
        }
    }

    updateUserInfo() {
        const {currentUser} = this.props;
        var userOrgInfo = getUserOrganizationById(currentUser.organizations);
        if(userOrgInfo) {
            var isApprovedMember = userOrgInfo.isApproved ? true : false;
            var isPendingMember = userOrgInfo.isApproved ? false : true;
            this.setState({isApprovedMember: isApprovedMember, isPendingMember:isPendingMember, membershipInfo:userOrgInfo});
        }
    }

    handleJoinClick() {
        if(this.props.organizationInfo && this.props.organizationInfo.id) {
            this.setState({loadingRequest:true});
            this.props.sendJoinOrgRequest({organizationId:this.props.organizationInfo.id, callback:this.loadCompleted});
        }
    };
    handleGenerateCodeClick(linkCodeType) {
        if(this.props.organizationInfo && this.props.organizationInfo.id) {
            this.setState({loadingRequest:true});
            this.props.generateLinkCode({organizationId:this.props.organizationInfo.id, codeType:linkCodeType, callback:this.loadCompleted});
        }
    };

    loadCompleted() {
        this.setState({loading:false, loadingRequest:false});
    }

    loadMorePosts = () => {
        this.setState({hasMorePosts: false});
        const {posts, organizationInfo} =  this.props;
        var orgPosts = organizationInfo ? posts[organizationInfo.id] : null;
        var lastPost = orgPosts ? orgPosts[orgPosts.length - 1] : null;
        if(lastPost) {
            this.props.fetchPosts({type: POST_TYPE.PERSONAL, limit:POST_LIMIT, last: lastPost.dateUpdated, userId:this.props.authenticated.uid, callback:this.fetchPostCallback});
        }
    };
    handleVerifyClick() {
        if(this.props.organizationInfo && this.props.organizationInfo.id) {
            this.setState({loading:true});
            this.props.verifyOrganizationAttribute({organizationId:this.props.organizationInfo.id, createdById:this.props.organizationInfo.createdById,
                currentUserId:this.props.currentUser.id, currentUsername:this.props.currentUser.username, callback:this.loadCompleted});
        }
    };

    updateHash(){
        this.setState({imageHash: Date.now()});
    }

    dataChanged(data) {
        var address = {...this.props.organizationInfo.address};
        if(data.street) {
            address.street = data.street;
            this.props.updateDocumentAttribute({path:`organizations/${this.props.organizationInfo.id}`, attribute: "address", value:address, currentUsername: this.props.currentUser.username, currentUserId:this.props.currentUser.id});
        } else if(data.city) {
            address.city = data.city;
            this.props.updateDocumentAttribute({path:`organizations/${this.props.organizationInfo.id}`, attribute: "address", value:address, currentUsername: this.props.currentUser.username, currentUserId:this.props.currentUser.id});
        } else if(data.state) {
            address.state = data.state;
            this.props.updateDocumentAttribute({path:`organizations/${this.props.organizationInfo.id}`, attribute: "address", value:address, currentUsername: this.props.currentUser.username, currentUserId:this.props.currentUser.id});
        } else if(data.zip) {
            address.zip = data.zip;
            this.props.updateDocumentAttribute({path:`organizations/${this.props.organizationInfo.id}`, attribute: "address", value:address, currentUsername: this.props.currentUser.username, currentUserId:this.props.currentUser.id});
        } else if(data.description) {
            this.props.updateDocumentAttribute({path:`organizations/${this.props.organizationInfo.id}`, attribute: "description", value:data.description, currentUsername: this.props.currentUser.username, currentUserId:this.props.currentUser.id});
        }
    }

    validateText(text) {
        const  {t} = this.props;
        const defaultStreet = t('enter street');
        const defaultCity = t('enter city');
        const defaultState = t('enter state');
        return (text.length > 0 && text.length < 57 && text !== defaultStreet && text !== defaultCity && text !== defaultState);
    }
    validateZip(text) {
        return (text.length === 5);
    }
    validateDescription(text) {
        const  {t} = this.props;
        const bioDefault = t('enter bio');
        return (text.length > 0 && text.length < 201 && text !== bioDefault);
    }

    fetchOrganizationPostCallback(count) {
        var hasMorePosts =  count === POST_LIMIT;
        this.setState({hasMorePosts: hasMorePosts, postLoaded: true});
    }
    fetchOrganizationInfoCallback() {
        this.setState({loading:false});
    }

    goToGroup = (event, groupId, organizationId) => {
        if(organizationId) {
            this.props.history.push(`/group/${organizationId}/${groupId}`);
        } else {
            this.props.history.push(`/group/public/${groupId}`);
        }
    }

    handleTabChange(event, newValue) {
        debugger;
        if(newValue === 1 && !this.state.postLoaded) {
            const {posts} = this.props;
            var orgPosts = posts && posts[this.state.organizationId];
            if (orgPosts) {
                this.setState({postLoaded: true});
            } else {
                this.props.fetchPosts({
                    type: POST_TYPE.ORGANIZATION,
                    limit: POST_LIMIT,
                    orgId: this.state.organizationId,
                    callback: this.fetchOrganizationPostCallback
                });
            }
        } else if(newValue === 2 ){
            this.setState({membersViewRequestType: "members"})
        }
        else if(newValue === 3 ){
            this.setState({membersViewRequestType: "memberRequests"})
        } else if(newValue === 4 && !this.state.linkCodesLoaded) {
            var unsubscribeLinkCodes = this.props.fetchOrganizationLinkCodes({organizationId:this.state.organizationId, callback:this.fetchOrganizationPostCallback});
            this.setState({unsubscribeLinkCodes:unsubscribeLinkCodes});
        }
        this.setState({tabValue:newValue});
    }
    componentWillUnmount() {
        if(this.state.unsubscribeLinkCodes) {
            this.state.unsubscribeLinkCodes();
        }
    }
    render() {
        const {t, classes, authenticated, posts, currentUser,organizationInfo} = this.props;
        const {loading, tabValue, imageHash, hasMorePosts, postLoaded} = this.state;
        const orgPosts = posts && organizationInfo ? posts[organizationInfo.id] : null;
        const isApprovedMember = organizationInfo && currentUser && isApprovedMemberOfOrganization(currentUser.organizations, organizationInfo.id);
        const isUnapprovedMember = organizationInfo && currentUser && isUnapprovedMemberOfOrganization(currentUser.organizations, organizationInfo.id);
        const hasAdministrativeAccess = currentUser && currentUser.isApproved && (currentUser.isAdmin ||  (currentUser.designation === ADMINISTRATION && isApprovedMember));
        const postsCount = organizationInfo && (isApprovedMember || hasAdministrativeAccess)  && organizationInfo.postsCount ? organizationInfo.postsCount : 0;
        const membershipCount = organizationInfo && (isApprovedMember || hasAdministrativeAccess)  && organizationInfo.membersCount ? organizationInfo.membersCount : 0;
        const membershipReqCount = organizationInfo && (isApprovedMember || hasAdministrativeAccess)  && organizationInfo.memberRequestsCount ? organizationInfo.memberRequestsCount : 0;
        return (loading ? <div align="center" className={classes.progressRoot}> <CircularProgress className={classes.progress} size={100} thickness={6} /></div>
                :
                <div>
                    <Grid item container className={classes.root}>
                        <Grid item xs={12} md={3}>

                        </Grid>
                        <Grid item xs={12} md={6}>
                            <Grid container>
                                <Grid item xs={12}>

                                    {organizationInfo &&
                                        <Card>
                                            <CardHeader
                                                avatar={
                                                    <UserAvatar username={organizationInfo.name} userId={organizationInfo.id}
                                                                imageUrl={organizationInfo.profileImageUrl ? `${organizationInfo.profileImageUrl}?${imageHash}` : organizationInfo.profileImageUrl} type={AVATAR_TYPE.ORGANIZATION}/>
                                                }
                                                title={<Typography variant={"Title"}
                                                                   align={"right"}
                                                                   className={classes.title}>{organizationInfo.name}</Typography>}


                                                action={<span>
                                                    { !isUnapprovedMember && !isApprovedMember && organizationInfo && organizationInfo.isVerified && currentUser &&
                                                    <Button aria-label={t('buttons.join')}
                                                    onClick={() => this.handleJoinClick()}
                                                    className={classes.buttonSend}
                                                    variant="contained"
                                                    disabled={this.state.loadingRequest}
                                                    size="small">
                                                    {t('buttons.join')}
                                                    </Button>
                                                 }
                                                    {currentUser.isAdmin && !organizationInfo.isVerified &&
                                                    <Button aria-label={t('buttons.verify')}
                                                            onClick={() => this.handleVerifyClick()}
                                                            className={classes.buttonAccept}
                                                            variant="contained"
                                                            size="small">
                                                        {t('buttons.verify')}
                                                    </Button>
                                                    }
                                                </span>
                                                }
                                            />
                                            {hasAdministrativeAccess ?
                                                    <CardContent>
                                                        <Typography>
                                                            <RIETextArea
                                                                ref="descriptionEdit"
                                                                maxLength={200}
                                                                validate={this.validateDescription}
                                                                value={organizationInfo.description ? organizationInfo.description : t('enter description')}
                                                                propName="description"
                                                                change={this.dataChanged}

                                                            />
                                                        </Typography>
                                                    </CardContent>
                                                    :
                                                    organizationInfo.description &&
                                                    <CardContent>
                                                        <Typography>
                                                            {organizationInfo.description}
                                                        </Typography>
                                                    </CardContent>

                                            }
                                            <CardActions className={classes.cardActions}>
                                                <AppBar position="static" color={"default"}>
                                                    <Tabs value={tabValue} onChange={this.handleTabChange}
                                                          variant="scrollable"
                                                          scrollButtons="on"
                                                          indicatorColor="primary"
                                                          textColor="primary">
                                                        <Tab icon={<Description/>} label={t('application.info')} aria-label={t('application.info')}/>
                                                        <Tab icon={<Note/>} label={<Typography>{t('posts')}({postsCount})</Typography>} aria-label={t('posts')}/>
                                                        <Tab icon={<span><Person /></span>} label={<Typography>{t('members')}({membershipCount})</Typography>}  aria-label={t('members')} />
                                                        {hasAdministrativeAccess && <Tab icon={<span><Person/></span>} label={<Typography>{t('requests')}({membershipReqCount})</Typography>} aria-label={t('requests')}/> }
                                                        {hasAdministrativeAccess && <Tab icon={<span><FontAwesomeIcon icon={faUserShield} /></span>} label={<Typography>{t('application.admin')}</Typography>} aria-label={t('application.admin')}/> }
                                                    </Tabs>
                                                </AppBar>
                                            </CardActions>
                                        </Card>
                                    }
                                </Grid>
                                <Grid item xs={12}>
                                    <div>
                                        {tabValue === 0 &&
                                        <TabContainer>
                                            {organizationInfo && <div className={classes.infoDisplay}>
                                                <List subheader={
                                                    <ListSubheader> {t('organization details')}</ListSubheader>}
                                                      className={classes.details}>
                                                    <ListItem>
                                                        <ListItemIcon className={classes.listLabel}>
                                                            {t('fields.status')}:
                                                        </ListItemIcon>
                                                        <ListItemText className={classes.listText} id="status"
                                                                      primary={organizationInfo.isVerified ? t('status.verified') : t('status.unverified')}/>
                                                    </ListItem>
                                                    {hasAdministrativeAccess ?
                                                        <span>
                                                            <ListItem>
                                                                <ListItemIcon className={classes.listLabel}>
                                                                    {t('fields.avatar')}:
                                                                </ListItemIcon>
                                                                <ListItemText id="avatar"
                                                                              className={classes.listText}><UserAvatar
                                                                    username={organizationInfo.name}
                                                                    userId={organizationInfo.id}
                                                                    imageUrl={organizationInfo.profileImageUrl ? `${organizationInfo.profileImageUrl}?${imageHash}` : organizationInfo.profileImageUrl}
                                                                    type={AVATAR_TYPE.ORGANIZATION}/></ListItemText>
                                                                <ListItemSecondaryAction>
                                                                    <UploadCropImage icon={<Edit/>}
                                                                                     title={t('image crop title')}
                                                                                     description={t('image crop description')}
                                                                                     typeId={organizationInfo.id}
                                                                                     currentUser={currentUser}
                                                                                     type={IMAGE_CROP_TYPE.ORGANIZATION}
                                                                                     isNew={organizationInfo.profileImageUrl ? false : true}
                                                                                     updateHash={this.updateHash}
                                                                    />
                                                                </ListItemSecondaryAction>
                                                            </ListItem>
                                                            <ListItem>
                                                                <ListItemIcon className={classes.listLabel}>
                                                                    {t('fields.street')}:
                                                                </ListItemIcon>
                                                                <ListItemText className={classes.listText} id="street">
                                                                    <RIEInput
                                                                        ref="streetEdit"
                                                                        maxLength={56}
                                                                        validate={this.validateText}
                                                                        activeClassName="editing"
                                                                        value={organizationInfo.address.street ? organizationInfo.address.street : t('enter street')}
                                                                        propName="street"
                                                                        change={this.dataChanged}
                                                                    />
                                                                </ListItemText>
                                                                <ListItemSecondaryAction>
                                                                    <Tooltip  title={t('inline edit instruction')} aria-label={t('inline edit instruction')}>
                                                                        <Info className={classes.infoAvatar}/>
                                                                    </Tooltip>
                                                                </ListItemSecondaryAction>
                                                            </ListItem>
                                                            <ListItem>
                                                                <ListItemIcon className={classes.listLabel}>
                                                                    {t('fields.city')}:
                                                                </ListItemIcon>
                                                                <ListItemText id="city" className={classes.listText}>
                                                                    <RIEInput
                                                                        ref="cityEdit"
                                                                        maxLength={56}
                                                                        validate={this.validateText}
                                                                        activeClassName="editing"
                                                                        value={organizationInfo.address.city ? organizationInfo.address.city : t('enter city')}
                                                                        propName="city"
                                                                        change={this.dataChanged}
                                                                    />
                                                                </ListItemText>
                                                                <ListItemSecondaryAction>
                                                                    <Tooltip  title={t('inline edit instruction')} aria-label={t('inline edit instruction')}>
                                                                        <Info className={classes.infoAvatar}/>
                                                                    </Tooltip>
                                                                </ListItemSecondaryAction>
                                                            </ListItem>
                                                            <ListItem>
                                                                <ListItemIcon className={classes.listLabel}>
                                                                    {t('fields.state')}:
                                                                </ListItemIcon>
                                                                <ListItemText id="state" className={classes.listText}>
                                                                     <RIEInput
                                                                         ref="stateEdit"
                                                                         maxLength={56}
                                                                         validate={this.validateText}
                                                                         activeClassName="editing"
                                                                         value={organizationInfo.address.state ? organizationInfo.address.state : t('enter state')}
                                                                         propName="state"
                                                                         change={this.dataChanged}
                                                                     />
                                                                </ListItemText>
                                                                <ListItemSecondaryAction>
                                                                    <Tooltip  title={t('inline edit instruction')} aria-label={t('inline edit instruction')}>
                                                                        <Info className={classes.infoAvatar}/>
                                                                    </Tooltip>
                                                                </ListItemSecondaryAction>
                                                            </ListItem>
                                                            <ListItem>
                                                                <ListItemIcon className={classes.listLabel}>
                                                                    {t('fields.zip')}:
                                                                </ListItemIcon>
                                                                <ListItemText id="zip" className={classes.listText}>
                                                                     <RIENumber
                                                                         ref="zipEdit"
                                                                         maxLength={5}
                                                                         validate={this.validateZip}
                                                                         activeClassName="editing"
                                                                         value={organizationInfo.zip ? organizationInfo.zip : t('enter zip')}
                                                                         propName="zip"
                                                                         change={this.dataChanged}
                                                                     />
                                                                </ListItemText>
                                                                <ListItemSecondaryAction>
                                                                    <Tooltip  title={t('inline edit instruction')} aria-label={t('inline edit instruction')}>
                                                                        <Info className={classes.infoAvatar}/>
                                                                    </Tooltip>
                                                                </ListItemSecondaryAction>
                                                            </ListItem>
                                                        </span>
                                                        :
                                                        <span>
                                                            <ListItem>
                                                                <ListItemIcon className={classes.listLabel}>
                                                                    {t('fields.street')}:
                                                                </ListItemIcon>
                                                                <ListItemText className={classes.listText} id="street"
                                                                              primary={organizationInfo.address.street}/>
                                                            </ListItem>
                                                            <ListItem>
                                                                <ListItemIcon className={classes.listLabel}>
                                                                    {t('fields.city')}:
                                                                </ListItemIcon>
                                                                <ListItemText id="city" className={classes.listText}
                                                                              primary={organizationInfo.address.city}/>
                                                            </ListItem>
                                                            <ListItem>
                                                                <ListItemIcon className={classes.listLabel}>
                                                                    {t('fields.state')}:
                                                                </ListItemIcon>
                                                                <ListItemText id="state" className={classes.listText}
                                                                              primary={organizationInfo.address.state}/>
                                                            </ListItem>
                                                            <ListItem>
                                                                <ListItemIcon className={classes.listLabel}>
                                                                    {t('fields.zip')}:
                                                                </ListItemIcon>
                                                                <ListItemText id="zip" className={classes.listText}
                                                                              primary={organizationInfo.address.zip}/>
                                                            </ListItem>
                                                        </span>
                                                    }
                                                </List>
                                            </div>
                                            }
                                        </TabContainer>
                                        }
                                        {tabValue === 1 &&
                                            <TabContainer>
                                                {isApprovedMember &&
                                                    <span>
                                                        <Post postId="newPost" readOnly={false} type={POST_TYPE.ORGANIZATION} canEdit={true}
                                                              profileImageUrl={currentUser.profileImageUrl} username={currentUser.username}
                                                              userId={authenticated.uid} typeId={organizationInfo.id}/>
                                                        <br />
                                                        <Divider />
                                                        <br />
                                                    </span>
                                                }
                                                { !postLoaded && <div align="center" className={classes.progressRoot}> <CircularProgress className={classes.progress} size={20} thickness={3} /></div>

                                                }

                                                {orgPosts && orgPosts.length > 0 &&
                                                    <div className={classes.scrollContainer}>
                                                        <InfiniteScroll
                                                            pageStart={0}
                                                            loadMore={this.loadMorePosts}
                                                            hasMore={hasMorePosts}
                                                            useWindow={false}
                                                            loader={<div align="center" className={classes.progressRoot}> <CircularProgress className={classes.progress} size={20} thickness={3} /></div>}
                                                        >
                                                            {orgPosts.map(post =>
                                                                <div key={post.id} >
                                                                    <Post postId={post.id} readOnly={true} typeId={organizationInfo.id} type={POST_TYPE.ORGANIZATION} canEdit={currentUser.id === post.createdById} profileImageUrl={currentUser.profileImageUrl} username={currentUser.username} userId={authenticated.uid} post={post}/>
                                                                </div>
                                                            )}
                                                        </InfiniteScroll>
                                                    </div>
                                                }
                                                {
                                                   postLoaded && !orgPosts && <div> {t('no results')}</div>
                                                }
                                            </TabContainer>
                                        }
                                        {tabValue === 2
                                        && <TabContainer>
                                                {(isApprovedMember || hasAdministrativeAccess) ? <OrganizationMembersView requestType="members" organizationInfo={organizationInfo} currentUser={currentUser}/> : <div> {t('no results')}</div>}
                                            </TabContainer>
                                        }
                                        {tabValue === 3
                                        && <TabContainer>
                                                {(isApprovedMember || hasAdministrativeAccess) ? <OrganizationMembersView requestType="memberRequests" organizationInfo={organizationInfo} currentUser={currentUser}/> : <div> {t('no results')}</div>}
                                            </TabContainer>
                                        }
                                        {(tabValue === 4)
                                        && hasAdministrativeAccess && <TabContainer>
                                            <List subheader={
                                                <ListSubheader> {t('administration codes')} <Button aria-label={t('buttons.generate code')}
                                                                                                    onClick={() => this.handleGenerateCodeClick(LINK_CODES_TYPE.ADMINISTRATOR)}
                                                                                                    className={classes.buttonSend}
                                                                                                    variant="contained"
                                                                                                    disabled={this.state.loadingRequest}
                                                                                                    size="small">
                                                    {t('buttons.generate code')}
                                                </Button></ListSubheader>}
                                                  className={classes.details}>
                                                {organizationInfo && organizationInfo.linkCodes && organizationInfo.linkCodes.administrator.length > 0 &&
                                                    organizationInfo.linkCodes.administrator.map(linkCode => {
                                                        return(
                                                            <span>
                                                                <Divider variant="inset" component="li" />
                                                                <ListItem key={linkCode.id}>
                                                                    <ListItemIcon className={classes.listLabel}>
                                                                       {linkCode.users && linkCode.users.map(linkedUser =>
                                                                           <UserAvatar
                                                                                       username={linkedUser.username}
                                                                                       userId={linkedUser.id}
                                                                                       imageUrl={linkedUser.profileImageUrl}
                                                                                       badge={BADGE_TYPE.PROFESSOR}/>
                                                                       )
                                                                       }
                                                                    </ListItemIcon>
                                                                    <ListItemText className={classes.listText}
                                                                                  primary={linkCode.id}
                                                                    />
                                                                <ListItemSecondaryAction> {linkCode.users ? <Lock className={classes.lockAvatar} /> : <LockOpen className={classes.lockAvatar} /> } </ListItemSecondaryAction>
                                                                </ListItem>
                                                            </span>
                                                        );
                                                        })
                                                }
                                            </List>

                                            <List subheader={
                                                <ListSubheader> {t('professor codes')} <Button aria-label={t('buttons.generate code')}
                                                                                                    onClick={() => this.handleGenerateCodeClick(LINK_CODES_TYPE.PROFESSOR)}
                                                                                                    className={classes.buttonSend}
                                                                                                    variant="contained"
                                                                                                    disabled={this.state.loadingRequest}
                                                                                                    size="small">
                                                    {t('buttons.generate code')}
                                                </Button></ListSubheader>}
                                                  className={classes.details}>
                                                {organizationInfo && organizationInfo.linkCodes && organizationInfo.linkCodes.professor.length > 0 &&
                                                organizationInfo.linkCodes.professor.map(linkCode => {
                                                    return(
                                                            <span>
                                                                <Divider variant="inset" component="li" />
                                                                <ListItem key={linkCode.id}>
                                                                    <ListItemIcon className={classes.listLabel}>
                                                                        {linkCode.users && linkCode.users.map(linkedUser =>
                                                                            <UserAvatar
                                                                                        username={linkedUser.username}
                                                                                        userId={linkedUser.id}
                                                                                        imageUrl={linkedUser.profileImageUrl}
                                                                                        badge={BADGE_TYPE.PROFESSOR}/>
                                                                        )
                                                                        }
                                                                    </ListItemIcon>
                                                                    <ListItemText className={classes.listText}
                                                                                  primary={linkCode.id}
                                                                    />
                                                                <ListItemSecondaryAction> {linkCode.users ? <Lock className={classes.lockAvatar}/> : <LockOpen className={classes.lockAvatar}/> } </ListItemSecondaryAction>
                                                                </ListItem>
                                                            </span>
                                                    );
                                                })
                                                }
                                            </List>

                                            <List subheader={
                                                <ListSubheader> {t('parent/student codes')} <Button aria-label={t('buttons.generate code')}
                                                                                                    onClick={() => this.handleGenerateCodeClick(LINK_CODES_TYPE.PARENT_STUDENT)}
                                                                                                    className={classes.buttonSend}
                                                                                                    variant="contained"
                                                                                                    disabled={this.state.loadingRequest}
                                                                                                    size="small">
                                                    {t('buttons.generate code')}
                                                </Button></ListSubheader>}
                                                  className={classes.details}>
                                                {organizationInfo && organizationInfo.linkCodes && organizationInfo.linkCodes.parentStudent.length > 0 &&
                                                organizationInfo.linkCodes.parentStudent.map(linkCode => {
                                                    return(
                                                        <span>
                                                                <Divider variant="inset" component="li" />
                                                                <ListItem key={linkCode.id}>
                                                                    <ListItemIcon className={classes.listLabel}>
                                                                    {linkCode.student &&
                                                                        <UserAvatar
                                                                                    username={linkCode.student.username}
                                                                                    userId={linkCode.student.userId}
                                                                                    imageUrl={linkCode.student.profileImageUrl}
                                                                                    badge={BADGE_TYPE.STUDENT}/>
                                                                    }
                                                                    </ListItemIcon>
                                                                    <ListItemText className={classes.listText}
                                                                                  primary={linkCode.id}
                                                                                  secondary={<span>
                                                                      {linkCode.users && linkCode.users.map(linkedUser =>
                                                                          <span>
                                                                              {linkedUser.isParent && <UserAvatar size="small" username={linkedUser.username} userId={linkedUser.id}
                                                                                      imageUrl={linkedUser.profileImageUrl} badge={BADGE_TYPE.PARENT}/>}
                                                                          </span>
                                                                      )}
                                                                  </span>}
                                                                    />
                                                                <ListItemSecondaryAction> {linkCode.users ? <Lock className={classes.lockAvatar} /> : <LockOpen className={classes.lockAvatar}/> } </ListItemSecondaryAction>
                                                                </ListItem>
                                                            </span>
                                                    );
                                                })
                                                }
                                            </List>
                                        </TabContainer>
                                        }
                                    </div>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Divider />
                        <Grid item xs={12} md={3}>


                        </Grid>
                    </Grid>
                </div>
        )
    }

}

const mapStateToProps = ({user, posts, organizationInfo}) => {
        return {currentUser:user, posts:posts, organizationInfo:organizationInfo};
};

export default compose (
    withRouter,
    withStyles(styles),
    withI18n(),
    connect(mapStateToProps, {fetchOrganizationInfo, fetchOrganizationLinkCodes,  fetchPosts, generateLinkCode, removePostsListener, sendJoinOrgRequest, verifyOrganizationAttribute, updateDocumentAttribute})
)(Organization)
