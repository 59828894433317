import { withI18n } from "react-i18next";
import { withStyles } from '@material-ui/core/styles';
import { Link, withRouter } from 'react-router-dom';
import {compose} from "recompose";
import * as ROUTES from '../../constants/routes';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import {connect} from "react-redux";
import {doPasswordReset} from "../../actions";

const React = require('react');

const styles = {
    card: {
        minWidth: 275,
        maxWidth: 400,
        paddingBottom: 10
    },
    bullet: {
        display: 'inline-block',
        margin: '0 2px',
        transform: 'scale(0.8)',
    },
    actions: {
        display: 'inherit',
    },
    title: {
        textTransform: 'uppercase'
    },
    pos: {
        marginBottom: 12,
    },
    success : {
        color: 'green'
    }
};

const INITIAL_STATE = {
    email: '',
    message:null,
    error: null,
};

class PasswordForget extends React.Component {

    constructor(props) {
        super(props);

        this.state = { ...INITIAL_STATE };
        this.onSubmit = this.onSubmit.bind(this);
        this.onChange = this.onChange.bind(this);
    }

    onSubmit = event => {
        const { email} = this.state;
        event.preventDefault();
        this.props
            .doPasswordReset(email);
    };
    onChange = prop => event => {
        this.setState({ [prop]: event.target.value });
    };


    render() {
        const {t, classes} = this.props;
        const { email, message, error } = this.state;
        const isInvalid = email === '';

        return (
            <div align="center">
                <Card className={classes.card}>
                    <form onSubmit={this.onSubmit}>
                        <CardContent>
                            <CardHeader
                                title={ <Typography className={classes.title} color="textSecondary" gutterBottom>
                                    {t('application.forgot password')}
                                </Typography>
                                }
                            />
                            <div>
                                <Grid>
                                    <Grid item>
                                        <TextField value={email} type="email" onChange={this.onChange('email')} id="input-email" label={t('fields.email')} />
                                    </Grid>
                                </Grid>
                            </div>

                        </CardContent>
                        <CardActions  className={classes.actions}>
                            <Button size="small" color="primary" type="submit" disabled={isInvalid}> {t('reset my password')} </Button>
                        </CardActions>
                        <Typography  className={classes.success}>
                            {message}
                        </Typography>
                        <Typography color="error">
                            {error && <p>{error.message}</p>}
                        </Typography>
                        {/*<Typography className={classes.title} color="primary">*/}
                            {/*<Link to={ROUTES.PASSWORD_FORGET}>{t('forgot password?')} </Link>*/}
                        {/*</Typography>*/}
                    </form>
                </Card>
                <Typography color="textSecondary">
                    {t('no account?')} <Link to={ROUTES.SIGN_UP}>{t('application.sign up')} </Link>
                </Typography>

            </div>


        )
    }

}
function mapStateToProps({ auth }) {
    return { auth };
}

export default compose (
    withRouter,
    withStyles(styles),
    withI18n(),
    connect(mapStateToProps, { doPasswordReset })
)(PasswordForget)