import { withI18n } from "react-i18next";
import { withStyles } from '@material-ui/core/styles';
import {Link, withRouter} from 'react-router-dom';
import {compose} from "recompose";
import InfiniteScroll from 'react-infinite-scroller';

import {Group, Image} from '@material-ui/icons';
import {Divider, Button, Card, CardActions, CardMedia, CardContent, CardHeader, Chip, CircularProgress, IconButton,   Grid, LinearProgress, Typography} from '@material-ui/core';
import {connect} from "react-redux";
import {addMembersToGroup, fetchGroup, fetchPosts, removeGroupListener, openSnack} from "../../actions";
import {
    ASSIGNEE_TYPE,
    AVATAR_TYPE,
    IMAGE_CROP_TYPE,
    PATHS,
    POST_LIMIT,
    POST_TYPE,
    USER_SEARCH_TYPE
} from "../../constants/common";
import {CHIP, LOADING_MASK, PRIMARY, RADIO_BUTTON_SELECT, RED, SECONDARY_ICON, WHITE} from "../../constants/colors";
import CustomReactSelect from "../CustomReactSelect";
import UploadCropImage from "../UploadCropImage";
import Post from "../Post";
import UserAvatar from "../UserAvatar";
import TimeAgo from 'react-timeago';
import {getJSDate} from "../../util/functions";


const React = require('react');

const styles = theme => ({
    root: {
        width: '100%',
        //flexGrow: 1,
    },
    card: {
        width: '100%',
        margin: theme.spacing(2),
    },
    selectionCard: {
        width: '100%',
        overflow: 'visible',
        margin: theme.spacing(2),
    },
    cardContent: {
        //width: 300,
        paddingTop: 0,
    },
    inline: {
        display: 'inline',
    },
    formControl: {
        minWidth: 300,
    },
    formControlRadio: {
        paddingTop: theme.spacing.unit * 2,
        //margin: theme.spacing.unit * 1,
    },
    radioButton : {
        color: RADIO_BUTTON_SELECT,
        '&$checked': {
            color: RADIO_BUTTON_SELECT,
        },
    },
    progressRoot: {
        flexGrow: 1,
        spacing: '40'
    },
    progress: {
        margin: theme.spacing(2),
        color: LOADING_MASK
    },
    pageActions : {
        paddingTop : 0,
        color: PRIMARY
    },
    groupsCard: {
        //width: 300,
        margin: theme.spacing(2),
    },
    groupAvatar: {
        width: 50,
        height: 50,
        backgroundColor: SECONDARY_ICON
        // padding: theme.spacing.unit * 2,
    },
    actions: {
        display: 'inherit',
    },
    media: {
        height: 100,
        paddingTop: '75.25%', // 16:9
    },
    title: {
        textTransform: 'uppercase'
    },
    pos: {
        marginBottom: 12,
    },
    expand: {
        transform: 'rotate(0deg)',
        marginLeft: 'auto',
        transition: theme.transitions.create('transform', {
            duration: theme.transitions.duration.shortest,
        }),
    },
    group: {
        margin: theme.spacing(1),
        display: 'inline'
    },
    description : {
        whiteSpace: 'pre-wrap'
    },
    gray : {
        color: 'gray'
    },
    mainPhotoCard: {
        // width: 600,
        // minWidth: 275,
        margin: theme.spacing.unit * 2,
    },
    chip: {
        backgroundColor: CHIP,
        color: WHITE,
        fontSize: '.8em'
    },
    chipPrivate:{
        backgroundColor: RED,
        color: WHITE,
        fontSize: '.8em'
    }
});

class UserGroup extends React.Component {

    constructor(props) {
        super(props);

        const dataPath = props.match.params.organization === PATHS.PUBLIC_BASE ? `${PATHS.NON_ORG_GROUPS}/${props.match.params.groupId}`
            : `${PATHS.ORGANIZATION_BASE}/${props.match.params.organization}/groups/${props.match.params.groupId}`;
        this.state = {
            loading: false,
            saving: false,
            groupId:props.match.params.groupId,
            organizationId:props.match.params.organization,
            postLoaded:false,
            dataPath:dataPath,
            hasMorePosts:false,
            availableUserToAdd: [],
            newUserToAdd:[],
            imageHash : Date.now()
        };
        this.addNewMembers = this.addNewMembers.bind(this);
        this.onChange = this.onChange.bind(this);
        this.saveCompleted = this.saveCompleted.bind(this);
        this.setSelectedUsers = this.setSelectedUsers.bind(this);
        this.updateHash = this.updateHash.bind(this);
        this.loadMorePosts = this.loadMorePosts.bind(this);
        this.fetchGroupPostCallback = this.fetchGroupPostCallback.bind(this);
        this.fetchGroupCallback = this.fetchGroupCallback.bind(this);
    }

    componentDidMount() {
        const currentUserId = this.props.user.id;
        const {groupId, dataPath, organizationId} = this.state;

        var unsubscribeGroup = this.props.fetchGroup({path:dataPath, groupId:groupId, uid:currentUserId, callback:this.fetchGroupCallback});
        this.setState({unsubscribeGroup:unsubscribeGroup});
        if(!this.props.posts[groupId]){
            this.props.fetchPosts({type:POST_TYPE.GROUP, limit:POST_LIMIT, orgId:organizationId, groupId:groupId, callback:this.fetchGroupPostCallback});
        } else {
            this.setState({postLoaded:true});
        }
    }

    componentWillUnmount() {
        if(this.state.unsubscribeGroup){
            this.state.unsubscribeGroup();
        }
    }

    onChange = prop => event => {
        this.setState({ [prop]: event.target.value });
    };

    saveCompleted = (success, groupId) => {
        this.setState({ 'saving': false });
        if(success) {
           // this.props.history.push(`/group/${groupId}`);
        }
    };
    fetchGroupCallback = (group) => {
        const {posts, groupId} =  this.props;
        var groupPosts = posts[groupId];
        var hasMorePosts = groupPosts && group && group.postsCount ? groupPosts.length !== group.postsCount: false;
        this.setState({hasMorePosts: hasMorePosts});
    };


    addNewMembers() {
        const {group} = this.props;
        const {groupId, dataPath, organizationId, newUserToAdd} = this.state;
        this.setState({saving:true});
        const data = {path:dataPath, currentUserId:this.props.authenticated.uid, groupId:groupId,
            callback : this.addMembersToGroupCallback, organizationId:organizationId, groupName:group.name}

        if(group.members && group.memberKeys) {
            const newUsers = [];
            this.state.newUserToAdd.forEach(function (user) {
                if (!group.memberKeys.has(user.value)) {
                    newUsers.push(user);
                }
            });
            if(newUsers.length > 0) {
                data.usersToAdd = newUsers;
                this.props.addMembersToGroup(data);
            } else {
                this.props.openSnack({open:true, messageText:'users already added', variant:'info'});
            }
        } else {
            data.usersToAdd = newUserToAdd;
            this.props.addMembersToGroup(data);
        }

    }

    addMembersToGroupCallback = (success) => {
        this.setState({ 'saving': false });
        if(success) {
           this.setState({newUserToAdd:[]})
        }
    };
    loadMorePosts = () => {
        this.setState({hasMorePosts: false});
        const {posts} =  this.props;
        const {groupId, organizationId} =  this.state;
        var groupPosts = posts[groupId];
        var lastPost = groupPosts ? groupPosts[groupPosts.length - 1] : null;
        if(lastPost) {
            this.props.fetchPosts({type: POST_TYPE.GROUP, limit:POST_LIMIT, last: lastPost.dateUpdated, userId:this.props.authenticated.uid, orgId:organizationId, groupId:groupId, callback:this.fetchGroupPostCallback});
        }
    };
    fetchGroupPostCallback(count) {
        var hasMorePosts =  count === POST_LIMIT;
        this.setState({hasMorePosts: hasMorePosts, postLoaded: true});
    }

    setSelectedUsers(values) {
        if(values === null) {
            this.setState({newUserToAdd:[]});
        } else {
            this.setState({newUserToAdd:values});
        }

    }
    updateHash(){
        this.setState({imageHash: Date.now()});
    }
    render() {

        const {t, classes, group, user, posts} = this.props;
        const {loading, newUserToAdd, saving, dataPath, organizationId, groupId, imageHash, postLoaded, hasMorePosts} = this.state;
        const groupPosts = posts ? posts[groupId] : null;
        const isAddUserInvalid =  newUserToAdd.length < 1 || saving;
        const title = group ? group.name : '';
        const memberKeys = group && group.memberKeys ? group.memberKeys : new Set();
        const members = group && group.members ? group.members : [];
        debugger;

        return (loading ? <div align="center" className={classes.progressRoot}> <CircularProgress className={classes.progress} size={100} thickness={6} /></div>
                :
                <div align="center">
                    <Grid container className={classes.root} justify="center">
                        <Grid item key="title" xs={12} md={12}>
                            <Grid>
                                <Typography  variant={"title"} className={classes.title}>
                                    {title}
                                </Typography>
                                <br/>
                                {group &&
                                    <Typography
                                        component="div"
                                        variant="body2"
                                        className={classes.inline}
                                        color="textPrimary"
                                    >
                                        {t('created')} <TimeAgo date={getJSDate(group.dateCreated)}/>
                                    </Typography>
                                }
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid  item container className={classes.root} spacing={2}>
                        {group &&
                        <Grid item xs={12} md={3}>
                            <Grid id="leftPanel">
                                <Card className={classes.mainPhotoCard}>
                                    <CardHeader
                                        avatar={<Group />}
                                        title={t('application.info')}
                                        subheader={t('created by', {name:group.createdByUsername})}
                                        action={group.myMembership && group.myMembership.isAdmin &&
                                            <IconButton aria-label="edit photo">
                                                <UploadCropImage icon={<Image/>}
                                                                 title={t('image crop title')}
                                                                 description={t('image crop description')}
                                                                 typeId={group.id}
                                                                 type={IMAGE_CROP_TYPE.GROUP}
                                                                 isNew={group.profileImageUrl ? false : true}
                                                                 updateHash={this.updateHash}
                                                                 dataPath={dataPath}
                                                                 currentUser={user}
                                                />
                                            </IconButton>
                                        }

                                    />
                                    <CardContent>
                                        <div>
                                        {group.description}
                                        </div>
                                        <div>
                                            {group.isClass &&
                                            <Chip
                                                size="small"
                                                label={t('class').toUpperCase()}
                                                className={classes.chip}
                                            />
                                            }
                                            {group.isPrivate ?
                                            <Chip
                                                size="small"
                                                label={t('fields.private').toUpperCase()}
                                                className={classes.chipPrivate}
                                            /> : <Chip
                                                    size="small"
                                                    label={t('public').toUpperCase()}
                                                    className={classes.chip}
                                                />
                                            }

                                            {group.organizationId ?
                                                <Chip
                                                    size="small"
                                                    label={t('organization').toUpperCase()}
                                                    className={classes.chip}
                                                /> : <Chip
                                                    size="small"
                                                    label={t('non-organization').toUpperCase()}
                                                    className={classes.chip}
                                                />
                                            }
                                        </div>
                                    </CardContent>
                                    {group.profileImageUrl && <CardMedia
                                        className={classes.media}
                                        image={`${group.profileImageUrl}/${imageHash}`}
                                    />
                                    }

                                </Card>
                            </Grid>
                        </Grid>
                        }
                        <Grid item xs={12} md={6}>
                            <Grid>
                                {group && group.myMembership &&
                                <span>
                                                        <Post postId="newPost" readOnly={false} type={POST_TYPE.GROUP} canEdit={true}
                                                              profileImageUrl={user.profileImageUrl} username={user.username}
                                                              userId={user.id} typeId={group.id} subTypeId={organizationId}/>
                                                        <br />
                                                        <Divider />
                                                        <br />
                                                    </span>
                                }
                                { !postLoaded && <div align="center" className={classes.progressRoot}> <CircularProgress className={classes.progress} size={20} thickness={3} /></div>
                                }
                                {group && group.myMembership && groupPosts && groupPosts.length > 0 &&
                                    <div className={classes.scrollContainer}>
                                        <InfiniteScroll
                                            pageStart={0}
                                            loadMore={this.loadMorePosts}
                                            hasMore={hasMorePosts}
                                            useWindow={false}
                                            loader={<div align="center" className={classes.progressRoot}> <CircularProgress className={classes.progress} size={20} thickness={3} /></div>}
                                        >
                                            {groupPosts.map(post =>
                                                <div key={post.id} >
                                                    <Post postId={post.id} readOnly={true} typeId={group.id} type={POST_TYPE.GROUP} subTypeId={organizationId} canEdit={user.id === post.createdById} profileImageUrl={user.profileImageUrl} username={user.username} userId={user.id} post={post}/>
                                                </div>
                                            )}
                                        </InfiniteScroll>
                                    </div>
                                }
                            </Grid>
                        </Grid>
                        <Grid item xs={12} md={3}>
                            <Grid id="rightPanel">
                                {group && group.myMembership && group.myMembership.isAdmin && <Card className={classes.selectionCard}>
                                    <CardHeader
                                        title={<Typography className={classes.title} color="textSecondary" gutterBottom>
                                            {t('application.add members')}
                                        </Typography>
                                        }
                                    />
                                    <CardContent className={classes.cardContent}>
                                        <CustomReactSelect ref="memberSelection" data={group}
                                                           onChange={this.setSelectedUsers}
                                                           existingSelectionKeys={memberKeys} dataPath={dataPath}
                                                           isMulti={true} type={USER_SEARCH_TYPE.GROUP}
                                                           selectionType={ASSIGNEE_TYPE.USER}/>
                                    </CardContent>
                                    {saving &&
                                    <div className={classes.uploadProgress}>
                                        <LinearProgress classes={{
                                            colorPrimary: classes.linearColorPrimary,
                                            barColorPrimary: classes.linearBarColorPrimary,
                                        }}/>
                                    </div>
                                    }
                                    <CardActions className={classes.actions}>
                                        <Button size="small" color="primary" type="button" disabled={isAddUserInvalid}
                                                onClick={this.addNewMembers}> {t('buttons.add')}</Button>
                                    </CardActions>
                                </Card>
                                }
                                    <Card className={classes.selectionCard}>
                                        <CardHeader
                                            title={ <Typography className={classes.title} color="textSecondary" gutterBottom>
                                                {`${t('members')}(${group && group.membersCount? group.membersCount : 0})`}
                                            </Typography>
                                            }
                                        />
                                        <CardContent>
                                            <Grid container spacing={2}>
                                            {members &&
                                                    members.map(groupMember => (
                                                        <Grid item>
                                                            <UserAvatar username={groupMember.username} userId={groupMember.id}
                                                                        imageUrl={groupMember.profileImageUrl} />
                                                        </Grid>

                                                    ))
                                            }
                                            </Grid>
                                        </CardContent>
                                    </Card>
                            </Grid>
                        </Grid>
                    </Grid>

                </div>
        )
    }

}
const mapStateToProps = ({group, posts, user}) => {
        return {group:group, user:user, posts:posts};
};

export default compose (
    withRouter,
    withStyles(styles),
    withI18n(),
    connect(mapStateToProps, {addMembersToGroup, fetchGroup, fetchPosts, removeGroupListener, openSnack})
)(UserGroup)
