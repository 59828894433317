import {ADD_GROUPS, FETCH_GROUPS} from "../constants/action-types";
import {combineArrays} from "../util/functions";

export default (state = {}, action) => {
    switch (action.type) {
        case ADD_GROUPS:
            return {...state, [action.payload.id] : action.payload.top ?
                    combineArrays(action.payload.data, state[action.payload.id])
                    : combineArrays(state[action.payload.id], action.payload.data)};
        case FETCH_GROUPS:
            return {...state, [action.payload.id] : action.payload.data};
        default:
            return state;
    }
};