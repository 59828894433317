import MaterialTable, { MTableToolbar } from 'material-table';
import { withI18n } from "react-i18next";
import {compose} from "recompose";
import CustomEditor from "../CustomEditor"
import {Button} from '@material-ui/core';

import {
    withRouter
} from 'react-router-dom'
import {PATHS} from "../../constants/common";

const React = require('react');

class LessonTable extends React.Component {
    constructor(props) {
        super(props);
        this.openLesson = this.openLesson.bind(this);
    }

    openLesson = (lessonId, organizationId) => {
        if(organizationId) {
            this.props.history.push(`/lessons/${organizationId}/${lessonId}`);
        } else {
            this.props.history.push(`/lessons/${PATHS.PUBLIC_BASE}/${lessonId}`);
        }
    };



    render() {
        const {classes, t} = this.props;
        const lessonColumns = [
            {
                field: 'profileImageUrl',
                title: 'Avatar',
                render: rowData => <img src={rowData.profileImageUrl} style={{width: 50, borderRadius: '50%'}}/>
            },
            { title: this.props.t("fields.title"), field: 'title' },
            { title: this.props.t('fields.description'), field: 'description'},
            { title: this.props.t('fields.subject'), field: 'subject',
                lookup: { math: t('subject.math'), science: t('subject.science'), english: t('subject.english'), other: t('subject.other')}
            },
            { title: this.props.t('fields.grade level'), field: 'gradeLevel',
                lookup: { p: t('grade.p'), k: t('grade.k'), 1: t('grade.1'),2: t('grade.2'), 3: t('grade.3'), 4: t('grade.4'), 5: t('grade.5'),
                    6: t('grade.6'), 7: t('grade.7'), 8: t('grade.8'),9: t('grade.9'), 10: t('grade.10'), 11: t('grade.11'), 12: t('grade.12'),
                    other:t('grade.other')
                }
            },
            { title: this.props.t('fields.difficulty'), field: 'difficultyLevel',
                 lookup: { easy: t('easy'), medium: t('medium'), hard: t('hard')}
             },
            { title: this.props.t('fields.created by'), field: 'createdByUsername'},
            {
                title: this.props.t('fields.private'),
                field: 'isPrivate',
                type: 'boolean'
            },
            {
                title: this.props.t('fields.published'),
                field: 'published',
                type: 'boolean'
            }
        ];
        return (<div id="lession-table">
            <MaterialTable
                class={{root:classes.dataTable}}
                title={this.props.title}
                columns={lessonColumns}
                data={this.props.data}
                options={{
                    exportButton: this.props.export,
                    sorting: true,
                    // doubleHorizontalScroll:true
                }}
                onRowClick={((evt, selectedRow) => this.setState({ selectedRow }))}
                actions={[
                    {
                        icon: 'visibility',
                        tooltip: this.props.t('view lesson'),
                        onClick: (event, rowData) => this.openLesson(rowData.id, rowData.organizationId)
                    }
                ]}
                detailPanel={rowData => {
                    return (
                        <div align="center"> <CustomEditor content={rowData.content} readOnly={true} /> </div>
                    )
                }}
                components={{
                    Toolbar: props => (
                        <div>
                            <MTableToolbar {...props} />
                            {this.props.hasMore &&
                                <div>
                                    <Button
                                        onClick={this.props.loadMore}
                                        size="medium"
                                        variant="contained"
                                        className={classes.subActions}
                                    >
                                        {this.props.t('load more')}
                                    </Button>
                                </div>
                            }
                        </div>
                    ),
                }}
                /*editable={{
                    onRowAdd: newData =>
                        new Promise(resolve => {
                            setTimeout(() => {
                                resolve();
                                const data = [...state.data];
                                data.push(newData);
                                setState({ ...state, data });
                            }, 600);
                        }),
                    onRowUpdate: (newData, oldData) =>
                        new Promise(resolve => {
                            setTimeout(() => {
                                resolve();
                                const data = [...state.data];
                                data[data.indexOf(oldData)] = newData;
                                setState({ ...state, data });
                            }, 600);
                        }),
                    onRowDelete: oldData =>
                        new Promise(resolve => {
                            setTimeout(() => {
                                resolve();
                                const data = [...state.data];
                                data.splice(data.indexOf(oldData), 1);
                                setState({ ...state, data });
                            }, 600);
                        }),
                }}*/
            />
            </div>
        );
    }
}

export default compose(withI18n(), withRouter)(LessonTable)