import { withI18n } from "react-i18next";
import { withStyles } from '@material-ui/core/styles';
import { withRouter } from 'react-router-dom';
import {compose} from "recompose";

import {CheckCircle, PersonAdd, Person} from '@material-ui/icons';
import {Avatar, Button, Card, CardContent, CardHeader, CircularProgress, Grid, IconButton, InputBase, List, ListItem, ListItemText, ListItemAvatar, ListItemSecondaryAction,Paper, Typography} from '@material-ui/core';
import {connect} from "react-redux";
import { acceptFollowingRequest, declineFollowingRequest, fetchFollowingRequests, fetchFollowers, fetchFollowings, fetchUsers,sendFollowRequest,  removeFollowInfoListeners, unFollow} from "../../actions";
import UserAvatar from "../UserAvatar";
import {filterObjectInArray, hasUserInObjectList, objectToArray} from "../../util/functions";
import {ACCEPT, DECLINE, LOADING_MASK, PRIMARY, SECONDARY_ICON} from "../../constants/colors";
import InviteFriends from "../InviteFriends/InviteFriends"

const React = require('react');

const styles = theme => ({
    root: {
        flexGrow: 1,
    },
    progressRoot: {
        flexGrow: 1,
        spacing: '40'
    },
    progress: {
        margin: theme.spacing.unit * 2,
        color: LOADING_MASK
    },
    pageActions : {
        paddingTop : 0,
        color: PRIMARY
    },
    card: {
        width: 300,
        margin: theme.spacing.unit * 2,
    },
    inviteCard: {
        width: "auto",
        margin: theme.spacing.unit * 2
    },
    bullet: {
        display: 'inline-block',
        margin: '0 2px',
        transform: 'scale(0.8)',
    },
    actions: {
        display: 'inherit',
    },
    media: {
        paddingTop: '75.25%', // 16:9
    },
    title: {
        textTransform: 'uppercase'
    },
    pos: {
        marginBottom: 12,
    },
    expand: {
        transform: 'rotate(0deg)',
        marginLeft: 'auto',
        transition: theme.transitions.create('transform', {
            duration: theme.transitions.duration.shortest,
        }),
    },
    expandOpen: {
        transform: 'rotate(180deg)',
    },
    avatar: {
        backgroundColor: SECONDARY_ICON,
    },
    accept: {
        color: ACCEPT,
    },
    decline: {
        color: DECLINE,
    },
    buttonAccept: {
        backgroundColor: ACCEPT,
        color: 'white'
    },
    buttonDecline: {
        backgroundColor: DECLINE,
        color: 'white'
    },
    description : {
        whiteSpace: 'pre-wrap'
    },
    gray : {
        color: 'gray'
    },
    inputRoot: {
        padding: '2px 4px',
        display: 'flex',
        alignItems: 'center',
       // width: 400,
    },
    input: {
        // marginLeft: theme.spacing(1),
        flex: 1,
        borderColor: PRIMARY,
        borderWidth: 2,
    }
});

class Friends extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            friendsLoading: true,
            friends: [],
            friendsExpand: false,
            followRequestsLoading: true,
            followRequests: [],
            followRequestsExpand: false,
            followersLoading: true,
            followers: [],
            followersExpand: false,
            followingLoading: true,
            followingList: [],
            followingExpand: false,
            actionAnchorEl: null,
            snackShow: false,
            snackMessage: '',
            snackVariant: '',
            searchValue: '',
            users: []
        };

        this.handleActionsClick = this.handleActionsClick.bind(this);
        this.setOpen = this.setOpen.bind(this);
        this.handleActionsClose = this.handleActionsClose.bind(this);
        this.handleAcceptFollowRequestClick = this.handleAcceptFollowRequestClick.bind(this);
        this.handleDeclineFollowRequestClick = this.handleDeclineFollowRequestClick.bind(this);
        this.handleFollowClick = this.handleFollowClick.bind(this);
        this.handleUnfriendClick = this.handleUnfriendClick.bind(this);
        this.handleUnfollowClick = this.handleUnfollowClick.bind(this);
        this.displaySnack = this.displaySnack.bind(this);
        this.onSearchValueChange = this.onSearchValueChange.bind(this);


    }

    componentDidMount() {
        // const currentUserId = this.props.authenticated.uid;
        // this.props.fetchFollowingRequests({uid:currentUserId});
        // this.props.fetchFollowers({uid:currentUserId});
        // this.props.fetchFollowings({uid:currentUserId});
    }

    componentWillUnmount() {
        //this.props.removeFollowInfoListeners(this.props.authenticated.uid);
    }

    handleExpandClick = () => {
        this.setState(state => ({ expanded: !state.expanded }));
    };

    handleAcceptFollowRequestClick (followRequest) {
        const currentUserId = this.props.user.id;
        const currentUsername = this.props.user.username;
        const profileImageUrl = this.props.user.profileImageUrl;
        const isFollowing = hasUserInObjectList(this.props.myFollowings, followRequest.id);
        this.props.acceptFollowingRequest({
            currentUserId: currentUserId,
            currentUsername: currentUsername,
            profileImageUrl:profileImageUrl,
            followRequest: followRequest,
            isFollowing : isFollowing
        })
    };

    handleDeclineFollowRequestClick(requestUserId) {
        const currentUserId = this.props.authenticated.uid;
        this.props.declineFollowingRequest({currentUserId:currentUserId, requestUserId: requestUserId});
    };

    handleUnfriendClick(friendUserId) {
        const currentUserId = this.props.authenticated.uid;
        this.props.unFriend({currentUserId:currentUserId,friendUserId:friendUserId})
    };

    handleFollowClick(follower) {
        const currentUserId = this.props.authenticated.uid;
        const currentUsername = this.props.authenticated.displayName ? this.props.authenticated.displayName : this.props.user.username;
        let following = follower;
        following.isFollowing = true;
        this.props.sendFollowRequest({currentUsername: currentUsername, currentUserId:currentUserId, following:following});
    };

    handleUnfollowClick(followingUserId) {
        const currentUserId = this.props.authenticated.uid;
        this.props.unFollow({currentUserId: currentUserId, followingUserId: followingUserId})
    }

    handleActionsClick = event => {
        this.setState({ actionAnchorEl: event.currentTarget });
    };

    handleActionsClose = () => {
        this.setState({ actionAnchorEl: null });
    };

    setOpen(open) {
        this.setState({snackShow: open});
    }

    displaySnack(snackInfo) {
        this.setState(snackInfo);
    }
    onSearchValueChange=(event) =>{
        if(event.target.value){
            let searchValue = event.target.value;
             let currentUserId = this.props.user.id;
            // db.ref(`users`).orderByChild("username").startAt(searchValue).limitToFirst(5).on("child_added", function (snapshot) {
            //     var user = snapshot.val();
            //     user.uid = snapshot.key;
            //     if (user.uid !== currentUserId) {
            //         userList.push(user);
            //         that.setState({users: userList});
            //     }
            // });

            this.props.fetchUsers({username:searchValue, currentUserId});
        }
        this.setState({searchValue:event.target.value});
    };
    render() {

        const {t, classes, myFollowerRequests, myFollowers, mySentFollowingRequests, myFollowings, user, users} = this.props;
        const {searchValue} = this.state;
        const followers = objectToArray(myFollowers);
        const followRequests = objectToArray(myFollowerRequests);
        const followings = objectToArray(myFollowings);
        const userFiltered = users && filterObjectInArray(users, 'username', searchValue);
        //const {friendsExpand, followRequestsExpand, followersExpand, followingExpand, actionAnchorEl} = this.state;

        //const openActions = Boolean(actionAnchorEl);

        return (
                <div align="center">
                    {/*<Grid container className={classes.root} justify="center">*/}
                        {/*<Grid item key="title" xs={11} >*/}
                                {/*<Grid>*/}
                                    {/*<Typography  variant={"title"} className={classes.title}>*/}
                                        {/*{t('application.friends')}*/}
                                    {/*</Typography>*/}
                                {/*</Grid>*/}
                        {/*</Grid>*/}
                    {/*</Grid>*/}

                    <Grid container className={classes.root} justify="center">
                        <Grid item key='userSearch'>
                            <Card className={classes.card}>
                                <CardHeader
                                    avatar={
                                        <Avatar aria-label={t('search for users')} className={classes.avatar}>
                                            <PersonAdd />
                                        </Avatar>
                                    }
                                    title={<Paper className={classes.inputRoot}><InputBase
                                        className={classes.input}
                                        placeholder={t('search for users')}
                                        inputProps={{ 'aria-label': t('search for users') }}
                                        value={searchValue}
                                        onChange={this.onSearchValueChange}
                                    /></Paper>}
                                />
                                <CardContent>
                                    {userFiltered &&
                                    userFiltered.length > 0 && <List dense className={classes.root}>
                                                {userFiltered.map(user => {
                                                    return (
                                                        <ListItem key={`search-${user.uid}`} button onClick={() => { this.props.history.push(`/user/${user.uid}`);}}>
                                                            <ListItemAvatar>
                                                                <UserAvatar size="small" username={user.username} userId={user.uid}
                                                                            imageUrl={user.profileImageUrl}/>
                                                            </ListItemAvatar>
                                                            <ListItemText id={user.uid}
                                                                          primary={user.username}/>
                                                            <ListItemSecondaryAction>
                                                                {/*<IconButton aria-label={t('buttons.accept')}*/}
                                                                            {/*onClick={() => this.handleAcceptFollowRequestClick(followRequest)}*/}
                                                                            {/*className={classes.accept}>*/}
                                                                    {/*<CheckCircle/>*/}
                                                                {/*</IconButton>*/}
                                                                {/*<IconButton aria-label={t('buttons.decline')}*/}
                                                                {/*className={classes.decline}>*/}
                                                                {/*<Cancel onClick={() => this.handleDeclineFollowRequestClick(followRequest.uid)}/>*/}
                                                                {/*</IconButton>*/}
                                                            </ListItemSecondaryAction>
                                                        </ListItem>
                                                    );
                                                })}
                                            </List>
                                    }

                                </CardContent>
                            </Card>
                        </Grid>
                        <Grid item key='followRequest'>
                            <Card className={classes.card}>
                                <CardHeader
                                    avatar={
                                        <Avatar aria-label={t('following requests')} className={classes.avatar}>
                                            <PersonAdd />
                                        </Avatar>
                                    }
                                    title={<Typography variant="title" className={classes.title}>
                                            {t('following requests')} ({user && user.followerRequestsCount ? user.followerRequestsCount : 0})
                                            </Typography>}
                                />
                                <CardContent>
                                    {followRequests &&
                                        followRequests.length > 0 ? <List dense className={classes.root}>
                                            {followRequests.map(followRequest => {
                                                return (
                                                    <ListItem key={`request-${followRequest.id}`} button onClick={() => { this.props.history.push(`/user/${followRequest.id}`);}}>
                                                        <ListItemAvatar>
                                                            <UserAvatar size="small" username={followRequest.username} userId={followRequest.id}
                                                                        imageUrl={followRequest.profileImageUrl}/>
                                                        </ListItemAvatar>
                                                        <ListItemText id={followRequest.id}
                                                                      primary={followRequest.username}/>
                                                        <ListItemSecondaryAction>
                                                            <IconButton aria-label={t('buttons.accept')}
                                                                        onClick={() => this.handleAcceptFollowRequestClick(followRequest)}
                                                                        className={classes.accept}>
                                                                <CheckCircle/>
                                                            </IconButton>
                                                            {/*<IconButton aria-label={t('buttons.decline')}*/}
                                                                        {/*className={classes.decline}>*/}
                                                                {/*<Cancel onClick={() => this.handleDeclineFollowRequestClick(followRequest.id)}/>*/}
                                                            {/*</IconButton>*/}
                                                        </ListItemSecondaryAction>
                                                    </ListItem>
                                                );
                                            })}
                                        </List> :
                                        <Typography variant={"subheading"} className={classes.gray}>
                                        {t('messages.no following requests')}
                                        </Typography>
                                    }

                                </CardContent>
                            </Card>
                        </Grid>

                        <Grid item key='followers'>
                            <Card className={classes.card}>
                                <CardHeader
                                    avatar={
                                        <Avatar aria-label={t('followers')} className={classes.avatar}>
                                            <span><Person /></span>
                                        </Avatar>
                                    }
                                    title={<Typography variant="title" className={classes.title}>
                                                {t('followers')} ({user && user.followersCount ? user.followersCount : 0})
                                            </Typography>}
                                />
                                <CardContent>
                                    {followers &&
                                         followers.length > 0 ? <List dense className={classes.root}>
                                            {followers.map(follower => {
                                                return (
                                                    <ListItem key={`follower-${follower.id}`} button onClick={() => { this.props.history.push(`/user/${follower.id}`);}}>
                                                        <ListItemAvatar>
                                                                <UserAvatar size="small" username={follower.username} userId={follower.id}
                                                                            imageUrl={follower.profileImageUrl}/>
                                                        </ListItemAvatar>
                                                        <ListItemText id={follower.id}
                                                                      primary={follower.username}/>
                                                        <ListItemSecondaryAction>
                                                            {!hasUserInObjectList(myFollowings, follower.id) && !hasUserInObjectList(mySentFollowingRequests, follower.id) &&
                                                                <Button aria-label={t('buttons.follow')}
                                                                            onClick={() => this.handleFollowClick(follower)}
                                                                            className={classes.buttonAccept}
                                                                            variant="contained"
                                                                            size="small">
                                                                    {t('buttons.follow')}
                                                                </Button>
                                                            }
                                                        </ListItemSecondaryAction>
                                                    </ListItem>
                                                );
                                            })}
                                        </List> :
                                            <Typography variant={"subheading"} className={classes.gray}>
                                                {t('messages.no followers')}
                                            </Typography>
                                    }
                                </CardContent>
                            </Card>
                        </Grid>

                        <Grid item key='following'>
                            <Card className={classes.card}>
                                <CardHeader
                                    avatar={
                                        <Avatar size="small" aria-label={t('following')} className={classes.avatar}>
                                            <span><Person/></span>
                                        </Avatar>
                                    }
                                    title={<Typography variant="title" className={classes.title}>
                                                {t('following')} ({user && user.followingsCount ? user.followingsCount : 0})
                                            </Typography>}
                                />
                                <CardContent>
                                    {followings &&
                                         followings.length > 0 ? <List dense className={classes.root}>
                                            {followings.map(following => {
                                                return (
                                                    <ListItem key={`following-${following.id}`} button onClick={() => { this.props.history.push(`/user/${following.id}`);}}>
                                                        <ListItemAvatar>
                                                            <UserAvatar size="small" UserAvatar username={following.username} userId={following.id}
                                                                        imageUrl={following.profileImageUrl}/>
                                                        </ListItemAvatar>
                                                        <ListItemText id={following.id}
                                                                      primary={following.username}/>
                                                        <ListItemSecondaryAction>
                                                            <Button aria-label={t('buttons.unfollow')}
                                                                        className={classes.buttonDecline}
                                                                        onClick={() => this.handleUnfollowClick(following.id)}
                                                                        variant="contained"
                                                                        size="small">
                                                                {t('buttons.unfollow')}
                                                            </Button>
                                                        </ListItemSecondaryAction>
                                                    </ListItem>
                                                );
                                            })}
                                        </List> :
                                        <Typography variant={"subheading"} className={classes.gray}>
                                        {t('messages.no followings')}
                                        </Typography>
                                    }
                                </CardContent>
                            </Card>
                        </Grid>
                    <Grid item key='invite'>
                            <Card className={classes.inviteCard}>
                                <CardContent>
                                    <InviteFriends />
                                </CardContent>
                            </Card>
                        </Grid>
                    </Grid>
                </div>
        )
    }

}
const mapStateToProps = ({friends, myFollowerRequests, myFollowers, mySentFollowingRequests, myFollowings, user, users}) => {
        return {user:user, friends:friends, myFollowerRequests:myFollowerRequests, mySentFollowingRequests:mySentFollowingRequests, myFollowers:myFollowers, myFollowings:myFollowings, users:users};
};

export default compose (
    withRouter,
    withStyles(styles),
    withI18n(),
    connect(mapStateToProps, {acceptFollowingRequest, declineFollowingRequest, fetchFollowingRequests, fetchFollowers, fetchFollowings, fetchUsers,  sendFollowRequest, removeFollowInfoListeners, unFollow})
)(Friends)
