import React, { Component } from "react";
import { withI18n } from "react-i18next";
import { withStyles } from "@material-ui/core/styles";
import { withRouter } from "react-router-dom";
import { compose } from "recompose";

import PropTypes from "prop-types";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import Typography from "@material-ui/core/Typography";
import ErrorIcon from '@material-ui/icons/Error';

import {
  Paper,
  InputBase,
  IconButton,
  Collapse,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Slide
} from "@material-ui/core";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

const styles = {
  centeredTitle: {
    display: "flex",
    alignItems: "center"
  }

}

export class AddFriendToInvite extends Component {
  state = {
    email: "",
    showInvalidDialog: false,
  };

  constructor(props) {
    super(props);
    this.handleClose = this.handleClose.bind(this);
    this.validateEmail = this.validateEmail.bind(this);
  }

  onSubmit = (e) => {
    e.preventDefault();
    // Validate the input
    if (!this.validateEmail(this.state.email)) {
      this.setState({ showInvalidDialog: true });
    } else {
      this.props.addFriend(this.state.email);
      this.setState({ email: "" });
    }
  };



  handleClose = () => {
    this.setState({ showInvalidDialog: false });
  }

  validateEmail = (emailToValidate) => {
    var pattern = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);
    return pattern.test(emailToValidate);
  }


  // Update the state with the input values on change
  onChange = (e) =>
    this.setState({
      [e.target.name]: e.target.value,
    });

  render() {
    const { t, classes } = this.props;

    return (
      <React.Fragment>
        <Paper className={classes.inputRoot}>
          <InputBase
            className={classes.input}
            placeholder={" " + t("invitefriends.add-email")}
            value={this.state.email}
            onSubmit={this.onSubmit}
            onChange={this.onChange}
            onKeyPress={(e) => {
              if (e.key === 'Enter') {
                this.onSubmit(e);
                e.preventDefault();
              }
            }}
            name="email"
          />
          <IconButton
            edge="end"
            aria-label={"buttons.add"}
            aria-hidden={false}
            onClick={this.onSubmit}
            disabled={this.state.showInvalidDialog}
          >
            <AddCircleIcon color="primary" className={classes.icon} />
          </IconButton>
        </Paper>

        <Dialog
          open={this.state.showInvalidDialog}
          TransitionComponent={Transition}
          keepMounted
          onClose={this.handleClose}
          aria-labelledby="alert-dialog-slide-title"
          aria-describedby="alert-dialog-slide-description"
        >
        <DialogTitle id="alert-dialog-slide-title">
            <div style={styles.centeredTitle}>
              <ErrorIcon color="primary" /> {"    "}
              <Typography variant="h5" className={classes.title}>
                  {t("invitefriends.invalid-email-dialog.title")}
              </Typography>
            </div>
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-slide-description">
              {t("invitefriends.invalid-email-dialog.body")}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleClose} color="primary">
              OK
            </Button>
          </DialogActions>
        </Dialog>
      </React.Fragment>
    );
  }
}

// PropTypes
AddFriendToInvite.propTypes = {
  addFriend: PropTypes.func.isRequired,
};

export default compose(
  withRouter,
  withStyles(styles),
  withI18n()
)(AddFriendToInvite);