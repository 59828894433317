import React, {Component, Fragment} from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { withStyles } from '@material-ui/core/styles';
import { withI18n } from "react-i18next";
import { compose } from 'recompose';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import ErrorIcon from '@material-ui/icons/Error';
import InfoIcon from '@material-ui/icons/Info';
import CloseIcon from '@material-ui/icons/Close';
import { amber, green } from '@material-ui/core/colors';
import IconButton from '@material-ui/core/IconButton';
import Snackbar from '@material-ui/core/Snackbar';
import SnackbarContent from '@material-ui/core/SnackbarContent';
import WarningIcon from '@material-ui/icons/Warning';
import {closeSnack, openSnack} from "../../actions";
import {connect} from "react-redux";

const variantIcon = {
    success: CheckCircleIcon,
    warning: WarningIcon,
    error: ErrorIcon,
    info: InfoIcon,
};

const styles = theme => ({
    success: {
        backgroundColor: green[600],
    },
    error: {
        backgroundColor: theme.palette.error.dark,
    },
    info: {
        backgroundColor: theme.palette.primary.dark,
    },
    warning: {
        backgroundColor: amber[700],
    },
    icon: {
        fontSize: 20,
    },
    iconVariant: {
        opacity: 0.9,
        marginRight: theme.spacing(1),
    },
    message: {
        display: 'flex',
        alignItems: 'center',
    },
    margin: {
        margin: theme.spacing.unit * 1,
    },
});

function MySnackbarContentWrapper(props) {
    const { className, messageText, onClose, variant, classes, ...other } = props;
    const Icon = variantIcon[variant];

    return (
        <SnackbarContent
            className={clsx(classes[variant], className)}
            aria-describedby="client-snackbar"
            message={
                <span id="client-snackbar" className={classes.message}>
          <Icon className={clsx(classes.icon, classes.iconVariant)} />
                    {messageText}
        </span>
            }
            action={[
                <IconButton key="close" aria-label="Close" color="inherit" onClick={onClose}>
                    <CloseIcon className={classes.icon} />
                </IconButton>,
            ]}
            {...other}
        />
    );
}

MySnackbarContentWrapper.propTypes = {
    className: PropTypes.string,
    message: PropTypes.node,
    onClose: PropTypes.func,
    variant: PropTypes.oneOf(['success', 'warning', 'error', 'info'])
};

class CustomSnackbar extends Component {
    constructor(props) {
        super(props);
        // this.handleClick = this.handleClick.bind(this);
        this.handleClose = this.handleClose.bind(this);
    }

    // handleClick() {
    //     this.props.openSnack({open:true,messageText:'testing 1 3',variant:'success'});
    // }

    handleClose(event, reason) {
        if (reason === 'clickaway') {
            return;
        }
        if(event) {
            event.preventDefault();
        }
        this.props.closeSnack(this.props.variant);
    }
    render() {
        const {classes, open, messageText, variant, t} = this.props;
        return (
            <Fragment>
                <div>
                    {/*<Button variant="outlined" className={classes.margin} onClick={this.handleClick}>*/}
                        {/*Open success snackbar*/}
                    {/*</Button>*/}
                    <Snackbar
                        anchorOrigin={{
                            vertical: 'top',
                            horizontal: 'center',
                        }}
                        open={open}
                        autoHideDuration={10000}
                        onClose={this.handleClose}
                    >
                        <MySnackbarContentWrapper
                            onClose={this.handleClose}
                            variant={variant}
                            messageText={t(messageText)}
                            classes={classes}
                        />
                    </Snackbar>
                </div>
            </Fragment>
        );
    }
}
const mapStateToProps = ({snack}) => {
    if(snack){
        return {open:snack.open, messageText:snack.messageText, variant:snack.variant};
    }
    return { open:false };
};
export default compose(withStyles(styles), withI18n(),connect(mapStateToProps, {closeSnack, openSnack}))(CustomSnackbar)