import React from 'react';
import {ExpandLess, ExpandMore } from '@material-ui/icons';
import {Avatar, Collapse, Divider, MenuItem, Menu, MenuList} from '@material-ui/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import ReactCountryFlag from "react-country-flag";
import { faSignInAlt, faGlobeAmericas, faUser, faUserShield, faStar} from '@fortawesome/free-solid-svg-icons'
import toRenderProps from 'recompose/toRenderProps';
import withState from 'recompose/withState';
import SignOutButton from '../SignOut';
import LinkUser from '../LinkUser';
import i18n from '../../i18n';
import { withI18n } from "react-i18next";
import {Link} from "react-router-dom";
import {ACCOUNT, ADMIN, SIGN_IN} from "../../constants/routes";

const WithState = toRenderProps(withState('anchorEl', 'updateAnchorEl', null, 'openLanguage', 'updateOpenLanguage'));

function UserMenu(props) {
    const {authUser, imageHash, t, classes, currentUser}  = props;
    const displayName = authUser ?  authUser.displayName ? authUser.displayName : authUser.email : null;
    const isAdmin = currentUser && currentUser.isApproved && currentUser.isAdmin;


    return (
        <WithState>
            {({ anchorEl, updateAnchorEl }) => {
                const open = Boolean(anchorEl);
                const handleClose = () => {
                    updateAnchorEl(null);
                };
                const changeLanguage = (lng) => {
                    i18n.changeLanguage(lng);
                    handleClose();
                };

                return (
                    <React.Fragment>
                        {authUser && authUser.photoURL ? <Avatar
                                aria-owns={open ? 'render-props-menu' : undefined}
                                aria-haspopup="true"
                                src={`${authUser.photoURL}?${imageHash}`}
                                onClick={event => {
                                    updateAnchorEl(event.currentTarget);
                                }}
                            />
                            :
                            <Avatar
                                aria-owns={open ? 'render-props-menu' : undefined}
                                aria-haspopup="true"
                                onClick={event => {
                                    updateAnchorEl(event.currentTarget);
                                }}
                            >
                                {displayName ? displayName[0].toUpperCase() : <FontAwesomeIcon icon={faUser}/>}
                            </Avatar>
                        }
                        <Menu id="render-props-menu" anchorEl={anchorEl} open={open} onClose={handleClose}>
                            {currentUser && <MenuItem>
                                <FontAwesomeIcon icon={faStar} className={classes.iconStar}/> {t('points')}: {currentUser.points}
                            </MenuItem>

                            }
                            {currentUser && < Divider />}
                            {authUser && <MenuItem   component={Link} to={`/user/${authUser.uid}`} onClick={handleClose}><FontAwesomeIcon icon={faUser} className={classes.iconInDrawMenu}/>{t('application.my profile')}</MenuItem>}
                            {authUser && <MenuItem  component={Link} to={ACCOUNT} onClick={handleClose}><FontAwesomeIcon icon={faUser} className={classes.iconInDrawMenu}/>{t('application.my account')}</MenuItem>}
                            {isAdmin && <MenuItem component={Link} to={ADMIN} onClick={handleClose}><FontAwesomeIcon icon={faUserShield} className={classes.iconInDrawMenu}/>{t('application.admin')}</MenuItem>}
                            {authUser && <Divider />}
                            <MenuList>
                                <MenuItem onClick={props.handleLanguageSelectionClick}>
                                    <FontAwesomeIcon icon={faGlobeAmericas} className={classes.iconInDrawMenu}/>
                                    {t('language select')}
                                    {props.openLanguageSelection ? <ExpandLess /> : <ExpandMore />}
                                </MenuItem>
                                <Collapse in={props.openLanguageSelection} timeout="auto" unmountOnExit>
                                    <MenuList>
                                        <MenuItem className={classes.nested}  onClick={() => changeLanguage('en')}> <ReactCountryFlag code="us"  svg styleProps={{
                                            width: '20px',
                                            height: '12px'
                                        }} /> {t('language.english')} </MenuItem>
                                        <MenuItem className={classes.nested} onClick={() => changeLanguage('es')}> <ReactCountryFlag code="es" svg styleProps={{
                                            width: '20px',
                                            height: '12px'
                                        }}/> {t('language.spanish')} </MenuItem>
                                    </MenuList>
                                </Collapse>
                            </MenuList>
                            {authUser && <LinkUser/>}
                            <Divider/>
                            {authUser ? <SignOutButton classes={classes}/> : <MenuItem component={Link} to={SIGN_IN} onClick={handleClose}>  <FontAwesomeIcon icon={faSignInAlt} className={classes.iconInDrawMenu}/>{t('application.sign in')}</MenuItem>}
                        </Menu>
                    </React.Fragment>
                );
            }}
        </WithState>
    );
}

export default withI18n()(UserMenu);