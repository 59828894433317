import { withI18n } from "react-i18next";
import { withStyles } from '@material-ui/core/styles';
import {withRouter} from 'react-router-dom';
import {compose} from "recompose";
import TimeAgo from 'react-timeago';
import {Button, Card, CardActions, CardContent, CardHeader, Checkbox, Divider,  Grid, FormControl, FormControlLabel, InputLabel, LinearProgress, List, ListItem, ListItemText, ListItemAvatar, Select, TextField, Typography} from '@material-ui/core';
import {connect} from "react-redux";
import {createGroup, fetchGroups} from "../../actions";
import {ACCESS_LEVEL, AVATAR_TYPE, PATHS} from "../../constants/common";
import {LOADING_MASK, PRIMARY, RADIO_BUTTON_SELECT, SECONDARY_ICON} from "../../constants/colors";
import MyGroupList from "../MyGroupList";
import UserAvatar from "../UserAvatar";
import {getJSDate} from "../../util/functions";


const React = require('react');

const styles = theme => ({
    root: {
        width: '100%',
        //flexGrow: 1,
    },
    card: {
        //width: 300,
        margin: theme.spacing(.2),
    },
    cardContent: {
        //width: 300,
        paddingTop: 0,
    },
    inline: {
        display: 'inline',
    },
    formControl: {
        minWidth: 300,
    },
    formControlRadio: {
        paddingTop: theme.spacing(2),
        //margin: theme.spacing(1),
    },
    radioButton : {
        color: RADIO_BUTTON_SELECT,
        '&$checked': {
            color: RADIO_BUTTON_SELECT,
        },
    },
    progressRoot: {
        flexGrow: 1,
        spacing: '40'
    },
    progress: {
        margin: theme.spacing(2),
        color: LOADING_MASK
    },
    pageActions : {
        paddingTop : 0,
        color: PRIMARY
    },
    groupsCard: {
        //width: 300,
        margin: theme.spacing(2),
    },
    groupAvatar: {
        width: 50,
        height: 50,
        backgroundColor: SECONDARY_ICON
        // padding: theme.spacing(2),
    },
    actions: {
        display: 'inherit',
    },
    media: {
        paddingTop: '75.25%', // 16:9
    },
    title: {
        textTransform: 'uppercase'
    },
    pos: {
        marginBottom: 12,
    },
    expand: {
        transform: 'rotate(0deg)',
        marginLeft: 'auto',
        transition: theme.transitions.create('transform', {
            duration: theme.transitions.duration.shortest,
        }),
    },
    group: {
        margin: theme.spacing(1),
        display: 'inline'
    },
    description : {
        whiteSpace: 'pre-wrap'
    },
    gray : {
        color: 'gray'
    },
});

const INITIAL_NEW_GROUP_STATE = {
    newGroupName: '',
    newGroupAccess: ACCESS_LEVEL.PRIVATE,
    newGroupDescription: '',
    newGroupOrganizationId: '',
    newGroupIsPrivate: false,
    newGroupIsClass:false
};

class Groups extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            ...INITIAL_NEW_GROUP_STATE,
            groupsLoading: false,
            myGroupsLoading: false,
            saving: false,
            groupsToSearch: PATHS.PUBLIC_BASE
        };
        this.createGroup = this.createGroup.bind(this);
        this.onChange = this.onChange.bind(this);
        this.saveCompleted = this.saveCompleted.bind(this);
        this.onCheckChange = this.onCheckChange.bind(this);
    }

    componentDidMount() {
        const currentUserId = this.props.authenticated.uid;
        if(!this.props.groups[PATHS.PUBLIC_BASE]) {
            this.props.fetchGroups({path:PATHS.NON_ORG_GROUPS, collectionId:PATHS.PUBLIC_BASE, currentUserId:currentUserId});
        }
    }

    componentWillUnmount() {
        //this.props.removeGroupsListeners(this.props.authenticated.uid);
    }

    onChange = prop => event => {
        this.setState({ [prop]: event.target.value });
    };
    onCheckChange = prop => event => {
            this.setState({ [prop]: event.target.checked });
    };

    onGroupTypeSelectionChange = event => {
        debugger;
        var path = PATHS.NON_ORG_GROUPS;
        var collectionId = PATHS.PUBLIC_BASE;
        if(event.target.value !== PATHS.PUBLIC_BASE) {
            path = `${PATHS.ORGANIZATION_BASE}/${event.target.value}/groups`;
            collectionId = event.target.value;
        }
        const currentUserId = this.props.authenticated.uid;
        this.setState({groupsToSearch: event.target.value});
        if(!this.props.groups[collectionId]) {
            this.props.fetchGroups({path: path, currentUserId: currentUserId, collectionId: collectionId});
        }
    };

    createGroup = () => {
        this.setState({ 'saving': true });
        const {newGroupName,  newGroupIsPrivate, newGroupDescription, newGroupOrganizationId, newGroupIsClass} = this.state;
        const {authenticated, user} = this.props;
        const members = [];
        members.push({label: user.username, value:authenticated.uid, profileImageUrl: user.profileImageUrl, isAdmin:true});
        const group = {name:newGroupName, isPrivate:newGroupIsPrivate, isClass:newGroupIsClass, description:newGroupDescription, createdByUsername:user.username, createdById:authenticated.uid};
        var path = PATHS.NON_ORG_GROUPS;
        if(newGroupOrganizationId !== '') {
            path = `${PATHS.ORGANIZATION_BASE}/${newGroupOrganizationId}/groups`;
            group.organizationId = newGroupOrganizationId;
            group.organizationName = user.organizations[newGroupOrganizationId].name;
        }
        this.props.createGroup({path:path, members:members, group:group, callback:this.saveCompleted});
    };

    goToGroup = (event, groupId, organizationId) => {
        if(organizationId) {
            this.props.history.push(`/group/${organizationId}/${groupId}`);
        } else {
            this.props.history.push(`/group/public/${groupId}`);
        }
    }

    saveCompleted = (success, groupId, organizationId) => {
        this.setState({ 'saving': false });
        this.setState(INITIAL_NEW_GROUP_STATE);
        if(success) {
            if(organizationId) {
                this.props.history.push(`/group/${organizationId}/${groupId}`);
            } else {
                this.props.history.push(`/group/public/${groupId}`);
            }
        }
    };
    render() {

        const {t, classes, groups, user} = this.props;
        const {newGroupName,  newGroupIsPrivate, newGroupDescription, newGroupOrganizationId, newGroupIsClass, saving, groupsToSearch} = this.state;
        const isCreateInvalid = newGroupName === '' || newGroupDescription === '' || saving;
        const canCreateClass = user && user.isApproved && (user.isAdmin || user.isAdministrator || user.isProfessor);
        const groupsToDisplay = groups && groups[groupsToSearch] ? groups[groupsToSearch] : [];
        return (
                <div>
                    <Grid  item container className={classes.root} spacing={2}>
                        <Grid item xs={12} md={3}>
                            <Grid id="leftPanel">
                                <div id="mygroups">
                                    <Card className={classes.card}>
                                        <CardHeader
                                            title={ <Typography className={classes.title} color="textSecondary" gutterBottom>
                                                {t('application.my groups')}
                                            </Typography>
                                            }
                                        />
                                        <CardContent className={classes.cardContent}>
                                            <MyGroupList userId={user.id} />
                                        </CardContent>
                                    </Card>
                                </div>
                            </Grid>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <Grid>
                                <Card className={classes.card}>
                                    <CardHeader
                                        title={t('groups search')}
                                        action={<FormControl className={classes.formControl}>
                                            <InputLabel htmlFor="organization-select"  shrink={true}><Typography > {t('fields.organization')}</Typography></InputLabel>
                                            <Select
                                                value={groupsToSearch}
                                                onChange={this.onGroupTypeSelectionChange}
                                                inputProps={{
                                                    name: 'groupsToSearch',
                                                    id: 'groupsToSearch-select',
                                                }}
                                                native
                                            >

                                                <option value={PATHS.PUBLIC_BASE}>{t('public').toUpperCase()}</option>
                                                {user && user.verifiedOrganizations && user.verifiedOrganizations.map(organization =>
                                                    <option value={organization.id}>{organization.name}</option>
                                                )}
                                            </Select>
                                        </FormControl>}
                                    />
                                    <CardContent  className={classes.cardContent}>
                                        { groupsToDisplay && groupsToDisplay.length > 0 ?
                                            <List className={classes.root}>
                                                {groupsToDisplay.map((group,index) => (
                                                    <span><ListItem alignItems="flex-start" key={group.id}  button onClick={(e)=>this.goToGroup(e, group.id, group.organizationId)}>
                                                        <ListItemAvatar>
                                                            <UserAvatar username={group.name}
                                                                        userId={group.organizationId ? `${group.organizationId}/${group.id}` : `public/${group.id}`}
                                                                        imageUrl={group.profileImageUrl}
                                                                        type={AVATAR_TYPE.GROUP}
                                                            />
                                                        </ListItemAvatar>
                                                        <ListItemText
                                                            primary={group.name}
                                                            secondary={
                                                                <React.Fragment>
                                                                    <Typography
                                                                        component="div"
                                                                        variant="body2"
                                                                        className={classes.inline}
                                                                        color="textPrimary"
                                                                    >
                                                                        {t('created')} <TimeAgo date={getJSDate(group.dateCreated)}/>
                                                                        <br/>
                                                                        {t(group.access)}
                                                                    </Typography>
                                                                    <br />
                                                                    {group.description}
                                                                </React.Fragment>
                                                            }
                                                        />
                                                    </ListItem>
                                                {
                                                    index !== (groups.length - 1) && <Divider variant="inset" component="li" />
                                                }
                                                    </span>
                                                ))}
                                            </List> :
                                            <div>{t('no results')}</div>
                                        }
                                    </CardContent>
                                </Card>
                            </Grid>
                        </Grid>
                        <Grid item xs={12} md={3}>
                            <Grid id="rightPanel">
                                <div id="createGroup">
                                    <Card className={classes.card}>
                                        <CardHeader
                                            title={ <Typography className={classes.title} color="textSecondary" gutterBottom>
                                                {t('application.create group')}
                                            </Typography>
                                            }
                                        />
                                        <CardContent className={classes.cardContent}>
                                            <Grid>
                                                <Grid item>
                                                    <TextField value={newGroupName}  inputProps={{maxLength: 80}} className={classes.formControl} required type="text" onChange={this.onChange('newGroupName')} id="input-newGroupName" label={t('fields.name')} />
                                                </Grid>
                                                <Grid item>
                                                    <TextField inputProps={{maxLength: 200}} value={newGroupDescription} className={classes.formControl} required type="text"
                                                               onChange={this.onChange('newGroupDescription')} id="input-description" label={t('fields.description')}
                                                               multiline rows="2" />
                                                </Grid>
                                                {user && user.verifiedOrganizations &&
                                                    <Grid item>
                                                        <FormControl className={classes.formControl}>
                                                            <InputLabel htmlFor="organization-select" required shrink={true}><Typography > {t('fields.organization')}</Typography></InputLabel>
                                                            <Select
                                                                value={newGroupOrganizationId}
                                                                onChange={this.onChange('newGroupOrganizationId')}
                                                                inputProps={{
                                                                    name: 'newGroupOrganizationId',
                                                                    id: 'newGroupOrganization-select',
                                                                }}
                                                                native
                                                            >

                                                                <option value="">{t('none')}</option>
                                                                {user.verifiedOrganizations.map(organization =>
                                                                    <option value={organization.id}>{organization.name}</option>
                                                                )}
                                                            </Select>
                                                        </FormControl>
                                                    </Grid>
                                                }
                                                <Grid item  className={classes.formControlRadio}>
                                                    <FormControl component="fieldset" required className={classes.formControl}>
                                                        <FormControlLabel
                                                            control={
                                                                <Checkbox
                                                                    checked={newGroupIsPrivate}
                                                                    onChange={this.onCheckChange('newGroupIsPrivate')}
                                                                    value={newGroupIsPrivate}
                                                                    // color="primary"
                                                                    style={{color:RADIO_BUTTON_SELECT}}
                                                                />
                                                            }
                                                            label={t('fields.private')}
                                                        />
                                                    </FormControl>
                                                </Grid>
                                                {canCreateClass && <Grid item className={classes.formControlRadio}>
                                                    <FormControl component="fieldset" required
                                                                 className={classes.formControl}>
                                                        <FormControlLabel
                                                            control={
                                                                <Checkbox
                                                                    checked={newGroupIsClass}
                                                                    onChange={this.onCheckChange('newGroupIsClass')}
                                                                    value={newGroupIsClass}
                                                                    // color="primary"
                                                                    style={{color: RADIO_BUTTON_SELECT}}
                                                                />
                                                            }
                                                            label={t('is class?')}
                                                        />
                                                    </FormControl>
                                                </Grid>
                                                }
                                            </Grid>
                                        </CardContent>
                                        {saving &&
                                        <div className={classes.uploadProgress}>
                                            <LinearProgress classes={{
                                                colorPrimary: classes.linearColorPrimary,
                                                barColorPrimary: classes.linearBarColorPrimary,
                                            }}/>
                                        </div>
                                        }
                                        <CardActions  className={classes.actions}>
                                            <Button size="small" color="primary" type="button" disabled={isCreateInvalid} onClick={this.createGroup}> {t('buttons.create')}</Button>
                                        </CardActions>
                                    </Card>
                                </div>
                            </Grid>
                        </Grid>
                    </Grid>

                </div>
        )
    }

}
const mapStateToProps = ({groups, user}) => {
        return {groups:groups, user:user};
};

export default compose (
    withRouter,
    withStyles(styles),
    withI18n(),
    connect(mapStateToProps, {createGroup, fetchGroups})
)(Groups)
