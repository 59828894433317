import { withI18n } from "react-i18next";
import { withStyles } from '@material-ui/core/styles';
import { withRouter } from 'react-router-dom';
import {compose} from "recompose";
import {Delete, Favorite, FavoriteBorderOutlined, Send, Share} from '@material-ui/icons';
import {Card, CardActions, CardContent, CardHeader,CardMedia,  Chip, Divider, Grid, IconButton, InputBase, LinearProgress, List, ListItem, ListItemAvatar, ListItemText, Paper,  Tooltip}  from '@material-ui/core';
import {
    createPost,
    doUploadBase64File,
    doUploadFile,
    doUploadPostImage,
    getFileDownloadUrl,
    openSnack,
    doDeleteFile,
    deletePost,
    updatePostLike
} from "../../actions";
import {MEDIA_LOCATION, MEDIA_TYPE, PATHS, POST_PATHS, POST_TYPE} from "../../constants/common";
import UserAvatar from "../UserAvatar"
import {connect} from "react-redux";
import TimeAgo from 'react-timeago';
import FileUpload from '../FileUpload';
import * as COLORS from "../../constants/colors";
import {createUserLikeKey, getJSDate, getYoutubeVideoId} from "../../util/functions";
import {RED, BLUE, PRIMARY, WHITE} from "../../constants/colors";
var linkify = require('linkify-it')();
const React = require('react');

const styles =  theme => ({
    root: {
        padding:0,
    },
    card: {
        width: '90%',
        maxWidth: '600px',
        margin: 3
    },
    cardEdit: {
        width: '90%',
        maxWidth: '600px',
        //margin: 3
    },
    cardHeader: {
        marginTop:0,
        marginBottom:0,
        paddingTop:0,
        paddingBottom: '0px !important',
        padding:0
    },
    cardContent: {
        marginTop:0,
        marginBottom:0,
        paddingTop:0,
        paddingBottom:'0px !important',
        padding:0,
    },
    cardContentLastChild: {
        paddingBottom: 0
    },
    cardMedia: {
        height: 0,
        paddingTop: '56.25%', // 16:9
    },
    cardMediaEdit: {
        width:'50%'
    },
    cardMediaYoutube: {
        width:'100%'
    },
    postContent : {
        paddingTop: 0,
        paddingBottom: 0
    },
    listPadding : {
        paddingTop: 0,
        paddingBottom: 0
    },
    hidden: {
        display: 'none'
    },
    mediaPreview: {
        width: 40,
        height: 30,
        // padding: theme.spacing.unit * 2,
        backgroundColor: COLORS.MAIN_ICON,
    },
    bullet: {
        display: 'inline-block',
        margin: '0 2px',
        transform: 'scale(0.8)',
    },
    actions: {
        display: 'inherit',
    },
    title: {
        textTransform: 'uppercase'
    },
    pos: {
        marginBottom: 12,
    },
    uploadProgress : {
        flexGrow: 1,
    },
    linearColorPrimary : {
        backgroundColor: COLORS.LINARY_LOADING_MASK,
    },
    linearBarColorPrimary : {
        backgroundColor: COLORS.LINARY_LOADING_MASK_BAR,
    },
    icon: {
        color: PRIMARY,
        cursor: 'pointer'
    },
    iconDelete: {
        color: RED,
        cursor: 'pointer'
    },
    iconFavorite: {
        color: RED,
    },
    iconFavoriteSelected: {
        color: BLUE,
    },
    expand: {
        transform: 'rotate(0deg)',
        marginLeft: 'auto',
        transition: theme.transitions.create('transform', {
            duration: theme.transitions.duration.shortest,
        }),
    },
    expandOpen: {
        transform: 'rotate(180deg)',
    },
    inputRoot: {
        padding: '6px 4px 0px',
        display: 'flex',
       // alignItems: 'center',
        // width: 400,
    },
    input: {
        // marginLeft: theme.spacing(1),
        flex: 1,
        //borderWidth: 2,
        border: '1px solid #ddd;',
        boxShadow: 'inset 0 1px 8px -3px #ababab',
        width: '100%',
        minHeight: '70px'
    },
    likedToolTip : {
        width: '100%',
        maxWidth: 250,
    },
    chip: {
        backgroundColor: RED,
        color: WHITE,
        fontSize: '.8em'
    }
});

const INITIAL_STATE = {
    saving: false,
    media: null,
    mediaType: null,
    mediaName: null,
    firstLink: null,
    expanded: false
};


const INITIAL_POST_STATE = {
    saving: false,
    media: null,
    mediaType: null,
    mediaName: null,
    content: '',
    contentPreview: null,
    id:'newPost',
    firstLink: null,
    localFileAvailable:false,
    mediaLocation: null,
    mediaUrl: null,
    expanded:false
};
class Post extends React.Component {

    constructor(props) {
        super(props);

       // this.state = { ...INITIAL_STATE };
        var readOnly = props.readOnly;
        var showShareButton = navigator && navigator.share ? true : false;

        if(props.post) {
            this.state = {text:props.post.text, content:props.post.content, mediaName: props.post.mediaName, mediaUrl:props.post.mediaUrl, mediaType:props.post.mediaType, id:props.post.id, ...INITIAL_STATE, readOnly:readOnly, showShareButton:showShareButton}
        } else {
            this.state = {...INITIAL_POST_STATE, readOnly:readOnly, showShareButton:showShareButton};
        }
        this.createPost = this.createPost.bind(this);
        this.onChange = this.onChange.bind(this);
        this.createPostCallback = this.createPostCallback.bind(this);
        this.cancelEdit = this.cancelEdit.bind(this);
        this.editPost = this.editPost.bind(this);
        this.contentChange = this.contentChange.bind(this);
        this.onUploadChange = this.onUploadChange.bind(this);
        this.loading = this.loading.bind(this);
        this.deletePost = this.deletePost.bind(this);
        this.getUrlContentType = this.getUrlContentType.bind(this);
        this.handleExpandClick = this.handleExpandClick.bind(this);
        this.handleLikeClick = this.handleLikeClick.bind(this);
        this.handleShareClick = this.handleShareClick.bind(this);
        this.populatePostInfo = this.populatePostInfo.bind(this);

    }

    handleExpandClick = () => {
        this.setState({expanded:!this.state.expanded});
    };
    handleLikeClick = liked => event => {
        const {userId, username} = this.props;
        var data = {isLiked:liked, currentUserId: userId, currentUsername:username};
        this.populatePostInfo(data);
        this.props.updatePostLike(data);
    };

    handleShareClick = () => {
        const {post} = this.props;
        var that = this;
        if (navigator.share) {
            navigator.share({
                title: post.createdByUsername + ' post on Socipod',
                text: post.mediaUrl? post.mediaUrl + ' ' + post.content : post.content,
                url: window.location.href
            }).then(() => {
                console.log('Thanks for sharing!');
                that.props.openSnack({open:true, messageText:'Thanks for sharing!', variant: 'success'});
            })
                .catch(err => {
                    console.log(`Couldn't share because of`, err.message);
                    that.props.openSnack({open:true, messageText:`Couldn't share because of ${err.message}`, variant: 'error'});
                });
        } else {
            console.log('web share not supported');
            that.props.openSnack({open:true, messageText:'web share not supported', variant: 'warning'});
        }
    };

    submit = action => event => {
        this.setState({saving:true});
        if (this.state.media) {
            this.uploadPostMedia(action)
        } else {
            if(action === 'create') {
                this.createPost();
            } else {
                this.updatePost();
            }
        }

    }
    createPost() {
        const {text, content, initialContent, mediaUrl, mediaType, filename, mediaLocation } = this.state;
        const { type, userId, username, profileImageUrl, typeId, subTypeId} = this.props;
        const newPost = {createdByUsername: username, createdById:userId};
        if(text){
            newPost.text = text;
        }
        if(content !== initialContent) {
            newPost.content = content;
        }

        if(mediaUrl){
            newPost.mediaUrl = mediaUrl;
            newPost.mediaType = mediaType;
        }
        if(filename){
            newPost.mediaName = filename;
        }
        if(mediaLocation){
            newPost.mediaLocation = mediaLocation;
        }

        if(profileImageUrl) {
            newPost.profileImageUrl = profileImageUrl;
        }

        var path = POST_PATHS.PERSONAL;
        var data = {type:type, path:path, post:newPost, currentUserId:userId, callback:this.createPostCallback};

        if(POST_TYPE.GROUP === type){
            data.path = `${POST_PATHS.GROUP}/${subTypeId}/${typeId}`;
            data.orgId = subTypeId;
            data.groupId = typeId;
        } else if(POST_TYPE.LESSON === type){

        } else if(POST_TYPE.ORGANIZATION === type) {
            data.path = `${PATHS.ORGANIZATION_BASE}/${typeId}`;
            data.orgId = typeId;
        }
        this.props.createPost(data);
    };

    populatePostInfo(data) {
        const { type, typeId, subTypeId, postId, post} = this.props;
        data.postId = postId;

        if(POST_TYPE.GROUP === type){
            data.path = `${POST_PATHS.GROUP}/${subTypeId}/${typeId}`;
            data.orgId = subTypeId;
            data.groupId = typeId;
            data.type = type;
            data.collectionId= typeId;
        } else if(POST_TYPE.LESSON === type){

        } else if(POST_TYPE.ORGANIZATION === type) {
            data.path = `${PATHS.ORGANIZATION_BASE}/${typeId}`;
            data.orgId = typeId;
            data.type = type;
            data.collectionId = typeId;
        } else {
            data.type = type;
            data.path = POST_PATHS.PERSONAL;
            data.collectionId= post.createdById;
        }
    }

    updatePost() {
        var {text, postImages, postVideos, imageChanged, videoChanged, content, mediaName} = this.state;
        var { type, post, userId, username, profileImageUrl} = this.props;
        var currentDate = Date.now();
        var newPost = {dateUpdated:currentDate};
        if(text !== post.text){
            newPost.text = text;
        }

        if(content !== post.content){
            newPost.content = content;
        }
        if(imageChanged){
            newPost.imageUrl = postImages;
        }
        if(videoChanged){
            newPost.videoUrl = postVideos;
        }
        if(mediaName) {
            newPost.mediaName = mediaName;
        }
        if(profileImageUrl) {
            newPost.profileImageUrl = profileImageUrl;
        }
        alert('to be implemented');
        this.updatePostCallback(true);
        return;

        if(POST_TYPE.PERSONAL === type) {
            this.props.updatePost(newPost, userId, this.updatePostCallback);
        } else if(POST_TYPE.GROUP === type){

        } else if(POST_TYPE.LESSON === type){

        }
    };

    uploadPostMedia(action) {
        var {media, mediaName, mediaType, mediaInfo} = this.state;
        //var metadata = {name:media.name, size:media.size, type:media.type};
        var {userId, typeId, type} = this.props;
        const currentTime = Date.now();
        var that = this;
        var path = type === POST_TYPE.PERSONAL ? `media/posts/${userId}` : type === POST_TYPE.GROUP === type ? `media/group-posts/${typeId}` : type === POST_TYPE.ORGANIZATION === type ? `media/organization-posts/${typeId}` : `media/lesson-posts/${typeId}`;
        var filename = currentTime.toString() + mediaName;
        var fullPath =  path + '/' + filename;

        var dataUrlStringToReplace = "data:"+mediaInfo.type+";base64,";
        var mediaContent = mediaType === MEDIA_TYPE.IMAGE ? media.replace(dataUrlStringToReplace, "") : media.replace(dataUrlStringToReplace,"");
        var uploadTask = doUploadBase64File({fullPath: fullPath, file:mediaContent});
        uploadTask.on('state_changed',
            (snapshot) => {

            },
            (error) => {
                that.setState({ 'saving': false });
                that.props.openSnack({open:true,messageText:error.message,variant:'error'});
            },
            () => {
                uploadTask.snapshot.ref.getDownloadURL().then(
                    url => {
                        that.setState({'mediaUrl': url, 'filename':filename});
                        if(action === 'create') {
                            that.createPost()
                        } else {
                            that.updatePost()
                        }
                    }
                )
            }
        )
    }


    createPostCallback(success) {
        this.setState({saving:false});
        if(success) {
            if(this.state.id === 'newPost') {
                this.setState({...INITIAL_POST_STATE});
                this.setState({content: ''});
            } else {
                this.setState({readOnly:true});
            }
            //this.props.saveCompleted(this.state.id);
        }
    }

    updatePostCallback(success) {
        this.setState({saving:false});
        if(success) {
            this.setState({readOnly:true});
        }
    }
    onChange = prop => event => {
        if(prop === 'content' && !this.state.localFileAvailable) {
            this.contentChange(event.target.value );
        }
        this.setState({ [prop]: event.target.value });
    };

    contentChange = (content) => {
       var links = linkify.match(content);
       if(links && links.length > 0) {
           if(this.state.firstLink){
               var found = links.filter(match => (match.url === this.state.firstLink));
               if(found && found.length > 0) {
                   return;
               }
               this.setState({firstLink:null, mediaType: null, mediaUrl:null, mediaLocation: null});
           }

           var i;
           for (i = 0; i < links.length; i++) {
               var link = links[i].url;
               var mediaUrl = null;
               var mediaType = null;
               var videoId = getYoutubeVideoId(link);
               if (videoId && videoId !== 'error') {
                   mediaUrl = 'https://www.youtube.com/embed/' + videoId;
                   mediaType = MEDIA_TYPE.VIDEO;
                   this.setState({firstLink:link, mediaType: mediaType, mediaUrl:mediaUrl, mediaLocation: MEDIA_LOCATION.YOU_TUBE});
                   return;
               } else {
                   this.getUrlContentType(link).then(result => console.log('found content type', result)).catch(error => console.log('failed', error));
               }
           }

       }
    };

    async getUrlContentType(url){
        try {
            const proxyurl = "https://cors-anywhere.herokuapp.com/";
            const response = await fetch(proxyurl + url);
            console.log(response);
            this.setState({});
            var contentType = response.headers.get('Content-Type');
            var mediaType = null;
            if(contentType.indexOf('image/') === 0) {
                 mediaType = MEDIA_TYPE.IMAGE;
            } else if(contentType.indexOf('video/') === 0) {
                 mediaType = MEDIA_TYPE.VIDEO;
            } else {
                return;
            }

            this.setState({firstLink:url, mediaType: mediaType, mediaUrl:url, mediaLocation: MEDIA_LOCATION.UNKNOWN, media:null});

        }catch(error) {
            console.log(error);
        }
    }

    displayEmojiPicker () {
        console.log('display emoji picker');
    }

    editPost () {
        this.setState({readOnly:false});
    }
    deletePost () {
        var {post, type, typeId, userId, subTypeId} = this.props;
        if(post.mediaName) {
            const path = type === POST_TYPE.PERSONAL ? `media/${POST_PATHS.PERSONAL}/${userId}` : type === POST_TYPE.GROUP === type ? `media/${POST_PATHS.GROUP}/${subTypeId}/${typeId}` : type === POST_TYPE.ORGANIZATION === type ? `media/${POST_PATHS.ORGANIZATION}/${typeId}` : `media/${POST_PATHS.LESSON}/${typeId}`;
            const fullPath = path + '/' + post.mediaName;
            this.props.doDeleteFile(fullPath);
        }
        var postPath = type === POST_TYPE.PERSONAL ? `${POST_PATHS.PERSONAL}` : type === POST_TYPE.GROUP ? `${POST_PATHS.GROUP}/${subTypeId}/${typeId}` : POST_TYPE.ORGANIZATION === type ? `${PATHS.ORGANIZATION_BASE}/${typeId}/posts` : `${POST_PATHS.LESSON}/${subTypeId}/${typeId}`;
        var collectionId = POST_TYPE.PERSONAL === type ? userId : typeId;
        this.props.deletePost(postPath, post.id, collectionId);
    }
    cancelEdit () {
        this.setState({readOnly:true});
    }

    onUploadChange = (fileObject) => {
        if(fileObject) {
            var mediaType = fileObject.info.type.indexOf('image/') === 0 ? MEDIA_TYPE.IMAGE : MEDIA_TYPE.VIDEO;
            this.setState({mediaInfo:fileObject.info, mediaName:fileObject.info.name, mediaType:mediaType, media:fileObject.src, mediaLocation: MEDIA_LOCATION.SOCIPOD_FIREBASE, firstLink:null, localFileAvailable:true});
        } else {
            this.setState({mediaInfo:null, mediaName:null, mediaType:null, media:null, mediaLocation:null, localFileAvailable:false});
            this.contentChange(this.state.content);
        }
    };

    loading(isLoading) {
        this.setState({saving:isLoading})
    }

    render() {
        const {t, classes, profileImageUrl, username, userId, canEdit, post, postId, openSnack,} = this.props;
        const {media, mediaType, saving, readOnly, content, mediaName, mediaUrl, mediaLocation, expanded, showShareButton} = this.state;
        const isInvalid = (content === ''  && media === null) || saving;
        //const isUpdateInvalid = post && post.content === content;
        const isLiked = post && post.likes && post.likes.includes(createUserLikeKey(userId, username));
        const usersLikedToolTip = (
            <List dense className={classes.likedToolTip}>
                {post && post.likes && post.likes.map(value => {
                    const valueObject = value.split("_");
                    const labelId = `list-label-${value}`;
                    return (
                        <ListItem key={value} button onClick={() => { this.props.history.push(`/user/${valueObject[0]}`);}}>
                            <ListItemAvatar>
                                <UserAvatar userId={valueObject[0]}
                                            username={valueObject[1]}
                                            size='small'/>
                            </ListItemAvatar>
                            <ListItemText id={labelId} primary={valueObject[1]} />
                        </ListItem>
                    );
                })}
            </List>


        );
        return (
            <div align="center">
                {readOnly ?
                    <Card className={classes.card}>

                        {canEdit ? <CardHeader classes={{root: classes.cardHeader}}
                                                            avatar={
                                                                <UserAvatar username={post.createdByUsername}
                                                                            userId={post.createdById}
                                                                            imageUrl={post.profileImageUrl}/>
                                                            }
                                                            action={
                                                                <IconButton aria-label="delete" onClick={this.deletePost}>
                                                                    <Delete className={classes.iconDelete}/>
                                                                </IconButton>
                                                            }
                                                            title={<span>{post.createdByUsername} {post.moderated &&
                                                            <Chip
                                                                size="small"
                                                                label={t('moderated')}
                                                                className={classes.chip}
                                                            />}</span>}
                                                            subheader={<TimeAgo date={getJSDate(post.dateCreated)}/>}
                        />  :
                            <CardHeader classes={{root: classes.cardHeader}}
                                                             avatar={
                                                                 <UserAvatar username={post.createdByUsername}
                                                                             userId={post.createdById}
                                                                             imageUrl={post.profileImageUrl}/>
                                                             }
                                                            title={<span>{post.createdByUsername} {post.moderated &&
                                                            <Chip
                                                                size="small"
                                                                label={t('moderated')}
                                                                className={classes.chip}
                                                            />}</span>}
                                                            subheader={<TimeAgo
                                                                date={getJSDate(post.dateCreated)}/>}
                        />
                        }

                        {!post.mediaUrl && <Divider variant="middle"/>}

                        {post.mediaUrl && post.mediaType === MEDIA_TYPE.IMAGE &&
                            <CardMedia
                            component="img"
                            className={classes.media}
                            image={post.mediaUrl}
                            />
                        }
                        {post.mediaUrl && post.mediaType === MEDIA_TYPE.VIDEO &&
                            <span>
                                {post.mediaLocation ?
                                    <iframe
                                        title={post.mediaUrl}
                                        allowFullScreen
                                        className={classes.cardMediaYoutube}
                                        class="MuiCardMedia-root MuiCardMedia-media"
                                        width="100%"
                                        src={post.mediaUrl}
                                        controls
                                    />
                                    :
                                    <CardMedia
                                        component={"video"}
                                        controls
                                        allowfullscreen
                                        className={classes.cardMedia}
                                        src={post.mediaUrl}
                                    />
                                }
                            </span>
                        }
                        <CardContent classes={{root: classes.cardContent}}>
                            <Grid>
                                <Grid container>
                                    <Grid item xs={12}>
                                        <List classes={{padding: classes.listPadding}}>
                                                <ListItem classes={{root: classes.postContent}}>
                                                    {post.text && <ListItemText primary={post.text}/>}
                                                    {post.content && <ListItemText primary={post.content}/>}

                                                </ListItem>
                                        </List>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </CardContent>
                        <CardActions disableSpacing>
                            <Tooltip title={usersLikedToolTip} interactive>
                                <IconButton aria-label="add to favorites" onClick={this.handleLikeClick(!isLiked)}>
                                    {isLiked ? <Favorite className={classes.iconFavorite}/> : <FavoriteBorderOutlined className={classes.iconFavorite}/> }
                                </IconButton>
                            </Tooltip>
                            {/*{showShareButton &&*/}

                                {/*<IconButton aria-label="share" onClick={this.handleShareClick}>*/}
                                    {/*<Share  className={classes.icon}/>*/}
                                {/*</IconButton>*/}
                            {/*}*/}
                            {/*<IconButton*/}
                                {/*className={clsx(classes.expand, {*/}
                                    {/*[classes.expandOpen]: expanded,*/}
                                {/*})}*/}
                                {/*onClick={this.handleExpandClick}*/}
                                {/*aria-expanded={expanded}*/}
                                {/*aria-label="show more"*/}
                            {/*>*/}
                                {/*{t('comments')} <ExpandMore />*/}
                            {/*</IconButton>*/}
                        </CardActions>

                        {/*<Collapse in={expanded} timeout="auto" unmountOnExit>*/}
                            {/*<CardContent>*/}
                                {/*<Typography paragraph>Method:</Typography>*/}
                                {/*<Typography paragraph>*/}
                                    {/*Heat 1/2 cup of the broth in a pot until simmering, add saffron and set aside for 10*/}
                                    {/*minutes.*/}
                                {/*</Typography>*/}
                                {/*<Typography paragraph>*/}
                                    {/*Heat oil in a (14- to 16-inch) paella pan or a large, deep skillet over medium-high*/}
                                    {/*heat. Add chicken, shrimp and chorizo, and cook, stirring occasionally until lightly*/}
                                    {/*browned, 6 to 8 minutes. Transfer shrimp to a large plate and set aside, leaving chicken*/}
                                    {/*and chorizo in the pan. Add pimentón, bay leaves, garlic, tomatoes, onion, salt and*/}
                                    {/*pepper, and cook, stirring often until thickened and fragrant, about 10 minutes. Add*/}
                                    {/*saffron broth and remaining 4 1/2 cups chicken broth; bring to a boil.*/}
                                {/*</Typography>*/}
                                {/*<Typography paragraph>*/}
                                    {/*Add rice and stir very gently to distribute. Top with artichokes and peppers, and cook*/}
                                    {/*without stirring, until most of the liquid is absorbed, 15 to 18 minutes. Reduce heat to*/}
                                    {/*medium-low, add reserved shrimp and mussels, tucking them down into the rice, and cook*/}
                                    {/*again without stirring, until mussels have opened and rice is just tender, 5 to 7*/}
                                    {/*minutes more. (Discard any mussels that don’t open.)*/}
                                {/*</Typography>*/}
                                {/*<Typography>*/}
                                    {/*Set aside off of the heat to let rest for 10 minutes, and then serve.*/}
                                {/*</Typography>*/}
                            {/*</CardContent>*/}
                        {/*</Collapse>*/}
                    </Card> :
                    <Card className={classes.cardEdit}>
                        {(media || mediaUrl) && mediaType === MEDIA_TYPE.IMAGE &&
                        <CardMedia
                            component="img"
                            className={classes.cardMediaEdit}
                            image={media ? media : mediaUrl}
                        />
                        }
                        {(media || mediaUrl) && mediaType === MEDIA_TYPE.VIDEO &&
                            <span>
                                {mediaLocation ?
                                    <iframe
                                        title={media ? media.name : mediaUrl}
                                        allowFullScreen
                                        className={classes.cardMediaEdit}
                                        class="MuiCardMedia-root MuiCardMedia-media"
                                        width="80%"
                                        src={media ? media : mediaUrl}
                                        controls
                                    />
                                    :
                                    <CardMedia
                                        component={"video"}
                                        controls
                                        allowfullscreen
                                        className={classes.cardMediaEdit}
                                        src={media ? media : mediaUrl}
                                    />
                                }
                            </span>
                        }

                        <CardContent classes={{root: classes.cardContent}}>
                        <Paper className={classes.inputRoot}>
                            <UserAvatar username={username} userId={userId}
                                        imageUrl={profileImageUrl}/>
                            <div style={{width: '100%'}}>
                                <InputBase
                                    className={classes.input}
                                    placeholder={t('create post')}
                                    inputProps={{ 'aria-label': t('create post') }}
                                    value={content}
                                    multiline
                                    margin="normal"
                                    onChange={this.onChange('content')}
                                />
                                <FileUpload id={`${postId}-file-input`} onChange={this.onUploadChange}
                                            loading={this.loading} isSaving={saving}
                                            accept="video/*, image/*" displayFileName={mediaName}/>
                            </div>
                            <IconButton edge="end" aria-label={t('buttons.post')}
                                        aria-hidden={false}
                                        disabled={isInvalid}
                                        onClick={this.state.id === "newPost" ? this.submit('create') : this.submit('update')}>
                                <Send className={classes.icon}/>
                            </IconButton>

                        </Paper>
                        </CardContent>

                        {/*<CardContent classes={{root: classes.cardContent}}>*/}
                            {/*<List>*/}
                                    {/*<ListItem>*/}
                                        {/*<ListItemText>*/}
                                            {/*<Paper className={classes.inputRoot}>*/}
                                                {/*<UserAvatar username={username} userId={userId}*/}
                                                            {/*imageUrl={profileImageUrl}/>*/}
                                                {/*<div><InputBase*/}
                                                    {/*className={classes.input}*/}
                                                    {/*placeholder={t('create post')}*/}
                                                    {/*inputProps={{ 'aria-label': t('create post') }}*/}
                                                    {/*value={content}*/}
                                                    {/*multiline*/}
                                                    {/*margin="normal"*/}
                                                    {/*onChange={this.onChange('content')}*/}
                                                {/*/>*/}
                                                {/*<FileUpload id={`${postId}-file-input`} onChange={this.onUploadChange}*/}
                                                              {/*loading={this.loading} isSaving={saving}*/}
                                                              {/*accept="video/*, image/*" displayFileName={mediaName}/>*/}
                                                {/*</div>*/}
                                                {/*<IconButton edge="end" aria-label={t('buttons.post')}*/}
                                                            {/*aria-hidden={false}*/}
                                                            {/*onClick={this.state.id === "newPost" ? this.submit('create') : this.submit('update')}>*/}
                                                    {/*<Send className={classes.icon}/>*/}
                                                {/*</IconButton>*/}

                                            {/*</Paper>*/}

                                            {/*<FileUpload id={`${postId}-file-input`} onChange={this.onUploadChange}*/}
                                                        {/*loading={this.loading} isSaving={saving}*/}
                                                        {/*accept="video/*, image/*" displayFileName={mediaName}/>*/}
                                        {/*</ListItemText>*/}
                                        {/*<ListItemSecondaryAction>*/}
                                            {/*<IconButton edge="end" aria-label={t('buttons.post')}*/}
                                                        {/*aria-hidden={false}*/}
                                                        {/*onClick={this.state.id === "newPost" ? this.submit('create') : this.submit('update')}>*/}
                                                {/*<Send className={classes.icon}/>*/}
                                            {/*</IconButton>*/}
                                            {/*{this.state.id !== "newPost" && <IconButton edge="end" aria-label={t('buttons.cancel')}*/}
                                                                                        {/*aria-hidden={false}*/}
                                                                                        {/*onClick={this.cancelEdit}>*/}
                                                {/*<Cancel className={classes.iconDelete}/>*/}
                                            {/*</IconButton> }*/}
                                        {/*</ListItemSecondaryAction>*/}
                                    {/*</ListItem>*/}
                            {/*</List>*/}
                        {/*</CardContent>*/}
                        {saving &&
                        <div className={classes.uploadProgress}>
                            <LinearProgress classes={{
                                colorPrimary: classes.linearColorPrimary,
                                barColorPrimary: classes.linearBarColorPrimary,
                            }}/>
                        </div>
                        }
                    </Card>
                }
            </div>


        )
    }

}

// function mapStateToProps({user}) {
//     return {user};
// }
export default compose (
    withRouter,
    withStyles(styles),
    withI18n(),
    connect(null, { createPost, doUploadFile, doUploadPostImage, getFileDownloadUrl, openSnack, doDeleteFile, deletePost,updatePostLike})
)(Post)