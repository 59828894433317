import { withI18n } from "react-i18next";
import { withStyles } from '@material-ui/core/styles';
import {withRouter } from 'react-router-dom';
import {compose} from "recompose";
import * as COLORS from "../../constants/colors";
import {Edit} from '@material-ui/icons'
import ImageCrop from 'react-image-crop-component'
import 'react-image-crop-component/style.css'
import {Avatar, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Divider, IconButton, Grid, LinearProgress, Typography} from'@material-ui/core';
import { connect } from "react-redux";
import { doAuthUserProfileUpdate, doUploadProfileImage, getProfileImageDownloadUrl, updateProfileImageHash, updateGroupAttribute, updateDocumentAttribute, openSnack} from "../../actions";
import {IMAGE_CROP_TYPE, USER_FIELD} from "../../constants/common";
import {storage} from "../Firebase/firebase";

const React = require('react');

const styles = theme => ({
    container: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    colorSwitchBase: {
        color: COLORS.SWITCH,
        '&$colorChecked': {
            color: COLORS.SWITCH,
            '& + $colorBar': {
                backgroundColor: COLORS.SWITCH,
            },
        },
    },
    content : {
        maxWidth: 600,
        maxHeight: 650
    },
    colorBar: {},
    colorChecked: {},
    uploadProgress : {
        flexGrow: 1,
    },
    linearColorPrimary : {
        backgroundColor: COLORS.LINARY_LOADING_MASK,
    },
    linearBarColorPrimary : {
        backgroundColor: COLORS.LINARY_LOADING_MASK_BAR,
    },
    actions: {
        display: 'inherit',
    },
    title: {
        textTransform: 'uppercase'
    },
    pos: {
        marginBottom: 12,
    },
    success : {
        color: 'green'
    },
    imageUpload : {
        marginTop: theme.spacing(2)
    },
    Avatar: {
        width: 70,
        height: 70,
        // padding: theme.spacing.unit * 2,
        backgroundColor: COLORS.MAIN_ICON,
    },
    Post: {
        width: 304,
        height: 171,
        //height: 0,
        //paddingTop: '56.25%', // 16:9
    },
    icon: {
        margin: theme.spacing(),
        color: COLORS.PRIMARY,
    },
});

const INITIAL_STATE = {
    message:null,
    name: '',
    error: null,
    saving: false,
    src: null,
    imageUrl: null,
    image: null,
    image2: null,
};
class UploadCropImage extends React.Component {

    constructor(props) {
        super(props);


        this.state = { ...INITIAL_STATE, open:props.open, type:props.type};
        this.handleClickOpen = this.handleClickOpen.bind(this);
        this.handleClose = this.handleClose.bind(this);
        this.handleCreate = this.handleCreate.bind(this);
        this.handleSave = this.handleSave.bind(this);
        this.updateProfileImageUrl = this.updateProfileImageUrl.bind(this);
        this.onChange = this.onChange.bind(this);
        this.onCheckChange = this.onCheckChange.bind(this);
        this.onCropped = this.onCropped.bind(this);


    }

    handleClickOpen = () => {
        this.setState({saving:false});
        this.setState({ open: true});
    };

    handleClose = () => {
        this.setState({saving:false});
        this.setState({ open: false});
    };

    handleSave = () => {
        this.setState({ 'saving': true });
        var that = this;
        const {image} = this.state;
        const typeId = this.props.typeId;
        const dataPath = this.props.dataPath;
        debugger;
        if(this.props.type === IMAGE_CROP_TYPE.PROFILE || this.props.type === IMAGE_CROP_TYPE.ORGANIZATION || this.props.type === IMAGE_CROP_TYPE.GROUP || this.props.type === IMAGE_CROP_TYPE.LESSON) {
            const uploadTask = doUploadProfileImage({uid: typeId, image:image.replace("data:image/png;base64,", "")});
            uploadTask.on('state_changed',
                (snapshot) => {

                },
                (error) => {
                    that.setState({ 'saving': false });
                    that.setState({ error });
                    that.props.openSnack({open:true,messageText:error.message,variant:'error'});
                },
                () => {

                    if (that.props.isNew) {
                        uploadTask.snapshot.ref.getDownloadURL().then(
                            url => {
                                that.setState({'imageUrl': url});
                                if(this.props.type === IMAGE_CROP_TYPE.PROFILE) {
                                    that.updateProfileImageUrl();
                                } else if(this.props.type === IMAGE_CROP_TYPE.ORGANIZATION) {
                                    that.props.updateDocumentAttribute({path:`organizations/${typeId}`, attribute: USER_FIELD.PROFILE_IMAGE_URL, value:url, currentUsername:this.props.currentUser.username, currentUserId:this.props.currentUser.id});
                                    //that.props.updateOrganizationAttribute(typeId, 'profileImageUrl', url);
                                    that.props.updateHash();
                                    that.handleClose();
                                } else if(this.props.type === IMAGE_CROP_TYPE.GROUP || this.props.type === IMAGE_CROP_TYPE.LESSON) {
                                    that.props.updateDocumentAttribute({path:`${dataPath}`, attribute: USER_FIELD.PROFILE_IMAGE_URL, value:url, currentUsername:this.props.currentUser.username, currentUserId:this.props.currentUser.id});
                                    that.props.updateHash();
                                    that.handleClose();
                                }
                            }
                        )
                    } else {
                        console.log("completed");
                        if(this.props.type === IMAGE_CROP_TYPE.PROFILE) {
                            that.props.updateProfileImageHash();
                        } else if(this.props.type === IMAGE_CROP_TYPE.ORGANIZATION || this.props.type === IMAGE_CROP_TYPE.GROUP) {
                            that.props.updateHash();
                        }
                        that.setState({ 'saving': false });
                        that.handleClose();
                    }
                }
            )
        }  else if(this.props.type === IMAGE_CROP_TYPE.POST) {
            const {image} = this.state;
            this.props.onChange(image);
            this.handleClose();
        }

    };

    updateProfileImageUrl = () => {
        const {imageUrl} = this.state;
        var that = this;
        doAuthUserProfileUpdate({
            photoURL: imageUrl,
        }).then(function() {
            //dispatch updates
            // Update successful.
            that.props.updateProfileImageHash();
            that.setState({ 'saving': false });
            that.handleClose();
        }).catch(function(error) {
            // An error happened.
            that.setState({ 'saving': false });
            that.props.openSnack({open:true,messageText:error.message,variant:'success'});
        });
        this.props.updateDocumentAttribute({path:`users/${this.props.typeId}`, attribute: USER_FIELD.PROFILE_IMAGE_URL, value:imageUrl, currentUserId:this.props.currentUser.id});
    };

    onSelectFile = e => {
        if (e.target.files && e.target.files.length > 0) {
            const reader = new FileReader();
            reader.addEventListener("load", () =>
                this.setState({ src: reader.result })
            );
            reader.readAsDataURL(e.target.files[0]);
        }

        if(e.target.files[0]) {
            const image = e.target.files[0];

            this.setState({'image2' : image});
        }
    };

    handleCreate = () => {
        this.setState({ 'saving': true });
        const { name, publish} = this.state;
        const { authUser} = this.props;
        const dateNow = Date.now();
        const teamId = name.split(" ").join( "-").toLowerCase() + '-'+dateNow;
        const team = {name:name, publish:publish, creator:authUser.uid, createdDate:dateNow};
        this.props.firebase.team(teamId).set(team).then(() => {
            //display snack of success
            this.setState({ ...INITIAL_STATE });
            this.props.history.push(`/team/${teamId}`);
            this.props.switchTeam(teamId);
        }).catch(error => {
            this.setState({ 'saving': false });
            this.setState({ error });
        });

    };
    onChange = prop => event => {
        this.setState({ [prop]: event.target.value });
    };
    onCheckChange = name => event => {
        this.setState({ [name]: event.target.checked });
    };

    onCropped = e => {
        this.setState({image:e.image});
        //let image_data = e.data
    }

    render() {
        const {
            image,
            message,
            error,
            saving,
            src,
            open
        } = this.state;
        const {t, title, description, classes, type, icon} = this.props;
        const isInvalid = image === null || saving;
        return (
            <div>
                <IconButton aria-label={t('buttons.edit')}
                            onClick={() => this.handleClickOpen()}
                            className={classes.avatar}>
                    {icon}
                </IconButton>
                <Dialog
                    open={open?open:false}
                    onClose={this.handleClose}
                    aria-labelledby="form-dialog-title"
                >
                    <DialogTitle id="form-dialog-title"> <Typography  className={classes.title}>
                        {title}
                    </Typography></DialogTitle>
                    <DialogContent className={classes.content}>
                        <DialogContentText>
                            {description}
                        </DialogContentText>
                        <div>
                            <div>
                                <input
                                    accept="image/*"
                                    className={classes.input}
                                    id="raised-button-file"
                                    type="file"
                                    onChange={this.onSelectFile}
                                />
                            </div>
                            {src && <div>
                                <ImageCrop src={src}
                                           setWidth={300}
                                           setHeight={300}
                                           square={true}
                                           resize={true}
                                           border={"dashed #ffffff 2px"}
                                           onCrop={this.onCropped}/>
                            </div>
                            }
                        </div>


                        { image &&  <div><br /> <Divider/> <br />
                            <Grid container direction="row" justify="space-evenly" alignItems="center">
                                    <Avatar src={image} className={classes.Avatar} color="inherit"/>
                                    <img src={image} className={classes.Avatar} alt="avatar"/>
                            </Grid>
                        </div>
                        }
                    </DialogContent>
                    {saving &&
                    <div className={classes.uploadProgress}>
                        <LinearProgress classes={{
                            colorPrimary: classes.linearColorPrimary,
                            barColorPrimary: classes.linearBarColorPrimary,
                        }}/>
                    </div>
                    }
                    <DialogActions>
                        <Button onClick={this.handleClose} color="primary">
                            {t('buttons.cancel')}
                        </Button>
                        <Button onClick={this.handleSave} color="primary" disabled={isInvalid}>
                            { t('buttons.save')}
                        </Button>
                    </DialogActions>
                    <Typography  className={classes.success}>
                        {message}
                    </Typography>
                    <Typography color="error">
                        {error && <p>{error.message}</p>}
                    </Typography>
                </Dialog>
            </div>
        )
    }

}

export default compose (
    withRouter,
    withStyles(styles),
    withI18n(),
    connect(null, { doAuthUserProfileUpdate, doUploadProfileImage, getProfileImageDownloadUrl, updateProfileImageHash, updateGroupAttribute, updateDocumentAttribute, openSnack })
)(UploadCropImage)
