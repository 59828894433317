import React, { Component } from 'react';
import DanteEditor, {VideoRecorderBlockConfig} from 'Dante2';
import '../DraftwysiwygEditor/editorStyles.css';
import {storage} from '../Firebase/firebase'
import { Edit, Save} from '@material-ui/icons';
import {IconButton} from '@material-ui/core';
import {getFileDownloadUrl, doUploadFile, openSnack} from "../../actions";
import {connect} from "react-redux";
import {ImageBlockConfig} from "Dante2/package/lib/components/blocks/image";
import {EmbedBlockConfig} from "Dante2/package/lib/components/blocks/embed";
import {PlaceholderBlockConfig} from "Dante2/package/lib/components/blocks/placeholder";
import {VideoBlockConfig} from "Dante2/package/lib/components/blocks/video";
import {DividerBlockConfig} from "Dante2/package/lib/components/blocks/divider";
import {convertFromRaw, EditorState } from 'draft-js';

class DanteEditorImp extends Component {
    constructor(props) {
        super(props);
        const readOnly = props.readOnly ? true : false;
        this.state = {readOnly:readOnly, editorContext: null, content:null, newMediaAddedMap: new Map(), }
        this.onSaveStateHandler = this.onSaveStateHandler.bind(this);
        this.onEditorStateChange = this.onEditorStateChange.bind(this);
        this.cancelEdit = this.cancelEdit.bind(this);
        this.saveContent = this.saveContent.bind(this);
        this.toggleContentEdit = this.toggleContentEdit.bind(this);
        this.handleFileUpload = this.handleFileUpload.bind(this);
        this.handleFileUploadError = this.handleFileUploadError.bind(this);
        this.handleFileUploadSuccess = this.handleFileUploadSuccess.bind(this);
        this.removeUnusedFiles = this.removeUnusedFiles.bind(this);
        this.getContentChange = this.getContentChange.bind(this);
    }
    onEditorStateChange = (editor) => {
        console.log('editor content: ', editor.emitSerializedOutput())
    };
    onSaveStateHandler = (editorContext, content) => {
        this.setState({editorContext:editorContext});
    };
    toggleContentEdit() {
        if(!this.state.readOnly && this.state.editorContext && this.state.editorContext.editor && this.state.editorContext.editor.closePopOvers) {
            this.state.editorContext.editor.closePopOvers();
        }
        this.setState({readOnly: !this.state.readOnly});
    }
    cancelEdit() {
        if(this.getContentChange()) {
            //TODO:reset content
            debugger;
            this.removeUnusedFiles();
            const editorState = this.props.content ? EditorState.createWithContent(convertFromRaw(JSON.parse(this.props.content))) : EditorState.createEmpty();
            this.state.editorContext.editorState = editorState;
            this.state.editorContext.editorContent = JSON.parse(this.props.content);
            this.state.editorContext.editor.setState(editorState);
            window.reload();
        }
        //his.props.reload();
        this.toggleContentEdit();
    }
    saveContent() {
        this.toggleContentEdit();
        var content = this.getContentChange();
        if(content){
            this.props.onUpdateContentHandler(content)
        } else {
            this.props.openSnack( {open:true,messageText:'no change to save', variant:'info'});
        }
    }

    getContentChange () {
        debugger;
        if(this.state.editorContext && this.state.editorContext.editor) {
            var content = this.state.editorContext.editor.emitSerializedOutput();
            if(content!= null) {
                content = JSON.stringify(content);
                if(content === this.props.content){
                  return false;
                } else {
                    return content;
                }
            }
        }
    }
    handleFileUploadSuccess(ctx, imageBlock) {
       this.handleFileUpload(imageBlock.file, imageBlock)
    }
    handleFileUpload(file, imageBlock) {
       // alert('file uploaded handle');
        debugger;
        const filePath =  'media/lessons/'+this.props.itemId;
        const uploadTask = doUploadFile({fullPath: filePath+'/'+ file.name, file:file});
        var that = this;
        uploadTask.on('state_changed',
            (snapshot) => {

            },
            (error) => {
                that.props.openSnack({open:true,messageText:error.message,variant:'error'});
            },
            () => {

                storage.ref(filePath).child(file.name).getDownloadURL().then(
                    url => {
                        that.state.newMediaAddedMap.set(file.name, url);
                        imageBlock.uploadCompleted(url)

                    }
                )

            }
        )
    }
    handleFileUploadError(ctx, imageBlock) {
        this.handleFileUpload(imageBlock.file, imageBlock);

    }

    componentWillUnmount() {
      this.removeUnusedFiles();
    }

    removeUnusedFiles() {
        const content = this.props.content;
        var filePath = 'media/lessons/'+this.props.itemId;
        if(this.state.newMediaAddedMap.size > 1) {
            this.state.newMediaAddedMap.forEach(function (value, key) {
                debugger;
                if(content.indexOf(value) === -1) {
                    storage.ref(filePath).child(key).delete();
                }
            })
        }

    }

    render() {
        const { canEdit, content } = this.props;
        const { readOnly} = this.state;
        const classname = readOnly ? "":"editor";
        const contentJson = content ? JSON.parse(content) : null;
       // const uploadUrl = config.databaseURL+'/media/lessons/'+itemId;
        // const fileUploadURL = config.storageBucket+'/media/lessons/'+itemId;
        return (
            <div className={classname}>
                {canEdit && <div>{readOnly ? <IconButton onClick={this.toggleContentEdit}> <Edit /> </IconButton>: <div> <IconButton onClick={this.saveContent}><Save/></IconButton>  {/*<IconButton onClick={this.cancelEdit}> <Cancel/></IconButton>*/}</div>} </div>}
                <DanteEditor
                        ref="editor"
                        read_only={readOnly}
                        content={contentJson}
                        body_placeholder={this.props.placeHolder}
                        onChange={this.onEditorStateChange}
                        data_storage = {{interval: 500, url: "/store", method: "POST", save_handler: this.onSaveStateHandler}}
                        widgets={[
                            ImageBlockConfig({
                                type:'image',
                                options: {
                                    upload_url: '/store',
                                    upload_callback: this.handleFileUploadSuccess,
                                    upload_error_callback: this.handleFileUploadError,
                                    image_upload_handler: this.handleFileUpload
                                },
                            }),
                            EmbedBlockConfig({ options: { placeholder: this.props.t('put an external link') }, }),
                            PlaceholderBlockConfig(),
                            VideoBlockConfig({}),
                            DividerBlockConfig(),
                            // PlaceholderBlockConfig(),
                            // VideoBlockConfig()
                            // VideoRecorderBlockConfig({
                            //     options: {
                            //         seconds_to_record: 5000,
                            //         upload_url: '/store',
                            //         upload_callback: this.handleFileUploadSuccess,
                            //         upload_error_callback: this.handleFileUploadError
                            //     },
                            // }),

                        ]}

                        //onChange={editor => { console.log('editor content: ', editor.emitSerializedOutput()) }}

                    />
            </div>
        )
    }
}

export default connect(null, {doUploadFile, getFileDownloadUrl, openSnack })(DanteEditorImp)