import { withI18n } from "react-i18next";
import { withStyles } from '@material-ui/core/styles';
import {compose} from "recompose";
import PropTypes from 'prop-types';
import {CHIP, GREEN, LOW_GRADE, PRIMARY, RED, SEND, SUB_APP_BAR, WHITE} from "../../constants/colors";
import {Avatar, AppBar, Button,  Card, CardActions, CardHeader, CardContent, Chip, Grid, CircularProgress, IconButton, List, ListItem, ListItemText, ListItemIcon, ListItemSecondaryAction, Menu, MenuItem, Tab, Tabs, Typography} from '@material-ui/core';
import {Add, Grade, Publish, Settings, VerticalAlignBottom, Share} from '@material-ui/icons';
//import {Rating} from '@material-ui/lab';
import {fetchLesson,fetchLessonAssignment, fetchLessonAssignees, fetchLessonQuestions, openSnack, removeLessonListen, assignUsersToLesson, updateDocumentAttribute, reassignUser} from "../../actions";
import {connect} from "react-redux";
import {Link} from "react-router-dom";
import {CREATE_LESSON} from "../../constants/routes";
import DanteEditorImp from "../DanteEditorImp";
import CreateQuestion from "../QuestionCreate";
import CustomReactSelect from "../CustomReactSelect";
import UserAvatar from "../UserAvatar";
import Ratings from "../Ratings";
import TimeAgo from 'react-timeago';

import GradeQuestions from "../QuestionsGrade";
import Questions from "../Questions";
import LessonInfo from "../LessonInfo";
import * as COLORS from "../../constants/colors";
import {ASSIGNEE_TYPE, ASSIGNMENT_STATUS, AVATAR_TYPE, PATHS, USER_SEARCH_TYPE} from "../../constants/common";
import {db} from '../Firebase/firebase'
import {getJSDate} from "../../util/functions";
import {canAssignLesson, canEditLesson} from "../../util/rules";

const React = require('react');

const INITIAL_STATE = {
    loading: true,
    saving:false,
    selectedTab:0,
    contentReadOnly:true,
    openQuestionModifyDialog:false,
    openQuestionGradeDialog:false,
    questionToModify: null,
    availableUserAssignees: [],
    newUserAssignees:[],
    assigneeToGrade:null,
    assigneesLoaded:false,
    questionsLoaded: false,
};

const styles = theme => ({
    container: {
        // display: 'flex',
        flexWrap: 'wrap',
    },
    card: {
        //maxWidth: 400,
        minHeight: 400,
        margin: theme.spacing(2),
    },
    selectionCard: {
        //maxWidth: 400,
        overflow: 'visible',
        margin: theme.spacing.unit * 2,
    },
    pageActions : {
        paddingTop : 0,
        color: PRIMARY
    },
    title: {
        textTransform: 'uppercase'
    },
    tabColor : {
        backgroundColor : SUB_APP_BAR
    },
    progressRoot: {
        flexGrow: 1,
        spacing: '40'
    },
    progress: {
        margin: theme.spacing(2),
        color: COLORS.LOADING_MASK
    },
    failAvatar : {
        backgroundColor: RED,
        width: 50,
        height: 50
    },
    passAvatar : {
        backgroundColor: GREEN,
        width: 50,
        height: 50
    },
    lowAvatar : {
        backgroundColor: LOW_GRADE,
        width: 50,
        height: 50
    },
    buttonAssign: {
        margin: theme.spacing(.2),
        backgroundColor: SEND,
        color: 'white'
    },
    chip: {
        backgroundColor: CHIP,
        color: WHITE,
        fontSize: '.8em'
    },
    chipPrivate:{
        backgroundColor: RED,
        color: WHITE,
        fontSize: '.8em'
    },
    icon: {
        color: PRIMARY,
        cursor: 'pointer'
    }

});

// const HeartRating = withStyles({
//     iconFilled: {
//         color: '#ff6d75',
//     },
//     iconHover: {
//         color: '#ff3d47',
//     },
// })(Rating);


function TabContainer(props) {
    return (
        <Typography component="div" style={{ padding: 8 * 3 }}>
            {props.children}
        </Typography>
    );
}

TabContainer.propTypes = {
    children: PropTypes.node.isRequired,
};

class Lesson extends React.Component {

    constructor(props) {
        super(props);
        var showShareButton = navigator && navigator.share ? true : false;
        const dataPath = props.match.params.organization === PATHS.PUBLIC_BASE ? PATHS.NON_ORG_LESSONS : PATHS.ORGANIZATION_BASE+'/'+ props.match.params.organization + '/lessons';
        this.state = { ...INITIAL_STATE, dataPath:dataPath, lessonId:props.match.params.lessonId, organizationId:props.match.params.organization, showShareButton:showShareButton};
        this.closeAllDialogs = this.closeAllDialogs.bind(this);
        this.handleActionsClick = this.handleActionsClick.bind(this);
        this.setOpen = this.setOpen.bind(this);
        this.handleActionsClose = this.handleActionsClose.bind(this);
        this.handleTabChange = this.handleTabChange.bind(this);
        this.onUpdateContentHandler = this.onUpdateContentHandler.bind(this);
        this.reload = this.reload.bind(this);
        this.openQuestionModifyDialog = this.openQuestionModifyDialog.bind(this);
        this.openQuestionsGradeDialog = this.openQuestionsGradeDialog.bind(this);
        this.closeQuestionModifyDialog = this.closeQuestionModifyDialog.bind(this);
        this.closeQuestionsGradeDialog = this.closeQuestionsGradeDialog.bind(this);
        this.onUserInputChange = this.onUserInputChange.bind(this);
        this.saveNewUserAssignees = this.saveNewUserAssignees.bind(this);
        this.updateState = this.updateState.bind(this);
        this.setSelectedUsers = this.setSelectedUsers.bind(this);
        this.fetchLessonCallBack = this.fetchLessonCallBack.bind(this);
        this.addNewAssigneesCallback = this.addNewAssigneesCallback.bind(this);
        this.fetchAssigneesCallback = this.fetchAssigneesCallback.bind(this);
        this.reassignUser = this.reassignUser.bind(this);
        this.reloadLessonAssignment = this.reloadLessonAssignment.bind(this);
        this.reloadAssignees = this.reloadAssignees.bind(this);
        this.reloadLesson = this.reloadLesson.bind(this);
        this.assignMyself = this.assignMyself.bind(this);
        this.handleShareClick = this.handleShareClick.bind(this);
        this.editQuestion = this.editQuestion.bind(this);

    }

    componentDidMount() {
        this.setState({loading:true});
        this.props.fetchLesson({path:this.state.dataPath,lessonId: this.state.lessonId, callback:this.fetchLessonCallBack});
        this.props.fetchLessonAssignment(this.state.lessonId, this.props.authenticated.uid);
        this.props.fetchLessonQuestions({path:this.state.dataPath, lessonId:this.state.lessonId});
    }
    fetchLessonCallBack() {
        this.setState({loading:false});
    }
    componentWillUnmount() {
        this.setState({...INITIAL_STATE, lessonId: null});
    }

    handleTabChange = (event, selectedTab) => {
        const {dataPath, lessonId}  =  this.state;
        const {lessonAssignees} = this.props;
        var assigneesLoaded = true;
        if(selectedTab === 3 && !lessonAssignees[lessonId]) {
            assigneesLoaded = false;
            this.props.fetchLessonAssignees({path:dataPath, lessonId:lessonId, callback:this.fetchAssigneesCallback});
        }
        this.setState({ selectedTab, assigneesLoaded:assigneesLoaded});
    };

    handleShareClick = () => {
        const {lesson, user} = this.props;
        var that = this;
        if (navigator.share) {
            navigator.share({
                title: `${user.username} shared a lesson`,
                text:  `lesson shared "${lesson.title}"`,
                url: window.location.href
            }).then(() => {
                console.log('Thanks for sharing!');
                that.props.openSnack({open:true, messageText:'Thanks for sharing!', variant: 'success'});
            })
                .catch(err => {
                    console.log(`Couldn't share because of`, err.message);
                    that.props.openSnack({open:true, messageText:`Couldn't share because of ${err.message}`, variant: 'error'});
                });
        } else {
            console.log('web share not supported');
            that.props.openSnack({open:true, messageText:'web share not supported', variant: 'warning'});
        }
    };
    reloadLessonAssignment() {
        this.props.fetchLessonAssignment(this.state.lessonId, this.props.authenticated.uid);
    }
    reloadLesson() {
    this.props.fetchLesson({path:this.state.dataPath,lessonId: this.state.lessonId, callback:this.fetchLessonCallBack});
    }

    fetchAssigneesCallback() {
        this.setState({assigneesLoaded:true});
    }

    fetchQuestionsCallback() {
        this.setState({questionsLoaded:true});
    }

    handleActionsClick = event => {
        this.setState({ actionAnchorEl: event.currentTarget });
    };

    onUpdateContentHandler(content) {
        console.log('call update lesson content from lesson with:' +  content);
        this.props.updateDocumentAttribute({path:`${this.state.dataPath}/${this.state.lessonId}`, attribute: "content", value:content, currentUsername: this.props.user.username, currentUserId:this.props.authenticated.uid, successMessage:"content saved", callback:this.reloadLesson});
    }
    handleActionsClose = () => {
        this.setState({ actionAnchorEl: null });
    };
    onUserInputChange(searchText) {
        const availableAssignees = [];
        var that = this;
        if(searchText && searchText.length > 0) {
            db.ref('users').orderByChild("username").startAt(searchText).limitToFirst(10).on("child_added", function(snapshot) {
                var user = snapshot.val();
                console.log("The " + snapshot.key + " dinosaur's score is " + user);
                availableAssignees.push({value:snapshot.key, label:user.username});
                that.setState({availableUserAssignees:availableAssignees});
            });
        }
    }

    saveNewUserAssignees() {
        this.setState({ 'saving': true});
        const {lesson, user} = this.props;
        const {lessonId, dataPath, organizationId, newUserAssignees} = this.state;
        const data = {path:dataPath, lessonTitle:lesson.title, currentUserId:this.props.authenticated.uid, currentUsername:user.username, lessonId:lessonId,
            callback : this.addNewAssigneesCallback, organizationId:organizationId}

        if(lesson.assignees && lesson.assignees.userKeys) {
            const newAssignees = [];
            newUserAssignees.forEach(function (newAssignee) {
                if (!lesson.assignees.userKeys.has(newAssignee.value)) {
                    newAssignees.push(newAssignee);
                }
            });
            if(newAssignees.length > 0) {
                data.usersToAssign = newAssignees;
                this.props.assignUsersToLesson(data);
            } else {
                this.props.openSnack({open:true, messageText:'users already added', variant:'info'});
            }
        } else {
            data.usersToAssign = newUserAssignees;
            this.props.assignUsersToLesson(data);
        }

    }

    assignMyself() {
        this.setState({ 'saving': true});
        const {lesson, user} = this.props;
        const {lessonId, dataPath, organizationId} = this.state;
        const newAssignees = [];
        var userToAssign = {label:user.username, value:user.id};
        if(user.profileImageUrl) {
            userToAssign.profileImageUrl =  user.profileImageUrl;
        }
        newAssignees.push(userToAssign);
        const data = {path:dataPath, lessonTitle:lesson.title, currentUserId:this.props.authenticated.uid, currentUsername:user.username, lessonId:lessonId,
            callback : this.addMyselfCallback, organizationId:organizationId, usersToAssign:newAssignees}
        this.props.assignUsersToLesson(data);

    }


    reassignUser = (uid, username) => {
        this.setState({ 'saving': true});
        var callback = uid === this.props.user.id ? this.addMyselfCallback: this.addNewAssigneesCallback;
        this.props.reassignUser({path:this.state.dataPath, organizationId:this.state.organizationId, lessonId:this.state.lessonId, assigneeId:uid,
            assigneeUsername:username, currentUserId:this.props.user.id, currentUsername:this.props.user.username, callback:callback});
    }

    addNewAssigneesCallback = (success) => {
        this.setState({ 'saving': false });
        if(success) {
            this.setState({newUserAssignees:[]});
            this.reloadAssignees();
        }
    };

    addMyselfCallback = (success) => {
        this.setState({ 'saving': false });
        if(success) {
            this.props.fetchLessonAssignment(this.state.lessonId, this.props.authenticated.uid);
        }
    };

    updateState(type, value, args){
        if(ASSIGNEE_TYPE.USER === type){
            var newUserAssignees = this.state.newUserAssignees;
            if(args.action === 'clear') {
                this.setState({newUserAssignees:[]});
            } else if(args.action === 'select-option'){
                newUserAssignees.push(value);
            } else if(args.action === 'remove-value') {
                var assigneeToRemove = args.removedValue.find(val => !Array.isArray(val));
                var newUserAssigneeRemoved = newUserAssignees.filter(function (assignee){
                    return assignee.value === assigneeToRemove.value;
                })
                console.log(newUserAssigneeRemoved);
            }
            this.setState({newUserAssignees:newUserAssignees});
        }
    }

    setSelectedUsers(values) {
        if(values === null) {
            this.setState({newUserAssignees:[]});
        } else {
            this.setState({newUserAssignees:values});
        }

    }
    reload() {
        this.props.fetchLesson({path:this.state.dataPath,lessonId: this.state.lessonId, callback:this.fetchLessonCallBack});
    }
    reloadAssignees() {
        const {dataPath, lessonId}  =  this.state;
        this.props.fetchLessonAssignees({path:dataPath, lessonId:lessonId});
    }
    setOpen(open) {
        this.setState({snackShow: open});
    }
    closeAllDialogs() {
        this.handleActionsClose();
        this.closeQuestionsGradeDialog()
        this.closeQuestionModifyDialog();
    };
    openQuestionModifyDialog(question) {
        this.closeAllDialogs();
        this.setState({openQuestionModifyDialog: true, questionToModify:question});
    };
    handlePublishToggle(publish) {
        this.closeAllDialogs();
        var message = publish ? "lesson published" : "lesson unpublished";
        this.props.updateDocumentAttribute({path:`${this.state.dataPath}/${this.state.lessonId}`,
            attribute: "published", value:publish, currentUserId:this.props.authenticated.uid,
            currentUsername: this.props.user.username, successMessage:message, callback:this.reload});

    };
    closeQuestionModifyDialog() {
        this.setState({openQuestionModifyDialog: false, questionToModify:null});
    }
    openQuestionsGradeDialog(event, assignee) {
       // if(!assignee.submissions[assignee.attempts-1].gradedById === 'SYSTEM') {
            this.closeAllDialogs();
            this.setState({assigneeToGrade: assignee});
            this.setState({openQuestionGradeDialog: true});
      //  }
    };
    closeQuestionsGradeDialog() {
        this.setState({openQuestionGradeDialog: false});
        this.setState({assigneeToGrade:null});
    }

    editQuestion(question){
        //this.setState({questionToModify:question});
        this.openQuestionModifyDialog(question);
    }

    render() {
        const {t, classes, lesson, user, authenticated, myAssignment, lessonAssignees, questions} = this.props;
        const {loading, selectedTab, actionAnchorEl, contentReadOnly, lessonId, assigneeToGrade, dataPath, organizationId, saving, showShareButton, assigneesLoaded} = this.state;

        const openActions = Boolean(actionAnchorEl);
        const currentUserId = authenticated ? authenticated.uid : null;
        const canEdit = canEditLesson(user, lesson);
        //const isStudent = user && user.isStudent;
        const canAnswerQuestions =  myAssignment && (myAssignment.status === ASSIGNMENT_STATUS.ASSIGNED || myAssignment.status === ASSIGNMENT_STATUS.REASSIGNED);
        const lessonTitle = lesson ? lesson.title : t('Not Found');
        const assignedUsers = lessonAssignees[lessonId] && lessonAssignees[lessonId].users ? lessonAssignees[lessonId].users : [];
        const assignedUserKeys = lessonAssignees[lessonId] && lessonAssignees[lessonId].userKeys ? lessonAssignees[lessonId].userKeys : new Set();
        //const assignedGroups = lesson && lesson.assignees && lesson.assignees.groups ? lesson.assignees.groups : [];
        return (
            loading ? <div align="center" className={classes.progressRoot}> <CircularProgress className={classes.progress} size={100} thickness={6} /></div> :
                <div align="center">
                    <Grid container className={classes.root} justify="center">
                        <Grid item key="title" xs={11} md={11}>
                            <Grid>
                                <Typography  variant={"title"} className={classes.title}>
                                    {lessonTitle} {lesson
                                    && <span>
                                    (<Link to={`/user/${lesson.createdById}`} title={lesson.createdByUsername}>{t('created by', {name:lesson.createdByUsername})}</Link>)
                                </span>}
                                </Typography>
                            </Grid>
                        </Grid>
                        {canEdit &&
                        <Grid item key="actions" xs={1} md={1} justify="right">
                            <IconButton aria-label={t('buttons.actions')}
                                        aria-owns={openActions ? 'actions-menu' : undefined}
                                        aria-haspopup="true" onClick={this.handleActionsClick}
                                        className={classes.pageActions}>
                                <Settings/>
                            </IconButton>
                            <Menu id="actions-menu" anchorEl={actionAnchorEl} open={openActions}
                                  onClose={this.handleActionsClose}>
                                <MenuItem component={Link} to={CREATE_LESSON} onClick={this.handleActionsClose}>
                                    <Add className={classes.icon} />
                                    {t('application.create lesson')}
                                </MenuItem>
                                {lesson && !lesson.published &&
                                <MenuItem onClick={() => this.openQuestionModifyDialog(null)}>
                                    <Add className={classes.icon} />
                                    {t('application.add question')}
                                </MenuItem>}
                                {lesson ? lesson.published ? <MenuItem variant="outlined" color="primary" onClick={() => this.handlePublishToggle(false)}>
                                    <VerticalAlignBottom className={classes.icon} />
                                    {t('buttons.unpublish')}
                                    </MenuItem> : <MenuItem variant="outlined" color="primary" onClick={() => this.handlePublishToggle(true)}>
                                    <Publish className={classes.icon} />
                                    {t('buttons.publish')}
                                    </MenuItem> : ""
                                }
                            </Menu>
                        </Grid>
                        }
                        {lesson && <Grid item key="title" xs={12} md={12}>


                                <Ratings
                                    lessonId={lesson.id}
                                    organizationId={organizationId}
                                    rating={lesson.avgRating}
                                    numRatings={lesson.numRatings}
                                    name="overall rating"
                                    showTotal={true}
                                    readOnly={true}
                                />
                            {lesson.gradeLevel &&
                                <Chip
                                    size="small"
                                    label={t('grade.' + lesson.gradeLevel)}
                                    className={classes.chip}
                                />
                            }
                            {lesson.skill &&
                            <Chip
                                size="small"
                                label={t(lesson.skill).toUpperCase()}
                                className={classes.chip}
                            />
                            }
                            {lesson.difficultyLevel &&
                                <Chip
                                    size="small"
                                    label={t(lesson.difficultyLevel).toUpperCase()}
                                    className={classes.chip}
                                />
                            }
                            {lesson.readingLexileLevel &&
                            <Chip
                                size="small"
                                label={lesson.readingLexileLevel + "L"}
                                className={classes.chip}
                            />
                            }

                            {lesson.topic &&
                            <Chip
                                size="small"
                                label={lesson.topic.toUpperCase()}
                                className={classes.chip}
                            />
                            }
                            {lesson.isPrivate &&
                                <Chip
                                    size="small"
                                    label={t('fields.private').toUpperCase()}
                                    className={classes.chipPrivate}
                                />
                            }
                            {myAssignment &&
                                <Chip
                                    size="small"
                                    label={<span>{t('status.'+myAssignment.status.toLowerCase())}: <TimeAgo date={myAssignment.status == ASSIGNMENT_STATUS.ASSIGNED ? getJSDate(myAssignment.dateCreated) : myAssignment.status == ASSIGNMENT_STATUS.COMPLETED ? getJSDate(myAssignment.dateSubmitted) : getJSDate(myAssignment.dateUpdated)}/></span>}
                                    className={classes.chip}
                                />

                            }

                            {lesson && lesson.published && !lesson.isPrivate && myAssignment === null &&
                                <Button aria-label={t('buttons.assign myself')}
                                        onClick={() => this.assignMyself()}
                                        className={classes.buttonAssign}
                                        variant="contained"
                                        size="small"
                                        disabled={saving}>
                                    {t('buttons.assign myself')}
                                </Button>
                            }
                            {lesson && lesson.published && !lesson.isPrivate && myAssignment && ASSIGNMENT_STATUS.COMPLETED == myAssignment.status &&
                                <Button aria-label={t('buttons.retake')}
                                onClick={() => this.reassignUser(user.id, user.username)}
                                className={classes.buttonAssign}
                                variant="contained"
                                size="small"
                                disabled={saving}>
                                {t('buttons.retake')}
                                </Button>
                            }

                            {myAssignment && (ASSIGNMENT_STATUS.COMPLETED == myAssignment.status || ASSIGNMENT_STATUS.REASSIGNED == myAssignment.status) &&
                            <Avatar className={myAssignment.gradePercentage < 45 ? classes.failAvatar :
                                myAssignment.gradePercentage < 70 ? classes.lowAvatar : classes.passAvatar}
                            > {myAssignment.gradePercentage}</Avatar>
                            }
                            {showShareButton &&

                            <IconButton aria-label="share" onClick={this.handleShareClick}>
                                <Share  className={classes.icon}/>
                            </IconButton>
                            }
                        </Grid>
                        }
                    </Grid>
                    {lesson && lesson.id === this.state.lessonId ? <div>
                            <AppBar position="static" className={classes.tabColor}>
                                <Tabs value={selectedTab} indicatorColor="primary" onChange={this.handleTabChange}>
                                    <Tab label={t('fields.content')} />
                                    <Tab label={t('fields.questions')}  disabled={questions && questions.length > 0 ? false : true}/>
                                    <Tab label={t('info')}/>
                                    <Tab label={t('fields.assignees')}  disabled={!canAssignLesson(user, lesson)}/>
                                </Tabs>
                            </AppBar>
                            {selectedTab === 0 && <TabContainer><DanteEditorImp t={t} reload={this.reload} itemId={lessonId} onUpdateContentHandler={this.onUpdateContentHandler} readOnly={contentReadOnly} canEdit={canEdit} content={lesson.content} placeHolder={t('create a lesson')} /></TabContainer>}
                            {selectedTab === 1 && <TabContainer> <Questions dataPath={dataPath} currentUserId={currentUserId} questions={questions} lessonId={lessonId} canEdit={canEdit} canAnswerQuestions={canAnswerQuestions} myAssignment={myAssignment} organizationId={organizationId} onSubmitComplete={this.reloadLessonAssignment} editQuestion={this.editQuestion}/></TabContainer>}
                            {selectedTab === 2 && <TabContainer>
                                {myAssignment && (ASSIGNMENT_STATUS.COMPLETED == myAssignment.status || ASSIGNMENT_STATUS.REASSIGNED == myAssignment.status || ASSIGNMENT_STATUS.SUBMITTED == myAssignment.status) &&
                                <div>
                                    <Typography component="legend">{t('rate me')}</Typography>
                                    <Ratings
                                        lessonId={lesson.id}
                                        organizationId={organizationId}
                                        name="rating lesson"
                                        readOnly={false}
                                        updateSuccess={this.reload}
                                        size={'small'}
                                    />
                                    <br />
                                </div>
                                }
                                <LessonInfo lesson={lesson} canEdit={canEdit} path={`${dataPath}/${lesson.id}`} updateSuccess={this.reload}/>
                            </TabContainer> }
                            {selectedTab === 3 && <TabContainer><Grid  item container className={classes.root} spacing={2}>
                                <Grid item xs={12} md={8}>
                                    <Card className={classes.card}>
                                        <CardHeader
                                            title={t('users assigned')}
                                        />
                                        {assigneesLoaded ?
                                            <CardContent>
                                                {assignedUsers && assignedUsers.length > 0 ?
                                                    <List className={classes.root}>
                                                        {assignedUsers.map(assignee => (
                                                            <ListItem key={assignee.id} dense>
                                                                <ListItemIcon>
                                                                    <UserAvatar username={assignee.username}
                                                                                userId={assignee.id}
                                                                                imageUrl={assignee.profileImageUrl}/>
                                                                </ListItemIcon>
                                                                <ListItemText primary={<span>{assignee.username} <Chip
                                                                    size="small"
                                                                    label={t('status.' + assignee.status.toLowerCase())}
                                                                    className={classes.chip}
                                                                /> <TimeAgo
                                                                    date={assignee.status == ASSIGNMENT_STATUS.ASSIGNED ? getJSDate(assignee.dateCreated) : assignee.status == ASSIGNMENT_STATUS.COMPLETED ? getJSDate(assignee.dateSubmitted) : getJSDate(assignee.dateUpdated)}/></span>}
                                                                              secondary={assignee.status === ASSIGNMENT_STATUS.COMPLETED &&
                                                                              <Button aria-label={t('buttons.reassign')}
                                                                                      onClick={() => this.reassignUser(assignee.id, assignee.username)}
                                                                                      className={classes.buttonAssign}
                                                                                      variant="contained"
                                                                                      size="small"
                                                                                      disabled={saving}>
                                                                                  {t('buttons.reassign')}
                                                                              </Button>}/>
                                                                {assignee.status === ASSIGNMENT_STATUS.SUBMITTED &&
                                                                <ListItemSecondaryAction>
                                                                    <IconButton edge="end"
                                                                                aria-label={t('buttons.grade')}
                                                                                aria-hidden={false}
                                                                                onClick={event => this.openQuestionsGradeDialog(event, assignee)}>
                                                                        <Grade/>
                                                                    </IconButton>
                                                                </ListItemSecondaryAction>
                                                                }
                                                                {assignee.status === ASSIGNMENT_STATUS.COMPLETED &&
                                                                <ListItemSecondaryAction>
                                                                    <IconButton edge="end"
                                                                                aria-label={t('buttons.grade')}
                                                                                aria-hidden={false}
                                                                                onClick={event => this.openQuestionsGradeDialog(event, assignee)}>
                                                                        <Avatar
                                                                            className={assignee.gradePercentage < 45 ? classes.failAvatar :
                                                                                assignee.gradePercentage < 70 ? classes.lowAvatar : classes.passAvatar}
                                                                        > {assignee.gradePercentage}</Avatar>
                                                                    </IconButton>
                                                                </ListItemSecondaryAction>
                                                                }

                                                            </ListItem>

                                                        ))}
                                                    </List> :
                                                    <div>{t('no users assigned')}</div>
                                                }
                                            </CardContent> :
                                                <div align="center" className={classes.progressRoot}> <CircularProgress className={classes.progress} size={50} /></div>
                                        }
                                    </Card>
                                </Grid>
                                <Grid item xs={12} md={4}>
                                    <div id="assignUser">
                                        <Card className={classes.card}>
                                            <CardHeader
                                                title={ <Typography className={classes.title} color="textSecondary" gutterBottom>
                                                    {t('application.assign users')}
                                                </Typography>
                                                }
                                            />
                                            <CardContent>
                                                {/*<Select value={newUserAssignees} options={availableUserAssignees} onChange={(val, args)=>updateState(ASSIGNEE_TYPE.USER, val, args)} onInputChange={onUserInputChange} isMulti/>*/}
                                                <CustomReactSelect ref="userSelection" data={lesson} isMulti={true} onChange={this.setSelectedUsers} existingSelectionKeys={assignedUserKeys} type={USER_SEARCH_TYPE.LESSON_ASSIGNEE} dataPath={dataPath} selectionType={ASSIGNEE_TYPE.USER}/>
                                            </CardContent>
                                            <CardActions  className={classes.actions}>
                                                <Button size="small" color="primary" type="button" disabled={this.state.newUserAssignees.length < 1} onClick={this.saveNewUserAssignees}> {t('buttons.add')}</Button>
                                            </CardActions>
                                        </Card>
                                    </div>
                                    <div id="assignGroup">

                                    </div>
                                </Grid>
                            </Grid></TabContainer>}
                            {questions && <GradeQuestions onGradeComplete={this.reloadAssignees} lessonId={lessonId}  organizationId={organizationId} dataPath={dataPath} assignee={assigneeToGrade} questions={questions} currentUsername= {user.username} currentUserId={authenticated.uid} open={this.state.openQuestionGradeDialog} close={this.closeQuestionsGradeDialog} /> }
                        {this.state.openQuestionModifyDialog && <CreateQuestion lessonId={lessonId} dataPath={dataPath} currentUserId={authenticated.uid} open={this.state.openQuestionModifyDialog} questionObject={this.state.questionToModify} close={this.closeQuestionModifyDialog}/>}
                        </div> :
                        <div align="center" className={classes.progressRoot}> <CircularProgress className={classes.progress} size={30} thickness={3} /></div>}
                </div>
        )
    }
}

const mapStateToProps = ({user, lesson, assignment, lessonAssignees, questions}) => {
    return {user:user, lesson:lesson, myAssignment:assignment, questions:questions, lessonAssignees:lessonAssignees};
};

export default compose (
    withStyles(styles),
    withI18n(),
    connect(mapStateToProps, {assignUsersToLesson, fetchLesson, fetchLessonAssignment, fetchLessonAssignees, fetchLessonQuestions, openSnack, updateDocumentAttribute, removeLessonListen, reassignUser})
)(Lesson)