import { withI18n } from "react-i18next";
import { withStyles } from '@material-ui/core/styles';
import { withRouter} from 'react-router-dom';
import {compose} from "recompose";
import InfiniteScroll from 'react-infinite-scroller';
import {AddBox, } from '@material-ui/icons';
import {Card, CardContent, CircularProgress, Divider,  Grid, List, ListItem, ListItemText, ListItemAvatar, ListItemSecondaryAction} from '@material-ui/core';
import {connect} from "react-redux";
import {fetchMyChats, removeMyChatsListener, openSnack} from "../../actions";
import {LOADING_MASK, PRIMARY, RADIO_BUTTON_SELECT} from "../../constants/colors";
import Chat from "../Chat";
import ChatCreate from "../ChatCreate";
import UserAvatar from "../UserAvatar";
import * as COLORS from "../../constants/colors";
import {CHAT_LIMIT, POST_LIMIT, POST_TYPE} from "../../constants/common";

const React = require('react');

const styles = theme => ({
    root: {
        width: '100%',
        //flexGrow: 1,
    },
    card: {
        //width: 300,
        margin: theme.spacing(.2),
    },
    cardContent: {
        //width: 300,
        paddingTop: 0,
    },
    inline: {
        display: 'inline',
    },
    formControl: {
        minWidth: 300,
    },
    formControlRadio: {
        paddingTop: theme.spacing.unit * 2,
        //margin: theme.spacing.unit * 1,
    },
    radioButton : {
        color: RADIO_BUTTON_SELECT,
        '&$checked': {
            color: RADIO_BUTTON_SELECT,
        },
    },
    progressRoot: {
        flexGrow: 1,
        spacing: '40'
    },
    progress: {
        margin: theme.spacing(2),
        color: LOADING_MASK
    },
    pageActions : {
        paddingTop : 0,
        color: PRIMARY
    },
    actions: {
        display: 'inherit',
    },
    title: {
        textTransform: 'uppercase'
    },
    pos: {
        marginBottom: 12,
    },
    expand: {
        transform: 'rotate(0deg)',
        marginLeft: 'auto',
        transition: theme.transitions.create('transform', {
            duration: theme.transitions.duration.shortest,
        }),
    },
    group: {
        margin: theme.spacing.unit *  1,
        display: 'inline'
    },
    description : {
        whiteSpace: 'pre-wrap'
    },
    gray : {
        color: 'gray'
    },
    icon: {
        margin: theme.spacing(),
        //backgroundColor: red[500],
        color: COLORS.BLUE,
    },
});

class Messages extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            messagesLoading: false,
            myChatsLoading: false,
            hasMoreChats:false,
            saving: false
        };
        this.onChange = this.onChange.bind(this);
        this.loadChat = this.loadChat.bind(this);
        this.onNewChatCreated = this.onNewChatCreated.bind(this);
        this.loadMoreChats = this.loadMoreChats.bind(this);
        this.fetchMyChatsCallback = this.fetchMyChatsCallback.bind(this);
    }

    componentDidMount() {
        const currentUserId = this.props.authenticated.uid;
        this.props.fetchMyChats({currentUserId:currentUserId, limit:CHAT_LIMIT, callback:this.fetchMyChatsCallback});
    }

    componentWillUnmount() {
        if(this.state.userId !== this.props.user.id) {
            this.props.removeMyChatsListener(this.props.authenticated.uid);
        }
    }

    fetchMyChatsCallback = (count) => {
        var hasMore = count === CHAT_LIMIT;
        this.setState({hasMoreChats:hasMore});
    };
    loadMoreChats = () => {
        const currentUserId = this.props.authenticated.uid;
        this.setState({hasMoreChats: false});
        const {myChats} =  this.props;
        var last = myChats ? myChats[myChats.length - 1] : null;
        if(last) {
            this.props.fetchMyChats({currentUserId:currentUserId, last: last.dateUpdated, limit:CHAT_LIMIT, callback:this.fetchMyChatsCallback});
        }
    };
    onChange = prop => event => {
        this.setState({ [prop]: event.target.value });
    };

    loadChat = (event, chatId, index) => {
        let chat = this.props.myChats[index];
        this.setState({chat:chat});
    };
    onNewChatCreated = (chat) => {
        this.setState({chat:chat});
    };

    render() {
        const {t, classes, myChats, user} = this.props;
        const {hasMoreChats} = this.state;
        return (
                <div class="momentumScroll">
                    <Grid  item container className={classes.root} spacing={2}>
                        <Grid item xs={12} md={8}>
                            <Grid>
                                <Chat chat={this.state.chat} currentUser={user}/>
                            </Grid>
                        </Grid>
                        <Grid item xs={12} md={4}>
                            <Grid id="leftPanel">
                                <div id="messages">
                                    <Card className={classes.card}>
                                        <CardContent className={classes.cardContent}>
                                            <List className={classes.root}>
                                                    <span><ListItem alignItems="flex-start">
                                                        <ListItemAvatar>
                                                                <UserAvatar username={user.username} userId={this.props.authenticated.uid}
                                                                            imageUrl={user.profileImageUrl}/>
                                                        </ListItemAvatar>
                                                        <ListItemText
                                                            primary={t('application.messages')}
                                                        />
                                                        <ListItemSecondaryAction>
                                                            <ChatCreate icon={<AddBox className={classes.icon}/>} currentUser={user} onComplete={this.onNewChatCreated}/>
                                                        </ListItemSecondaryAction>
                                                    </ListItem>
                                                    </span>
                                            </List>
                                            <Divider variant="inset" />
                                            {myChats && myChats.length > 0 ?
                                                <div className={classes.scrollContainer}>
                                                    <InfiniteScroll
                                                        pageStart={0}
                                                        loadMore={this.loadMoreChats}
                                                        hasMore={hasMoreChats}
                                                        useWindow={false}
                                                        loader={<div align="center" className={classes.progressRoot}> <CircularProgress className={classes.progress} size={20} thickness={3} /></div>}
                                                    >
                                                        {myChats.map((chat, index) => (
                                                            <span key={`messages-${index}`}>
                                                                <ListItem alignItems="flex-start" key={chat.id}  button onClick={(e)=>this.loadChat(e, chat.id, index)}>
                                                                    <ListItemAvatar>
                                                                            <UserAvatar  size='small' username={chat.participantUsername} userId={chat.participantUserId}
                                                                                         imageUrl={chat.participantImageUrl}/>
                                                                    </ListItemAvatar>
                                                                    <ListItemText
                                                                        primary={chat.participantUsername}
                                                                        secondary={chat.lastMessage}
                                                                    />
                                                                </ListItem>
                                                                {
                                                                    index !== (myChats.length - 1) && <Divider variant="inset" component="li" />
                                                                }
                                                    </span>
                                                        ))}
                                                    </InfiniteScroll>
                                                </div>
                                                :
                                                <div>{t('no results')}</div>
                                            }
                                        </CardContent>
                                    </Card>
                                </div>
                            </Grid>
                        </Grid>
                    </Grid>

                </div>
        )
    }

}
const mapStateToProps = ({myChats, user}) => {
        return {myChats:myChats, user:user};
};

export default compose (
    withRouter,
    withStyles(styles),
    withI18n(),
    connect(mapStateToProps, {fetchMyChats, removeMyChatsListener, openSnack})
)(Messages)
