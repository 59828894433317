import { withI18n } from "react-i18next";
import { withStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
import { withRouter } from 'react-router-dom';
import {compose} from "recompose";

import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import { connect } from "react-redux";
import {doPasswordUpdate} from "../../actions";

const React = require('react');

const styles = theme => ({
    card: {
        minWidth: 200,
        maxWidth: 300,
        margin: theme.spacing(1)
    },
    bullet: {
        display: 'inline-block',
        margin: '0 2px',
        transform: 'scale(0.8)',
    },
    actions: {
        display: 'inherit',
    },
    title: {
        textTransform: 'uppercase'
    },
    pos: {
        marginBottom: 12,
    },
    success : {
        color: 'green'
    }
});

const INITIAL_STATE = {
    message:null,
    passwordOne: '',
    passwordTwo: '',
    showPasswordOne: false,
    showPasswordTwo: false,
    error: null,
};
class PasswordChange extends React.Component {

    constructor(props) {
        super(props);

        this.state = { ...INITIAL_STATE};
        this.onSubmit = this.onSubmit.bind(this);
        this.onChange = this.onChange.bind(this);

    }


    onSubmit = event => {
        event.preventDefault();

        const { passwordOne } = this.state;
        const { t } = this.props;

        this.props
            .doPasswordUpdate(passwordOne)
            .then(() => {
                this.setState({ ...INITIAL_STATE });
                this.setState({'message':t('password successfully changed')});
            })
            .catch(error => {
                this.setState({ error });
            });

    };
    onChange = prop => event => {
        this.setState({ [prop]: event.target.value });
    };

    render() {
        const {t, classes} = this.props;
        const {
            passwordOne,
            passwordTwo,
            message,
            error,
        } = this.state;

        const isInvalid = passwordOne !== passwordTwo || passwordOne === '';
        return (
            <div align="center">
                <Card className={classes.card}>
                    <form onSubmit={this.onSubmit}>
                        <CardHeader
                            title={ <Typography  className={classes.title}>
                                {t('application.change password')}
                            </Typography>
                            }
                        />
                        <CardContent>
                            <div>
                                <Grid>
                                    <Grid item>
                                        <TextField
                                            id="input-password-one"
                                            className={classNames(classes.margin, classes.textField)}
                                            variant="filled"
                                            type={this.state.showPasswordOne ? 'text' : 'password'}
                                            label={t('fields.password')}
                                            value={this.state.passwordOne}
                                            onChange={this.onChange('passwordOne')}
                                        />
                                    </Grid>
                                    <Grid item>
                                        <TextField
                                            id="input-password-two"
                                            className={classNames(classes.margin, classes.textField)}
                                            variant="filled"
                                            type={this.state.showPasswordOne ? 'text' : 'password'}
                                            label={t('fields.password confirm')}
                                            value={this.state.passwordTwo}
                                            onChange={this.onChange('passwordTwo')}
                                        />
                                    </Grid>
                                </Grid>
                            </div>

                        </CardContent>
                        <CardActions  className={classes.actions}>
                            <Button size="small" color="primary" type="submit" disabled={isInvalid}>{t('reset my password')}</Button>
                        </CardActions>
                        <Typography  className={classes.success}>
                            {message}
                        </Typography>
                        <Typography color="error">
                            {error && <p>{error.message}</p>}
                        </Typography>
                    </form>
                </Card>
            </div>


        )
    }

}
function mapStateToProps({ auth }) {
    return { auth };
}

export default compose (
    withRouter,
    withStyles(styles),
    withI18n(),
    connect(mapStateToProps, { doPasswordUpdate })
)(PasswordChange)
