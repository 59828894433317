import { withI18n } from "react-i18next";
import { withStyles } from '@material-ui/core/styles';
import {compose} from "recompose";
import PropTypes from 'prop-types';
import {LINARY_LOADING_MASK, LINARY_LOADING_MASK_BAR, GREEN, RADIO_BUTTON_SELECT} from "../../constants/colors";
import {Button, Checkbox, FormControl, Input, FormHelperText, FormLabel, Grid, FormControlLabel, InputLabel, InputAdornment, LinearProgress, RadioGroup, Radio, Select, TextField, Typography} from '@material-ui/core';
import {createLesson, openSnack} from "../../actions";
import {connect} from "react-redux";
import {PATHS, SUBJECT, DIFFICULTY_LEVEL, GRADE_LEVEL, PUBLIC, SKILLS} from "../../constants/common";

const React = require('react');

const INITIAL_STATE = {
    title: '',
    description:'',
    subject : '',
    topic: '',
    image: null,
    imageUrl: '',
    isPrivate: false,
    organizationId: '',
    difficultyLevel: 'easy',
    gradeLevel: 'p',
    skill:'addition',
    subSkill:'',
    readingLevel:'',
    readingLexileLevel:null,
    saving: false,
    editor: ''
};

const styles = theme => ({
    container: {
        // display: 'flex',
        flexWrap: 'wrap',
    },
    title: {
        textTransform: 'uppercase'
    },
    uploadProgress : {
        flexGrow: 1,
    },
    linearColorPrimary : {
        backgroundColor: LINARY_LOADING_MASK,
    },
    linearBarColorPrimary : {
        backgroundColor: LINARY_LOADING_MASK_BAR,
    },
    formControl: {
        minWidth: 400,
        margin: theme.spacing(1),
    },
    selectControl: {
        minWidth: 150,
        margin: theme.spacing(3),
    },
    editorControl: {
        minWidth: 400,
        minHeight: 200,
        margin: theme.spacing.unit * 3
    },
    group: {
        margin: theme.spacing(1),
        display: 'inline'
    },
    textInput : {
        minWidth: 400,
    },
    pos: {
        marginBottom: 12,
    },
    success : {
        color: GREEN
    },
    imageUpload : {
        marginTop: theme.spacing.unit * 2
    }
});

class LessonCreate extends React.Component {

    constructor(props) {
        super(props);

        this.state = { ...INITIAL_STATE};
        this.createLessonCallback = this.createLessonCallback.bind(this);
        this.getSkill = this.getSkill.bind(this);
        this.isLexileValid = this.isLexileValid.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
        this.onChange = this.onChange.bind(this);
        this.onCheckChange = this.onCheckChange.bind(this);
        this.reset = this.reset.bind(this);
    }

    static contextTypes = {
        router: PropTypes.object
    };

    componentWillUpdate(nextProps) {
        if (nextProps.lesson) {
            if(nextProps.lesson.createSuccess) {
                this.props.history.push(`/lessons/${nextProps.lesson.id}`);
            } else if(nextProps.lesson.createFailed) {

                this.props.openSnack({open:true, messageText:nextProps.lesson.error.message, variant:'error'});
                this.setState({saving:false});
            }
        }
    }
    onCheckChange = prop => event => {
        this.setState({ [prop]: event.target.checked});
    };

    onSubmit = event => {
        event.preventDefault();
        this.setState({saving:true});
        const { title, description, subject, topic, organizationId, isPrivate, gradeLevel, difficultyLevel, skill, subSkill, readingLexileLevel} = this.state;
        const {user}  = this.props;
        const lesson = {title:title, description:description,
            subject:subject, published:false, createdById: user.id, createdByUsername: user.username,
            isPrivate:isPrivate, gradeLevel:gradeLevel, difficultyLevel:difficultyLevel, topic:topic, skill:skill, subSkill:subSkill, readingLexileLevel:readingLexileLevel};
        var path = PATHS.NON_ORG_LESSONS;
        debugger;
        if(organizationId !== '') {
            path = PATHS.ORGANIZATION_BASE+'/'+organizationId +'/lessons';
            lesson.organizationId = organizationId;
            lesson.organizationName = user.organizations[organizationId].name;
        } else{
            lesson.organizationId = PUBLIC;
        }
        this.props.createLesson({path:path, lesson:lesson, callback:this.createLessonCallback});
    };
    createLessonCallback(success, lessonId, organizationId) {
        this.setState({ 'saving': false });
        if(success) {
            if(organizationId) {
                this.props.history.push(`/lessons/${organizationId}/${lessonId}`);
            } else {
                this.props.history.push(`/lessons/public/${lessonId}`);
            }
        }
    }
    onChange = prop => event => {
        debugger;
        if(prop == 'gradeLevel') {
            this.setState({ [prop]: event.target.value, skill:this.getSkill(event.target.value)});
        } else {
            this.setState({ [prop]: event.target.value });
        }
    };

    getSkill(gradeLevel) {
        debugger;
        let gradeSkills = SKILLS[gradeLevel];
        if(!gradeSkills.includes(this.state.skill)){
            return gradeSkills[0];
        } else {
            return this.state.skill;
        }
    }
    isLexileValid(){
        const {readingLexileLevel} = this.state;
        if(readingLexileLevel == 0 || readingLexileLevel == null) {
            return true;
        }
        if(readingLexileLevel >= 5 && readingLexileLevel <= 2000 && readingLexileLevel % 5 === 0 ) {
            return true;
        }
        return false;
    }

    reset = () => {
        this.setState(INITIAL_STATE)
    };
    render() {
        const {t, classes, user} = this.props;
        const {title, description,organizationId, isPrivate, subject, gradeLevel, topic, difficultyLevel, skill, subSkill, readingLexileLevel, saving } = this.state;
        const isInvalid = title === '' || description === '' || subject === '' || topic ==='' || !this.isLexileValid() || saving;
        return (
            <div align="center">
                <Grid container className={classes.root} justify="center" spacing={24}>
                    <Grid item key="title" xs={11} md={8}>
                        <Grid>
                            <Typography  variant={"title"} className={classes.title}>
                                {t('application.create lesson')}
                            </Typography>
                        </Grid>
                    </Grid>
                </Grid>
                <div className={classes.container}>
                    <form onSubmit={this.onSubmit}>
                        <Grid>
                            <Grid item>
                                <TextField value={title}  InputProps={{maxLength: 80}} className={classes.formControl} required type="text" onChange={this.onChange('title')} id="input-title" label={t('fields.title')} />
                            </Grid>
                            <Grid item>
                                <TextField InputProps={{maxLength: 200}} value={description} className={classes.formControl} required type="text"
                                           onChange={this.onChange('description')} id="input-description" label={t('fields.description')}
                                           multiline rows="4" />
                            </Grid>
                            <br />
                            <Grid item>
                                <FormControl component="fieldset" required className={classes.formControl}>
                                    <FormLabel component="legend">{t('fields.subject')}</FormLabel>
                                    <RadioGroup
                                        aria-label={t('fields.subject')}
                                        name={t('fields.subject')}
                                        className={classes.group}
                                        value={subject}
                                        onChange={this.onChange('subject')}
                                    >
                                        {SUBJECT.map(subject =>
                                            <FormControlLabel value={subject} control={<Radio />} label={t("subject."+subject)} />
                                        )}
                                        {/*<FormControlLabel value="math" control={<Radio />} label={t("subject.math")} />*/}
                                        {/*<FormControlLabel value="english" control={<Radio />} label={t("subject.english")}/>*/}
                                        {/*<FormControlLabel value="science" control={<Radio />} label={t("subject.science")}/>*/}
                                    </RadioGroup>
                                </FormControl>
                            </Grid>
                            <Grid item>
                                <TextField value={topic}  InputProps={{maxLength: 40}} className={classes.formControl} required type="text" onChange={this.onChange('topic')} id="input-subTopic" label={t('fields.topic')} />
                            </Grid>
                             <Grid item>
                                <FormControl className={classes.selectControl}>
                                    <InputLabel htmlFor="grade-level-select" required shrink={true}><Typography > {t('fields.grade level')}</Typography></InputLabel>
                                    <Select
                                        value={gradeLevel}
                                        onChange={this.onChange('gradeLevel')}
                                        inputProps={{
                                            name: 'gradeLevel',
                                            id: 'grade-level-select',
                                        }}
                                        native
                                    >

                                        {GRADE_LEVEL.map(level =>
                                            <option value={level}>{t('grade.'+level)}</option>
                                        )}
                                    </Select>
                                </FormControl>
                                <FormControl className={classes.selectControl}>
                                     <InputLabel htmlFor="skill-select" required shrink={true}><Typography > {t('fields.skill')}</Typography></InputLabel>
                                     <Select
                                         value={skill}
                                         onChange={this.onChange('skill')}
                                         inputProps={{
                                             name: 'skill',
                                             id: 'skill-select',
                                         }}
                                         native
                                     >

                                         {SKILLS[gradeLevel].map(skillItem =>
                                             <option value={skillItem}>{t(skillItem)}</option>
                                         )}
                                     </Select>
                                </FormControl>
                                 <FormControl className={classes.selectControl}>
                                 <TextField value={subSkill} InputProps={{maxLength: 40}} type="text" onChange={this.onChange('subSkill')} id="input-sub-skill" label={t('fields.sub skill')} />
                                 </FormControl>
                            </Grid>
                            <Grid item>
                                <FormControl className={classes.selectControl}>
                                    <InputLabel htmlFor="difficulty-level-select" required shrink={true}><Typography > {t('fields.difficulty')}</Typography></InputLabel>
                                    <Select
                                        value={difficultyLevel}
                                        onChange={this.onChange('difficultyLevel')}
                                        inputProps={{
                                            name: 'difficultyLevel',
                                            id: 'difficulty-level-select',
                                        }}
                                        native
                                    >

                                        {DIFFICULTY_LEVEL.map(level =>
                                            <option value={level}>{t(level)}</option>
                                        )}
                                    </Select>
                                </FormControl>
                                <FormControl className={classes.selectControl}>
                                <TextField value={readingLexileLevel}
                                           InputProps={{endAdornment: <InputAdornment position="end">L</InputAdornment>}}
                                    // className={classes.formControl}
                                           type="number"
                                           onChange={this.onChange('readingLexileLevel')}
                                           id="adornment-input-reading-lexile-level"
                                           label={t('fields.reading lexile level')}
                                           error={!this.isLexileValid()}
                                />
                                </FormControl>
                            </Grid>
                            {user && user.verifiedOrganizations &&
                                <Grid item>
                                    <FormControl className={classes.selectControl}>
                                        <InputLabel htmlFor="organization-select" required shrink={true}><Typography > {t('fields.organization')}</Typography></InputLabel>
                                        <Select
                                            value={organizationId}
                                            onChange={this.onChange('organizationId')}
                                            inputProps={{
                                                name: 'organizationId',
                                                id: 'organizationId-select',
                                            }}
                                            native
                                        >

                                            <option value="">{t('none')}</option>
                                            {user.verifiedOrganizations.map(organization =>
                                                <option value={organization.id}>{organization.name}</option>
                                            )}
                                        </Select>
                                    </FormControl>
                                </Grid>
                            }
                            <Grid item>
                                <FormControl component="fieldset" required>
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                checked={isPrivate}
                                                onChange={this.onCheckChange('isPrivate')}
                                                value={isPrivate}
                                                style={{color:RADIO_BUTTON_SELECT}}
                                            />
                                        }
                                        label={t('fields.private')}
                                    />
                                </FormControl>
                            </Grid>
                        </Grid>
                        {saving &&
                        <div className={classes.uploadProgress}>
                            <LinearProgress classes={{
                                colorPrimary: classes.linearColorPrimary,
                                barColorPrimary: classes.linearBarColorPrimary,
                            }}/>
                        </div>
                        }
                        <br />
                        <Button size="small" color="primary" type="submit" disabled={isInvalid}>{t('buttons.create')}</Button>
                        <Button size="small" color="primary" type="button" onClick={this.reset}>{t('buttons.clear')}</Button>
                    </form>
                    <br />
                    <br />
                </div>
            </div>
        )

    }
}

const mapStateToProps = ({user, lesson}) => {
    return {user:user, lesson:lesson};
};

export default compose (
    withStyles(styles),
    withI18n(),
    connect(mapStateToProps, {createLesson, openSnack})
)(LessonCreate)