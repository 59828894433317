import { withI18n } from "react-i18next";
import { withStyles } from '@material-ui/core/styles';
import { Divider, Grid } from '@material-ui/core';
import { withRouter } from 'react-router-dom';
import {compose} from "recompose";
import SignIn from "../SignIn"

import Typography from '@material-ui/core/Typography';

const React = require('react');

const styles = theme => ({
    root: {
        flexGrow: 1,
    },
    title: {
        textTransform: 'uppercase'
    },
    pos: {
        marginBottom: 12,
    },
    divider : {
        margin: theme.spacing(2)
    },
    iframe : {
        minHeight: 400
    }
});


class Landing extends React.Component {

    render() {
        const {t, classes} = this.props;

        return (
            <div align="center">
                    <Typography  variant="title" className={classes.title} >
                        {t('application.welcome')}
                </Typography>

                <Divider className={classes.divider} />
                <Grid  item container className={classes.root} spacing={2}>
                    <Grid item xs={12} md={9} spacing={6}>
                        <iframe title="socipod-vid" width="100%" className={classes.iframe} src="https://www.youtube.com/embed/m9YwtzG1eXk" frameBorder="0"
                                allow="accelerometer; encrypted-media; gyroscope; picture-in-picture"
                                allowFullScreen></iframe>
                    </Grid>
                    <Grid item xs={12} md={3}>
                        <SignIn />
                    </Grid>
                </Grid>
            </div>
        )
    }

}

export default compose (
    withRouter,
    withStyles(styles),
    withI18n()
)(Landing)
