import React, {Component} from 'react'
import {BrowserRouter, Route, Switch} from 'react-router-dom';
// import AdminPage from "./components/Admin";
import AccountPage from "./components/Account";
import AboutUs from "./components/About";
import ContactUs from "./components/Contact";
import DrawerLayout from "./components/layout/DrawerLayout";
import Groups from "./components/Groups";
import Group from "./components/Group";
import Friends from "./components/Friends";
import Home from "./components/Home";
import LandingPage from "./components/Landing";
import Lesson from "./components/Lesson";
import LessonCreate from "./components/LessonCreate";
import Library from "./components/Library";
import Messages from "./components/Messages";
import Organization from "./components/Organization";
import PasswordForget from "./components/PasswordForget";
import SignIn from "./components/SignIn";
import SignUp from "./components/SignUp";
import User from "./components/User";
import requireAuth from "./components/Session/requireAuth";
import {NotFound} from "./components/errors";

import * as ROUTES from './constants/routes';
import { connect } from "react-redux";
import { fetchUser } from "./actions";

class App extends Component {

    componentWillMount() {
        this.props.fetchUser();
    }
    render() {
        return (
            <BrowserRouter>
                <DrawerLayout>
                    <Switch>
                        <Route exact path={ROUTES.ABOUT_US} component = {AboutUs} />
                        <Route exact path={ROUTES.CONTACT_US} component = {ContactUs} />
                        <Route exact path={ROUTES.LANDING} component = {LandingPage} />
                        <Route exact path={ROUTES.HOME} component={requireAuth(Home)}/>
                        {/*<Route exact path='/admin' component={requireAuth(AdminPage)}/>*/}
                        <Route path={ROUTES.ACCOUNT} component={requireAuth(AccountPage)}/>
                        <Route path={ROUTES.CREATE_LESSON} component={requireAuth(LessonCreate)}/>
                        <Route path={ROUTES.FRIENDS} component={requireAuth(Friends)}/>
                        <Route path={ROUTES.GROUP} component={requireAuth(Group)} />
                        <Route path={ROUTES.GROUPS} component={requireAuth(Groups)} />
                        <Route path={ROUTES.LIBRARY} component={requireAuth(Library)}/>
                        <Route path={ROUTES.LESSON} component={requireAuth(Lesson)}/>
                        <Route path={ROUTES.MESSAGES} component={requireAuth(Messages)}/>
                        <Route path={ROUTES.ORGANIZATION} component={requireAuth(Organization)}/>
                        <Route path={ROUTES.PASSWORD_FORGET} component={PasswordForget}/>
                        <Route path={ROUTES.SIGN_IN} component={SignIn}/>
                        <Route path={ROUTES.SIGN_UP} component={SignUp}/>
                        <Route path={ROUTES.USER} component={requireAuth(User)}/>
                        {/*/!*<Route path={ROUTES.MESSAGES} component={Messages}/>*!/*/}
                        {/*<Route path={ROUTES.GROUP}  render={*/}
                        {/*props => <Group {...props} authUser={authUser} />*/}
                        {/*} />*/}
                        <Route component={NotFound} />
                    </Switch>
                </DrawerLayout>
            </BrowserRouter>
        )
    }

}

export default connect(null, {fetchUser})(App)


