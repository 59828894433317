import { withI18n } from "react-i18next";
import { withStyles } from '@material-ui/core/styles';
import { withRouter } from 'react-router-dom';
import {compose} from "recompose";
import InfiniteScroll from 'react-infinite-scroller';
import {CheckCircle} from '@material-ui/icons';
import {Card, CardContent, CardHeader, CircularProgress, Divider, Grid, IconButton, List, ListItemSecondaryAction, ListItem, ListItemText, ListItemAvatar, Typography} from '@material-ui/core';
import * as COLORS from "../../constants/colors";
import {connect} from "react-redux";
import {acceptOrgMemberRequest, fetchOrganizationMemberRequests, fetchOrganizationMembers, removeOrganizationMembersListeners} from "../../actions";
import UserAvatar from "../UserAvatar";
import {BADGE_TYPE, USER_SEARCH_LIMIT} from "../../constants/common";
import {filterObjectInArray} from "../../util/functions";

const React = require('react');

const styles = theme => ({
    mainView: {
        width: '100%',
        display: 'contents'
    },
    progressRoot: {
        flexGrow: 1,
        spacing: '40'
    },
    progress: {
        margin: theme.spacing(2),
        color: COLORS.LOADING_MASK
    },
    pageActions : {
        paddingTop : 0,
        color: COLORS.PRIMARY
    },
    card: {
        width: '100%',
    },
    bullet: {
        display: 'inline-block',
        margin: '0 2px',
        transform: 'scale(0.8)',
    },
    actions: {
        display: 'inherit',
    },
    media: {
        paddingTop: '75.25%', // 16:9
    },
    title: {
        textTransform: 'uppercase'
    },
    pos: {
        marginBottom: 12,
    },
    expand: {
        transform: 'rotate(0deg)',
        marginLeft: 'auto',
        transition: theme.transitions.create('transform', {
            duration: theme.transitions.duration.shortest,
        }),
    },
    expandOpen: {
        transform: 'rotate(180deg)',
    },
    avatar: {
        backgroundColor: COLORS.SECONDARY_ICON,
    },
    accept: {
        color: COLORS.ACCEPT,
    },
    decline: {
        color: COLORS.DECLINE,
    },
    buttonAccept: {
        backgroundColor: COLORS.ACCEPT,
        color: 'white'
    },
    buttonDecline: {
        backgroundColor: COLORS.DECLINE,
        color: 'white'
    },
    description : {
        whiteSpace: 'pre-wrap'
    },
    gray : {
        color: 'gray'
    },
});

class OrganizationMembersView extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            loading: true,
            hasMore: false,
            loadingRequest:false
        };

        this.displaySnack = this.displaySnack.bind(this);
        this.filterRequestType = this.filterRequestType.bind(this);
        this.loadRequestType = this.loadRequestType.bind(this);
        this.requestCallback = this.requestCallback.bind(this);
        this.loadMore = this.loadMore.bind(this);


    }

    componentDidMount() {
        this.loadRequestType();
    }

    loadRequestType() {
        this.setState({loading:false, currentRequestType: this.props.requestType});
        if ("members" === this.props.requestType && !this.props.members[this.props.organizationInfo.id]) {
            this.props.fetchOrganizationMembers({organizationId: this.props.organizationInfo.id, callback:this.fetchOrgMemberCallback});
        } else if ("memberRequests" === this.props.requestType && !this.props.memberRequests[this.props.organizationInfo.id]) {
            this.props.fetchOrganizationMemberRequests({organizationId: this.props.organizationInfo.id, limit:USER_SEARCH_LIMIT, callback:this.fetchOrgMemberCallback});
        }
    }
    loadMore = () => {
        debugger;
        const {members, memberRequests, organizationInfo, requestType} =  this.props;
        var membersCollection = "members" === requestType ? members[organizationInfo.id] : memberRequests[organizationInfo.id];
        var last = membersCollection ? membersCollection[membersCollection.length - 1] : null;
        if(last) {
            if ("members" === this.props.requestType) {
                this.props.fetchOrganizationMembers({organizationId: this.props.organizationInfo.id, limit:USER_SEARCH_LIMIT, last: last.dateUpdated, callback:this.fetchOrgMemberCallback});
            } else if ("memberRequests" === this.props.requestType) {
                this.props.fetchOrganizationMemberRequests({organizationId: this.props.organizationInfo.id, limit:USER_SEARCH_LIMIT, last: last.dateUpdated, callback:this.fetchOrgMemberCallback});
            }
        } else {
            this.setState({hasMore:false});
        }

    };

    componentWillUpdate(nextProps, nextState, nextContext) {
        if(nextProps.requestType !== this.state.currentRequestType) {
            this.loadRequestType();
        }
    }
    componentWillUnmount() {
        //this.props.removeOrganizationMembersListeners(this.props.organizationInfo.id);
    }

    fetchOrgMemberCallback(count) {
        var hasMore = count === USER_SEARCH_LIMIT;
        this.setState({hasMore:hasMore});

    }
    handleAcceptRequestClick (memberRequest) {
        debugger;
        this.setState({loadingRequest:true});
        const currentUserId = this.props.currentUser.id;
        const currentUsername = this.props.currentUser.username;
        const profileImageUrl = this.props.currentUser.profileImageUrl;
        this.props.acceptOrgMemberRequest({
            currentUserId: currentUserId,
            currentUsername: currentUsername,
            profileImageUrl:profileImageUrl,
            memberRequest: memberRequest,
            organizationId: this.props.organizationInfo.id,
            callback:this.requestCallback
        })
    };

    requestCallback() {
        this.setState({loadingRequest:false});
    }

    displaySnack(snackInfo) {
        this.setState(snackInfo);
    }
    onSearchValueChange=(event) =>{
        this.filterRequestType(event.target.value.trim());
        this.setState({searchValue:event.target.value.trim()});
    }
    filterRequestType(searchValue) {
        if ("members" === this.props.requestType && !this.props.members[this.props.organizationInfo.id]) {
            this.props.fetchOrganizationMembers({organizationId: this.props.organizationInfo.id, limit:USER_SEARCH_LIMIT, username: searchValue, callback:this.fetchOrgMemberCallback});
        } else if ("membersRequest" === this.props.requestType && !this.props.memberRequests[this.props.organizationInfo.id]) {
            this.props.fetchOrganizationMemberRequests({organizationId: this.props.organizationInfo.id, limit:USER_SEARCH_LIMIT, username: searchValue, callback:this.fetchOrgMemberCallback});
        }
    }
    render() {

        const {t, classes, memberRequests, members, organizationInfo} = this.props;
        const {searchValue, hasMore, loadingRequest, loading} =  this.state;
        const orgMembers = organizationInfo && members[organizationInfo.id] ? members[organizationInfo.id] : [];
        const orgMemberRequests = organizationInfo && memberRequests[organizationInfo.id] ? memberRequests[organizationInfo.id] : [];
        const requestList = this.props.requestType === "memberRequests" ? filterObjectInArray(orgMemberRequests, 'username', searchValue) :
            this.props.requestType === "members" ? filterObjectInArray(orgMembers, 'username', searchValue) : [];
        debugger;
        return (
                <div align="center">
                        <Grid container className={classes.mainView} justify="center">
                            <Grid item>
                                <Card className={classes.card}>
                                    <CardHeader
                                        title={<input type="text" placeholder={t('filter list')} value={searchValue} onChange={this.onSearchValueChange}/>}
                                    />
                                    <CardContent>
                                        {loading ? <div align="center" className={classes.progressRoot}><CircularProgress
                                                className={classes.progress} size={30} thickness={3}/></div> :
                                            requestList && requestList.length > 0 ?
                                                <div className={classes.scrollContainer}>
                                                    <List dense className={classes.root}>
                                                    <InfiniteScroll
                                                        pageStart={0}
                                                        loadMore={this.loadMore}
                                                        hasMore={hasMore}
                                                        useWindow={false}
                                                        loader={<div align="center" className={classes.progressRoot}> <CircularProgress className={classes.progress} size={20} thickness={3} /></div>}
                                                    >
                                                        {requestList.map(element =>
                                                            <span>
                                                            <Divider variant="inset" component="li" />
                                                            <ListItem key={element.uid} button onClick={() => { this.props.history.push(`/user/${element.uid}`);}}>
                                                                <ListItemAvatar>
                                                                    <UserAvatar username={element.username}
                                                                                userId={element.uid}
                                                                                imageUrl={element.profileImageUrl}
                                                                                badge={element.isAdministrator ? BADGE_TYPE.ADMIN
                                                                                    : element.isProfessor ? BADGE_TYPE.PROFESSOR
                                                                                        : element.isParent ? BADGE_TYPE.PARENT
                                                                                            : element.isStudent ? BADGE_TYPE.STUDENT : false }
                                                                    />
                                                                </ListItemAvatar>
                                                                <ListItemText id={element.uid}
                                                                              primary={element.username}/>
                                                                {this.props.requestType === "memberRequests" &&
                                                                <ListItemSecondaryAction>
                                                                    <IconButton aria-label={t('buttons.accept')}
                                                                                onClick={() => this.handleAcceptRequestClick(element)}
                                                                                disabled={loadingRequest}
                                                                                className={classes.accept}>
                                                                        <CheckCircle/>
                                                                    </IconButton>
                                                                </ListItemSecondaryAction>
                                                                }
                                                            </ListItem>
                                                            </span>
                                                        )}
                                                    </InfiniteScroll>
                                                    </List>
                                                </div>
                                                :
                                                <Typography variant={"subheading"} className={classes.gray}>
                                                    {t('no results')}
                                                </Typography>
                                        }
                                    </CardContent>
                                </Card>
                            </Grid>
                        </Grid>
                </div>
        )
    }

}
const mapStateToProps = ({organizationMemberRequests, organizationMembers}) => {
        return {members:organizationMembers, memberRequests:organizationMemberRequests};
};

export default compose (
    withRouter,
    withStyles(styles),
    withI18n(),
    connect(mapStateToProps, {acceptOrgMemberRequest, fetchOrganizationMemberRequests, fetchOrganizationMembers, removeOrganizationMembersListeners})
)(OrganizationMembersView)
