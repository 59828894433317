import {ADD_FOLLOWINGS, FETCH_FOLLOWINGS} from "../constants/action-types";
import {combineArrays} from "../util/functions";

export default (state = {}, action) => {
    switch (action.type) {
        case ADD_FOLLOWINGS:
            return {...state, [action.payload.id] : combineArrays(state[action.payload.id], action.payload.data)};
        case FETCH_FOLLOWINGS:
            debugger;
            return {...state, [action.payload.id] : action.payload.data};
        default:
            return state;
    }
};