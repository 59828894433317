import { withI18n } from "react-i18next";
import { withStyles } from '@material-ui/core/styles';
import {Link, withRouter } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {compose} from "recompose";
import {Avatar, Badge, Box, IconButton} from'@material-ui/core';
import {faChild, faUser, faUserShield, faChalkboardTeacher, faUserGraduate, faUserTie} from "@fortawesome/free-solid-svg-icons/index";
import {AVATAR_TYPE, BADGE_TYPE} from "../../constants/common";
import {getAvatarDownloadUrl} from "../../actions";
import {connect} from "react-redux";
import {storage} from "../Firebase/firebase";
import {BADGE_ADMIN, BADGE_PARENT, BADGE_PROFESSOR, BADGE_STUDENT, BLUE} from "../../constants/colors";
const React = require('react');

const styles = theme => ({
    Avatar: {
        width: 70,
        height: 70,
        // padding: theme.spacing.unit * 2,
        backgroundColor: BLUE
    },
    bigAvatar: {
        width: 50,
        height: 50,
        backgroundColor: BLUE
    },
    smallAvatar: {
        width: 30,
        height: 30,
        backgroundColor: BLUE
    },
    badgeContentAvatar: {
        width: 22,
        height: 22,
        fontSize: 'inherit',
        border: `2px solid ${theme.palette.background.paper}`
    },


    admin : {
        color: BADGE_ADMIN
    },
    professor : {
        color: BADGE_PROFESSOR
    },
    parent : {
        color: BADGE_PARENT
    },
    student : {
        color: BADGE_STUDENT
    }

});

class UserAvatar extends React.Component {
    constructor(props) {
        super(props);

        this.state = {avatarUrl:false}

    }

    componentWillMount() {
        const {type, userId, imageUrl} = this.props;
        var that = this;
        if(!imageUrl) {
            var avatarId = AVATAR_TYPE.GROUP === type ? userId.split("/")[1] : userId;
            storage.ref('images/profile').child(avatarId).getDownloadURL().then(function (url) {
                that.setState({avatarUrl: url})
            }).catch(function (error) {
                return null;
            });
        }
    }

    render() {
        const {imageUrl, userId, classes, username, size, type, badge, imageHash} = this.props;
        const {avatarUrl} = this.state;
        const classname =  'small' === size ? classes.smallAvatar : classes.bigAvatar;
        return (
            <Box display="flex">
            <Link to={AVATAR_TYPE.ORGANIZATION === type ? `/organization/${userId}` : AVATAR_TYPE.GROUP === type ? `/group/${userId}` : `/user/${userId}`} title={username}>

                <IconButton>
                    <Box m={1}>
                    <Badge
                        overlap="circle"
                        anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'right',
                        }}
                        badgeContent={badge ? <Avatar className={classes.badgeContentAvatar}>
                                {BADGE_TYPE.ADMIN === badge ? <FontAwesomeIcon icon={faUserShield} size="sm" className={classes.admin}/>
                                    : BADGE_TYPE.PROFESSOR === badge ? <FontAwesomeIcon icon={faChalkboardTeacher} size="sm" className={classes.professor}/>
                                        : BADGE_TYPE.PARENT === badge ? <FontAwesomeIcon icon={faUserTie} size="sm" className={classes.parent}/>
                                            : BADGE_TYPE.CHILD === badge ? <FontAwesomeIcon icon={faChild} size="sm" className={classes.student}/>
                                                : BADGE_TYPE.STUDENT === badge ? <FontAwesomeIcon icon={faUserGraduate} size="sm" className={classes.student}/> : badge }
                            </Avatar> : 0}
                    >
                            {imageUrl ?
                                <Avatar
                                    aria-label={username}
                                    src={imageUrl}
                                    className={classname}
                                />
                                : avatarUrl ?
                                    <Avatar
                                        aria-label={username}
                                        src={avatarUrl}
                                        className={classname}
                                    />
                                    :
                                <Avatar
                                    aria-label={username}
                                    className={classname}
                                >
                                    {username ? username[0].toUpperCase() :  <FontAwesomeIcon icon={faUser}/>}
                                </Avatar>
                            }
                    </Badge>
                    </Box>
                </IconButton>
            </Link>
            </Box>
        )
    }

}

export default compose (
    withRouter,
    withStyles(styles),
    withI18n(),
    connect(null, {getAvatarDownloadUrl})
)(UserAvatar)
