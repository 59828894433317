import { withI18n } from "react-i18next";
import { withStyles } from '@material-ui/core/styles';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChild, faBuilding} from '@fortawesome/free-solid-svg-icons'
import {withRouter } from 'react-router-dom';
import {compose} from "recompose";
import * as COLORS from "../../constants/colors";
import * as ROLES from "../../constants/roles";
import 'react-image-crop-component/style.css'
import {Button, Dialog, DialogActions, DialogContent, MenuItem, DialogTitle, Grid, LinearProgress, TextField, Typography} from'@material-ui/core';
import { connect } from "react-redux";
import {applyLinkCode} from "../../actions";
import {DRAWER_ICON} from "../../constants/colors";

const React = require('react');

const styles = theme => ({
    container: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    colorSwitchBase: {
        color: COLORS.SWITCH,
        '&$colorChecked': {
            color: COLORS.SWITCH,
            '& + $colorBar': {
                backgroundColor: COLORS.SWITCH,
            },
        },
    },
    content : {
        minWidth: 300,
    },
    colorBar: {},
    colorChecked: {},
    uploadProgress : {
        flexGrow: 1,
    },
    linearColorPrimary : {
        backgroundColor: COLORS.LINARY_LOADING_MASK,
    },
    linearBarColorPrimary : {
        backgroundColor: COLORS.LINARY_LOADING_MASK_BAR,
    },
    Avatar: {
        width: 70,
        height: 70,
        // padding: theme.spacing(2),
        backgroundColor: COLORS.MAIN_ICON,
    },

    icon: {
        margin: theme.spacing.unit,
        color: COLORS.BLUE,
    },
    iconInDrawMenu: {
        margin: theme.spacing(),
        color: DRAWER_ICON,
    },
});

const INITIAL_STATE = {
    code: '',
    error: null,
    saving: false,
};
class LinkUser extends React.Component {

    constructor(props) {
        super(props);
        this.state = { ...INITIAL_STATE, open:props.open, type:props.type};
        this.handleClickOpen = this.handleClickOpen.bind(this);
        this.handleClose = this.handleClose.bind(this);
        this.handleLinkCode = this.handleLinkCode.bind(this);
        this.onChange = this.onChange.bind(this);
        this.linkCodeCallback = this.linkCodeCallback.bind(this);
    }

    handleClickOpen = () => {
        this.setState({ open: true});
    };

    handleClose = () => {
        this.setState({ open: false});
    };

    handleLinkCode = () => {
        this.setState({saving:false});
        const {code} = this.state;
        this.props.applyLinkCode({code:code, callback:this.linkCodeCallback});

    };
    linkCodeCallback (success, organizationId) {
        this.setState({saving:false});
        if(success){
            this.props.history.push(`/organization/${organizationId}`);
        }
        this.handleClose();
    }
    onChange = prop => event => {
        this.setState({ [prop]: event.target.value });
    };

    setSelectedUser(value) {
        if(value === null) {
            this.setState({participant: null});
        } else {
            var participant = {userId: value.value, username:value.label};
            if(value.profileImageUrl) {
                participant.profileImageUrl = value.profileImageUrl;
            }
            this.setState({participant: participant});
        }

    };

    render() {
        const {saving, open, code} = this.state;
        const {t, classes, currentUser} = this.props;
        const isInvalid = code === '' || code.length !== 8;
        return (
            <div>
                <MenuItem onClick={this.handleClickOpen}>
                    <FontAwesomeIcon icon={currentUser.designation === ROLES.PARENT ? faChild : faBuilding} className={classes.iconInDrawMenu}/>{currentUser.designation === ROLES.PARENT ? t('add child'): t('link organization') }
                </MenuItem>
                <Dialog
                    open={open?open:false}
                    onClose={this.handleClose}
                    aria-labelledby="form-dialog-title"
                >
                    <DialogTitle id="form-dialog-title"> <Typography  className={classes.title}>
                        {t('enter code')}
                    </Typography></DialogTitle>
                    <DialogContent className={classes.content}>
                        <div>
                            <Grid>
                                <Grid item xs={12}>
                                    <TextField value={code} type="text" onChange={this.onChange('code')} id="input-code" label={t('fields.code')} />
                                </Grid>
                            </Grid>
                        </div>
                    </DialogContent>
                    {saving &&
                    <div className={classes.uploadProgress}>
                        <LinearProgress classes={{
                            colorPrimary: classes.linearColorPrimary,
                            barColorPrimary: classes.linearBarColorPrimary,
                        }}/>
                    </div>
                    }
                    <DialogActions>
                        <Button onClick={this.handleClose} color="primary">
                            {t('buttons.cancel')}
                        </Button>
                        <Button onClick={this.handleLinkCode} color="primary" disabled={isInvalid}>
                            {t('buttons.send')}
                        </Button>
                    </DialogActions>
                </Dialog>
            </div>
        )
    }

}

const mapStateToProps = ({user}) => {
    return {currentUser:user};
};
export default compose (
    withRouter,
    withStyles(styles),
    withI18n(),
    connect(mapStateToProps, {applyLinkCode})
)(LinkUser)
