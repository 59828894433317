export const getChatId = (userId1, userId2) => {
    if(userId1 && userId2) {
        if(userId1 > userId2) {
            return userId2 +'_' + userId1;
        } else {
            return userId1+'_'+userId2;
        }
    }
     return null
}

export const isInSameOrganization = (user1OrganizationList, user2OrganizationList) => {
    var matchFound = false;
    if(user1OrganizationList && user1OrganizationList) {
        var orgKeys = Object.keys(user1OrganizationList)
        orgKeys.forEach(function (orgKey) {
            var matchOrganization = user2OrganizationList[orgKey];
            if(matchOrganization) {
                matchFound = true;
                return matchFound;
            }
        });
    }
    return matchFound;
}


export const isInSameApprovedOrganization = (user1OrganizationList, user2OrganizationList) => {
    var matchFound = false;
    if(user1OrganizationList && user2OrganizationList) {
        var orgKeys = Object.keys(user1OrganizationList)
        orgKeys.forEach(function (orgKey) {
            var organization = user1OrganizationList[orgKey];
            if(organization && organization.isApproved) {
                var matchOrganization = user2OrganizationList[orgKey];
                if(matchOrganization && matchOrganization.isApproved) {
                    matchFound = true;
                    return matchFound;
                }
            }
        });
    }
    return matchFound;
}

export const getUserOrganizationById = (userOrganizations, organizationId) => {
    var userOrgInfo = null;
    if(userOrganizations) {
        userOrgInfo = userOrganizations[organizationId];
    }
    return userOrgInfo;
}

export const isApprovedMemberOfOrganization = (userOrganizations, organizationId) => {
    var isApprovedMember = false;
    if(userOrganizations && organizationId) {
        var userOrgInfo = null;
        if(Array.isArray(userOrganizations))
        {
            userOrgInfo = userOrganizations.find(function(element) {
                return element.id === organizationId;
            });
        } else {
            userOrgInfo = userOrganizations[organizationId];
        }

        if(userOrgInfo) {
            isApprovedMember = userOrgInfo.isApproved ? true : false;
        }
    }
    return isApprovedMember;
}

export const isUnapprovedMemberOfOrganization = (userOrganizations, organizationId) => {
    var isUnapprovedMember = false;
    if(userOrganizations) {
        var userOrgInfo = null;
        if(Array.isArray(userOrganizations))
        {
            userOrgInfo = userOrganizations.find(function(element) {
                return element.id === organizationId;
            });
        } else {
            userOrgInfo = userOrganizations[organizationId];
        }
        if(userOrgInfo) {
            isUnapprovedMember = userOrgInfo.isApproved ? false : true;
        }
    }
    return isUnapprovedMember;
}

export const hasUserInObjectList = (objectList, uid) => {
    var found = false;
    if(objectList) {
        let matchFound = objectList[uid];
        if(matchFound) {
            found = true;
        }
    }
    return found;
}


export const hasContent = (draftJsJSON) => {
    var hasContent = false;
    if(draftJsJSON && draftJsJSON.blocks) {
        draftJsJSON.blocks.forEach(function (block) {
            if(block.type === 'unstyled' && block.text.trim() !== '') {
                hasContent = true;
                return hasContent;
            } else if(block.type === 'atomic') {
                hasContent = true;
                return hasContent;
            }
        })
    }
    return hasContent;
}

export const getContentPreview = (draftJsJSON) => {
    var contentPreview = null;
    if(draftJsJSON && draftJsJSON.blocks) {
        var firstTextBlock = draftJsJSON.blocks.find(function (block) {
            return block.type === 'unstyled' && block.text.trim() !== '';
        })
        if(firstTextBlock) {
            contentPreview = firstTextBlock.text;
        }
    }
    return contentPreview;
}

export const getYoutubeVideoId = (url) => {
    var cleanUrl = url.endsWith("/") ? url.substr(0, url.length-1) : url;
    var regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|\&v=)([^#\&\?]*).*/;
    var match = cleanUrl.match(regExp);

    if (match && match[2].length == 11) {
        return match[2];
    } else {
        return 'error';
    }
}

export const getPushKey = () => {
    return Number((Date.now()).toString() + Math.floor(Math.random() * 10) + Math.floor(Math.random()));
}

export const createUserLikeKey = (userId, username) => {
    return userId + '_' + username;
}

export const areSetsEqual = (setA, SetB) => {
    if (setA.size !== SetB.size) {
        return false;
    }
    for (var a of setA) {
        if (!SetB.has(a)) {
            return false;
        }
    }
    return true;
};

export const  updateObjectInArrayById = (array, itemData) =>{
    debugger;
    if(array) {
        return array.map((item) => {
            if (item.id !== itemData.id) {
                // This isn't the item we care about - keep it as-is
                return item
            }
            debugger;
            // Otherwise, this is the one we want - return an updated value
            return {
                ...item,
                ...itemData
            }
        })
    } else {
        return [itemData];
    }
}

export const  removeObjectInArrayById = (array, id) =>{
    if(array) {
        return array.filter((item) => {
            return item.id !== id;
        })
    } else {
        return array;
    }
}
export const  removeObjectInArrayByKey = (array, key, value) =>{
    if(array) {
        return array.filter((item) => {
            debugger;
            return item[key] !== value;
        })
    } else {
        return array;
    }
}

export const  filterObjectInArray = (array, key, value) =>{
    if(array && value) {
        return array.filter((item) => {
            return item[key].includes(value);
        })
    } else {
        return array
    }

}

export const  combineArrays = (array1, array2) =>{
    var singleArray = [];
    if(Array.isArray(array1)) {
        if(Array.isArray(array2)){
            singleArray = [...array1, ...array2];
        } else {
            singleArray = [...array1];
        }
    } else {
        if(Array.isArray(array2)){
            singleArray = [...array2];
        }
    }
    return singleArray;
}

export const objectToArray = (jsonObject) => {
    var array = [];
    if(jsonObject) {
        array = Object.keys(jsonObject).map(key => ({
            ...jsonObject[key],
            id: key,
        }));

    }
    return array;
}

export const getJSDate = (dateValue) => {
    if (dateValue) {
        try {
            var jsDate = dateValue.toDate();
            return jsDate;
        } catch (error) {
            console.log('failed to convertDate', dateValue)
        }
        if (typeof dateValue === 'object') {
            if(dateValue._seconds) {
                return dateValue._seconds * 1000
            }
            if(dateValue.seconds) {
                return dateValue.seconds * 1000
            }
        }
    }
    return dateValue;
}

export const getUserLessionRatingKey = (userId, lessonId) => {
    if(userId && lessonId) {
        return userId+'_'+lessonId;
    }
    return null
}
