import { withI18n } from "react-i18next";
import { withStyles } from '@material-ui/core/styles';
import {compose} from "recompose";
import classNames from 'classnames';
import {Chip, MenuItem, Paper, TextField, Typography} from '@material-ui/core';
import { emphasize } from '@material-ui/core/styles/colorManipulator';

import {openSnack} from "../../actions";
import {connect} from "react-redux";
import * as COLORS from "../../constants/colors";
import {ASSIGNEE_TYPE, PUBLIC, QUERY_LIMIT, USER_SEARCH_TYPE} from "../../constants/common";
import Select from 'react-select';
import {db, firestoreDB} from '../Firebase/firebase'
import UserAvatar from '../UserAvatar'
import {isInSameApprovedOrganization, isInSameOrganization} from "../../util/functions";

const React = require('react');

const INITIAL_STATE = {
    single: null,
    multi: null,
    suggestions:[]
};

const styles = theme => ({
    container: {
        // display: 'flex',
        flexWrap: 'wrap',
    },
    root: {
        flexGrow: 1,
       //height: 250,
    },
    card: {
        //maxWidth: 400,
        minHeight: 400,
        margin: theme.spacing(2),
    },
    title: {
        textTransform: 'uppercase'
    },
    progressRoot: {
        flexGrow: 1,
        spacing: '40'
    },
    progress: {
        margin: theme.spacing(2),
        color: COLORS.LOADING_MASK
    },input: {
        display: 'flex',
        padding: 0,
    },
    valueContainer: {
        display: 'flex',
        flexWrap: 'wrap',
        flex: 1,
        alignItems: 'center',
    },
    chip: {
        backgroundColor: COLORS.CHIP,
        margin: `${theme.spacing(.5)}px ${theme.spacing(.25)}px`,
    },
    chipFocused: {
        backgroundColor: emphasize(
            theme.palette.type === 'light' ? COLORS.CHIP_LIGHT : COLORS.CHIP,
            0.08,
        ),
    },
    noOptionsMessage: {
        padding: `${theme.spacing()}px ${theme.spacing(2)}px`,
    },
    singleValue: {
        fontSize: 16,
    },
    placeholder: {
        position: 'absolute',
        left: 2,
        fontSize: 16,
    },
    paper: {
        position: 'absolute',
        zIndex: 10,
        marginTop: theme.spacing(),
        left: 0,
        right: 0
    },
    divider: {
        height: theme.spacing(2),
    },

    /*
    value containrt
        align-items: center;
    display: flex;
    flex-wrap: wrap;
    position: relative;
    box-sizing: border-box;
    flex: 1 1 0%;
    padding: 2px 8px;
    overflow: hidden;
     */
});

function NoOptionsMessage(props) {
    return (
        <Typography
            color="textSecondary"
            className={props.selectProps.classes.noOptionsMessage}
            {...props.innerProps}
        >
            {props.children}
        </Typography>
    );
}

function inputComponent({ inputRef, ...props }) {
    return <div ref={inputRef} {...props} />;
}

function Control(props) {
    return (
        <TextField
            fullWidth
            InputProps={{
                inputComponent,
                inputProps: {
                    className: props.selectProps.classes.input,
                    inputRef: props.innerRef,
                    children: props.children,
                    ...props.innerProps,
                },
            }}
            {...props.selectProps.textFieldProps}
        />
    );
}

function Option(props) {
    return (
        <MenuItem
            buttonRef={props.innerRef}
            selected={props.isFocused}
            component="div"
            style={{
                fontWeight: props.isSelected ? 500 : 400,
            }}
            {...props.innerProps}
        >
            <UserAvatar size="small" username={props.data.label} userId={props.data.value} imageUrl={props.data.profileImageUrl} />
            {props.children}
        </MenuItem>
    );
}

function Placeholder(props) {
    return (
        <Typography
            color="textSecondary"
            className={props.selectProps.classes.placeholder}
            {...props.innerProps}
        >
            {props.children}
        </Typography>
    );
}

function SingleValue(props) {
    return (
        <Chip
            avatar={<UserAvatar size="small" username={props.data.label} userId={props.data.value} imageUrl={props.data.profileImageUrl} />}
            tabIndex={-1}
            label={props.children}
            className={classNames(props.selectProps.classes.chip, {
                [props.selectProps.classes.chipFocused]: props.isFocused,
            })}
        />
    );
}

function ValueContainer(props) {
    return <div className={props.selectProps.classes.valueContainer}>{props.children}</div>;
}

function MultiValue(props) {
    return (
        <Chip
            avatar={<UserAvatar size="small" username={props.data.label} userId={props.data.value} imageUrl={props.data.profileImageUrl} />}
            tabIndex={-1}
            label={props.children}
            className={classNames(props.selectProps.classes.chip, {
                [props.selectProps.classes.chipFocused]: props.isFocused,
            })}
            onDelete={event => {
                props.removeProps.onClick();
                props.removeProps.onMouseDown(event);
            }}
        />
    );
}

function Menu(props) {
    return (
        <Paper square className={props.selectProps.classes.paper} {...props.innerProps}>
            {props.children}
        </Paper>
    );
}

const components = {
    // Control,
    Menu,
    MultiValue,
    NoOptionsMessage,
    Option,
    Placeholder,
    SingleValue,
    ValueContainer,
};

class CustomReactSelect extends React.Component {

    constructor(props) {
        super(props);
        this.state = { ...INITIAL_STATE};
        this.onUserInputChange = this.onUserInputChange.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.clearSelectedValues = this.clearSelectedValues.bind(this);
    }

    onUserInputChange(searchText) {
        const suggestions = [];

        var that = this;
        const {existingSelectionKeys, dataPath, type, currentUser, data} = this.props;
        if(searchText && searchText.length > 0) {
            if(type && type === USER_SEARCH_TYPE.MESSAGE ) {
                debugger;
                firestoreDB.collection(`soci/${currentUser.id}/following`).where('username', '>=',searchText).orderBy("username").limit(QUERY_LIMIT).get().then(function (snapshot) {
                    snapshot.forEach(userDoc => {
                        var user = userDoc.data();
                        var suggestion = {
                            value: userDoc.id,
                            label: user.username
                        }
                        if(user.profileImageUrl) {
                            suggestion.profileImageUrl = user.profileImageUrl;
                        }
                        suggestions.push(suggestion);
                    })

                    that.setState({suggestions: suggestions});
                });
                firestoreDB.collection('users').where('username', '>=',searchText).orderBy("username").limit(QUERY_LIMIT).get().then(function (snapshot) {
                    debugger;
                    snapshot.forEach(userDoc => {
                        var user = userDoc.data();
                        if (isInSameApprovedOrganization(currentUser.organizations, user.organizations)) {
                            debugger;
                            var suggestion = {
                                value: userDoc.id,
                                label: user.username
                            }
                            if (user.profileImageUrl) {
                                suggestion.profileImageUrl = user.profileImageUrl;
                            }
                            suggestions.push(suggestion);
                        }
                    })
                    that.setState({suggestions: suggestions});
                });
            } else if(type && type === USER_SEARCH_TYPE.GROUP) {
                //search following
                //search organization if group is not private
                if(data && data.organizationId && data.organizationId.toLowerCase() !== PUBLIC) {

                    firestoreDB.collection(`organizations/${data.organizationId}/members`).where('username', '>=',searchText).orderBy("username").limit(QUERY_LIMIT).get().then(function (snapshot) {
                        snapshot.forEach(userDoc => {
                            var user = userDoc.data();
                            if (!existingSelectionKeys.has(userDoc.id)) {
                                debugger;
                                var suggestion = {
                                    value: userDoc.id,
                                    label: user.username
                                }
                                if (user.profileImageUrl) {
                                    suggestion.profileImageUrl = user.profileImageUrl;
                                }
                                suggestions.push(suggestion);
                            }
                        })
                        that.setState({suggestions: suggestions});
                    });

                } else {
                    firestoreDB.collection(`soci/${currentUser.id}/following`).where('username', '>=',searchText).orderBy("username").limit(QUERY_LIMIT).get().then(function (snapshot) {
                        snapshot.forEach(userDoc => {
                            var user = userDoc.data();
                            if (!existingSelectionKeys.has(userDoc.id)) {
                                var suggestion = {
                                    value: userDoc.id,
                                    label: user.username
                                }
                                if (user.profileImageUrl) {
                                    suggestion.profileImageUrl = user.profileImageUrl;
                                }
                                suggestions.push(suggestion);
                            }
                        })

                        that.setState({suggestions: suggestions});
                    });
                }
            }
            else if(type && type === USER_SEARCH_TYPE.LESSON_ASSIGNEE) {
                //search organization if group is not private
                if(data && data.organizationId && data.organizationId.toLowerCase() !== PUBLIC) {
                    firestoreDB.collection(`organizations/${data.organizationId}/members`).where('username', '>=',searchText).orderBy("username").limit(QUERY_LIMIT).get().then(function (snapshot) {
                        debugger;
                        snapshot.forEach(userDoc => {
                            var user = userDoc.data();
                            if (!existingSelectionKeys.has(userDoc.id)) {
                                var suggestion = {
                                    value: userDoc.id,
                                    label: user.username
                                }
                                if (user.profileImageUrl) {
                                    suggestion.profileImageUrl = user.profileImageUrl;
                                }
                                suggestions.push(suggestion);
                            }
                        })
                        debugger;
                        that.setState({suggestions: suggestions});
                    });
                } else {
                    //search following
                    firestoreDB.collection(`soci/${currentUser.id}/following`).where('username', '>=',searchText).orderBy("username").limit(QUERY_LIMIT).get().then(function (snapshot) {
                        snapshot.forEach(userDoc => {
                            var user = userDoc.data();
                            debugger;
                            if (!existingSelectionKeys.has(userDoc.id)) {
                                var suggestion = {
                                    value: userDoc.id,
                                    label: user.username
                                }
                                if (user.profileImageUrl) {
                                    suggestion.profileImageUrl = user.profileImageUrl;
                                }
                                suggestions.push(suggestion);
                            }
                        })
                        that.setState({suggestions: suggestions});
                    });
                }
            } else {
                firestoreDB.collection('users').where('username', '>=',searchText).orderBy("username").limit(QUERY_LIMIT).get().then(function (snapshot) {
                    snapshot.forEach(userDoc => {
                        var user = userDoc.data();
                        if (!existingSelectionKeys.has(userDoc.id) && user.approved) {
                            var suggestion = {
                                value: userDoc.id,
                                label: user.username
                            }
                            if (user.profileImageUrl) {
                                suggestion.profileImageUrl = user.profileImageUrl;
                            }
                            suggestions.push(suggestion);
                        }
                    });

                    that.setState({suggestions: suggestions});
                });
            }
        }
    }
    clearSelectedValues(name) {
        this.setState({name:null});
    }

    handleChange = name => value => {
        debugger;
        this.setState({
            [name]: value,
        });
        this.props.onChange(value);
    };

    render() {
        const { t, classes, selectionType, isMulti} = this.props;
        const label = selectionType === ASSIGNEE_TYPE.USER ? t('Add Users') : t('Add Groups');
        const selectStyles = {
            input: base => ({
                ...base,
                color: COLORS.PRIMARY,
            }),
        };
        return (
                <div className={classes.root}>
                    {isMulti ?
                        <Select
                            ref="select"
                            classes={classes}
                            styles={selectStyles}
                            components={components}
                            value={this.state.multi}
                            onChange={this.handleChange('multi')}
                            options={this.state.suggestions}
                            onInputChange={this.onUserInputChange}
                            isMulti
                            placeholder={selectionType === ASSIGNEE_TYPE.USER ? t('search for users') : t('search for Groups')}
                            textFieldProps={{
                                label: label,
                                InputLabelProps: {
                                    shrink: true,
                                },
                            }}
                        />
                        :
                        <Select
                            ref="select"
                            classes={classes}
                            styles={selectStyles}
                            components={components}
                            isClearable={true}
                            value={this.state.single}
                            onChange={this.handleChange('single')}
                            options={this.state.suggestions}
                            onInputChange={this.onUserInputChange}
                            placeholder={selectionType === ASSIGNEE_TYPE.USER ? t('search for user') : t('search for Group')}
                            textFieldProps={{
                                label: label,
                                InputLabelProps: {
                                    shrink: true,
                                },
                            }}
                        />
                    }
                </div>
        )
    }
}

const mapStateToProps = ({user}) => {
    return {currentUser:user};
};

export default compose (
    withStyles(styles),
    withI18n(),
    connect(mapStateToProps, {openSnack})
)(CustomReactSelect)