import { withI18n } from "react-i18next";
import { withStyles } from "@material-ui/core/styles";
import { withRouter } from "react-router-dom";
import { compose } from "recompose";

import Typography from "@material-ui/core/Typography";
import { Divider, Grid, Card, Box, Paper } from "@material-ui/core";
import { spacing } from "@material-ui/system";

const React = require("react");

const styles = {
  card: {
    minWidth: 275,
    maxWidth: 400,
  },
  root: {
    flexGrow: 1,
  },
  paper: {
    margin: "auto",
    maxWidth: "60%",
  },
  bullet: {
    display: "inline-block",
    margin: "0 2px",
    transform: "scale(0.8)",
  },
  actions: {
    display: "inherit",
  },
  title: {
    textTransform: "uppercase",
    textAlign: "center",
  },
  pos: {
    marginBottom: 12,
  },
};

class AboutUs extends React.Component {
  render() {
    const { t, classes } = this.props;

    return (
      <div>
        <br />
        <Typography variant="h3" className={classes.title}>
          {t("application.about us")}
        </Typography>
        <br />
        <Paper className={classes.paper}>
          <Box m={2} textAlign="justify">
            <Grid
              container
              spacing={3}
              direction="column"
              justify="center"
              alignItems="center"
            >
              <Grid container item>
                <div className={styles.textPadding}>
                  <Typography variant="h4">
                    {t("aboutpage.company.title")}
                  </Typography>
                </div>
                <Typography component="p">
                  <br />
                  {t("aboutpage.company.body.p1")}
                </Typography>
                <br />
                <Typography component="p">
                  {t("aboutpage.company.body.p2")}
                </Typography>
                <br />
                <Typography component="p">
                  {t("aboutpage.company.body.p3")}
                </Typography>
                <br />
              </Grid>
              <Grid container item>
                <Typography variant="h4">
                  {t("aboutpage.mission.title")}
                  <br />
                </Typography>
                <Typography component="p">
                  <br />
                  {t("aboutpage.mission.body.p1")}
                </Typography>
                <br />
              </Grid>
              <Grid container item>
                <Typography variant="h4">
                  {t("aboutpage.vision.title")}
                  <br />
                </Typography>
                <Typography component="p">
                  <br />
                  {t("aboutpage.vision.body.p1")}
                </Typography>
                <br />
              </Grid>
            </Grid>
          </Box>
        </Paper>
      </div>
    );
  }
}

export default compose(
  withRouter,
  withStyles(styles),
  withI18n()
)(AboutUs);
