import { withI18n } from "react-i18next";
import { withStyles } from '@material-ui/core/styles';
import {withRouter} from 'react-router-dom';
import {compose} from "recompose";
import InfiniteScroll from 'react-infinite-scroller';
import {Card, CardContent, CardHeader, CircularProgress,  Divider, List, ListItem} from '@material-ui/core';
import {connect} from "react-redux";
import TimeAgo from 'react-timeago';
import {fetchChatMessages, removeChatMessageListener} from "../../actions";
import {
    LOADING_MASK,
    MESSAGE_CURRENT_USER,
    MESSAGE_OTHER_USER,
    PRIMARY,
    RADIO_BUTTON_SELECT,
    SECONDARY_ICON
} from "../../constants/colors";
import UserAvatar from "../UserAvatar";
import Message from "../Message";
import {CHAT_MESSAGE_LIMIT, POST_LIMIT, POST_TYPE} from "../../constants/common";
import {getJSDate} from "../../util/functions";


const React = require('react');

const styles = theme => ({
    root: {
        width: '100%',
        //flexGrow: 1,
    },
    card: {
        width: '100%',
        overflow: 'visible',
        margin: theme.spacing(.2),
    },
    cardContent: {
        //width: 300,
        paddingTop: 0,
        overflowY:'auto',
        maxHeight:'75vh',
        '-webkit-overflow-scrolling': 'touch'
    },
    inline: {
        display: 'inline',
    },
    formControl: {
        minWidth: 300,
    },
    formControlRadio: {
        paddingTop: theme.spacing.unit * 2,
        //margin: theme.spacing.unit * 1,
    },
    radioButton : {
        color: RADIO_BUTTON_SELECT,
        '&$checked': {
            color: RADIO_BUTTON_SELECT,
        },
    },
    progressRoot: {
        flexGrow: 1,
        spacing: '40'
    },
    progress: {
        margin: theme.spacing(2),
        color: LOADING_MASK
    },
    pageActions : {
        paddingTop : 0,
        color: PRIMARY
    },
    groupsCard: {
        //width: 300,
        margin: theme.spacing.unit * 2,
    },
    groupAvatar: {
        width: 50,
        height: 50,
        backgroundColor: SECONDARY_ICON
        // padding: theme.spacing.unit * 2,
    },
    actions: {
        display: 'inherit',
    },
    media: {
        paddingTop: '75.25%', // 16:9
    },
    title: {
        textTransform: 'uppercase'
    },
    pos: {
        marginBottom: 12,
    },
    expand: {
        transform: 'rotate(0deg)',
        marginLeft: 'auto',
        transition: theme.transitions.create('transform', {
            duration: theme.transitions.duration.shortest,
        }),
    },
    group: {
        margin: theme.spacing(1),
        display: 'inline'
    },
    description : {
        whiteSpace: 'pre-wrap'
    },
    gray : {
        color: 'gray'
    },
    chatInput: {
        display: 'block'
    },
    messageOtherUser: {
        width: '100%',
        margin: theme.spacing.unit * .2,
        backgroundColor: MESSAGE_OTHER_USER
    },

    messageCurrentUser: {
        width: '100%',
        margin: theme.spacing(.2),
        backgroundColor: MESSAGE_CURRENT_USER
    },
    messageLeft: {
        justifyContent: 'flex-start',
    },
    messageRight: {
        justifyContent: 'flex-end',
    },
    scrollContainer: {
        height:'400px',
        overflow:'auto'
    }
});

class Chat extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            loading: false,
            chat: null,
            hasMoreMessages:false,
        };
        this.onChange = this.onChange.bind(this);
        this.loadChat = this.loadChat.bind(this);
        this.fetchChatCallBack = this.fetchChatCallBack.bind(this);
        this.scrollToBottom = this.scrollToBottom.bind(this);
        this.loadMore = this.loadMore.bind(this);
        this.displayDate = this.displayDate.bind(this);
        this.isSameYear = this.isSameYear.bind(this);
        this.isSameMonth = this.isSameMonth.bind(this);
        this.isSameDay = this.isSameDay.bind(this);
        this.isSameHour = this.isSameHour.bind(this);
        this.isSameHour = this.isSameHour.bind(this);
    }

    componentWillUpdate(nextProps, nextState, nextContext) {
        if (nextProps.chat && (this.state.chat === null || this.state.chat === undefined
            || nextProps.chat.id !== this.state.chat.id) && (!nextState.chat || nextState.chat.id  !== nextProps.chat.id)){
            var participant = {userId: nextProps.chat.participantUserId, username:nextProps.chat.participantUsername};
            if(nextProps.chat.participantProfileImageUrl) {
                participant.profileImageUrl = nextProps.chat.participantProfileImageUrl;
            }
            this.setState({chat:nextProps.chat, participant:participant});
            this.loadChat(nextProps.chat);
        }
    }




    loadChat(chat) {
        const {chatMessages} = this.props;
        if(!chatMessages[chat.id]) {
            this.props.fetchChatMessages({currentUser:this.props.currentUser, limit:CHAT_MESSAGE_LIMIT, chatId:chat.id, callback:this.fetchChatCallBack});
        }
    }

    displayDate(index){
        debugger;
        const {chatMessages} = this.props;
        const {chat} = this.state;
        const currentMessages = chatMessages && chat ? chatMessages[chat.id] : null;
        const currentMessage = currentMessages? currentMessages[index] : null;
        if(currentMessage && currentMessage.dateCreated != null){
            if(index == 0){
                return true;
            }
            const currentMessageDate = getJSDate(currentMessage.dateCreated);
            const previousMessage = currentMessages[index-1];
            if(previousMessage.dateCreated == null) {
                return true;
            }
            var previousMessageDate = getJSDate(previousMessage.dateCreated);
            const currentDate = new Date();
            //today
            if(this.isSameDay(currentMessageDate, currentDate)){
                if(this.isSameDay(currentMessageDate, previousMessageDate)) {
                    if(this.isSameHour(currentMessageDate, currentDate)){
                        if(this.isSameHour(currentMessageDate, currentDate)){
                            if(this.isSameMinute(currentMessageDate, currentDate)){

                            }
                        } else {
                            return true
                        }
                    }
                } else {
                    return true;
                }
            } else if(this.isSameMonth(currentMessageDate, currentDate)) {
                if(this.isSameMonth(currentMessageDate, previousMessageDate)) {
                    if(this.isSameDay(currentMessageDate, previousMessageDate)) {
                        return false
                    } else {
                        return true;
                    }
                } else {
                    return true;
                }
            } else if(this.isSameYear(currentMessageDate, currentDate)) {
                if(this.isSameMonth(currentMessageDate, previousMessageDate)) {
                    return false
                } else {
                    return false
                }
            } else if(this.isSameYear(currentMessageDate, previousMessageDate)) {
                return false
            }
        } else {
            return false
        }

        return true;
    }

    isSameMinute(date, date2) {
        return date.getMinutes() == date2.getMinutes() &&
             date.getHours() == date2.getHours() &&
            date.getDate() == date2.getDate() &&
            date.getMonth() == date2.getMonth() &&
            date.getFullYear() == date2.getFullYear()
    }
    isSameHour(date, date2) {
        return date.getHours() == date2.getHours() &&
            date.getDate() == date2.getDate() &&
            date.getMonth() == date2.getMonth() &&
            date.getFullYear() == date2.getFullYear()
    }
    isSameDay(date, date2) {
        return date.getDate() == date2.getDate() &&
            date.getMonth() == date2.getMonth() &&
            date.getFullYear() == date2.getFullYear()
    }
    isSameMonth(date, date2) {
        return date.getMonth() == date2.getMonth() &&
            date.getFullYear() == date2.getFullYear()
    }
    isSameYear(date, date2) {
        return date.getFullYear() == date2.getFullYear()
    }

    componentWillUnmount() {
        // if(this.state.chat && this.state.chat.id){
        //     this.props.removeChatMessageListener(this.state.chat.id);
        // }
    }
    fetchChatCallBack(count, initial) {
        // if(initial) {
        //     this.scrollToBottom();
        // }
        var hasMore = count === CHAT_MESSAGE_LIMIT;
        this.setState({hasMoreMessages: hasMore});
    }

    onChange = prop => event => {
        this.setState({ [prop]: event.target.value });
    };

    scrollToBottom = () => {
        this.messageInput.scrollIntoView({ behavior: "smooth" });
    }

    loadMore= () => {
        this.setState({hasMoreMessages: false});
        const {chatMessages, currentUser} =  this.props;
        const {chat} = this.state;
       var currentChatMessages = chat ? chatMessages[chat.id] : null;
       var last = currentChatMessages ? currentChatMessages[currentChatMessages.length - 1] : null;
        if(last) {
            this.props.fetchChatMessages({currentUser:currentUser, limit:CHAT_MESSAGE_LIMIT, last: last.dateCreated, chatId:chat.id, callback:this.fetchChatCallBack});
        }
    };

    render() {

        const {t, classes, chatMessages, currentUser} = this.props;
        const {chat, participant, hasMoreMessages} = this.state;
        const messages = chat ? chatMessages[chat.id] : null;
        debugger;
        return (chat ?
            <Card className={classes.card}>
                 <CardHeader
                     avatar={
                         <UserAvatar username={chat.participantUsername} userId={chat.participantUserId}
                                      imageUrl={chat.participantImageUrl}/>
                     }
                     title={chat.participantUsername}
                 />
                 <Divider/>
                    <CardContent class="momentumScroll" className={classes.cardContent}>
                        <List className={classes.root}>
                            <ListItem ref={(el)=>{this.messageInput = el;}} classes={{root: classes.chatInput}} key="new-message">
                                <Message participant={participant} currentUser={currentUser} readOnly={false} messageId={'new-message-id'}/>
                            </ListItem>
                            {messages && messages.length > 0 &&
                            <div className={classes.scrollContainer}>
                                <InfiniteScroll
                                    pageStart={0}
                                    loadMore={this.loadMore}
                                    hasMore={hasMoreMessages}
                                    useWindow={false}
                                    //isReverse={true}
                                    loader={<div align="center" className={classes.progressRoot}><CircularProgress
                                        className={classes.progress} size={20} thickness={3}/></div>}
                                >
                                    {messages.map((chatMessage, index) => (
                                        <span key={`chat-message-${chatMessage.id}`}>
                                            {this.displayDate(index) && <div align="center"><TimeAgo date={getJSDate(chatMessage.dateCreated)}/></div>}
                                        <ListItem
                                            classes={chatMessage.senderId === currentUser.id ? {root: classes.messageRight} : {root: classes.messageLeft}}
                                            key={`item-message-${chatMessage.id}`}>
                                            <Message
                                                messageClass={chatMessage.senderId === currentUser.id ? classes.messageCurrentUser : classes.messageOtherUser}
                                                message={chatMessage} currentUser={currentUser} readOnly={true}
                                                messageId={chatMessage.id}/>
                                        </ListItem>
                                    </span>
                                    ))}
                                </InfiniteScroll>
                            </div>
                            }
                        </List>
                    </CardContent>
            </Card>
            :

            <div align="center" className={classes.progressRoot}> {t('select chat or start new')}</div>

        )
    }

}
const mapStateToProps = ({chatMessages, user}) => {
        return {chatMessages:chatMessages, user:user};
};

export default compose (
    withRouter,
    withStyles(styles),
    withI18n(),
    connect(mapStateToProps, {fetchChatMessages, removeChatMessageListener})
)(Chat)
