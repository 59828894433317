import {auth, db, storage, googleProvider,firestore, firestoreDB, fbFunctions } from "../components/Firebase/firebase";
import * as ACTIONS from "../constants/action-types";
import {
    ASSIGNMENT_STATUS,
    ASSIGNMENT_FIELD,
    FIELDS,
    QUESTION_TYPE,
    GROUP_TYPE,
    PATHS,
    LINK_CODES_TYPE,
    POST_TYPE, POST_PATHS, QUERY_LIMIT, ALL, QUERY_IMAGE_LIMIT
} from "../constants/common";
import * as ROLES from "../constants/roles";
import {getChatId, getUserLessionRatingKey, areSetsEqual, createUserLikeKey} from "../util/functions";
import {UPDATE_QUESTION} from "../constants/action-types";

export const fetchUser = () => dispatch => {

    auth.onAuthStateChanged(authUser => {
        if (authUser) {
            firestoreDB.collection(`soci/${authUser.uid}/following`).onSnapshot(snapshot => {
                var followings = {};
                snapshot.forEach(doc => {
                    followings[doc.id] = doc.data();
                });
                dispatch({
                    type: ACTIONS.FETCH_MY_FOLLOWINGS,
                    payload: followings
                });

            });
            firestoreDB.collection(`soci/${authUser.uid}/followers`).onSnapshot(snapshot => {
                var followers = {};
                snapshot.forEach(doc => {
                    followers[doc.id] = doc.data();
                });
                dispatch({
                    type: ACTIONS.FETCH_MY_FOLLOWERS,
                    payload: followers
                });

            });
            firestoreDB.collection(`soci/${authUser.uid}/sentFollowingRequests`).onSnapshot(snapshot => {
                var sentFollowingRequests = {};
                snapshot.forEach(doc => {
                    sentFollowingRequests[doc.id] = doc.data();
                });

                dispatch({
                    type: ACTIONS.FETCH_MY_SENT_FOLLOWING_REQUESTS,
                    payload: sentFollowingRequests
                });

            });
            firestoreDB.collection(`soci/${authUser.uid}/followerRequests`).onSnapshot(snapshot => {
                var followerRequests = {};

                snapshot.forEach(doc => {
                    followerRequests[doc.id] = doc.data();
                });

                dispatch({
                    type: ACTIONS.FETCH_MY_FOLLOWER_REQUESTS,
                    payload: followerRequests
                });

            });
            firestoreDB.collection(`soci/${authUser.uid}/lessons`).onSnapshot(snapshot => {
                var myAssignments = {[ASSIGNMENT_STATUS.COMPLETED] : [], [ASSIGNMENT_STATUS.SUBMITTED] : [], [ASSIGNMENT_STATUS.ASSIGNED] : [], [ASSIGNMENT_STATUS.REASSIGNED] : [] };

                snapshot.forEach(function(assigment) {
                    var myAssignment = assigment.data();
                    myAssignment.id = assigment.id;
                    myAssignments[myAssignment.status].push(myAssignment);
                });
                dispatch({
                    type: ACTIONS.FETCH_MY_LESSONS,
                    payload: myAssignments
                })
            });
            firestoreDB.collection(`soci/${authUser.uid}/groups`).onSnapshot(snapshot => {
                var groupList =  [];
                snapshot.forEach(doc => {
                    var docData = doc.data();
                    docData.id = doc.id;
                    groupList.push(docData);
                });
                dispatch({
                    type: ACTIONS.FETCH_MY_GROUPS,
                    payload: groupList
                });
            });
            firestoreDB.collection('users').doc(authUser.uid).onSnapshot(userDoc => {
                if(userDoc.exists) {
                    var userObject =  userDoc.data();
                    userObject.id = userDoc.id;
                    if(userObject.designation) {
                        userObject.isAdministrator =  userObject.designation === ROLES.ADMINISTRATION;
                        userObject.isProfessor = userObject.designation === ROLES.PROFESSOR;
                        userObject.isParent = userObject.designation === ROLES.PARENT;
                        userObject.isStudent = userObject.designation === ROLES.STUDENT;
                    }
                    var userChildList = [];
                    var userParentList = [];
                    if(userObject.children){
                        Object.keys(userObject.children).forEach(function (childKey) {
                            var child = userObject.children[childKey];
                            child.id = childKey;
                            userChildList.push(child);
                        });
                    }
                    if(userObject.parents){
                        Object.keys(userObject.parents).forEach(function (parentKey) {
                            var parent = userObject.parents[parentKey];
                            parent.id = parentKey;
                            userParentList.push(parent);
                        });
                    }

                    if(userParentList.length > 0) {
                        userObject.childrenList = userChildList;
                    }
                    if(userParentList.length > 0) {
                        userObject.parentList = userParentList;
                    }
                    if(userObject.organizations) {
                        var  orgKeys = Object.keys(userObject.organizations);
                        var organizations = [];
                        var verifiedOrganizations = [];
                        var unverifiedOrganizations = [];
                        orgKeys.forEach(function (orgKey) {
                            var org = userObject.organizations[orgKey];
                            org.id = orgKey;
                            var childrenList = [];
                            var parentList = [];
                            if(org.children){
                                Object.keys(org.children).forEach(function (childKey) {
                                    var child = org.children[childKey];
                                    child.id = childKey;
                                    childrenList.push(child);
                                });
                            }
                            if(org.parents){
                                Object.keys(org.parents).forEach(function (parentKey) {
                                    var parent = org.parents[parentKey];
                                    parent.id = parentKey;
                                    parentList.push(parent);
                                });
                            }

                            if(childrenList.length > 0) {
                                org.childrenList = childrenList;
                            }
                            if(parentList.length > 0) {
                                org.parentList = parentList;
                            }

                            if(org.isApproved) {
                                verifiedOrganizations.push(org);
                            } else {
                                unverifiedOrganizations.push(org);
                            }
                            organizations.push(org);
                        });

                        if(verifiedOrganizations.length > 0) {
                            userObject.verifiedOrganizations = verifiedOrganizations;
                        }
                        if(unverifiedOrganizations.length > 0) {
                            userObject.unverifiedOrganizations = unverifiedOrganizations;
                        }
                        userObject.organizationList = organizations;
                    }
                    dispatch({
                        type: ACTIONS.FETCH_USERINFO,
                        payload: userObject
                    })
                }
                dispatch({
                    type: ACTIONS.FETCH_USER,
                    payload: authUser
                });
            });
        } else {
            dispatch({
                type: ACTIONS.FETCH_USER,
                payload: null
            });
        }
    });
};

export const fetchUserInfo = (userId) => dispatch => {
    firestoreDB.doc(`users/${userId}`).get().then(snapshot => {
        var user = null;
        if(snapshot.exists) {
            user = snapshot.data();
            user.id = snapshot.id;
        }

        dispatch({
            type: ACTIONS.FETCH_USERINFO,
            payload: user
        })
    });
};

export const fetchUserDetails = (searchParams) => dispatch => {
    firestoreDB.collection('users').doc(searchParams.userId).get().then(userDoc => {
        var userObject = null
        if(userDoc.exists) {
            userObject =  userDoc.data();
            userObject.id = userDoc.id;
            if(userObject.designation) {
                userObject.isAdministrator =  userObject.designation === ROLES.ADMINISTRATION;
                userObject.isProfessor = userObject.designation === ROLES.PROFESSOR;
                userObject.isParent = userObject.designation === ROLES.PARENT;
                userObject.isStudent = userObject.designation === ROLES.STUDENT;
            }
            var userChildList = [];
            var userParentList = [];
            if(userObject.children){
                Object.keys(userObject.children).forEach(function (childKey) {
                    var child = userObject.children[childKey];
                    child.id = childKey;
                    userChildList.push(child);
                });
            }
            if(userObject.parents){
                Object.keys(userObject.parents).forEach(function (parentKey) {
                    var parent = userObject.parents[parentKey];
                    parent.id = parentKey;
                    userParentList.push(parent);
                });
            }
            if(userParentList.length > 0) {
                userObject.childrenList = userChildList;
            }
            if(userParentList.length > 0) {
                userObject.parentList = userParentList;
            }

            if(userObject.organizations) {
                var  orgKeys = Object.keys(userObject.organizations);
                var verifiedOrganizations = [];
                var unverifiedOrganizations = [];
                var organizations = [];
                orgKeys.forEach(function (orgKey) {
                    var org = userObject.organizations[orgKey];
                    org.id = orgKey;

                    var childrenList = [];
                    var parentList = [];
                    if(org.children){
                        Object.keys(org.children).forEach(function (childKey) {
                            var child = org.children[childKey];
                            child.id = childKey;
                            childrenList.push(child);
                        });
                    }
                    if(org.parents){
                        Object.keys(org.parents).forEach(function (parentKey) {
                            var parent = org.parents[parentKey];
                            parent.id = parentKey;
                            parentList.push(parent);
                        });
                    }

                    if(childrenList.length > 0) {
                        org.childrenList = childrenList;
                    }
                    if(parentList.length > 0) {
                        org.parentList = parentList;
                    }
                    if(org.isApproved) {
                        verifiedOrganizations.push(org);
                    } else {
                        unverifiedOrganizations.push(org);
                    }
                    organizations.push(org);
                });

                if(verifiedOrganizations.length > 0) {
                    userObject.verifiedOrganizations = verifiedOrganizations;
                }
                if(unverifiedOrganizations.length > 0) {
                    userObject.unverifiedOrganizations = unverifiedOrganizations;
                }
                userObject.organizationList = organizations
            }
        } else {
            dispatch({
                type: ACTIONS.ADD_SNACK,
                payload: {open:true,messageText:'failed to find user', variant:'error'}
            });
        }
        searchParams.callback(userObject);
    });
};

export const fetchUsers = searchParams => dispatch => {
    var usersRef = null;
    if(searchParams.username){
        usersRef = firestoreDB.collection('users').where('username', '>=', searchParams.username).orderBy('username').limit(QUERY_LIMIT);
    } else {
        usersRef = firestoreDB.collection('users').orderBy('username').limit(QUERY_LIMIT);
    }
    usersRef.get().then(snapshot => {
            var userList  = [];
        snapshot.forEach(function (child) {
                var user = child.data();
                user.uid = child.id;
                userList.push(user);

        });
        dispatch({
            type: ACTIONS.FETCH_USERS,
            payload: userList
        });
    });
    //return userList;
};

export const updateProfileImageHash = () => dispatch => {
    var currentDate = Date.now();
    dispatch({
        type: ACTIONS.UPDATE_PROFILE_HASH,
        payload: {currentDate}
    });
};

export const updateUserAttribute = (userId, attribute, value) => dispatch => {
    var updates = {};
    updates[`users/${userId}/${attribute}`] = value;
    db.ref().update(updates).then(() => {

    }).catch(error => {
        dispatch({
            type: ACTIONS.ADD_SNACK,
            payload: {open:true,messageText:error.message, variant:'error'}
        });
    });
};

export const doCreateUserWithEmailAndPassword = (request) => dispatch => {
    auth.createUserWithEmailAndPassword(request.email, request.password).then(authUser => {
        // Create a user in your Firebase realtime database
        var user = auth.currentUser;
        user.updateProfile({
            displayName: request.username,
        }).then(function() {
            // Update successful.

        }).catch(function(error) {
            dispatch({
                type: ACTIONS.ADD_SNACK,
                payload: {open:true,messageText: error.message, variant:'error'}
            });
        });

        db.ref(`users/${authUser.user.uid}`).set({
            username:request.username,
            email: request.email,
            designation: request.designation
        }).catch(function(error) {
            dispatch({
                type: ACTIONS.ADD_SNACK,
                payload: {open:true,messageText: error.message, variant:'error'}
            });
        });
    }).catch(error => {
        dispatch({
            type: ACTIONS.ADD_SNACK,
            payload: {open:true,messageText: error.message, variant:'error'}
        });
    });
}

export const registerUser = async (request) => dispatch => {
    var registerUser = fbFunctions.httpsCallable('registerUser');
    return registerUser(request).then(function (result) {
        return {success:true}
    }).catch(error => {
        return {success:false, message:error.message};
    });
};

export const doSignInWithEmailAndPassword = (email, password, callback) => dispatch => {
    auth.signInWithEmailAndPassword(email, password).then(function () {
        // Sign-out successful.
        dispatch({
            type: ACTIONS.ADD_SNACK,
            payload: {open:true,messageText: 'welcome', variant:'success'}
        });
        callback(true);
    }).catch(function (error) {
        // An error happened.
        console.log('user failed reset, error: ');
        console.log(error);
        dispatch({
            type: ACTIONS.ADD_SNACK,
            payload: {open:true,messageText: error.message, variant:'error'}
        });
        callback(false);
    });
}

export const doSignOut = () => dispatch => {
    auth.signOut().then(function () {
        // Sign-out successful.
        dispatch({
            type: ACTIONS.USER_LOGOUT,
            payload: null
        });
        console.log('user successfully signed out');
    }).catch(function (error) {
        // An error happened.
        console.log('user failed signed out, error: ');
        console.log(error);

    });
}

export const doPasswordReset = email => dispatch  => {
    auth.sendPasswordResetEmail(email).then(function () {
        // Sign-out successful.
        console.log('user successfully reset');
        dispatch({
            type: ACTIONS.ADD_SNACK,
            payload: {open:true,messageText:'messages.password reset email sent', variant:'success'}
        });
    }).catch(function (error) {
        // An error happened.
        console.log('user failed reset, error: ');
        console.log(error);
        dispatch({
            type: ACTIONS.ADD_SNACK,
            payload: {open:true,messageText: error.message, variant:'error'}
        });

    });
}

export const doPasswordUpdate = password => dispatch => {
    auth.currentUser.updatePassword(password).then(() => {
        // pw update successful.
        dispatch({
            type: ACTIONS.ADD_SNACK,
            payload: {open:true,messageText:'messages.password reset email sent', variant:'success'}
        });
        })
        .catch(error => {
            console.log(error);
            dispatch({
                type: ACTIONS.ADD_SNACK,
                payload: {open:true,messageText: error.message, variant:'error'}
            });
        });
};

export const signInWithGoogle = () => dispatch => {
    auth
        .signInWithPopup(googleProvider)
        .then(result => {})
        .catch(error => {
            console.log(error);
        });
};


export const signOut = () => dispatch => {
    auth
        .signOut()
        .then(() => {
            // Sign-out successful.
        })
        .catch(error => {
            console.log(error);
        });
};



/**
 * update auth user profile
 * @param profileInfo
 * @returns {Promise<void> | !firebase.Promise<void>}
 */
export const doAuthUserProfileUpdate = profileInfo => auth.currentUser.updateProfile(profileInfo);

// *** Files API ***

/**
 *
 * @param fileInfo
 * @returns {firebase.storage.UploadTask | !firebase.storage.UploadTask | *}
 */
export const doUploadProfileImage = fileInfo => storage.ref(`images/profile/${fileInfo.uid}`).putString(fileInfo.image, 'base64');

export const doUploadPostImage = fileInfo => storage.ref(fileInfo.path).putString(fileInfo.image, 'base64');

export const doUploadFile = fileInfo => storage.ref(fileInfo.fullPath).put(fileInfo.file);

export const doUploadBase64File = fileInfo => storage.ref(fileInfo.fullPath).putString(fileInfo.file, 'base64');
export const doUploadDataUrl = fileInfo => storage.ref(fileInfo.fullPath).putString(fileInfo.file, 'data_url');

export const doDeleteFile = fullPath => dispatch => {
    storage.ref(fullPath).delete().then(function() {
        // File deleted successfully
    }).catch(function(error) {
        console.log(error)
;    });;
}

/**
 *
 * @param filename
 * @returns {download url}
 */
export const getProfileImageDownloadUrl =  filename => storage.ref('images/profile').child(filename).getDownloadURL();

export const getFileDownloadUrl =  (filePath, filename) => storage.ref(filePath).child(filename).getDownloadURL();

export const getAvatarDownloadUrl =  filename => {
    return storage.ref('images/profile').child(filename).getDownloadURL().then(function (url) {
        return url
    }).catch(function(error) {
        return null;
    });
}

export const fetchImages = (search) => dispatch => {
    debugger;
    var listRef = storage.ref(search.path+'/'+ search.id);
    const limit = search.limit ? search.limit : QUERY_IMAGE_LIMIT;
    var request = {'maxResults':limit};
    if(search.nextPageToken) {
        request.nextPageToken = search.nextPageToken;
    }
    // Find all the prefixes and items.
    try {

        listRef.list(request).then(function (res) {
            // res.prefixes.forEach(function(folderRef) {
            //     // All the prefixes under listRef.
            //     // You may call listAll() recursively on them.
            // });
            debugger;
            let imageDataList = [];
            res.items.forEach(function (itemRef) {
                debugger;
                // All the items under listRef.
                var imageData = {id: itemRef.name, name: itemRef.name, ref: itemRef};
                if(imageData.name && imageData.name.endsWith('.svg')) {
                    imageData.isSvg = true;
                }
                imageDataList.push(imageData)
            });
            var imageInfo = {id: search.id, data: imageDataList};
            if (search.last) {
                dispatch({
                    type: ACTIONS.ADD_IMAGES,
                    payload: imageInfo
                });
            } else {
                dispatch({
                    type: ACTIONS.FETCH_IMAGES,
                    payload: imageInfo
                });
            }
            if (search.callback) {
                search.callback(imageInfo.length, res.nextPageToken);
            }
            imageDataList.forEach(item => {
                try {
                    item.ref.getDownloadURL().then(function (url) {
                        item.mediaLink = url;
                        dispatch({
                            type: ACTIONS.UPDATE_IMAGE,
                            payload: {id: search.id, data: item}
                        });

                    }).catch(function (error) {
                        // Handle any errors
                        console.log(error);
                    });
                } catch (error) {
                    console.log(error);
                }
            })

        }).catch(function (error) {
            // Uh-oh, an error occurred!
            dispatch({
                type: ACTIONS.ADD_SNACK,
                payload: {open: true, messageText: error.message, variant: 'error'}
            });
            search.callback(0, null);
        });
    } catch (error) {
        console.log(error);
        dispatch({
            type: ACTIONS.FETCH_IMAGES,
            payload:  {id: search.id, data: []}
        });
        search.callback(0, null);
    }
}

export const dispathAction = (data) => dispatch => {
    dispatch(data);
}


//Friends and followers

export const acceptFriendRequest = (request) => dispatch => {
    var currentDate = new Date();
    let newFriendObj = {username: request.friendRequest.username, dateCreated:currentDate};
    let myFriendObj = {username: request.currentUsername,  dateCreated:currentDate};
    var updates = {}
    var newFriendRef = db.ref(`users/${request.friendRequest.uid}`);
    var currentUserRef = db.ref(`users/${request.currentUserId}`);


    updates[`soci/${request.currentUserId}/friends/${request.friendRequest.uid}`] = newFriendObj;
    updates[`soci/${request.friendRequest.uid}/friends/${request.currentUserId}`] = myFriendObj;
    updates[`soci/${request.currentUserId}/friendrequests/${request.friendRequest.uid}`] = null;
    updates[`soci/${request.friendRequest.uid}/sentfriendrequests/${request.currentUserId}`] = null;
    db.ref().update(updates).then(() => {
        currentUserRef.transaction(function(user) {
            if (user) {
                if (user.friendsCount) {
                    user.friendsCount++;
                } else {
                    user.friendsCount = 1;
                }
            }
            return user;
        }, function(error, committed, snapshot) {
            if (error) {
                dispatch({
                    type: ACTIONS.ADD_SNACK,
                    payload: {open: true, messageText: error.message, variant: 'error'}
                });
            } else if (committed) {
                newFriendRef.transaction(function(newFriendUser) {
                    if (newFriendUser) {
                        if (newFriendUser.friendsCount) {
                            newFriendUser.friendsCount++;
                        } else {
                            newFriendUser.friendsCount = 1;
                        }
                    }
                    return newFriendUser;
                }, function(error, committed, snapshot) {
                    if (error) {
                        dispatch({
                            type: ACTIONS.ADD_SNACK,
                            payload: {open:true,messageText:error.message, variant:'error'}
                        });
                    }
                });
            }
        });
        dispatch({
            type: ACTIONS.ADD_SNACK,
            payload: {open:true,messageText:'messages.successfully accept', variant:'success'}
        });
    }).catch(error => {
        dispatch({
            type: ACTIONS.ADD_SNACK,
            payload: {open:true,messageText:error.message, variant:'error'}
        });
    });
};

export const declineFriendRequest = (request) => dispatch => {
    var updates = {}
    updates[`soci/${request.currentUserId}/friendrequests/${request.requestUserId}`] = null;
    updates[`soci/${request.requestUserId}/sentfriendrequests/${request.currentUserId}/decline`] = true;
    updates[`soci/${request.requestUserId}/sentfriendrequests/${request.currentUserId}/${FIELDS.UPDATED_DATE}`] = new Date();
    db.ref().update(updates).then(() => {
        dispatch({
            type: ACTIONS.ADD_SNACK,
            payload: {open:true,messageText:'messages.successfully decline', variant:'success'}
        });
    }).catch(error => {
        dispatch({
            type: ACTIONS.ADD_SNACK,
            payload: {open:true,messageText:error.message, variant:'error'}
        });
    });
};

export const unFriend = (request) => dispatch => {
    var updates = {}
    var friendUserRef = db.ref(`users/${request.friendUserId}`);
    var currentUserRef = db.ref(`users/${request.currentUserId}`);


    updates[`soci/${request.currentUserId}/friends/${request.friendUserId}`] = null;
    updates[`soci/${request.friendUserId}/friends/${request.currentUserId}`] = null;
    db.ref().update(updates).then(() => {
        currentUserRef.transaction(function(user) {
            if (user) {
                if (user.friendsCount) {
                    user.friendsCount--;
                } else {
                    user.friendsCount = 0;
                }
            }
            return user;
        }, function(error, committed, snapshot) {
            if (error) {
                dispatch({
                    type: ACTIONS.ADD_SNACK,
                    payload: {open: true, messageText: error.message, variant: 'error'}
                });
            } else if (committed) {
                friendUserRef.transaction(function(friendUser) {
                    if (friendUser) {
                        if (friendUser.friendsCount) {
                            friendUser.friendsCount--;
                        } else {
                            friendUser.friendsCount = 0;
                        }
                    }
                    return friendUser;
                }, function(error, committed, snapshot) {
                    if (error) {
                        dispatch({
                            type: ACTIONS.ADD_SNACK,
                            payload: {open:true,messageText:error.message, variant:'error'}
                        });
                    }
                });
            }
        });
        dispatch({
            type: ACTIONS.ADD_SNACK,
            payload: {open:true,messageText:'messages.successfully unfriend', variant:'success'}
        });
    }).catch(error => {
        dispatch({
            type: ACTIONS.ADD_SNACK,
            payload: {open:true,messageText:error.message, variant:'error'}
        });
    });
};


export const sendFollowRequest = (request) => dispatch => {
    var sendFollowRequest = fbFunctions.httpsCallable('sendFollowRequest');
    sendFollowRequest({followingUID:request.following.uid}).then(function (result) {
        dispatch({
            type: ACTIONS.ADD_SNACK,
            payload: {open:true,messageText:'messages.successfully sent follow', variant:'success'}
        });
        if(request.callback) {
            request.callback();
        }
    }).catch(error => {
        dispatch({
            type: ACTIONS.ADD_SNACK,
            payload: {open:true,messageText:error.message, variant:'error'}
        });
        if(request.callback) {
            request.callback();
        }
    });
};

export const acceptFollowingRequest = (request) => dispatch => {
    var acceptFollowRequest = fbFunctions.httpsCallable('acceptFollowRequest');
    acceptFollowRequest({requesterUID:request.followRequest.uid, isFollowing:request.isFollowing}).then(function (result) {
        dispatch({
            type: ACTIONS.ADD_SNACK,
            payload: {open:true,messageText:'messages.successfully accept follow', variant:'success'}
        });
        if(request.callback) {
            request.callback();
        }
    }).catch(error => {
        dispatch({
            type: ACTIONS.ADD_SNACK,
            payload: {open:true,messageText:error.message, variant:'error'}
        });
        if(request.callback) {
            request.callback();
        }
    });
};

export const declineFollowingRequest = (request) => dispatch => {
    var updates = {}
    updates[`soci/${request.currentUserId}/followerRequests/${request.requestUserId}/decline`] = true;
    updates[`soci/${request.currentUserId}/followerRequests/${request.requestUserId}/dateUpdated`] = new Date();

    updates[`soci/${request.requestUserId}/sentFollowingRequests/${request.currentUserId}/decline`] = true;
    updates[`soci/${request.requestUserId}/sentFollowingRequests/${request.currentUserId}/${FIELDS.UPDATED_DATE}`] = new Date();

    //var followingRequesterUserRef = db.ref(`users/${request.requestUserId}`);
    //var currentUserRef = db.ref(`users/${request.currentUserId}`);

    db.ref().update(updates).then(() => {
        // currentUserRef.transaction(function(user) {
        //     if (user) {
        //         if (user.followerRequestsCount) {
        //             user.followerRequestsCount--;
        //         } else {
        //             user.followerRequestsCount = 0;
        //         }
        //     }
        //     return user;
        // }, function(error, committed, snapshot) {
        //     if (error) {
        //         dispatch({
        //             type: ACTIONS.ADD_SNACK,
        //             payload: {open: true, messageText: error.message, variant: 'error'}
        //         });
        //     } else if (committed) {
        //         followingRequesterUserRef.transaction(function(followingRequester) {
        //             if (followingRequester) {
        //                 if (followingRequester.sentFollowingRequestsCount) {
        //                     followingRequester.sentFollowingRequestsCount--;
        //                 } else {
        //                     followingRequester.sentFollowingRequestsCount = 0;
        //                 }
        //             }
        //             return followingRequester;
        //         }, function(error, committed, snapshot) {
        //             if (error) {
        //                 dispatch({
        //                     type: ACTIONS.ADD_SNACK,
        //                     payload: {open:true,messageText:error.message, variant:'error'}
        //                 });
        //             }
        //         });
        //     }
        // });
        dispatch({
            type: ACTIONS.ADD_SNACK,
            payload: {open:true,messageText:'messages.successfully decline', variant:'success'}
        });
    }).catch(error => {
        dispatch({
            type: ACTIONS.ADD_SNACK,
            payload: {open:true,messageText:error.message, variant:'error'}
        });
    });
};


export const fetchFriends = uid => dispatch => {
    db.ref(`soci/${uid}/friends`).on('value', snapshot => {
        var friendsObject = snapshot.val();
        if(friendsObject) {
            var friendsList = Object.keys(friendsObject).map(key => ({
                ...friendsObject[key],
                uid: key,
            }));
            dispatch({
                type: ACTIONS.FETCH_FRIENDS,
                payload: friendsList
            });
        } else {
            dispatch({
                type: ACTIONS.FETCH_FRIENDS,
                payload: []
            });
        }

    });
};


export const fetchFollowingRequests = searchParams => dispatch => {
    var followerRequestsRef = null;

    if(searchParams.username){
        followerRequestsRef = db(`soci/${searchParams.uid}/followerRequests`).orderByChild("username").startAt(searchParams.username).limitToFirst(20)
    } else {
        followerRequestsRef = db.ref(`soci/${searchParams.uid}/followerRequests`).orderByChild("username").limitToFirst(20);
    }
    if(searchParams.last) {
        var dateUpdated = searchParams.last;
        followerRequestsRef = followerRequestsRef.startAfter(dateUpdated);
    }
    if(searchParams.limit && (typeof searchParams.limit === 'number')) {
        followerRequestsRef = followerRequestsRef.limit(searchParams.limit)
    }

    followerRequestsRef.on('value', snapshot => {
        var followerRequestsObject = snapshot.val();
        if (followerRequestsObject) {
            var followerRequestsList = Object.keys(followerRequestsObject).map(key => ({
                ...followerRequestsObject[key],
                uid: key,
            }));
            dispatch({
                type: ACTIONS.FETCH_FOLLOWER_REQUESTS,
                payload: {id:searchParams.uid, data:followerRequestsList}
            });
        } else {
            dispatch({
                type: ACTIONS.FETCH_FOLLOWER_REQUESTS,
                payload: {id:searchParams.uid, data:followerRequestsList}
            });
        }
    });
};

export const fetchFollowers = searchParams => dispatch => {
    var followersRef = firestoreDB.collection(`soci/${searchParams.uid}/followers`);
    if(searchParams.username && searchParams.username.length > 0){
        followersRef.where('username','>=', searchParams.username);
    }
    followersRef = followersRef.orderBy('username');
    if(searchParams.last) {
        followersRef = followersRef.startAfter(searchParams.last);
    }

    var limit = searchParams.limit ? searchParams.limit : QUERY_LIMIT ;
    followersRef = followersRef.limit(limit);
    followersRef.get().then(snapshot => {
        var followersList = [];
        snapshot.forEach(follower => {
            var data = follower.data();
            data.uid = follower.id;
            followersList.push(data);
        })
        if(searchParams.last) {
            dispatch({
                type: ACTIONS.ADD_FOLLOWERS,
                payload: {id:searchParams.uid, data:followersList}
            });
        } else {
            dispatch({
                type: ACTIONS.FETCH_FOLLOWERS,
                payload: {id: searchParams.uid, data: followersList}
            });
        }
        if(searchParams.callback){
            searchParams.callback(followersList.length)
        }
    });
};

export const fetchFollower = searchParams =>  db.ref(`users/${searchParams.uid}/followers/${searchParams.followerUID}`);

export const fetchFollowings = searchParams => dispatch => {
    var followingsRef = firestoreDB.collection(`soci/${searchParams.uid}/following`);
    if(searchParams.username && searchParams.username.length > 0){
        followingsRef.where('username','>=', searchParams.username);
    }
    followingsRef = followingsRef.orderBy('username');
    if(searchParams.last) {
        followingsRef = followingsRef.startAfter(searchParams.last);
    }

    var limit = searchParams.limit ? searchParams.limit : QUERY_LIMIT ;
    followingsRef = followingsRef.limit(limit);
    followingsRef.get().then(snapshot => {
        var followingList = [];
        snapshot.forEach(following => {
            var data = following.data();
            data.uid = following.id;
            followingList.push(data);
        });
        if(searchParams.last) {
            dispatch({
                type: ACTIONS.ADD_FOLLOWINGS,
                payload: {id:searchParams.uid, data:followingList}
            });
        } else {
            dispatch({
                type: ACTIONS.FETCH_FOLLOWINGS,
                payload: {id: searchParams.uid, data: followingList}
            });
        }
        if(searchParams.callback){
            searchParams.callback(followingList.length)
        }
    });
};

export const unFollow = (request) => dispatch => {
    var unFollowRequest = fbFunctions.httpsCallable('unFollow');
    unFollowRequest({followingUID:request.followingUserId}).then(function (result) {
        dispatch({
            type: ACTIONS.ADD_SNACK,
            payload: {open:true,messageText:'messages.successfully accept', variant:'success'}
        });
    }).catch(error => {
        dispatch({
            type: ACTIONS.ADD_SNACK,
            payload: {open:true,messageText:error.message, variant:'error'}
        });
    });
};

export const removeFollowInfoListeners = (uid) => dispatch => {
    db.ref(`soci/${uid}/friends`).off();
    db.ref(`soci/${uid}/followerRequests`).off();
    db.ref(`soci/${uid}/followers`).off();
    db.ref(`soci/${uid}/following`).off();
    dispatch({
        type: ACTIONS.FETCH_FOLLOWINGS,
        payload: []
    });
    dispatch({
        type: ACTIONS.FETCH_FOLLOWERS,
        payload: []
    });
    dispatch({
        type: ACTIONS.FETCH_FOLLOWER_REQUESTS,
        payload: []
    });
};

/**
 *
 * Lessons API
 *
 */

export const fetchLessons = (searchParams) => dispatch => {
    var lessonsRef = firestoreDB.collection(`${searchParams.path}`);
    if(searchParams.subject && searchParams.subject.length > 0 && searchParams.subject !== ALL){
        lessonsRef = lessonsRef.where('subject','==', searchParams.subject);
    }
    if(searchParams.gradeLevel && searchParams.gradeLevel.length > 0 && searchParams.gradeLevel !== ALL){
        lessonsRef = lessonsRef.where('gradeLevel','==', searchParams.gradeLevel);
    }
    if(searchParams.difficultyLevel && searchParams.difficultyLevel.length > 0 && searchParams.difficultyLevel !== ALL){
        lessonsRef = lessonsRef.where('difficultyLevel','==', searchParams.difficultyLevel);
    }
    lessonsRef = lessonsRef.orderBy('dateUpdated', 'desc');
    if(searchParams.last) {
        lessonsRef = lessonsRef.startAfter(searchParams.last);
    }

    var limit = searchParams.limit ? searchParams.limit : QUERY_LIMIT ;
    lessonsRef = lessonsRef.limit(limit);
    lessonsRef.get().then(snapshot => {
        var lessons = [];
        snapshot.forEach(function(lessonRef) {
            var lesson = lessonRef.data();
            lesson.id = lessonRef.id;
            lessons.push(lesson);
        });
        if(searchParams.last){
            dispatch({
                type: ACTIONS.ADD_LESSONS,
                payload: {id: searchParams.collectionId, data:lessons}
            });

        } else {
            dispatch({
                type: ACTIONS.FETCH_LESSONS,
                payload: {id: searchParams.collectionId, data: lessons}
            });
        }
        if(searchParams.callback){
            searchParams.callback(lessons.length);
        }
    });
};

export const removeLessonsListeners= (path) => dispatch => {
    //db.ref(`${path}/lessons`).off();
    //db.ref(`${PATHS.PUBLIC_BASE}/lessons`).off();
    dispatch({
        type: ACTIONS.FETCH_LESSONS,
        payload: null
    });
};

export const fetchLesson = (params) => dispatch => {
    firestoreDB.collection(params.path).doc(params.lessonId).get().then(doc => {
        if(doc.exists) {
            var lessonObject = doc.data();
            lessonObject.id = doc.id;
            if(lessonObject.questions) {
                var questionList = [];
                Object.keys(lessonObject.questions).map(function (key) {
                    var question = lessonObject.questions[key];
                    question.id = key;
                    if(question.type === QUESTION_TYPE.MULTIPLE_CHOICE && question.answers) {
                        var answerList = Object.keys(question.answers).map(key => ({
                            ...question.answers[key],
                            id: key,
                        }));
                        question.answers = answerList;
                    }
                    questionList.push(question);
                });
                lessonObject.questions = questionList;
            }
            if(lessonObject.assignees) {
                if(lessonObject.assignees.users) {
                    var assigneeUsersList = [];
                    var userKeys = new Set();
                    Object.keys(lessonObject.assignees.users).map(function (key) {
                        var assigneeUser = lessonObject.assignees.users[key];
                        assigneeUser.id = key;
                        // if(assigneeUser.submission) {
                        //     var answers = [];
                        //     assigneeUser.submission.answers.forEach(function (answer) {
                        //         if (Array.isArray(answer.answer)) {
                        //             answers[answer.questionId] = new Set(answer.answer);
                        //         } else {
                        //             answers[answer.questionId] = answer.answer;
                        //         }
                        //     })
                        //     assigneeUser.submission.answers = answers;
                        // }
                        assigneeUsersList.push(assigneeUser);
                        userKeys.add(key);
                    });

                    lessonObject.assignees.userKeys = userKeys;
                    lessonObject.assignees.users = assigneeUsersList;
                }
                if(lessonObject.assignees.groups) {
                    var assigneeGroupsList = [];
                    var groupKeys = new Set();
                    Object.keys(lessonObject.assignees.groups).map(function (key) {
                        var assigneeGroup = lessonObject.assignees.groups[key];
                        assigneeGroup.id = key;
                        assigneeGroupsList.push(assigneeGroup);
                        groupKeys.add(key);
                    });
                    lessonObject.assignees.groupKeys = groupKeys;
                    lessonObject.assignees.groups = assigneeGroupsList;
                }

            }
            dispatch({
                type: ACTIONS.FETCH_LESSON,
                payload: lessonObject
            });
            params.callback();
        } else {
            dispatch({
                type: ACTIONS.FETCH_LESSON,
                payload: null
            });
            params.callback();
        }

    });
};

export const fetchLessonAssignment = (lessonId, currentUserId) => dispatch => {
    firestoreDB.collection(`soci/${currentUserId}/lessons`).doc(lessonId).get().then(doc => {
        if (doc.exists) {
            var myAssignment = doc.data();
            dispatch({
                type: ACTIONS.FETCH_LESSON_ASSIGNMENT,
                payload: myAssignment
            })
        } else {
            dispatch({
                type: ACTIONS.FETCH_LESSON_ASSIGNMENT,
                payload: null
            });
        }
    })
};

export const fetchLessonAssignments = (currentUserId) => dispatch => {
    firestoreDB.collection(`soci/${currentUserId}/lessons`).get().then(assignments => {
        var myAssignments = {[ASSIGNMENT_STATUS.COMPLETED] : [], [ASSIGNMENT_STATUS.SUBMITTED] : [], [ASSIGNMENT_STATUS.ASSIGNED] : [], [ASSIGNMENT_STATUS.REASSIGNED] : [] };

        assignments.forEach(function(assigment) {
            var myAssignment = assigment.data();
            myAssignment.id = assigment.id;
            myAssignments[myAssignment.status].push(myAssignment);
        });
        dispatch({
            type: ACTIONS.FETCH_MY_LESSONS,
            payload: myAssignments
        })
    })
};


export const fetchLessonAssignees = (params) => dispatch => {
    firestoreDB.collection(`${params.path}/${params.lessonId}/assignees`).get().then(snapshot => {
        var assigneeUsersList = [];
        var userKeys = new Set();
        snapshot.forEach(function(assignee) {
            var assigneeUser = assignee.data();
            assigneeUser.id = assignee.id;
            assigneeUsersList.push(assigneeUser);
            userKeys.add(assignee.id);
        });
        dispatch({
            type: ACTIONS.FETCH_LESSON_ASSIGNEES,
            payload: {id:params.lessonId, data : {users:assigneeUsersList, userKeys:userKeys}}
        });
        if(params.callback) {
            params.callback();
        }

    });
};

export const removeLessonListen = (params) => dispatch => {
    db.ref(`${params.path}/lessons/${params.lessonId}`).off();
    db.ref(`${params.path}/lesson-assignees/${params.lessonId}`).off();
    dispatch({
        type: ACTIONS.FETCH_LESSON,
        payload: null
    });
};

export const createLesson = (data) => dispatch => {
    var createLesson = fbFunctions.httpsCallable('createLesson');
    createLesson(data).then(function (result) {
        debugger;
        data.lesson.id = result.data.lessonId;
        dispatch({
            type: ACTIONS.ADD_LESSON,
            payload: data.lesson
        });
        data.callback(true, data.lesson.id, data.lesson.organizationId);
    }).catch(error => {
        dispatch({
            type: ACTIONS.ADD_SNACK,
            payload: {open:true,messageText:error.message, variant:'error'}
        });
        data.callback(false);
    });
};

export const addQuestion = (data) => dispatch => {
    debugger;
    firestoreDB.collection(`${data.path}/${data.lessonId}/questions`).add(data.question).then((questionObject) => {
        debugger;
        var question = data.question;
        question.id = questionObject.id;
        dispatch({
            type: ACTIONS.ADD_SNACK,
            payload: {open:true, messageText:'question added', variant:'success'}
        });
        dispatch({
            type: ACTIONS.ADD_QUESTION,
            payload: question
        });
        data.callback(true);
    }).catch(error => {
        dispatch({
            type: ACTIONS.ADD_SNACK,
            payload: {open:true,messageText:error.message, variant:'error'}
        });
        data.callback(false);
    });
}

export const updateQuestion = (data) => dispatch => {
    debugger;
    firestoreDB.doc(`${data.path}/${data.lessonId}/questions/${data.questionId}`).set(data.question).then((questionObject) => {
        debugger;
        var question = data.question;
        question.id = data.questionId;
        dispatch({
            type: ACTIONS.ADD_SNACK,
            payload: {open:true, messageText:'question updated', variant:'success'}
        });
        dispatch({
            type: ACTIONS.UPDATE_QUESTION,
            payload: {data:question}
        });
        data.callback(true);
    }).catch(error => {
        dispatch({
            type: ACTIONS.ADD_SNACK,
            payload: {open:true,messageText:error.message, variant:'error'}
        });
        data.callback(false);
    });
}

/*export const addQuestion = (basePath, lessonId, question) => dispatch => {
    db.ref(`${basePath}/lesson-questions/${lessonId}`).push(question).then(() => {
        question.createSuccess = true;
        dispatch({
            type: ACTIONS.ADD_QUESTION,
            payload: question
        });
    }).catch(error => {
        question.createFailed = true;
        question.error = error;
        dispatch({
            type: ACTIONS.ADD_QUESTION,
            payload: question
        });
    });
}*/

export const fetchLessonQuestions = (params) => dispatch => {
    firestoreDB.collection(`${params.path}/${params.lessonId}/questions`).get().then(snapshot => {
        var questionList = [];
        snapshot.forEach(function(questionObject) {
            var question = questionObject.data();
            question.id = questionObject.id;
            if(question.type === QUESTION_TYPE.MULTIPLE_CHOICE && question.answers) {
                var answerList = Object.keys(question.answers).map(key => ({
                    ...question.answers[key],
                    id: key,
                }));
                question.answers = answerList;
            }
            questionList.push(question);
        });
        dispatch({
            type: ACTIONS.FETCH_QUESTIONS,
            payload: questionList
        });
        if(params.callback) {
            params.callback();
        }
    })
}

export const assignUsersToLesson = (data) => dispatch => {
    var assignUsers = fbFunctions.httpsCallable('assignUsers');
    assignUsers(data).then(function (result) {
        dispatch({
            type: ACTIONS.ADD_SNACK,
            payload: {open:true,messageText:'users added', variant:'success'}
        });
        if(data.callback){
            data.callback(true);
        }

    }).catch(error => {
        console.log(error);
        dispatch({
            type: ACTIONS.ADD_SNACK,
            payload: {open:true,messageText:error.message, variant:'error'}
        });
        if(data.callback) {
            data.callback(false);
        }
    });
}

export const reassignUser = (data) => dispatch => {
    var reassignUser = fbFunctions.httpsCallable('reassignUser');
    reassignUser(data).then(function (result) {
        debugger;
        dispatch({
            type: ACTIONS.ADD_SNACK,
            payload: {open:true,messageText:'user reassigned', variant:'success'}
        });
        if(data.callback){
            data.callback(true);
        }

    }).catch(error => {
        debugger;
        dispatch({
            type: ACTIONS.ADD_SNACK,
            payload: {open:true,messageText:error.message, variant:'error'}
        });
        if(data.callback){
            data.callback(false);
        }
    });
}

export const submitAnswers = (data) => dispatch => {
    var submitAnswers = fbFunctions.httpsCallable('submitAnswers');
    submitAnswers(data).then(function (result) {
        debugger;
        var message = result.data.submission.grade ? 'assignment graded' : 'assignment submitted';
        dispatch({
            type: ACTIONS.ADD_SNACK,
            payload: {open:true,messageText:message, variant:'success'}
        });
        if(data.callback) {
            data.callback(true);
        }

    }).catch(error => {
        console.log(error);
        debugger;
        dispatch({
            type: ACTIONS.ADD_SNACK,
            payload: {open:true,messageText:error.message, variant:'error'}
        });
        if(data.callback) {
            data.callback(false);
        }
    });

}

export const submitGrade = (data) => dispatch => {
    // var updates = {};
    // var currentDate = Date.now();
    //
    // var questionKeys = Object.keys(data.submission.answers);
    // for(var questionKey of questionKeys){
    //     updates[`${data.path}/lesson-assignees/${data.lessonId}/${data.assigneeId}/submission/answers/${questionKey}/isCorrect`] = data.submission.answers[questionKey].isCorrect;
    //     updates[`soci/${data.assigneeId}/lessons/${data.lessonId}/submissions/${data.submission.attempt}/answers/${questionKey}/isCorrect`] = data.submission.answers[questionKey].isCorrect;
    // }
    // //update lesson
    // updates[`${data.path}/lesson-assignees/${data.lessonId}/${data.assigneeId}/status/`] = ASSIGNMENT_STATUS.COMPLETED;
    // updates[`${data.path}/lesson-assignees/${data.lessonId}/${data.assigneeId}/${FIELDS.UPDATED_DATE}`] = currentDate;
    // updates[`${data.path}/lesson-assignees/${data.lessonId}/${data.assigneeId}/${FIELDS.UPDATED_BY_ID}`] = data.currentUserId;
    // updates[`${data.path}/lesson-assignees/${data.lessonId}/${data.assigneeId}/submission/${ASSIGNMENT_FIELD.GRADED_DATE}`] = currentDate;
    // updates[`${data.path}/lesson-assignees/${data.lessonId}/${data.assigneeId}/submission/${ASSIGNMENT_FIELD.GRADED_BY_ID}`] = data.currentUserId;
    // updates[`${data.path}/lesson-assignees/${data.lessonId}/${data.assigneeId}/submission/grade`] = data.submission.grade;
    //
    // //update assignee lesson
    // updates[`soci/${data.assigneeId}/lessons/${data.lessonId}/status/`] = ASSIGNMENT_STATUS.COMPLETED;
    // updates[`soci/${data.assigneeId}/lessons/${data.lessonId}/${FIELDS.UPDATED_DATE}`] = currentDate;
    // updates[`soci/${data.assigneeId}/lessons/${data.lessonId}/${FIELDS.UPDATED_BY_ID}`] = data.currentUserId;
    // updates[`soci/${data.assigneeId}/lessons/${data.lessonId}/submissions/${data.submission.attempt}/${ASSIGNMENT_FIELD.GRADED_DATE}`] = currentDate;
    // updates[`soci/${data.assigneeId}/lessons/${data.lessonId}/submissions/${data.submission.attempt}/${ASSIGNMENT_FIELD.GRADED_BY_ID}`] = data.currentUserId;
    // updates[`soci/${data.assigneeId}/lessons/${data.lessonId}/submissions/${data.submission.attempt}/grade`] = data.submission.grade;
    //
    // updates[`${data.path}/grades/${data.lessonId}/submissions/${data.submission.attempt}/grade`] = data.submission.grade;
    // db.ref().update(updates).then(() => {
    var submitGrade = fbFunctions.httpsCallable('submitGrade');
    submitGrade(data).then(function (result) {
        debugger;
        dispatch({
            type: ACTIONS.ADD_SNACK,
            payload: {open:true,messageText:'assignment graded', variant:'success'}
        });
        data.callback(true);

    }).catch(error => {
        debugger;
        dispatch({
            type: ACTIONS.ADD_SNACK,
            payload: {open:true,messageText:error.message, variant:'error'}
        });
        data.callback(false);
    });
}

export const getLesson = (lessonId) => db.ref(`lessons/${lessonId}`);

export const updateLessonContent = (data) => dispatch => {

    var lessonRef = firestoreDB.collection(data.path).doc(data.lessonId);
    return lessonRef.update({
        content: data.content,
        updatedById: data.currentUserId,
        dateUpdated: firestore.FieldValue.serverTimestamp()
    }).then(() => {
        dispatch({
            type: ACTIONS.ADD_SNACK,
            payload: {open:true,messageText:'content saved', variant:'success'}
        });
    }).catch(error => {
        dispatch({
            type: ACTIONS.ADD_SNACK,
            payload: {open:true,messageText:error.message, variant:'error'}
        });
    });
};


//GROUPS API
export const createGroup = (data) => dispatch => {
    var currentDate =  firestore.FieldValue.serverTimestamp();
    var batch = firestoreDB.batch();
    var initialMembership = {groupType: GROUP_TYPE.PUBLIC};
    if(data.group.organizationId) {
        initialMembership.groupType = GROUP_TYPE.ORGANIZATION;
        initialMembership.organizationId = data.group.organizationId;
    }
    var newGroupRef = firestoreDB.collection(data.path).doc();
    var groupId = newGroupRef.id;
    data.members.forEach(function (user) {
        var member =  user.profileImageUrl ? {username:user.label, isAdmin:true, profileImageUrl:user.profileImageUrl, dateCreated:currentDate} :
            {username:user.label, dateCreated:currentDate, isAdmin:true};
        var membership = {dateCreated:currentDate, groupType:initialMembership.groupType, groupName: data.group.name, isAdmin:true}
            membership.isAdmin = true;
            member.isAdmin = true;
        if(initialMembership.organizationId) {
            membership.organizationId = initialMembership.organizationId;
        } else {
            membership.groupType = GROUP_TYPE.PUBLIC;
        }

        var userGroupRef = firestoreDB.doc(`soci/${user.value}/groups/${groupId}`);
        var memberRef = firestoreDB.doc(`${data.path}/${groupId}/members/${user.value}`);

        batch.set(userGroupRef, membership);
        batch.set(memberRef, member);


    });
    data.group.dateCreated = currentDate;
    data.group.dateUpdated = currentDate;
    batch.set(newGroupRef, data.group);
    return batch.commit().then(() => {
        debugger;
        dispatch({
            type: ACTIONS.ADD_SNACK,
            payload: {open: true, messageText: 'group created', variant: 'success'}
        });
        data.callback(true, groupId, data.group.organizationId);
    }).catch(error => {
        dispatch({
            type: ACTIONS.ADD_SNACK,
            payload: {open: true, messageText: error.message, variant: 'error'}
        });

        data.callback(false);
    });
}

export const fetchGroups= (searchParams) => dispatch => {
    firestoreDB.collection(`${searchParams.path}`).get().then(snapshot => {
        debugger;
        var groupList =  [];
        snapshot.forEach(doc => {
            var docData = doc.data();
            docData.id = doc.id;
            groupList.push(docData);
        });
        dispatch({
            type: ACTIONS.FETCH_GROUPS,
            payload: {id:searchParams.collectionId, data:groupList}
        });
        if(searchParams.callback) {
            searchParams.callback(groupList.length)
        }
    });
};

export const fetchGroup = (searchParams) => dispatch => {
    return firestoreDB.doc(`${searchParams.path}`).onSnapshot(snapshot => {
        debugger;
        if(snapshot.exists) {
            var groupObject = snapshot.data();
            groupObject.id = snapshot.id;
            firestoreDB.collection(`${searchParams.path}/members`).get().then(membersSnapshot => {
                debugger;
                var membersList = [];
                var memberKeys = new Set();
                membersSnapshot.forEach(doc => {
                    var member = doc.data();
                    member.id = doc.id;
                    membersList.push(member);
                    memberKeys.add( doc.id);
                    debugger;
                    if (doc.id === searchParams.uid) {
                        groupObject.myMembership = member;
                    }
                });
                groupObject.memberKeys = memberKeys;
                groupObject.members = membersList;
                dispatch({
                    type: ACTIONS.FETCH_GROUP,
                    payload: groupObject
                });
            });
            if(searchParams.callback){
                searchParams.callback(true);
            }
        } else {
            dispatch({
                type: ACTIONS.ADD_SNACK,
                payload: {open:true,messageText:'group does not exists', variant:'info'}
            });
            if(searchParams.callback){
                searchParams.callback(false);
            }
        }
    });
};

export const fetchUserGroups =  (searchParams) =>  async dispatch => {
    let results = await firestoreDB.collection(`soci/${searchParams.userId}/groups`).get().then(snapshot => {
        var groupList =  [];
        snapshot.forEach(doc => {
            var docData = doc.data();
            docData.id = doc.id;
            groupList.push(docData);
        });
       return groupList;
    });

    return results;
};

export const fetchMyGroups= (searchParams) => dispatch => {
    firestoreDB.collection(`soci/${searchParams.userId}/groups`).get().then(snapshot => {
        var groupList =  [];
        snapshot.forEach(doc => {
            var docData = doc.data();
            docData.id = doc.id;
            groupList.push(docData);
        });
        dispatch({
            type: ACTIONS.FETCH_MY_GROUPS,
            payload: groupList
        });
    });
};

export const addMembersToGroup = (data) => dispatch => {
    var currentDate =  firestore.FieldValue.serverTimestamp();
    var batch = firestoreDB.batch();
    data.usersToAdd.forEach(function (user) {
        var membership = {dateCreated:currentDate, name:data.groupName};
        if(data.organizationId.toLowerCase() === GROUP_TYPE.PUBLIC.toLowerCase()) {
            membership.groupType = GROUP_TYPE.PUBLIC;
        } else {
            membership.groupType = GROUP_TYPE.ORGANIZATION;
            membership.organizationId = data.organizationId;
        }

        var member =  user.profileImageUrl ? {username:user.label, profileImageUrl:user.profileImageUrl, dateCreated:currentDate} :
            {username:user.label, dateCreated:currentDate};

        var userGroupRef = firestoreDB.doc(`soci/${user.value}/groups/${data.groupId}`);
        var memberRef = firestoreDB.doc(`${data.path}/members/${user.value}`);

        batch.set(userGroupRef, membership);
        batch.set(memberRef, member);


    });
    return batch.commit().then(() => {
        debugger;
        dispatch({
            type: ACTIONS.ADD_SNACK,
            payload: {open: true, messageText: 'users added', variant: 'success'}
        });
        data.callback(true);
    }).catch(error => {
        dispatch({
            type: ACTIONS.ADD_SNACK,
            payload: {open: true, messageText: error.message, variant: 'error'}
        });

        data.callback(false);
    });
}

export const removeGroupsListeners = (uid) => dispatch => {
    db.ref(`soci/${uid}/groups`).off();
    db.ref(`groups`).off();
};
export const removeUserGroupsListener = (uid) => dispatch => {
    db.ref(`soci/${uid}/groups`).off();
    db.ref(`groups`).off();
};
export const removeGroupListener = (data) => dispatch => {
    db.ref(`${data.path}/groups/${data.groupId}`).off();
    db.ref(`${data.path}/group-members/${data.groupId}`).off();
    db.ref(`group-post/${data.organizationId}/${data.groupId}`).off();
    // dispatch({
    //     type: ACTIONS.REMOVE_POSTS,
    //     payload: {id:data.groupId}
    // });
};

export const updateGroupAttribute = (dataPath, groupId, attribute, value) => dispatch => {
    var updates = {};
    updates[`${dataPath}/groups/${groupId}/${attribute}`] = value;
    db.ref().update(updates).then(() => {

    }).catch(error => {
        dispatch({
            type: ACTIONS.ADD_SNACK,
            payload: {open:true,messageText:error.message, variant:'error'}
        });
    });
};

/**
 *  POSTS API
 *
 */

// export const fetchPosts = (searchParams) => dispatch => {
//     var postRef = null;
//     if(POST_TYPE.GROUP === searchParams.type){
//         postRef = firestoreDB.collection(POST_PATHS.GROUP).doc(searchParams.orgId).collection(searchParams.groupId);
//     } else if(POST_TYPE.LESSON === searchParams.type){
//
//     } else if(POST_TYPE.ORGANIZATION === searchParams.type) {
//         postRef = firestoreDB.collection(PATHS.ORGANIZATION_BASE).doc(searchParams.orgId).collection('posts');
//     } else {
//         postRef = firestoreDB.collection(POST_PATHS.PERSONAL);
//     }
//     debugger;
//     return postRef.orderBy('dateUpdated', 'desc').onSnapshot(snapshot => {
//         var postList = [];
//         snapshot.forEach(function(child) {
//             var post = child.data();
//             post.id = child.id;
//             debugger;
//             if(POST_TYPE.PERSONAL === searchParams.type) {
//                 if(post.createdById === searchParams.currentUserId || hasUserInObjectList(searchParams.followings,post.createdById)) {
//                     postList.push(post);
//                 }
//             } else {
//                 postList.push(post);
//             }
//         });
//         dispatch({
//             type: ACTIONS.FETCH_POSTS,
//             payload: postList
//         });
//     });
// };

export const fetchPosts = (searchParams) => dispatch => {
    var fetchPostFunction = fbFunctions.httpsCallable('fetchPosts');
    fetchPostFunction(searchParams).then(function (result) {
        debugger;
        var id = POST_TYPE.GROUP == searchParams.type ? searchParams.groupId : POST_TYPE.ORGANIZATION == searchParams.type ? searchParams.orgId : 'feeds';
        var postsInfo = {id:id, posts:result.data.postList};
        if(searchParams.last && postsInfo.posts.length > 0) {
            dispatch({
                type: ACTIONS.ADD_POSTS,
                payload: postsInfo
            });
        } else {
            dispatch({
                type: ACTIONS.FETCH_POSTS,
                payload: postsInfo
            });
        }

        if(searchParams.callback) {
            searchParams.callback(postsInfo.posts.length);
        }

    }).catch(error => {
        if(searchParams.callback) {
            searchParams.callback(0);
        }
        dispatch({
            type: ACTIONS.ADD_SNACK,
            payload: {open: true, messageText: error.message, variant: 'error'}
        });
    });
};


export const deletePost= (path, postId, collectionId) => dispatch => {
    firestoreDB.doc(`${path}/${postId}`).delete().then(function() {
        dispatch({
            type: ACTIONS.REMOVE_POST,
            payload: {id:collectionId, postId:postId}
        });
        dispatch({
            type: ACTIONS.REMOVE_POST,
            payload: {id:'feeds', postId:postId}
        });
    }).catch(function(error) {
        dispatch({
            type: ACTIONS.ADD_SNACK,
            payload: {open: true, messageText: error.message, variant: 'error'}
        });
    });
};



export const FetchPostsByUserId = (searchParams) => dispatch => {
    var postRef = firestoreDB.collection(`posts`).where('createdById', '==', searchParams.userId).orderBy('dateUpdated', 'desc');
    if(searchParams.last) {
        var dateUpdated = searchParams.last;
        postRef = postRef.startAfter(dateUpdated);
    }
    if(searchParams.limit && (typeof searchParams.limit === 'number')) {
        postRef = postRef.limit(searchParams.limit)
    }


    postRef.get().then(snapshot => {
        debugger;
        var postList = [];
        snapshot.forEach(function(child) {
            var post = child.data();
            post.id = child.id;
            postList.push(post);
        });
        if(searchParams.last && postList.length > 0) {
            dispatch({
                type: ACTIONS.ADD_POSTS,
                payload: {id: searchParams.userId, posts:postList}
            });
        } else {
            dispatch({
                type: ACTIONS.FETCH_POSTS,
                payload: {id: searchParams.userId, posts:postList}
            });
        }
        if(searchParams.callback) {
            searchParams.callback(postList.length);
        }
    });

};

export const fetchOrganizationPosts = (searchParams) => dispatch => {
    return firestoreDB.collection(PATHS.ORGANIZATION_BASE).doc(searchParams.organizationId).collection('posts').orderBy('dateUpdated', 'desc').onSnapshot(snapshot => {
        var postList = [];
        snapshot.forEach(function(child) {
            var post = child.data();
            post.id = child.id;
            postList.push(post);
        });
        dispatch({
            type: ACTIONS.FETCH_POSTS,
            payload: postList
        });
    });
};

export const fetchGroupPosts = (searchParams) => dispatch => {
    return firestoreDB.collection(POST_PATHS.GROUP).doc(searchParams.organizationId).collection(searchParams.groupId).orderBy('dateUpdated', 'desc').onSnapshot(snapshot => {
        var postList = [];
        snapshot.forEach(function(child) {
            var post = child.data();
            post.id = child.id;
            postList.push(post);
        });
        dispatch({
            type: ACTIONS.FETCH_POSTS,
            payload: postList
        });
    });
};

export const createPost = (data) => dispatch => {
    var postRef = null;
    var collectionId = null;
    if(POST_TYPE.GROUP === data.type){
        postRef = firestoreDB.collection(POST_PATHS.GROUP).doc(data.orgId).collection(data.groupId).doc();
        collectionId = data.groupId;
    } else if(POST_TYPE.LESSON === data.type){

    } else if(POST_TYPE.ORGANIZATION === data.type) {
        postRef = firestoreDB.collection(PATHS.ORGANIZATION_BASE).doc(data.orgId).collection('posts').doc();
        collectionId = data.orgId;
    } else {
        postRef = firestoreDB.collection(POST_PATHS.PERSONAL).doc();
        collectionId = data.currentUserId;
    }
    var currentDate = firestore.FieldValue.serverTimestamp();
    data.post.dateCreated = currentDate;
    data.post.dateUpdated = currentDate;
    var post = {...data.post, dateCreated: currentDate, dateUpdated:currentDate};
    debugger;
    postRef.set(post).then(() => {
        post.id = postRef.id;
        post.dateCreated = Date.now();
        var postsInfo = {id:collectionId, posts: [post], top:true};
        dispatch({
            type: ACTIONS.ADD_POSTS,
            payload: postsInfo
        });
        debugger;
        if(POST_TYPE.PERSONAL === data.type) {
            dispatch({
                type: ACTIONS.ADD_POSTS,
                payload: {id:'feeds', posts: [post], top:true}
            });
        }
        debugger;
        if(data.callback){
            data.callback(true);
        }
    }).catch(error => {
        dispatch({
            type: ACTIONS.ADD_SNACK,
            payload: {open:true,messageText:error.message, variant:'error'}
        });
        data.callback(false);
    });
};

export const updatePostLike = (data) => dispatch => {
    var postRef = null;
    if(POST_TYPE.GROUP === data.type){
        postRef = firestoreDB.collection(POST_PATHS.GROUP).doc(data.orgId).collection(data.groupId).doc(data.postId);
    } else if(POST_TYPE.LESSON === data.type){

    } else if(POST_TYPE.ORGANIZATION === data.type) {
        postRef = firestoreDB.collection(PATHS.ORGANIZATION_BASE).doc(data.orgId).collection('posts').doc(data.postId);
    } else {
        postRef = firestoreDB.collection(POST_PATHS.PERSONAL).doc(data.postId);
    }
    var userKey = createUserLikeKey(data.currentUserId, data.currentUsername);
    if(data.isLiked) {
        postRef.update({likes: firestore.FieldValue.arrayUnion(userKey)}).then(function () {
            postRef.get().then(postDoc => {
                var post =  postDoc.data();
                post.id = postDoc.id;
                dispatch({
                    type: ACTIONS.UPDATE_POST,
                    payload: {id: data.collectionId, post:post}
                });

                if(POST_TYPE.PERSONAL === data.type) {
                    dispatch({
                        type: ACTIONS.UPDATE_POST,
                        payload: {id:'feeds', post:post}
                    });
                }
            })
        }).catch(error => {
            dispatch({
                type: ACTIONS.ADD_SNACK,
                payload: {open:true,messageText:error.message, variant:'error'}
            });
        });
    } else {
        postRef.update({likes: firestore.FieldValue.arrayRemove(userKey)}).then(function () {
            postRef.get().then(postDoc => {
                var post =  postDoc.data();
                post.id = postDoc.id;
                dispatch({
                    type: ACTIONS.UPDATE_POST,
                    payload: {id: data.collectionId, post:post}
                });

                if(POST_TYPE.PERSONAL === data.type) {
                    dispatch({
                        type: ACTIONS.UPDATE_POST,
                        payload: {id:'feeds', post:post}
                    });
                }
            })
        }).catch(error => {
            dispatch({
                type: ACTIONS.ADD_SNACK,
                payload: {open:true,messageText:error.message, variant:'error'}
            });
        });
    }

};

export const removePostsListener = (userId) => dispatch => {
    // dispatch({
    //     type: ACTIONS.REMOVE_POSTS,
    //     payload: {id:userId}
    // });
};

export const removeOrganizationPostsListener = (organizationId) => dispatch => {
    // dispatch({
    //     type: ACTIONS.REMOVE_POSTS,
    //     payload: {id:organizationId}
    // });
};

/**
 *  CHATS API
 *
 */

export const fetchMyChats = (searchParams) => dispatch => {
    var chatsRef = firestoreDB.collection(`soci/${searchParams.currentUserId}/chats`).orderBy(FIELDS.UPDATED_DATE, 'desc');
    if(searchParams.last) {
        chatsRef = chatsRef.startAfter(searchParams.last);
    }
    debugger;
    var limit = searchParams.limit ? searchParams.limit : QUERY_LIMIT ;
    chatsRef = chatsRef.limit(limit);
    chatsRef.onSnapshot(snapshot => {
        debugger;
        var chatList = [];
        snapshot.forEach(chatDoc => {
            var chat = chatDoc.data();
            chat.id = getChatId(chatDoc.id, searchParams.currentUserId);
            chatList.push(chat);
        });
        if(searchParams.last) {
            dispatch({
                type: ACTIONS.ADD_MY_CHATS,
                payload: chatList
            });
        } else {
            dispatch({
                type: ACTIONS.FETCH_MY_CHATS,
                payload: chatList
            });
        }
        if(searchParams.callback){
            searchParams.callback(chatList.length)
        }
    });
}

export const createChat = (data) => dispatch => {
    debugger;
    firestoreDB.doc(`soci/${data.currentUser.id}/chats/${data.participant.userId}`).get().then(function (snapshot) {
        debugger;
        if(snapshot.exists){
            var chat = snapshot.data();
            chat.id = getChatId(data.participant.userId, data.currentUser.id);
            // dispatch({
            //     type: ACTIONS.ADD_MY_CHATS,
            //     payload: [chat]
            // });
            data.callback(true, chat);
        } else {
            var currentDate = firestore.FieldValue.serverTimestamp();
            var batch = firestoreDB.batch();

            var participant = {participantUserId: data.participant.userId, participantUsername: data.participant.username, dateUpdated:currentDate, dateCreated:currentDate};
            if(data.participant.profileImageUrl) {
                participant.participantProfileImageUrl = data.participant.profileImageUrl;
            }

            var myParticipantObject = {participantUserId: data.currentUser.id, participantUsername: data.currentUser.username, dateUpdated:currentDate, dateCreated:currentDate};
            if(data.currentUser.profileImageUrl) {
                myParticipantObject.participantProfileImageUrl = data.currentUser.profileImageUrl;
            }

            var myChatRef = firestoreDB.doc(`soci/${data.currentUser.id}/chats/${data.participant.userId}`);
            var participantChatRef = firestoreDB.doc(`soci/${data.participant.userId}/chats/${data.currentUser.id}`);

            batch.set(myChatRef, participant);
            batch.set(participantChatRef, myParticipantObject);
            batch.commit().then(() => {
                participant.id = getChatId(data.participant.userId, data.currentUser.id);
                data.callback(true, participant);
            }).catch(error => {
                dispatch({
                    type: ACTIONS.ADD_SNACK,
                    payload: {open:true,messageText:error.message, variant:'error'}
                });
                data.callback(false);
            });
        }

    }).catch(error => {
        dispatch({
            type: ACTIONS.ADD_SNACK,
            payload: {open: true, messageText: error.message, variant: 'error'}
        });
        data.callback(false);
    });
}

export const fetchChatMessages= (searchParams) => dispatch => {
    var chatMessagesRef = firestoreDB.collection(`chat-messages/${searchParams.chatId}/messages`).orderBy(FIELDS.CREATED_DATE, 'desc');
    //var chatMessagesRef = firestoreDB.collection(`chat-messages/${searchParams.chatId}/messages`);
    debugger;
    if(searchParams.last) {
        chatMessagesRef = chatMessagesRef.startAfter(searchParams.last);
    }
    var limit = searchParams.limit ? searchParams.limit : QUERY_LIMIT ;
    chatMessagesRef = chatMessagesRef.limit(limit);
    chatMessagesRef.onSnapshot(snapshot => {
        debugger;
        var chatMessages = [];
        snapshot.forEach(messageDoc => {
            var message = messageDoc.data();
            message.id = messageDoc.id;
            chatMessages.push(message);
        })
        var initial = true;
        if(searchParams.last) {
            initial = false;
            dispatch({
                type: ACTIONS.ADD_CHAT_MESSAGES,
                payload: {id:searchParams.chatId, data:chatMessages}
            });
        } else {
            dispatch({
                type: ACTIONS.FETCH_CHAT_MESSAGES,
                payload: {id:searchParams.chatId, data:chatMessages}
            });
        }
        if(searchParams.callback){
            searchParams.callback(chatMessages.length, initial);
        }
    });
};

export const sendMessage= (data) => dispatch => {
    debugger;
    var chatId = getChatId(data.message.senderId, data.message.receiverId);
    var batch = firestoreDB.batch();
    var newMessageRef = firestoreDB.collection(`chat-messages/${chatId}/messages`).doc();
    var currentDate = firestore.FieldValue.serverTimestamp();
    var senderRef = firestoreDB.doc(`soci/${data.message.senderId}/chats/${data.message.receiverId}`);
    var recieverRef = firestoreDB.doc(`soci/${data.message.receiverId}/chats/${data.message.senderId}`);
    var chatUpdates = {lastMessage:data.messagePreview, updatedById:data.message.senderId, dateUpdated:currentDate};
    batch.update(senderRef,{...chatUpdates, read:true});
    batch.update(recieverRef,{...chatUpdates, read:false});

    batch.set(newMessageRef,{...data.message, dateCreated:currentDate});
    batch.commit().then(() => {
        data.callback(true);
    }).catch(error => {
        dispatch({
            type: ACTIONS.ADD_SNACK,
            payload: {open:true,messageText:error.message, variant:'error'}
        });
        data.callback(false);
    });
};

export const removeMyChatsListener = (uid) => dispatch => {
    db.ref(`soci/${uid}/chats`).off();
};

export const removeChatMessageListener = (chatId) => dispatch => {
    db.ref(`chats-messages/${chatId}`).off();
};
/**
 * ORGANIZATION API
 */

export const createOrganization = (data) => dispatch => {
   var createOrganization = fbFunctions.httpsCallable('createOrganization');
    createOrganization(data.organization).then(function (result) {
        data.callback(true, result.data.organizationId);
    }).catch(function(error) {
        dispatch({
            type: ACTIONS.ADD_SNACK,
            payload: {open:true,messageText:error.message, variant:'error'}
        });
        data.callback(false);
    });
};

export const generateLinkCode = (data) => dispatch => {
    var generateLinkCode = fbFunctions.httpsCallable('generateLinkCode');
    generateLinkCode({organizationId:data.organizationId, codeType:data.codeType}).then(function (result) {
        const message = 'coded generated: ' + result.data.code;
        dispatch({
            type: ACTIONS.ADD_SNACK,
            payload: {open:true,messageText:message, variant:'success'}
        });
        if(data.callback) {
            data.callback(false);
        }
    }).catch(function(error) {
        dispatch({
            type: ACTIONS.ADD_SNACK,
            payload: {open:true,messageText:error.message, variant:'error'}
        });
       if(data.callback) {
           data.callback(false);
       }
    });
};

export const fetchOrganizationInfo = (search) => dispatch => {
    firestoreDB.collection('organizations').doc(search.organizationId).onSnapshot(orgDoc => {
        if(orgDoc.exists) {
            var organizationInfo =  orgDoc.data();
            organizationInfo.id = orgDoc.id;
            dispatch({
                type: ACTIONS.FETCH_ORGANIZATION_INFO,
                payload: organizationInfo
            });
        } else {
            dispatch({
                type: ACTIONS.FETCH_ORGANIZATION_INFO,
                payload: null
            });
        }
        if(search.callback) {
            search.callback();
        }
    });
};


export const fetchOrganizationLinkCodes = (searchParams) => dispatch => {
    return firestoreDB.collection('link-codes').where('organizationId','==', searchParams.organizationId).onSnapshot(snapshot => {
        var adList = [];
        var prList = [];
        var psList = [];
        snapshot.forEach(function (child) {
            var linkCode = child.data();
            linkCode.id = child.id;
            if(linkCode.users) {
                var userList = [];
                Object.keys(linkCode.users).forEach( function (uid) {
                    var user = linkCode.users[uid];
                    user.id = uid;
                    if(linkCode.student && linkCode.student.userId === uid) {
                        user.isStudent = true;
                    }
                    userList.push(user);
                })
                linkCode.users = userList;
            }
            if (LINK_CODES_TYPE.ADMINISTRATOR === linkCode.type) {
                adList.push(linkCode);
            } else if (LINK_CODES_TYPE.PROFESSOR === linkCode.type) {
                prList.push(linkCode);
            } else if (LINK_CODES_TYPE.PARENT_STUDENT === linkCode.type) {
                psList.push(linkCode);
            }
        });
        dispatch({
            type: ACTIONS.FETCH_ORGANIZATION_LINK_CODES,
            payload: {linkCodes: {'administrator': adList, 'professor': prList, 'parentStudent': psList}}
        });
    });
};

export const fetchOrganizationMemberRequests = searchParams => dispatch => {
    var memberRequestsRef = firestoreDB.collection(`organizations/${searchParams.organizationId}/member-requests`);
    if(searchParams.username && searchParams.username.length > 0){
        memberRequestsRef = memberRequestsRef.where('username','>=', searchParams.username);
    }
    memberRequestsRef = memberRequestsRef.orderBy('username');
    if(searchParams.last) {
        memberRequestsRef = memberRequestsRef.startAfter(searchParams.last);
    }
    var limit = searchParams.limit ? searchParams.limit : QUERY_LIMIT ;
    memberRequestsRef = memberRequestsRef.limit(limit);
    memberRequestsRef.get().then(snapshot => {
        var memberRequestsList = [];
        snapshot.forEach(member => {
            var data = member.data();
            data.uid = member.id;
            memberRequestsList.push(data);
        });
        if(searchParams.last) {
            dispatch({
                type: ACTIONS.ADD_ORGANIZATION_MEMBER_REQUESTS,
                payload: {id:searchParams.organizationId, data:memberRequestsList}
            });
        } else {
            dispatch({
                type: ACTIONS.FETCH_ORGANIZATION_MEMBER_REQUESTS,
                payload: {id:searchParams.organizationId, data:memberRequestsList}
            });
        }
    });
};

export const fetchOrganizationMembers = searchParams => dispatch => {
    var membersRef = firestoreDB.collection(`organizations/${searchParams.organizationId}/members`);
    if(searchParams.username && searchParams.username.length > 0){
        membersRef = membersRef.where('username','>=', searchParams.username);
    }
    membersRef = membersRef.orderBy('username');
    if(searchParams.last) {
        membersRef = membersRef.startAfter(searchParams.last);
    }

    var limit = searchParams.limit ? searchParams.limit : QUERY_LIMIT ;
    membersRef = membersRef.limit(limit);
    membersRef.get().then(snapshot => {
        var membersList = [];
        snapshot.forEach(member => {
            var data = member.data();
            data.uid = member.id;
            membersList.push(data);
        });
        if(searchParams.last) {
            dispatch({
                type: ACTIONS.ADD_ORGANIZATION_MEMBERS,
                payload: {id:searchParams.organizationId, data:membersList}
            });
        } else {
            dispatch({
                type: ACTIONS.FETCH_ORGANIZATION_MEMBERS,
                payload: {id:searchParams.organizationId, data:membersList}
            });
        }
    });
};

export const verifyOrganizationAttribute = (data) => dispatch => {
    var verifyOrganization = fbFunctions.httpsCallable('verifyOrganization');
    verifyOrganization(data).then(function (result) {
        debugger;
        if(data.callback) {
            data.callback();
        }

    }).catch(error => {
        debugger;
        dispatch({
            type: ACTIONS.ADD_SNACK,
            payload: {open:true,messageText:error.message, variant:'error'}
        });
        if(data.callback) {
            data.callback();
        }
    });
};

export const sendJoinOrgRequest = (data) => dispatch => {
    if(data.organizationId) {
        var joinOrganization = fbFunctions.httpsCallable('joinOrganization');
        joinOrganization({organizationId: data.organizationId}).then(function (result) {
            if (result.data.success) {
                dispatch({
                    type: ACTIONS.ADD_SNACK,
                    payload: {open: true, messageText: 'join request sent', variant: 'success'}
                });
            }
            if(data.callback) {
                data.callback();
            }

        }).catch(function (error) {
            dispatch({
                type: ACTIONS.ADD_SNACK,
                payload: {open: true, messageText: error.message, variant: 'error'}
            });
            if(data.callback) {
                data.callback();
            }
        });
    }
};

export const acceptOrgMemberRequest = (data) => dispatch => {

    if(data && data.organizationId &&  data.memberRequest.uid) {
        var acceptOrgMemberReq = fbFunctions.httpsCallable('acceptOrgMemberReq');
        acceptOrgMemberReq({organizationId: data.organizationId, memberId:  data.memberRequest.uid}).then(function (result) {
            var member = result.data.membership;
            member.id = data.memberRequest.uid;
            debugger;
            dispatch({
                type: ACTIONS.ADD_ORGANIZATION_MEMBERS,
                payload: {id:data.organizationId, data:[member]}
            });
            dispatch({
                type: ACTIONS.REMOVE_MEMBER_REQUESTS,
                payload: {id:data.organizationId, memberId:data.memberRequest.uid}
            });
            if(data.callback) {
                data.callback();
            }
        }).catch(function (error) {
            debugger;
            dispatch({
                type: ACTIONS.ADD_SNACK,
                payload: {open: true, messageText: error.message, variant: 'error'}
            });
            if(data.callback) {
                data.callback();
            }
        });
    }
};

export const applyLinkCode = (data) => dispatch => {
    var acceptOrgMemberByCode = fbFunctions.httpsCallable('acceptOrgMemberByCode');
    acceptOrgMemberByCode({linkCode: data.code}).then(function (result) {
        dispatch({
            type: ACTIONS.ADD_SNACK,
            payload: {open: true, messageText: 'joined organization', variant: 'success'}
        });
        if(data.callback) {
            data.callback(true, result.data.organizationId);
        }
    }).catch(function (error) {
        dispatch({
            type: ACTIONS.ADD_SNACK,
            payload: {open: true, messageText: error.message, variant: 'error'}
        });
        if(data.callback) {
            data.callback(false);
        }
    });
};
export const removeOrganizationListener = (organizationId) => dispatch => {
    db.ref(`organizations/${organizationId}/info`).off();
   // db.ref(`organization-posts/${organizationId}`).off();
   //  dispatch({
   //      type: ACTIONS.FETCH_ORGANIZATION_INFO,
   //      payload: {}
   //  });
    // dispatch({
    //     type: ACTIONS.REMOVE_POSTS,
    //     payload: {id:organizationId}
    // });
};

export const removeOrganizationMembersListeners = (organizationId) => dispatch => {
    db.ref(`organizations/${organizationId}/members`).off();
    db.ref(`organizations/${organizationId}/member-requests`).off();
    dispatch({
        type: ACTIONS.FETCH_ORGANIZATION_MEMBERS,
        payload: []
    });
    dispatch({
        type: ACTIONS.FETCH_ORGANIZATION_MEMBER_REQUESTS,
        payload: []
    });
};



/**
 *  UTILS API
 *
 */

export const openSnack = (snack) => dispatch => {
    if (snack) {
        dispatch({
            type: ACTIONS.ADD_SNACK,
            payload: snack
        });
    }
};

export const closeSnack = (variant) => dispatch => {
    dispatch({
            type: ACTIONS.ADD_SNACK,
            payload: {open:false,messageText:'', variant:variant}
        });
};

/**
 * @param data : {path, attribute, value, currentUserId, successMessage }
 * @returns {Function}
 */
export const updateDocumentAttribute = (data) => dispatch => {
    var docRef = firestoreDB.doc(data.path);
    return docRef.update({
        [data.attribute]: data.value,
        updatedById: data.currentUserId,
        updatedByUsername: data.currentUsername,
        dateUpdated: firestore.FieldValue.serverTimestamp()
    }).then(() => {
        if(data.successMessage) {
            dispatch({
                type: ACTIONS.ADD_SNACK,
                payload: {open: true, messageText: data.successMessage, variant: 'success'}
            });
        }
        if(data.callback) {
            data.callback(true, data)
        }
    }).catch(error => {
        if(data.callback) {
            data.callback(false, data)
        }
        dispatch({
            type: ACTIONS.ADD_SNACK,
            payload: {open:true,messageText:error.message, variant:'error'}
        });
    });
};

/**
 * 
 * @param {*} data: {
 *              emails: [example@gmail.com] ,
 *              lang: "en"
 *            }
 * @param {*} successCallback callback function for successful execution
 * @param {*} errorCallback callback function for failed execution
 */
export const sendFriendInvite = (data, successCallback, errorCallback) => {
    // Get invite friends function
    const inviteFriendsFunctions = fbFunctions.httpsCallable("inviteFriends");
    // Call invite friends function
    inviteFriendsFunctions(data).then(result => {
        console.log(JSON.stringify(result));
        successCallback(result);
    }).catch(error => {
        console.log(JSON.stringify(error));
        errorCallback(error);
    });
}

/**
 * Ratings API
 */
export const fetchRatingForLesson = (userId, lessonId, callback) => dispatch => {
    var ratingsRef = firestoreDB.collection(`ratings`).where('lessonId', '==', lessonId);
    return ratingsRef.get().then(snapshot => {
        var ratings = [];
        var userRating = null;
        snapshot.forEach(function(lessonRef) {
            var rating = lessonRef.data();
            if(rating.userId == userId){
                userRating = rating;
            }
            ratings.push(rating);
        });

        if(callback){
            callback({ratings:ratings, userRating:userRating});
        }
    });
}

export const fetchAllLessonRatingsForUser = (userId, callback) => dispatch => {
    debugger;
    var ratingsRef = firestoreDB.collection(`ratings`).where('userId', '==', userId);
    return ratingsRef.get().then(snapshot => {
        var ratings = [];
        snapshot.forEach(function(lessonRef) {
            var rating = lessonRef.data();
            if(rating)
            ratings.push(rating);
        });

        if(callback){
            callback({ratings:ratings});
        }
    });
}
export const fetchUserRatingForLesson = (userId, lessonId, callback) => dispatch => {
    debugger;
    var ratingKey = getUserLessionRatingKey(userId, lessonId)
    return firestoreDB.collection(`ratings`).doc(ratingKey).get().then(snapshot => {
        var rating = null;
        if(snapshot.exists) {
            rating = snapshot.data();
        }
        callback(rating)
    });
}

export const setLessonRating = (userId, lessonId, organizationId, value, callback) => dispatch => {
    debugger;
    var ratingKey = getUserLessionRatingKey(userId, lessonId);
    const rating =  { userId:userId, lessonId:lessonId, organizationId:organizationId, value:value};
    return firestoreDB.collection(`ratings`).doc(ratingKey).set(rating).then(() => {
        callback(true);
    }).catch(error => {
        dispatch({
            type: ACTIONS.ADD_SNACK,
            payload: {open:true,messageText:error.message, variant:'error'}
        });
        callback(false);
    });
}
