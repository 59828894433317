import { withI18n } from "react-i18next";
import { withStyles } from '@material-ui/core/styles';
import {withRouter} from 'react-router-dom';
import {compose} from "recompose";
import {AppBar, CircularProgress, Button, Divider, Grid, Tabs, Tab, Card, CardActions, CardHeader, CardContent, Typography} from '@material-ui/core';
import {Description, Note, Person, ArrowBack, ArrowForward} from '@material-ui/icons';
import InfiniteScroll from 'react-infinite-scroller';
import {connect} from "react-redux";
import * as ROLES from "../../constants/roles"
import PropTypes from 'prop-types';
import {acceptFollowingRequest, fetchUserDetails, FetchPostsByUserId, removePostsListener, sendFollowRequest} from "../../actions";
import ProfileView from "../ProfileView";
import FriendsView from "../FriendsView";
import Post from "../Post";
import UserAvatar from "../UserAvatar";
import {POST_LIMIT, POST_TYPE} from "../../constants/common";
import {hasUserInObjectList, isInSameApprovedOrganization} from "../../util/functions";
import {ACCEPT, SEND} from "../../constants/colors";


const React = require('react');

const styles = theme => ({
    root: {
        width: '100%',
        //flexGrow: 1,
        marginBottom: '20px',
        paddingBottom: '20px',
    },
    mainRoot: {
        flexGrow: 1,
        width: '100%',
        backgroundColor: theme.palette.background.paper,
    },
    card: {
        width: '100%',
        margin: theme.spacing(.2),
    },
    cardActions: {
        padding:0
    },
    buttonAccept: {
        margin: theme.spacing(.2),
        backgroundColor: ACCEPT,
        color: 'white'
    },
    buttonSend: {
        margin: theme.spacing(.2),
        backgroundColor: SEND,
        color: 'white'
    },
    scrollContainer: {
        height:'700px',
        overflow:'auto',
        marginBottom: '20px',
        paddingBottom: '20px',
    }
});


function TabContainer(props) {
    return (
        <Typography component="div" style={{ paddingTop: 2 * 2 }}>
            {props.children}
        </Typography>
    );
}

TabContainer.propTypes = {
    children: PropTypes.node.isRequired,
};
class User extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            loading: false,
            postLoaded:false,
            tabValue: 0,
            saving: false,
            isCurrentUser: false,
            canViewPosts: false,
            canViewFollowers: false,
            canViewProfile: false,
            isFollowing: false,
            isFollower: false,
            followerRequestSent:false,
            canFollow : false,
            followViewRequestType: null,
            disableAccept:false,
            disableFollow:false,
            hasMorePosts: false
        };
        this.fetchUserInfoCallback = this.fetchUserInfoCallback.bind(this);
        this.fetchUserPostCallback = this.fetchUserPostCallback.bind(this);
        this.handleTabChange = this.handleTabChange.bind(this);
        this.goToGroup = this.goToGroup.bind(this);
        this.updateUserInfo = this.updateUserInfo.bind(this);
        this.handleAcceptFollowRequestClick = this.handleAcceptFollowRequestClick.bind(this);
        this.handleFollowClick = this.handleFollowClick.bind(this);
        this.loadUser = this.loadUser.bind(this);
        this.acceptFollowCallback = this.acceptFollowCallback.bind(this);
        this.sendFollowCallback = this.sendFollowCallback.bind(this);
    }

    componentDidMount() {
        this.loadUser(this.props.match.params.userId);
    }

    loadUser(userId){
        const currentUserId = this.props.currentUser.id;
        if(userId !== this.state.userId) {
            this.setState({postLoaded:false});
            this.setState({userId: userId});
            if (userId === currentUserId) {
                this.setState({
                    isCurrentUser: true,
                    canViewPosts: true,
                    canViewFollowers: true,
                    canViewProfile: true,
                    loading: false,
                    tabValue: 0,
                    userId:userId
                });
            } else {
                this.setState({loading: true, tabValue: 0});
                this.props.fetchUserDetails({userId: userId, callback: this.fetchUserInfoCallback});
            }
        }
    }
    componentWillUpdate(nextProps, nextState, nextContext) {
        if(nextProps.match.params.userId !== this.state.userId) {
            this.loadUser(nextProps.match.params.userId);
        }
    }
    handleFollowClick() {
        const {userInfo} = this.state;
        this.setState({disableFollow:true});
        const currentUserId = this.props.currentUser.id;
        const currentUsername = this.props.currentUser.username;
        const profileImageUrl = this.props.currentUser.profileImageUrl;
        let following = {username:userInfo.username, uid:userInfo.id};
        if(userInfo.profileImageUrl){
            following.profileImageUrl = userInfo.profileImageUrl;
        };
        this.props.sendFollowRequest({currentUsername: currentUsername, currentUserId:currentUserId, profileImageUrl: profileImageUrl, following:following, callback:this.sendFollowCallback});
    };

    handleAcceptFollowRequestClick () {
        const {userInfo} = this.state;
        this.setState({disableAccept:true});
        const currentUserId = this.props.currentUser.id;
        const currentUsername = this.props.currentUser.username;
        const profileImageUrl = this.props.currentUser.profileImageUrl;
        let following = {username:userInfo.username, uid:userInfo.id};
        if(userInfo.profileImageUrl){
            following.profileImageUrl = userInfo.profileImageUrl;
        };
        const isFollowing = hasUserInObjectList(this.props.myFollowings, userInfo.id);
        this.props.acceptFollowingRequest({
            currentUserId: currentUserId,
            currentUsername: currentUsername,
            followRequest: following,
            profileImageUrl: profileImageUrl,
            isFollowing : isFollowing,
            callback: this.acceptFollowCallback
        })
    };

    acceptFollowCallback() {
        this.setState({disableAccept:false});
    }
    sendFollowCallback() {
        this.setState({disableFollow:false});
    }

    updateUserInfo(userInfo) {
        this.setState({userInfo:userInfo});
    }
    fetchUserPostCallback() {
        this.setState({postLoaded:true});
    }
    fetchUserInfoCallback(userInfo) {
        this.setState({loading:false});
        const {currentUser} = this.props;
        if(userInfo) {
            var canViewProfile = false;
            var canViewPosts = false;
            var canFollow = false;
            const isAdmin = currentUser.isApproved && currentUser.isAdmin? true : false;
            const hasMutualOrganization = isInSameApprovedOrganization(userInfo.verifiedOrganizations, currentUser.verifiedOrganizations);
            let isFollowing = hasUserInObjectList(this.props.myFollowings, userInfo.id);
            if(hasMutualOrganization) {
                canViewProfile = true;
            }
            if(isFollowing) {
                canViewProfile = true;
                canViewPosts = true;
            }
            //todo add current user is parent and user is his child
            if(currentUser.isParent && currentUser.children[userInfo.id]) {
                canViewProfile = true;
                canViewPosts = true;
                canFollow = true;
            }
            if(isAdmin) {
                canViewProfile = true;
                canViewPosts = true;
                canFollow = true;
            }
            this.setState({canViewProfile:canViewProfile, canFollow:canFollow, canViewPosts:canViewPosts, userInfo:userInfo,
                isFollowing:isFollowing, isCurrentUser:false});
        }
    }

    goToGroup = (event, groupId, organizationId) => {
        if(organizationId) {
            this.props.history.push(`/group/${organizationId}/${groupId}`);
        } else {
            this.props.history.push(`/group/public/${groupId}`);
        }
    }

    handleTabChange(event, newValue) {
        debugger;
        if(newValue === 1 && !this.state.postLoaded) {
            if(this.state.canViewPosts || this.state.isCurrentUser) {
                this.props.FetchPostsByUserId({userId:this.state.userId, limit:POST_LIMIT, callback:this.fetchPostCallback});
            }
            this.setState({postLoaded:true});
        }
        if(newValue === 2 ){
            this.setState({followViewRequestType: "follower"})
        }
        if(newValue === 3 ){
            this.setState({followViewRequestType: "following"})
        }
        this.setState({tabValue:newValue});
    }


    loadMorePosts = () => {
        this.setState({hasMorePosts: false});
        const {posts} =  this.props;
        var userPosts = posts && posts[this.state.userId];
        var lastPost = userPosts ? userPosts[userPosts.length - 1] : null;
        if(lastPost) {
            this.props.FetchPostsByUserId({userId:this.state.userId, callback:this.fetchPostCallback, limit:POST_LIMIT,
                last: lastPost.dateUpdated});
        }
    };
    fetchPostCallback = (count) =>{
        var hasMorePosts =  count === POST_LIMIT;
        this.setState({hasMorePosts: hasMorePosts});
    }

    componentWillUnmount() {
        this.props.removePostsListener();
        if(this.state.unsubscribePost) {
            this.state.unsubscribePost();
        }
    }
    render() {
        const {t, classes, authenticated, posts, currentUser} = this.props;
        const {loading, isCurrentUser, userInfo, canViewProfile, postCount, tabValue, followViewRequestType, hasMorePosts,userId} = this.state;
        const userPosts = posts && userId ? posts[userId] : null;
        debugger;
        const user  = isCurrentUser ? currentUser : userInfo;
        const isFollowing = userInfo ? hasUserInObjectList(this.props.myFollowings, userInfo.id) : false;
        const isFollower = userInfo? hasUserInObjectList(this.props.myFollowers, userInfo.id) :false;
        const followRequestSent = userInfo ? hasUserInObjectList(this.props.mySentFollowingRequests, userInfo.id) : false;
        const requestToFollow = userInfo ? hasUserInObjectList(this.props.myFollowerRequests, userInfo.id) : false;
        const canViewRelations = (isCurrentUser) || (canViewProfile && currentUser && (ROLES.ADMINISTRATION === currentUser.designation || ROLES.PROFESSOR === currentUser.designation)) ||
            (currentUser && currentUser.isApproved && currentUser.isAdmin) ;

        return (loading ? <div align="center" className={classes.progressRoot}> <CircularProgress className={classes.progress} size={20} thickness={3} /></div>
                :
                <div>
                    <Grid item container className={classes.root}>
                        <Grid item xs={12} md={3}>

                        </Grid>
                        <Grid item xs={12} md={6}>
                            <Grid container>
                                <Grid item xs={12}>

                                    {user &&
                                        <Card>
                                            <CardHeader
                                                avatar={
                                                    <UserAvatar username={user.username} userId={user.id}
                                                                imageUrl={user.profileImageUrl}/>
                                                }
                                                title={<Typography variant={"Title"}
                                                                   align={"right"}
                                                                   className={classes.title}>{user.username}</Typography>}


                                                action={<span>
                                                    {!isFollowing && !followRequestSent && !isCurrentUser &&
                                                        <Button aria-label={t('buttons.follow')}
                                                                onClick={() => this.handleFollowClick()}
                                                                className={classes.buttonSend}
                                                                disabled={this.state.disableFollow}
                                                                variant="contained"
                                                                size="small">
                                                            {t('buttons.follow')}
                                                        </Button>}

                                                    {followRequestSent &&
                                                    <Button aria-label={t('buttons.follow pending')}
                                                            className={classes.buttonSend}
                                                            disabled
                                                            variant="contained"
                                                            size="small">
                                                        {t('buttons.request pending')}
                                                    </Button>}

                                                    {requestToFollow && <Button aria-label={t('buttons.accept')}
                                                            onClick={() => this.handleAcceptFollowRequestClick()}
                                                            className={classes.buttonAccept}
                                                            variant="contained"
                                                            disabled={this.state.disableAccept}
                                                            size="small">

                                                            {t('buttons.accept')}
                                                        </Button>}
                                                </span>
                                                }
                                            />
                                            {user.bio &&
                                                <CardContent>
                                                    <Typography>
                                                        {user.bio}
                                                    </Typography>
                                                </CardContent>
                                            }
                                            <CardActions className={classes.cardActions}>
                                                <AppBar position="static" color={"default"}>
                                                    <Tabs value={tabValue} onChange={this.handleTabChange}
                                                          variant="scrollable"
                                                          scrollButtons="on"
                                                          indicatorColor="primary"
                                                          textColor="primary">
                                                        <Tab icon={<Description/>} label={t('application.profile')} aria-label={t('application.profile')}/>
                                                        <Tab icon={<Note/>} label={<Typography>{t('posts')} ({canViewProfile ? user.personalPostCount ? user.personalPostCount : 0: 0})</Typography>} aria-label={t('posts')}/>
                                                        <Tab icon={<span><Person /> <ArrowBack /></span>} label={<Typography>{t('followers')} ({canViewProfile ? user.followersCount ? user.followersCount : 0: 0})</Typography>}  aria-label={t('followers')}/>
                                                        <Tab icon={<span><Person/> <ArrowForward/></span>} label={<Typography>{t('following')} ({canViewProfile ? user.followingsCount ? user.followingsCount : 0 : 0})</Typography>} aria-label={t('following')}/>
                                                    </Tabs>
                                                </AppBar>
                                            </CardActions>
                                        </Card>
                                    }
                                </Grid>
                                <Grid item xs={12}>
                                    <div>
                                        {/*<AppBar position="static" >
                                            <Tabs value={tabValue} onChange={this.handleTabChange} variant="scrollable" scrollButtons="off">
                                                <Tab icon={<Note />} aria-label={t('application.profile')} />
                                                <Tab icon={<Note />} aria-label={postCount} />
                                                <Tab icon={<Person />} aria-label={followersCount} />
                                                <Tab icon={<Person />} aria-label={followingCount}  />
                                            </Tabs>
                                        </AppBar>*/}

                                        {tabValue === 0 &&
                                            <TabContainer>
                                                    {(canViewProfile || (currentUser && currentUser.isApproved && currentUser.isAdmin)) && <ProfileView userInfo={user} canViewRelations={canViewRelations}/>}
                                            </TabContainer>
                                        }

                                        {tabValue === 1 &&
                                            <TabContainer>

                                                    {isCurrentUser &&
                                                        <span>
                                                            <Post postId="newPost" readOnly={false} type={POST_TYPE.PERSONAL} canEdit={true}
                                                                  profileImageUrl={currentUser.profileImageUrl} username={currentUser.username}
                                                                  userId={authenticated.uid}/>
                                                            <br />
                                                            <Divider />
                                                            <br />
                                                        </span>
                                                    }
                                                    {userPosts && userPosts.length > 0 ?
                                                        <div className={classes.scrollContainer}>
                                                            <InfiniteScroll
                                                                pageStart={0}
                                                                loadMore={this.loadMorePosts}
                                                                hasMore={hasMorePosts}
                                                                useWindow={false}
                                                                loader={<div align="center" className={classes.progressRoot}> <CircularProgress className={classes.progress} size={20} thickness={3} /></div>}
                                                            >
                                                                {userPosts.map(post =>
                                                                    <div key={post.id} >
                                                                        <Post postId={post.id} readOnly={true} type={POST_TYPE.PERSONAL} canEdit={currentUser.id === post.createdById} profileImageUrl={currentUser.profileImageUrl} username={currentUser.username} userId={currentUser.id} post={post}/>
                                                                    </div>
                                                                )}
                                                            </InfiniteScroll>
                                                        </div>: <div> {t('no results')}</div>
                                                    }

                                            </TabContainer>
                                        }
                                        {(tabValue === 2 || tabValue === 3)
                                        && <TabContainer>
                                                {canViewProfile ? <FriendsView ref={instance => { this.childFriends = instance;}} requestType={followViewRequestType} userInfo={user}/> : <div> {t('no results')}</div>}
                                            </TabContainer>
                                        }
                                    </div>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Divider />
                        <Grid item xs={12} md={3}>


                        </Grid>
                    </Grid>
                </div>
        )
    }

}

const mapStateToProps = ({user, posts, myFollowings, myFollowers, mySentFollowingRequests, myFollowerRequests}) => {
        return {currentUser:user, posts:posts, myFollowings:myFollowings, myFollowers:myFollowers,
                                            mySentFollowingRequests:mySentFollowingRequests, myFollowerRequests:myFollowerRequests};
};

export default compose (
    withRouter,
    withStyles(styles),
    withI18n(),
    connect(mapStateToProps, {acceptFollowingRequest, fetchUserDetails, FetchPostsByUserId, removePostsListener, sendFollowRequest})
)(User)
