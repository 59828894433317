import React from 'react';
import {convertToRaw, convertFromRaw, Editor, EditorState, Modifier, RichUtils, getDefaultKeyBinding} from 'draft-js';
import {withStyles} from "@material-ui/core/styles/index";
import {AppBar, Tab, Tabs} from "@material-ui/core";
import { withI18n } from "react-i18next";
import {compose} from "recompose";
import * as colors from "../../constants/colors"

const styles = theme => ({
    container : {
        borderColor: colors.EDITOR_BORDER,
        padding: theme.spacing(1),
        margin: theme.spacing(1),
        // border: theme.spacing.unit * .5,
        // borderStyle: 'solid'
    },
    RichEditorControls : {
        backgroundColor: colors.EDITOR_BUTTONS_BACKGROUND
    },
    bullet: {
        display: 'inline-block',/**/
        margin: '0 2px',
        transform: 'scale(0.8)',
    },
    actions: {
        display: 'inherit',
    },
    title: {
        textTransform: 'uppercase'
    },
    pos: {
        marginBottom: 12,
    },
    root: {
        fontFamily: '\'Georgia\', serif',
        fontSize: 14,
        padding: 20,
        width: 600,
    },
    editor: {
        borderTop: '1px solid #ddd',
        cursor: 'text',
        fontSize: 16,
        marginTop: 20,
        minHeight: 400,
        paddingTop: 20,
    },
    controls: {
        fontFamily: '\'Helvetica\', sans-serif',
        fontSize: 14,
        marginBottom: 10,
        userSelect: 'none',
    },
    styleButton: {
        color: '#999',
        cursor: 'pointer',
        marginRight: 16,
        padding: '2px 0',
    },
    CODE: {
        backgroundColor: 'rgba(0, 0, 0, 0.05)',
        fontFamily: '"Inconsolata", "Menlo", "Consolas", monospace',
        fontSize: 16,
        padding: 2,
    },
    tabColor : {
        backgroundColor : colors.EDITOR_BUTTONS_BACKGROUND
    },
    tabIndicatorColor : {
        color : colors.PRIMARY
    }
});


const customStyleMap = {
    CODE: {
        backgroundColor: 'rgba(0, 0, 0, 0.05)',
        fontFamily: '"Inconsolata", "Menlo", "Consolas", monospace',
        fontSize: 16,
        padding: 2,
    },
    STRIKETHROUGH: {
        textDecoration: 'line-through',
    }
};
function getBlockStyle(block) {
    switch (block.getType()) {
        case 'blockquote':
            return 'RichEditor-blockquote';
        case 'left':
            return 'align-left';
        case 'center':
            return 'align-center';
        case 'right':
            return 'align-right';
        default: return null;
    }
}
class StyleButton extends React.Component {
    constructor() {
        super();
        this.onToggle = (e) => {
            e.preventDefault();
            this.props.onToggle(this.props.style);
        };
    }
    render() {
        let className = 'RichEditor-styleButton';
        let style;
        if (this.props.type === 'color') {
            if (this.props.active) {
                style = {...styles.styleButton, ...colorStyleMap[this.props.style]};
            } else {
                style = styles.styleButton;
            }
        }

        if (this.props.active) {
            className += ' RichEditor-activeButton';
        }
        return ( this.props.type === 'color' ? <span style={style} className={className} onMouseDown={this.onToggle}>
              {this.props.label}
            </span> :
            <span className={className} onMouseDown={this.onToggle}>
              {this.props.label}
            </span>
        );
    }
}
const BLOCK_TYPES = [
    {label: 'H1', style: 'header-one'},
    {label: 'H2', style: 'header-two'},
    {label: 'H3', style: 'header-three'},
    {label: 'H4', style: 'header-four'},
    {label: 'H5', style: 'header-five'},
    {label: 'H6', style: 'header-six'},
    {label: 'Blockquote', style: 'blockquote'},
    {label: 'UL', style: 'unordered-list-item'},
    {label: 'OL', style: 'ordered-list-item'},
    {label: 'Code Block', style: 'code-block'},
    {label: 'Left', style: 'left'},
    {label: 'Center', style: 'center'},
    {label: 'Right', style: 'right'},
];
const BlockStyleControls = (props) => {
    const {editorState} = props;
    const selection = editorState.getSelection();
    const blockType = editorState
        .getCurrentContent()
        .getBlockForKey(selection.getStartKey())
        .getType();
    return (
        <div className="RichEditor-controls">
            {BLOCK_TYPES.map((type) =>
                <StyleButton
                    type='block'
                    key={type.label}
                    active={type.style === blockType}
                    label={type.label}
                    onToggle={props.onToggle}
                    style={type.style}
                />
            )}
        </div>
    );
};
var INLINE_STYLES = [
    {label: 'Bold', style: 'BOLD'},
    {label: 'Italic', style: 'ITALIC'},
    {label: 'Underline', style: 'UNDERLINE'},
    {label: 'StrikeThrough', style: 'STRIKETHROUGH'},
    {label: 'Monospace', style: 'CODE'},
];
const InlineStyleControls = (props) => {
    const currentStyle = props.editorState.getCurrentInlineStyle();

    return (
        <div className="RichEditor-controls">
            {INLINE_STYLES.map((type) =>
                <StyleButton
                    type="inline"
                    key={type.label}
                    active={currentStyle.has(type.style)}
                    label={type.label}
                    onToggle={props.onToggle}
                    style={type.style}
                />
            )}
        </div>
    );
};

//Colors styling
var COLORS = [
    {label: 'Red', style: 'red'},
    {label: 'Orange', style: 'orange'},
    {label: 'Yellow', style: 'yellow'},
    {label: 'Green', style: 'green'},
    {label: 'Blue', style: 'blue'},
    {label: 'Indigo', style: 'indigo'},
    {label: 'Violet', style: 'violet'},
];
const ColorControls = (props) => {
    var currentStyle = props.editorState.getCurrentInlineStyle();
    return (
        <div style={styles.controls}>
            {COLORS.map(type =>
                <StyleButton
                    type="color"
                    key={type.label}
                    active={currentStyle.has(type.style)}
                    label={type.label}
                    onToggle={props.onToggle}
                    style={type.style}
                />
            )}
        </div>
    );
};
const colorStyleMap = {
    red: {
        color: 'rgba(255, 0, 0, 1.0)',
    },
    orange: {
        color: 'rgba(255, 127, 0, 1.0)',
    },
    yellow: {
        color: 'rgba(180, 180, 0, 1.0)',
    },
    green: {
        color: 'rgba(0, 180, 0, 1.0)',
    },
    blue: {
        color: 'rgba(0, 0, 255, 1.0)',
    },
    indigo: {
        color: 'rgba(75, 0, 130, 1.0)',
    },
    violet: {
        color: 'rgba(127, 0, 255, 1.0)',
    },
};




class CustomEditor extends React.Component {

    constructor(props) {
        super(props);
        const editorState = props.content ? EditorState.createWithContent(convertFromRaw(JSON.parse(this.props.content))) : EditorState.createEmpty();
        this.state = {editorState: editorState, selectedTab: 0};
        this.focus = () => this.refs.editor.focus();
        //this.onChange = (editorState) => this.setState({editorState});
        //this.focus = () => this.editor.focus();
        this.onChange = this.onChange.bind(this);
        this.handleKeyCommand = this.handleKeyCommand.bind(this);
        this.mapKeyToEditorCommand = this.mapKeyToEditorCommand.bind(this);
        this.toggleBlockType = this.toggleBlockType.bind(this);
        this.toggleInlineStyle = this.toggleInlineStyle.bind(this);
        this.toggleColor =  this.toggleColor.bind(this);
        this.handleTabChange = this.handleTabChange.bind(this);
    }

    handleKeyCommand(command, editorState) {
        const newState = RichUtils.handleKeyCommand(editorState, command);
        if (newState) {
            this.onChange(newState);
            return true;
        }
        return false;
    }
    mapKeyToEditorCommand(e) {
        if (e.keyCode === 9 /* TAB */) {
            const newEditorState = RichUtils.onTab(
                e,
                this.state.editorState,
                4, /* maxDepth */
            );
            if (newEditorState !== this.state.editorState) {
                this.onChange(newEditorState);
            }
            return;
        }
        return getDefaultKeyBinding(e);
    }

    toggleBlockType(blockType) {
        this.onChange(
            RichUtils.toggleBlockType(
                this.state.editorState,
                blockType
            )
        );
    }
    toggleInlineStyle(inlineStyle) {
        this.onChange(
            RichUtils.toggleInlineStyle(
                this.state.editorState,
                inlineStyle
            )
        );
    }

    toggleColor(toggledColor) {
        const {editorState} = this.state;
        const selection = editorState.getSelection();
        // Let's just allow one color at a time. Turn off all active colors.
        const nextContentState = Object.keys(colorStyleMap)
            .reduce((contentState, color) => {
                return Modifier.removeInlineStyle(contentState, selection, color)
            }, editorState.getCurrentContent());
        let nextEditorState = EditorState.push(
            editorState,
            nextContentState,
            'change-inline-style'
        );
        const currentStyle = editorState.getCurrentInlineStyle();
        // Unset style override for current color.
        if (selection.isCollapsed()) {
            nextEditorState = currentStyle.reduce((state, color) => {
                return RichUtils.toggleInlineStyle(state, color);
            }, nextEditorState);
        }
        // If the color is being toggled on, apply it.
        if (!currentStyle.has(toggledColor)) {
            nextEditorState = RichUtils.toggleInlineStyle(
                nextEditorState,
                toggledColor
            );
        }
        this.onChange(nextEditorState);
    }

    onChange = (editorState) => {
        this.setState({editorState});
        this.props.onChange(JSON.stringify(convertToRaw(editorState.getCurrentContent())));
        console.log(convertToRaw(editorState.getCurrentContent()));
    };

    handleTabChange = (event, selectedTab) => {
        this.setState({ selectedTab });
    };
    render() {
        const {editorState, selectedTab} = this.state;
        const styleMap = Object.assign(customStyleMap, colorStyleMap);
        const {classes, t, readOnly} = this.props;
        let className = 'RichEditor-editor';
        var contentState = editorState.getCurrentContent();
        if (!contentState.hasText()) {
            if (contentState.getBlockMap().first().getType() !== 'unstyled') {
                className += ' RichEditor-hidePlaceholder';
            }
        }


        return (
            <div className={classes.container}>
                {!readOnly && selectedTab === 0 && <div className="RichEditor-root">
                    <BlockStyleControls
                        editorState={editorState}
                        onToggle={this.toggleBlockType}
                    />
                    <InlineStyleControls
                        editorState={editorState}
                        onToggle={this.toggleInlineStyle}
                    />
                    <ColorControls
                        editorState={editorState}
                        onToggle={this.toggleColor}
                    />
                    <div className={className} onClick={this.focus}>
                        <Editor
                            blockStyleFn={getBlockStyle}
                            customStyleMap={styleMap}
                            editorState={editorState}
                            handleKeyCommand={this.handleKeyCommand}
                            keyBindingFn={this.mapKeyToEditorCommand}
                            onChange={this.onChange}
                            placeholder={t('create a lesson')}
                            ref="editor"
                            spellCheck={true}
                        />
                    </div>
                </div>}
                {(selectedTab === 1 || readOnly) && <div id='preview'>
                    <Editor
                        editorState={editorState}
                        customStyleMap={styleMap}
                        readOnly ={true}
                    />
                </div>}
                {!readOnly &&
                <AppBar position="static" className={classes.tabColor}>
                    <Tabs value={selectedTab} indicatorColor="primary" onChange={this.handleTabChange}>
                        <Tab label={t('buttons.write')} />
                        <Tab label={t('buttons.preview')}  />
                    </Tabs>
                </AppBar>
                }



                {/*<div className={classes.RichEditorControls}>*/}

                {/*</div>*/}
                {/*<Editor editorState={this.state.editorState} onChange={this.onChange}/>*/}
            </div>
        );
    }

}
export default compose(withI18n(),withStyles(styles))(CustomEditor)