import { withI18n } from "react-i18next";
import { withStyles } from '@material-ui/core/styles';
import {withRouter } from 'react-router-dom';
import {compose} from "recompose";
import PasswordChangeForm from '../PasswordChange';
import ProfileEdit from '../ProfileEdit';
import OrganizationCreate from '../OrganizationCreate';
import {Divider, Grid} from '@material-ui/core';

const React = require('react');

const styles = {
    card: {
        minWidth: 275,
        maxWidth: 400,
    },
    bullet: {
        display: 'inline-block',
        margin: '0 2px',
        transform: 'scale(0.8)',
    },
    actions: {
        display: 'inherit',
    },
    title: {
        textTransform: 'uppercase'
    },
    pos: {
        marginBottom: 12,
    },
};




const AccountPage = (t, classes) => (
    <div>

                <div>
                    <Grid item container className={classes.root}>
                        <Grid item xs={12} md={3}>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <ProfileEdit />
                        </Grid>
                        <Divider />
                        <Grid item xs={12} md={3}>
                            <PasswordChangeForm />
                            <OrganizationCreate />
                        </Grid>

                    </Grid>
                </div>
    </div>
)


export default compose (
    withRouter,
    withStyles(styles),
    withI18n()
)(AccountPage)
