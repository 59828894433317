import {ADD_IMAGES, FETCH_IMAGES, REMOVE_IMAGE, UPDATE_IMAGE} from "../constants/action-types";
import {combineArrays, removeObjectInArrayById, updateObjectInArrayById} from "../util/functions";

export default (state = {}, action) => {
    switch (action.type) {
        case ADD_IMAGES:
            return {...state, [action.payload.id] : action.payload.top ?
                    combineArrays(action.payload.data, state[action.payload.id])
                    : combineArrays(state[action.payload.id], action.payload.data)};
        case FETCH_IMAGES:
            return {...state, [action.payload.id] : action.payload.data};
        case REMOVE_IMAGE:
            return {...state, [action.payload.id] : removeObjectInArrayById(state[action.payload.id], action.payload.id)};
        case UPDATE_IMAGE:
            debugger;
            return {...state, [action.payload.id] : updateObjectInArrayById(state[action.payload.id], action.payload.data)};
        default:
            return state;
    }
};