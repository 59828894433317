import { withI18n } from "react-i18next";
import { withStyles } from '@material-ui/core/styles';
import {compose} from "recompose";
import { withRouter } from 'react-router-dom';
import {LINARY_LOADING_MASK, LINARY_LOADING_MASK_BAR, GREEN, DRAWER_ICON} from "../../constants/colors";
import {Button,Card, CardContent, CardHeader, CardActions, Grid, LinearProgress, TextField, Typography} from '@material-ui/core';
import {createOrganization} from "../../actions";
import {connect} from "react-redux";
import classNames from "classnames";
import {ADMINISTRATION} from "../../constants/roles";

const React = require('react');

const INITIAL_STATE = {
    name: '',
    description:'',
    street : '',
    state : '',
    city: null,
    zip: '',
    saving: false,
};

const styles = theme => ({
    container: {
        flexWrap: 'wrap',
    },
    card: {
        minWidth: 200,
        maxWidth: 300,
        margin: theme.spacing(1)
    },
    actions: {
        display: 'inherit',
    },
    title: {
        textTransform: 'uppercase'
    },
    iconInDrawMenu: {
        margin: theme.spacing(),
        color: DRAWER_ICON,
    },
    uploadProgress : {
        flexGrow: 1,
    },
    linearColorPrimary : {
        backgroundColor: LINARY_LOADING_MASK,
    },
    linearBarColorPrimary : {
        backgroundColor: LINARY_LOADING_MASK_BAR,
    },
    formControl: {
        minWidth: 400,
        margin: theme.spacing(3),
    },
    editorControl: {
        minWidth: 400,
        minHeight: 200,
        margin: theme.spacing(3)
    },
    group: {
        margin: theme.spacing(),
        display: 'inline'
    },
    textInput : {
        minWidth: 400,
    },
    pos: {
        marginBottom: 12,
    },
    success : {
        color: GREEN
    },
    imageUpload : {
        marginTop: theme.spacing.unit * 2
    }
});

class OrganizationCreate extends React.Component {

    constructor(props) {
        super(props);

        this.state = { ...INITIAL_STATE};
        this.createOrganizationCallback = this.createOrganizationCallback.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
        this.onChange = this.onChange.bind(this);
    }

    onChange = prop => event => {
        this.setState({ [prop]: event.target.value });

    }

    onSubmit = event => {
        debugger;
        event.preventDefault();
        this.setState({saving:true});
        const { name, description, street, state, city, zip} = this.state;
        const currentUserId = this.props.currentUser.id;
        const currentUsername = this.props.currentUser.username;
        const address = {street:street, zip:zip, state:state, city:city, countryCode:'USA'};
        const organization = {name:name, description:description, address:address};
        const member = {username:currentUsername, isAdministrator:true}
        if(this.props.currentUser.profileImageUrl) {
            organization.createdByProfileImageUrl = this.props.currentUser.profileImageUrl;
            member.profileImageUrl = this.props.currentUser.profileImageUrl;
        }

        this.props.createOrganization({organization:organization, member:member, memberId:currentUserId, callback:this.createOrganizationCallback});
    };

    createOrganizationCallback(success, organizationId) {
        this.setState({ 'saving': false });
        debugger;
        if(success) {
            this.props.history.push(`/organization/${organizationId}`);
        }
    }

    render(){
        const {t, classes, currentUser} = this.props;
        const {name, description, city, street, state, zip, saving} = this.state;
        const isInvalid = name === '' || street === '' || state === '' || description === '' || zip === '' || zip.length !== 5 || city === '' || saving;
        const hasCreateOrgAccess = currentUser && (currentUser.isAdmin ||  currentUser.designation === ADMINISTRATION);

        debugger;
        return (
            <div align="center">{hasCreateOrgAccess &&
            <Card className={classes.card}>
                <form onSubmit={this.onSubmit}>
                    <CardHeader
                        title={<Typography className={classes.title}>
                            {t('application.create organization')}
                        </Typography>
                        }
                    />
                    <CardContent>
                        <div>
                            <Grid>
                                <Grid item>
                                    <TextField variant="filled"
                                               className={classNames(classes.margin, classes.textField)} value={name}
                                               inputProps={{maxLength: 80, tabIndex: 0}} required type="text"
                                               onChange={this.onChange('name')} id="input-name"
                                               label={t('fields.name')}/>
                                </Grid>
                                <Grid item>
                                    <TextField variant="filled"
                                               className={classNames(classes.margin, classes.textField)}
                                               inputProps={{maxLength: 200, tabIndex: 1}} value={description} required
                                               type="text"
                                               onChange={this.onChange('description')} id="input-description"
                                               label={t('fields.description')}
                                               multiline rows="2"/>
                                </Grid>
                                <Grid item>
                                    <TextField variant="filled"
                                               className={classNames(classes.margin, classes.textField)} value={street}
                                               inputProps={{maxLength: 25, tabIndex: 2}} required type="text"
                                               onChange={this.onChange('street')} id="input-name"
                                               label={t('fields.street')}/>
                                </Grid>
                                <Grid item>
                                    <TextField variant="filled"
                                               className={classNames(classes.margin, classes.textField)} value={city}
                                               inputProps={{maxLength: 25, tabIndex: 3}} required type="text"
                                               onChange={this.onChange('city')} id="input-name"
                                               label={t('fields.city')}/>
                                </Grid>
                                <Grid item>
                                    <TextField variant="filled"
                                               className={classNames(classes.margin, classes.textField)} value={state}
                                               inputProps={{maxLength: 25, tabIndex: 4}} required type="text"
                                               onChange={this.onChange('state')} id="input-name"
                                               label={t('fields.state')}/>
                                </Grid>
                                <Grid item>
                                    <TextField variant="filled"
                                               className={classNames(classes.margin, classes.textField)} value={zip}
                                               inputProps={{maxLength: 5, tabIndex: 5}} required type="number"
                                               onChange={this.onChange('zip')} id="input-name" label={t('fields.zip')}/>
                                </Grid>
                            </Grid>
                        </div>
                        {saving &&
                        <div className={classes.uploadProgress}>
                            <LinearProgress classes={{
                                colorPrimary: classes.linearColorPrimary,
                                barColorPrimary: classes.linearBarColorPrimary,
                            }}/>
                        </div>
                        }
                    </CardContent>
                    <CardActions className={classes.actions}>
                        <Button size="small" color="primary" type="submit"
                                disabled={isInvalid}> {t('buttons.create')}</Button>
                    </CardActions>
                </form>
            </Card>
            }
            </div>
        );
    }
}

function mapStateToProps({ user }) {
    return { currentUser:user };
}
export default compose (
    withStyles(styles),
    withRouter,
    withI18n(),
    connect(mapStateToProps, {createOrganization})
)(OrganizationCreate)