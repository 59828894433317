export const ADMIN = '/admin';
export const ABOUT_US= '/about-us';
export const ACCOUNT = '/account';
export const CONTACT_US = '/contact-us';
export const CREATE_LESSON ='/create-lesson';
export const FRIENDS = '/friends';
export const LIBRARY = '/library';
export const LESSON = '/lessons/:organization/:lessonId';
export const GROUP = '/group/:organization/:groupId';
export const GROUPS = '/groups';
export const HOME = '/home';
export const LANDING = '/';
export const MESSAGES = '/messages';
export const PASSWORD_FORGET = '/pw-forget';
export const SCHEDULES = '/schedules';
export const SIGN_UP = '/signup';
export const SIGN_IN = '/signin';
export const ORGANIZATION = '/organization/:organizationId';
export const ORGANIZATIONS = '/organizations';
export const TEAM ='/team/:teamId';
export const USER ='/user/:userId';
