import React, { Component } from "react";

import { withI18n } from "react-i18next";
import { withStyles } from "@material-ui/core/styles";
import { withRouter } from "react-router-dom";
import { compose } from "recompose";

import AddFriendToInvite from "./AddFriendToInvite";
import FriendInvites from "./FriendInvites";
import { PersonAdd } from "@material-ui/icons";
import SendIcon from "@material-ui/icons/Send";
import Typography from "@material-ui/core/Typography";
import ErrorIcon from "@material-ui/icons/Error";
import DraftsIcon from "@material-ui/icons/Drafts";
import EmailIcon from "@material-ui/icons/Email";
import { sendFriendInvite } from "../../actions";
import { connect } from "react-redux";

import {
  Grid,
  Card,
  CardHeader,
  CardContent,
  Avatar,
  IconButton,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Slide,
  CircularProgress,
} from "@material-ui/core";
import { withAuthentication } from "../Session";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

const styles = {
  paper: {
    width: "60%",
  },
  titleDiv: {
    textAlign: "center",
    width: "auto",
  },
  centeredTitle: {
    display: "flex",
    alignItems: "center",
  },
};

export class InviteFriends extends Component {
  state = {
    emails: [],
    showConfirmationDialog: false,
    showInvalidDialog: false,
    showFailureDialog: false,
    showSuccessDialog: false,
    disableDialogButtons: false,
    sendingSpinnerVisible: false,
    friendInvitesVisible: true,
    failedEmails: [],
  };

  constructor(props) {
    super(props);
    this.handleClose = this.handleClose.bind(this);
    this.showConfirmationDialog = this.showConfirmationDialog.bind(this);
    this.getElementVisibility = this.getElementVisibility.bind(this);
    this.showFailureDialog = this.showFailureDialog.bind(this);
    this.showSuccessDialog = this.showSuccessDialog.bind(this);
  }

  getElementVisibility = (elementId) => {
    return {
      textAlign: "center",
      display: this.state[elementId] ? "block" : "none",
    };
  };

  // Closes any all all dialogs
  handleClose = () => {
    this.setState({
      showConfirmationDialog: false,
      showInvalidDialog: false,
      showFailureDialog: false,
      showSuccessDialog: false
    });
  };

  showConfirmationDialog = () => {
    if (this.state.emails.length === 0) {
      this.setState({ showInvalidDialog: true });
    } else {
      this.setState({ showConfirmationDialog: true });
    }
  };

  showFailureDialog = () => {
    this.setState({
      showFailureDialog: true,
    });
  };

  showSuccessDialog = () => {
    this.setState({
      showSuccessDialog: true,
    });
  };

  //Delete friend from invite list
  delFriend = (emailToDelete) => {
    this.setState({
      emails: [...this.state.emails.filter((email) => email !== emailToDelete)],
    });
  };

  // Add friend to invite list
  addFriend = (newEmail) => {
    this.setState({ emails: [...this.state.emails, newEmail] });
  };

  // Send invites
  sendInvites = () => {
    const { lng } = this.props;
    const data = {
      emails: this.state.emails,
      lang: lng,
    };

    // Display processing screen
    this.setState({
      showConfirmationDialog: false,
      sendingSpinnerVisible: true,
      friendInvitesVisible: false,
    });

    // Display success dialog and reset to input screen
    const successCallback = (result) => {
      this.setState({
        emails: [],
      });
      this.showSuccessDialog();
      this.setState({
        sendingSpinnerVisible: false,
        friendInvitesVisible: true,
      });
    };

    // Display error message and reset to input screen
    const errorCallback = (result) => {

      if (result !== undefined && result.data != undefined) {
        this.setState({
          failedEmails: result.data.failedEmails,
        });
      }

      this.showFailureDialog();
      this.setState({
        sendingSpinnerVisible: false,
        friendInvitesVisible: true,
      });
    };

    sendFriendInvite(data, successCallback, errorCallback);
  };

  render() {
    const { t, classes } = this.props;
    return (
      <React.Fragment>
        <br />
        <div style={styles.titleDiv}>
          <Typography variant="h5" className={classes.title}>
            {t("application.invite friends")}
            <br />
          </Typography>
        </div>
        <Grid container className={classes.root} justify="center">
          <Grid
            item="sendingInvites"
            style={this.getElementVisibility("sendingSpinnerVisible")}
          >
            <br />
            {t("invitefriends.sending")}
            <br />
            <br />
            <CircularProgress />
          </Grid>
          <Grid
            item
            key="friendInvites"
            style={this.getElementVisibility("friendInvitesVisible")}
          >
            <Card className={classes.card}>
              <CardHeader
                avatar={
                  <Avatar aria-label={"Add email"} className={classes.icon}>
                    <PersonAdd color="primary" />
                  </Avatar>
                }
                title={
                  <Grid container>
                    <Grid item>
                      <AddFriendToInvite
                        addFriend={this.addFriend}
                        classes={classes}
                      />
                    </Grid>
                    <Grid>
                      <IconButton
                        edge="end"
                        aria-label={"buttons.send"}
                        aria-hidden={false}
                        onClick={this.showConfirmationDialog}
                      >
                        <SendIcon color="primary" />
                      </IconButton>
                    </Grid>
                  </Grid>
                }
              />
              <CardContent>
                <FriendInvites
                  emails={this.state.emails}
                  delFriend={this.delFriend}
                  classes={classes}
                />
              </CardContent>
            </Card>
          </Grid>
        </Grid>

        <Dialog
          open={this.state.showConfirmationDialog}
          TransitionComponent={Transition}
          keepMounted
          onClose={this.handleClose}
          aria-labelledby="alert-dialog-slide-title"
          aria-describedby="alert-dialog-slide-description"
        >
          <DialogTitle id="alert-dialog-slide-title">
            <div style={styles.centeredTitle}>
              <DraftsIcon color="primary" /> {"    "}
              <Typography variant="h5" className={classes.title}>
                {"  "}
                {t("invitefriends.send-confirmation-dialog.title")}
              </Typography>
            </div>
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-slide-description">
              {t("invitefriends.send-confirmation-dialog.body")}
              <br />
              <ul>
                {this.state.emails.map((email) => (
                  <li>{email}</li>
                ))}
              </ul>
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={this.handleClose}
              disabled={this.state.disableDialogButtons}
              color="primary"
            >
              Cancel
            </Button>
            <Button
              onClick={this.sendInvites}
              disabled={this.state.disableDialogButtons}
              color="primary"
            >
              Send
            </Button>
          </DialogActions>
        </Dialog>

        <Dialog
          open={this.state.showInvalidDialog}
          TransitionComponent={Transition}
          keepMounted
          onClose={this.handleClose}
          aria-labelledby="alert-dialog-slide-title"
          aria-describedby="alert-dialog-slide-description"
        >
          <DialogTitle id="alert-dialog-slide-title">
            <div style={styles.centeredTitle}>
              <ErrorIcon color="primary" /> {"    "}
              <Typography variant="h5" className={classes.title}>
                {t("invitefriends.send-invalid-dialog.title")}
              </Typography>
            </div>
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-slide-description">
              {t("invitefriends.send-invalid-dialog.body")}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleClose} color="primary">
              OK
            </Button>
          </DialogActions>
        </Dialog>

        <Dialog
          open={this.state.showFailureDialog}
          TransitionComponent={Transition}
          keepMounted
          onClose={this.handleClose}
          aria-labelledby="alert-dialog-slide-title"
          aria-describedby="alert-dialog-slide-description"
        >
          <DialogTitle id="alert-dialog-slide-title">
            <div style={styles.centeredTitle}>
              <ErrorIcon color="primary" /> {"    "}
              <Typography variant="h5" className={classes.title}>
                {t("invitefriends.send-failure-dialog.title")}
              </Typography>
            </div>
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-slide-description">
              {t("invitefriends.send-failure-dialog.body")}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleClose} color="primary">
              OK
            </Button>
          </DialogActions>
        </Dialog>

        <Dialog
          open={this.state.showSuccessDialog}
          TransitionComponent={Transition}
          keepMounted
          onClose={this.handleClose}
          aria-labelledby="alert-dialog-slide-title"
          aria-describedby="alert-dialog-slide-description"
        >
          <DialogTitle id="alert-dialog-slide-title">
            <div style={styles.centeredTitle}>
              <EmailIcon color="primary" /> {"    "}
              <Typography variant="h5" className={classes.title}>
                {t("invitefriends.send-success-dialog.title")}
              </Typography>
            </div>
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-slide-description">
              {t("invitefriends.send-success-dialog.body")}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleClose} color="primary">
              OK
            </Button>
          </DialogActions>
        </Dialog>
      </React.Fragment>
    );
  }
}

const mapStateToProps = ({ user }) => {
  return { user: user };
};

export default compose(
  withRouter,
  withStyles(styles),
  withI18n(),
  connect(
    mapStateToProps,
    { sendFriendInvite }
  )
)(InviteFriends);
