import { withI18n } from "react-i18next";
import { withStyles } from '@material-ui/core/styles';
import {compose} from "recompose";
import * as COLORS from "../../constants/colors";
import {Edit} from '@material-ui/icons';
import {QUESTION_IMAGE_BASE_PATH, QUESTION_TYPE} from "../../constants/common";
import {Button, Checkbox, Chip, Card, CardContent, CardHeader, CircularProgress, Divider, Grid, IconButton, Paper, LinearProgress, List, ListItem, ListItemIcon, ListItemText, TextField, Typography} from'@material-ui/core';
import {submitAnswers, openSnack} from "../../actions";
import {connect} from "react-redux";
import {WHITE} from "../../constants/colors";
import {CHIP} from "../../constants/colors";

const React = require('react');

const styles = theme => ({
    container: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    card: {
        margin: theme.spacing(2),
    },
    formControl: {
        margin: 5,
        minWidth: 120,
    },
    icon: {
        margin: theme.spacing(),
        color: COLORS.PRIMARY,
    },
    uploadProgress : {
        flexGrow: 1,
    },
    linearColorPrimary : {
        backgroundColor: COLORS.LINARY_LOADING_MASK,
    },
    linearBarColorPrimary : {
        backgroundColor: COLORS.LINARY_LOADING_MASK_BAR,
    },
    progressRoot: {
        flexGrow: 1,
        spacing: '40'
    },
    progress: {
        margin: theme.spacing(2),
        color: COLORS.LOADING_MASK
    },
});

const INITIAL_STATE = {
    message:null,
    saving: false,
    submittedAnswers: null,
    questionsAnswered: new Set()
};
class Questions extends React.Component {

    constructor(props) {
        super(props);

        this.state = { ...INITIAL_STATE};
        this.handleSubmit = this.handleSubmit.bind(this);
        this.onChange = this.onChange.bind(this);
        this.onAnswerCheckChange = this.onAnswerCheckChange.bind(this);
        this.onAnswerValueChange = this.onAnswerValueChange.bind(this);
        this.setSavingToFalse = this.setSavingToFalse.bind(this);
    }

    componentWillMount() {
        const submittedAnswers = {};
        this.props.questions.forEach(function (question) {
            if(QUESTION_TYPE.MULTIPLE_CHOICE === question.type) {
                submittedAnswers[question.id] = {answers:new Set()};
            } else {
                submittedAnswers[question.id] = {answer:''};
            }
        })
        this.setState({submittedAnswers:submittedAnswers});
    };

    componentWillUpdate(nextProps) {
        if (nextProps.questions && this.state.submittedAnswers != null && nextProps.questions.length !== Object.keys(this.state.submittedAnswers).length) {
            var submittedAnswers  = this.state.submittedAnswers;
            nextProps.questions.forEach(function (question) {
                if(!submittedAnswers[question.id]) {
                    if (QUESTION_TYPE.MULTIPLE_CHOICE === question.type) {
                        submittedAnswers[question.id] = {answers: new Set()};
                    } else {
                        submittedAnswers[question.id] = {answer: ''};
                    }
                }
            });
            this.setState({submittedAnswers:submittedAnswers});
        }
    }


    handleSubmit = () => {
        this.setState({ 'saving': true });
        const { currentUserId, lessonId, myAssignment, organizationId} = this.props;
        const {submittedAnswers } = this.state;
        var count = myAssignment.attempts ? myAssignment.attempts + 1 : 1;
        var answers = {};
        const answerKeys = Object.keys(submittedAnswers);
        for(const key of answerKeys){
            if(submittedAnswers[key].answers) {
                var answerArr = [];
                submittedAnswers[key].answers.forEach(answer => {
                    answerArr.push(answer);
                });
                answers[key] = {answers: answerArr};
            } else {
                answers[key] = {answer: submittedAnswers.answer};
            }
        }
        this.props.submitAnswers({organizationId:organizationId, lessonId:lessonId, currentUserId:currentUserId, answers:answers, count:count, callback:this.saveCompleted});

    };
    saveCompleted = (success) => {
        this.setState({ 'saving': false });
        if(success && this.props.onSubmitComplete) {
            this.props.onSubmitComplete();
        }
    };
    setSavingToFalse(){
        this.setState({saving:false});
    }
    onChange = prop => event => {
        this.setState({ [prop]: event.target.value });
    };
    onCheckChange = name => event => {
        this.setState({ [name]: event.target.checked });
    };

    onAnswerValueChange = (questionId) => event => {
        var submittedAnswers  = this.state.submittedAnswers;
        var questionsAnswered  = this.state.questionsAnswered;
        if(event.target.value === '') {
            questionsAnswered.delete(questionId);
        } else {
            questionsAnswered.add(questionId)
        }
        submittedAnswers[questionId].answer = event.target.value;

        this.setState({questionsAnswered:questionsAnswered})
        this.setState({submittedAnswers:submittedAnswers})

    };
    onAnswerCheckChange = (questionId, answerId) => event => {
        var submittedAnswers = this.state.submittedAnswers;
        var questionsAnswered  = this.state.questionsAnswered;
        if(event.target.checked) {
            submittedAnswers[questionId].answers.add(answerId);
        } else if(submittedAnswers[questionId].answers.has(answerId)){
            submittedAnswers[questionId].answers.delete(answerId);
        }
        if(submittedAnswers[questionId].answers.size === 0) {
            questionsAnswered.delete(questionId);
        } else {
            questionsAnswered.add(questionId);
        }
        this.setState({questionsAnswered:questionsAnswered})
        this.setState({submittedAnswers:submittedAnswers})

    };
    render() {
        const {
            saving,
            submittedAnswers,
            questionsAnswered
        } = this.state;
        const {t, classes, canAnswerQuestions, questions, currentUserId, lessonId, path, canEdit} = this.props;
        const isInvalid = (questionsAnswered && questionsAnswered.size !== questions.length) || saving;
        return (
            <div>
                {questions && questions.length > 0 ?
                    <div>
                        {questions.map(question => (
                            <Grid item key={question.id}>
                                <Card className={classes.card}>
                                    <CardHeader
                                        title={question.question ? question.question : question.type == QUESTION_TYPE.DRAG_AND_MATCH ? t('drag and match').toUpperCase() : t('drag and match multiple choice').toUpperCase()}
                                        subheader={ !question.question && t('available on mobile only')}
                                        action={canEdit &&
                                            <IconButton aria-label="settings" onClick={() => this.props.editQuestion(question)}>
                                                <Edit />
                                            </IconButton>
                                        }
                                    />
                                    <CardContent>
                                        {question.type === QUESTION_TYPE.MULTIPLE_CHOICE &&
                                        <List className={classes.root}>
                                            {question.answers.map(answer => (

                                                <ListItem key={'answer-' + question.id + '-' + answer.id}
                                                          role={undefined} dense
                                                          onClick={this.onAnswerCheckChange(question.id, answer.id)}>
                                                    <ListItemIcon>
                                                        <Checkbox
                                                            edge="start"
                                                            checked={submittedAnswers[question.id].answers.has(answer.id)}
                                                            tabIndex={-1}
                                                            disableRipple
                                                            inputProps={t('field.is correct')}
                                                            disabled={!canAnswerQuestions}
                                                            color={"primary"}
                                                        />
                                                    </ListItemIcon>
                                                    <ListItemText id={'answer-value-' + question.id + '-' + answer.id}
                                                                  primary={answer.value}/>
                                                </ListItem>

                                            ))}
                                        </List>
                                        }
                                        {
                                            question.type === QUESTION_TYPE.OPEN_ENDED &&
                                            <TextField placeholder={t('enter answer')}  disabled={!canAnswerQuestions} inputProps={{maxLength: 400}} fullWidth multiline rows="2" value={submittedAnswers[question.id].answer} required type="text" onChange={this.onAnswerValueChange(question.id)} label={t('fields.answer')} />
                                        }
                                        {
                                            question.type === QUESTION_TYPE.DRAG_AND_MATCH && t('available on mobile only') &&
                                            <Grid container item spacing={2} justify="center">
                                                <Grid item xs={12} md={6}  justify="center">
                                                    <Paper>
                                                        {question.dragAndMatchList.map((dragAndMatch, index) => (
                                                            <Grid item spacing={4}>
                                                                <Typography
                                                                    style={{paddingTop: 2}}>{t('pair count', {count: index + 1})}</Typography>
                                                                <List className={classes.root}>
                                                                    <ListItem key={`dropzone-${index}`} dense>
                                                                        {dragAndMatch.dropZone.imageLink &&
                                                                        <ListItemIcon>
                                                                            <img src={dragAndMatch.dropZone.imageLink}
                                                                                 alt={dragAndMatch.dropZone.name}
                                                                                 width={50}/>
                                                                        </ListItemIcon>
                                                                        }

                                                                        <ListItemText id={'dropZone' + index}>
                                                                          {dragAndMatch.dropZone.name}
                                                                        </ListItemText>
                                                                    </ListItem>
                                                                    <ListItem key={`draggable-${index}`} dense>
                                                                        {dragAndMatch.draggable.imageLink &&
                                                                        <ListItemIcon>
                                                                            <img
                                                                                src={dragAndMatch.draggable.imageLink}
                                                                                alt={dragAndMatch.draggable.name}
                                                                                width={50}
                                                                            />
                                                                        </ListItemIcon>
                                                                        }
                                                                        <ListItemText id={'draggable' + index}>
                                                                            {t('speech').toUpperCase()}: {dragAndMatch.draggable.speechText}
                                                                        </ListItemText>
                                                                    </ListItem>
                                                                </List>
                                                                {index < question.dragAndMatchList.length && <Divider/>}
                                                            </Grid>
                                                        ))}
                                                    </Paper>
                                                </Grid>
                                            </Grid>
                                        }
                                        {
                                              question.type === QUESTION_TYPE.DRAG_AND_MATCH_CHOICE &&
                                              <Grid container item spacing={2} justify="center">
                                                  <Grid item xs={12} md={6}  justify="center">
                                                      <Paper>
                                                          <Grid item spacing={4}>
                                                              <Typography style={{paddingTop: 2}}>{t('drop zone').toUpperCase()}</Typography>
                                                              <List className={classes.root}>
                                                                  <ListItem key={`dropzone-image`} dense>
                                                                      {question.dropZone.imageLink &&
                                                                          <ListItemIcon>
                                                                              <img src={question.dropZone.imageLink} alt={question.dropZone.name} width={50}/>
                                                                          </ListItemIcon>
                                                                      }
                                                                      <ListItemText id={'dropZone-info'}>
                                                                          {t('speech').toUpperCase()}: {question.dropZone.speechText} <br />
                                                                          {t('text and background').toUpperCase()}: {question.dropZone.text  && <Chip
                                                                              size="small"
                                                                              label={question.dropZone.text}
                                                                              style={{backgroundColor:question.dropZone.bgColor, color:question.dropZone.textColor}}
                                                                          />
                                                                          }
                                                                      </ListItemText>
                                                                  </ListItem>
                                                              </List>
                                                          </Grid>
                                                          <Grid item spacing={4}>
                                                              <Typography style={{paddingTop: 2}}>{t('draggables').toUpperCase()}</Typography>
                                                              <List className={classes.root}>
                                                                  {question.answers.map((draggable, index) => (
                                                                      <ListItem key={`draggable-${index}`} dense>
                                                                          <ListItemIcon>
                                                                              <img src={draggable.imageLink} alt={draggable.name} width={50}/>
                                                                          </ListItemIcon>
                                                                          <ListItemText id={'draggable-text' + index}>
                                                                              {t('speech').toUpperCase()}: {draggable.speechText}
                                                                          </ListItemText>
                                                                      </ListItem>
                                                                  ))}
                                                              </List>
                                                          </Grid>
                                                      </Paper>
                                                  </Grid>
                                              </Grid>

                                        }
                                    </CardContent>
                                </Card>
                            </Grid>

                        ))}
                        {saving &&
                        <div className={classes.uploadProgress}>
                            <LinearProgress classes={{
                                colorPrimary: classes.linearColorPrimary,
                                barColorPrimary: classes.linearBarColorPrimary,
                            }}/>
                        </div>
                        }
                        <Button onClick={this.handleSubmit} color="primary" disabled={isInvalid}>
                            {t('buttons.submit')}
                        </Button>
                    </div>
                    :
                    <div align="center" className={classes.progressRoot}> <CircularProgress className={classes.progress} size={30} thickness={3} /></div>
                }
            </div>
        );
    }

}

const mapStateToProps = ({answers}) => {
    return {answersResult:answers};
};

export default compose (
    withStyles(styles),
    withI18n(),
    connect(mapStateToProps, {submitAnswers, openSnack})
)(Questions)
