export const ADD_ARTICLE = "ADD_ARTICLE";
export const ADD_CHAT_MESSAGES = "ADD_CHAT_MESSAGES";
export const ADD_IMAGES = "ADD_IMAGES";
export const ADD_MY_CHATS = "ADD_MY_CHATS";
export const ADD_SNACK = "ADD_SNACK";
export const ADD_LESSON = "ADD_LESSON";
export const ADD_LESSONS = "ADD_LESSONS";
export const ADD_LESSON_ASSIGNMENT = "ADD_LESSON_ASSIGNMENT";
export const ADD_QUESTION = "ADD_QUESTION";
export const ADD_POSTS = "ADD_POSTS";
export const ADD_GROUPS = "ADD_GROUPS";
export const ADD_FOLLOWERS = "ADD_FOLLOWERS";
export const ADD_FOLLOWINGS = "ADD_FOLLOWINGS";
export const ADD_ORGANIZATION_MEMBER_REQUESTS = "ADD_ORGANIZATION_MEMBER_REQUESTS";
export const ADD_ORGANIZATION_MEMBERS = "ADD_ORGANIZATION_MEMBERS";
export const FETCH_CHAT_MESSAGES = "FETCH_CHAT_MESSAGES";
export const FETCH_FRIENDS = "FETCH_FRIENDS";
export const FETCH_FRIEND_REQUESTS = "FETCH_FRIEND_REQUESTS";
export const FETCH_FOLLOWINGS = "FETCH_FOLLOWINGS";
export const FETCH_FOLLOWERS = "FETCH_FOLLOWERS";
export const FETCH_GROUP = "FETCH_GROUP";
export const FETCH_GROUPS = "FETCH_GROUPS";
export const FETCH_IMAGES = "FETCH_IMAGES";
export const FETCH_LESSON = "FETCH_LESSON";
export const FETCH_LESSON_ASSIGNMENT = "FETCH_LESSON_ASSIGNMENT";
export const FETCH_LESSON_ASSIGNEES = "FETCH_LESSON_ASSIGNEES";
export const FETCH_LESSONS = "FETCH_LESSONS";
export const FETCH_MY_CHATS = "FETCH_MY_CHATS";
export const FETCH_MY_GROUPS = "FETCH_MY_GROUPS";
export const FETCH_MY_FOLLOWINGS = "FETCH_MY_FOLLOWINGS";
export const FETCH_MY_FOLLOWER_REQUESTS = "FETCH_MY_FOLLOWER_REQUESTS";
export const FETCH_MY_FOLLOWERS = "FETCH_MY_FOLLOWERS";
export const FETCH_MY_LESSONS = "FETCH_MY_LESSONS";
export const FETCH_MY_SENT_FOLLOWING_REQUESTS = "FETCH_MY_SENT_FOLLOWING_REQUESTS";
export const FETCH_FOLLOWER_REQUESTS = "FETCH_FOLLOWER_REQUESTS";
export const FETCH_ORGANIZATION_INFO = "FETCH_ORGANIZATION_INFO";
export const FETCH_ORGANIZATION_LINK_CODES = "FETCH_ORGANIZATION_LINK_CODES";
export const FETCH_ORGANIZATION_MEMBERS = "FETCH_ORGANIZATION_MEMBERS";
export const FETCH_ORGANIZATION_MEMBER_REQUESTS = "FETCH_ORGANIZATION_MEMBER_REQUESTS";
export const FETCH_POSTS = "FETCH_POSTS";
export const FETCH_QUESTIONS = "FETCH_QUESTIONS";
export const FETCH_USER = "FETCH_USER";
export const FETCH_USERS = "FETCH_USERS";
export const FETCH_USERINFO = "FETCH_USERINFO";
export const REMOVE_MEMBER_REQUESTS = "REMOVE_MEMBER_REQUESTS";
export const REMOVE_IMAGE = "REMOVE_IMAGE";
export const REMOVE_POST = "REMOVE_POST";
export const REMOVE_POSTS = "REMOVE_POSTS";
export const UPDATE_IMAGE = "UPDATE_IMAGE";
export const UPDATE_POST = "UPDATE_POST";
export const UPDATE_PROFILE_HASH = "UPDATE_PROFILE_HASH";
export const UPDATE_QUESTION = "UPDATE_QUESTION";
export const USER_LOGOUT = "USER_LOGOUT";
