import * as firebase from "firebase";

// const prodConfig = {
//     apiKey: process.env.REACT_APP_PROD_API_KEY,
//     authDomain: process.env.REACT_APP_PROD_AUTH_DOMAIN,
//     databaseURL: process.env.REACT_APP_PROD_DATABASE_URL,
//     projectId: process.env.REACT_APP_PROD_PROJECT_ID,
//     storageBucket: process.env.REACT_APP_PROD_STORAGE_BUCKET,
//     messagingSenderId: process.env.REACT_APP_PROD_MESSAGING_SENDER_ID,
// };
//
// const devConfig = {
//     apiKey: process.env.REACT_APP_DEV_API_KEY,
//     authDomain: process.env.REACT_APP_DEV_AUTH_DOMAIN,
//     databaseURL: process.env.REACT_APP_DEV_DATABASE_URL,
//     projectId: process.env.REACT_APP_DEV_PROJECT_ID,
//     storageBucket: process.env.REACT_APP_DEV_STORAGE_BUCKET,
//     messagingSenderId: process.env.REACT_APP_DEV_MESSAGING_SENDER_ID,
// };
//
// const config =
//     process.env.NODE_ENV === 'production' ? prodConfig : devConfig;

// Your web app's Firebase configuration
var firebaseProdConfig = {
    apiKey: "AIzaSyBxo2-wvyEQK7PliP0iBHBcO4QuetsB8Hk",
    authDomain: "socipod-f6a4d.firebaseapp.com",
    databaseURL: "https://socipod-f6a4d.firebaseio.com",
    projectId: "socipod-f6a4d",
    storageBucket: "socipod-f6a4d.appspot.com",
    messagingSenderId: "364002959628",
    appId: "1:364002959628:web:5fcf25c769c3da3f786929"
};

export const devConfig = {
    apiKey: "AIzaSyA5OT_QQSAALYsT6MY_Omne2i7U-gjatQ4",
    authDomain: "socipoddev.firebaseapp.com",
    databaseURL: "https://socipoddev.firebaseio.com",
    projectId: "socipoddev",
    storageBucket: "socipoddev.appspot.com",
    messagingSenderId: "783337501364"
};
firebase.initializeApp(firebaseProdConfig);
export const auth = firebase.auth();
export const db = firebase.database();
export const storage = firebase.storage();
export const firestoreDB =  firebase.firestore();
export const fbFunctions = firebase.functions();
export const googleProvider = new firebase.auth.GoogleAuthProvider();
export const firestore = firebase.firestore;
