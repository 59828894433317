import { withI18n } from "react-i18next";
import { withStyles } from '@material-ui/core/styles';
import { withRouter } from 'react-router-dom';
import {compose} from "recompose";

import {CircularProgress, List, ListItem, ListItemText, ListItemAvatar, ListItemSecondaryAction, Typography} from '@material-ui/core';
import * as COLORS from "../../constants/colors";
import {connect} from "react-redux";
import TimeAgo from 'react-timeago';
import {fetchMyGroups, removeUserGroupsListener} from "../../actions";
import UserAvatar from "../UserAvatar";
import {AVATAR_TYPE} from "../../constants/common";
import {getJSDate} from "../../util/functions";

const React = require('react');

const styles = theme => ({
    mainView: {
        width: '100%',
        display: 'contents'
    },
    progressRoot: {
        flexGrow: 1,
        spacing: '40'
    },
    progress: {
        margin: theme.spacing(2),
        color: COLORS.LOADING_MASK
    },
    pageActions : {
        paddingTop : 0,
        color: COLORS.PRIMARY
    },
    details: {
        width: '100%',
        //maxWidth: 400,
        backgroundColor: theme.palette.background.paper,
    },
    title: {
        textTransform: 'uppercase'
    },
});

class MyGroupList extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            loading: false,
        };

        this.fetchMyGroupsCallback = this.fetchMyGroupsCallback.bind(this);
    }

    componentDidMount() {
        this.setState({ loading: true });
       // this.props.fetchMyGroups({userId:this.props.userId, callback:this.fetchMyGroupsCallback});
    }

    componentWillUnmount() {
        this.props.removeUserGroupsListener(this.props.userId);
    }

    fetchMyGroupsCallback(){
        this.setState({ loading: false });
    };


    render() {

        const {t, classes, myGroups} = this.props;
        return (
                <div align="center">
                    {myGroups && myGroups.length > 0 ? <List dense className={classes.details}>
                                {myGroups.map(group => {
                                    return (
                                        <span>
                                            <ListItem key={group.id} button onClick={() => { group.organizationId ? this.props.history.push(`/group/${group.organizationId}/${group.id}`) : this.props.history.push(`/group/public/${group.id}`)}}>
                                                <ListItemAvatar>
                                                    <UserAvatar username={group.name}
                                                                userId={group.organizationId ? `${group.organizationId}/${group.id}` : `public/${group.id}`}
                                                                imageUrl={group.profileImageUrl}
                                                                type={AVATAR_TYPE.GROUP}
                                                                />
                                                </ListItemAvatar>
                                                <ListItemText id={group.name}
                                                              primary={group.name}
                                                              secondary={<span>{t('joined')} <TimeAgo date={getJSDate(group.dateCreated)}/></span>}
                                                                  />
                                            </ListItem>
                                        </span>
                                    );
                                })}
                            </List> :
                            <Typography variant={"subheading"} className={classes.gray}>
                                {t('no results')}
                            </Typography>
                    }
                </div>
        )
    }

}
const mapStateToProps = ({myGroups}) => {
    return {myGroups:myGroups};
};
export default compose (
    withRouter,
    withStyles(styles),
    withI18n(),
    connect(mapStateToProps, {fetchMyGroups, removeUserGroupsListener})
)(MyGroupList)
