export const LOGO = 'logoOnly2.jpg';
export const DRAFT_JS_VIDEO_TYPE = 'video';
export const ATOMIC_TYPE = 'ATOMIC';
export const QUESTION_TYPE = {
     DRAG_AND_MATCH : 'DRAG_AND_MATCH',
     DRAG_AND_MATCH_CHOICE : 'DRAG_AND_MATCH_CHOICE',
     MULTIPLE_CHOICE : 'MULTIPLE CHOICE',
     OPEN_ENDED: 'OPEN ENDED'
}

export const ASSIGNEE_TYPE = {
     USER : 'USER',
     GROUP : 'GROUP'
}
export const USER_FIELD = {
    PROFILE_IMAGE_URL : 'profileImageUrl',
    USERNAME : 'username'
}
export const ASSIGNMENT_FIELD = {
    PROFILE_IMAGE_URL : 'profileImageUrl',
    STATUS : 'status',
    SUBMITTED_DATE: 'dateSubmitted',
    GRADED_DATE: 'dateGraded',
    GRADED_BY_ID: 'gradedById'
}


export const ASSIGNMENT_STATUS = {
    SUBMITTED : 'SUBMITTED',
    ASSIGNED : 'ASSIGNED',
    REASSIGNED : 'REASSIGNED',
    COMPLETED : 'COMPLETED',
}

export const FIELDS = {
    CREATED_DATE : 'dateCreated',
    CREATED_BY_ID : 'createdById',
    UPDATED_DATE : 'dateUpdated',
    REVERSE_DATE : 'reverseDate',
    UPDATED_BY_ID : 'updatedById',
    LAST_MESSAGE : 'lastMessage'
}

export const ACCESS_LEVEL = {
    PRIVATE : 'PRIVATE',
    ORGANIZATION : 'ORGANIZATION',
    PUBLIC : 'PUBLIC'
}

export const POST_TYPE = {
    PERSONAL : 'PERSONAL',
    GROUP : 'GROUP',
    LESSON : 'LESSON',
    ORGANIZATION : 'ORGANIZATION'
}
export const POST_LIMIT = 20;
export const USER_SEARCH_LIMIT = 20;
export const QUERY_LIMIT = 20;
export const CHAT_LIMIT = 20;
export const CHAT_MESSAGE_LIMIT = 20;
export const QUERY_IMAGE_LIMIT = 10;
export const PUBLIC = 'public';
export const PATHS = {
    ORGANIZATION_BASE : 'organizations',
    PUBLIC_BASE : 'public',
    NON_ORG_GROUPS : 'public-groups',
    NON_ORG_LESSONS : 'public-lessons',
}

export const GROUP_TYPE = {
    ORGANIZATION : 'ORGANIZATION',
    PUBLIC : 'PUBLIC'
}

export const LINK_CODES_TYPE = {
    ADMINISTRATOR : 'administrator',
    PROFESSOR : 'professor',
    PARENT_STUDENT : 'parent-student',
}
export const IMAGE_CROP_TYPE = {
    PROFILE: 'PROFILE',
    POST : 'POST',
    ORGANIZATION : 'ORGANIZATION',
    GROUP : 'GROUP',
    LESSON : 'LESSON'
}

export const AVATAR_TYPE = {
    PROFILE: 'PROFILE',
    ORGANIZATION : 'ORGANIZATION',
    GROUP : 'GROUP',
    LESSON : 'LESSON'
}
//Identify if using CustomReactSelect searching for user to create message, add to groups or assignee lesson
export const USER_SEARCH_TYPE = {
    MESSAGE : 'MESSAGE',
    GROUP : 'GROUP',
    LESSON_ASSIGNEE : 'LESSON_ASSIGNEE'
}

export const MEDIA_TYPE = {
    VIDEO : 'video',
    IMAGE : 'image'
}

export const MEDIA_LOCATION = {
    YOU_TUBE : 'youtube',
    UNKNOWN : 'unknown',
    SOCIPOD_FIREBASE : 'firebase'
}

export const BADGE_TYPE = {
    ADMIN: 'ADMIN',
    CHILD: 'CHILD',
    PARENT: 'PARENT',
    PROFESSOR: 'PROFESSOR',
    STUDENT: 'STUDENT',
}

export const POST_PATHS = {
    PERSONAL: 'posts',
    ORGANIZATION: 'organization-posts',
    GROUP: 'group-posts',
    LESSON: 'lesson-posts',
}

export const GRADE_LEVEL = ['p', 'k', '1', '2', '3', '4','5', '6','7','8','9','10','11','12', 'other'];
export const DIFFICULTY_LEVEL = ['easy','medium', 'hard'];
export const SUBJECT = ['english','math', 'science', 'other'];
export const SKILLS = {
    'p': ['addition', 'color', 'shape'],
    'k': ['addition', 'citing evidence', 'fractions'],
    '1': ['addition', 'citing evidence', 'fractions'],
    '2': ['addition', 'citing evidence', 'fractions'],
    '3': ['addition', 'citing evidence', 'fractions'],
    '4': ['addition', 'citing evidence', 'fractions'],
    '5': ['addition', 'citing evidence', 'fractions'],
    '6': ['addition', 'citing evidence', 'fractions'],
    '7': ['addition', 'citing evidence', 'fractions'],
    '8': ['addition', 'citing evidence', 'fractions'],
    '9': ['addition', 'citing evidence', 'fractions'],
    '10': ['addition', 'citing evidence', 'fractions'],
    '11': ['addition', 'citing evidence', 'fractions'],
    '12': ['addition', 'citing evidence', 'fractions'],
    'other': ['addition','subtraction', 'citing evidence', 'fractions']};
export const LIMITS = {
    EXPORT : 20
}



export const MAX_VIDEO_POST_SIZE_BTYE = 5000000;

export const ALL = 'all';

export const QUESTION_IMAGE_BASE_PATH = 'media/questions';