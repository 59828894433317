import { withI18n } from "react-i18next";
import { withStyles } from '@material-ui/core/styles';
import {compose} from "recompose";
import * as COLORS from "../../constants/colors";
import {QUESTION_IMAGE_BASE_PATH, QUESTION_TYPE} from "../../constants/common";
import { Add, Close,Remove, Edit, ImageSearch, CancelPresentation } from '@material-ui/icons';
import {Button, Checkbox, Dialog, DialogActions, DialogContent, DialogTitle, Divider, FormControl, Grid, IconButton, InputLabel, Paper, List, LinearProgress, ListItem, ListItemIcon, ListItemText, ListItemSecondaryAction, Select, TextField, Typography} from'@material-ui/core';
import {addQuestion, openSnack, updateQuestion} from "../../actions";
import ImageGrid from "../ImageGrid"
import {connect} from "react-redux";
import {PRIMARY} from "../../constants/colors";
import {RED} from "../../constants/colors";

const React = require('react');

const styles = theme => ({
    container: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500],
    },
    formControl: {
        margin: 5,
        minWidth: 120,
    },
    icon: {
        color: PRIMARY,
        cursor: 'pointer'
    },
    iconRemove: {
        color: RED,
        cursor: 'pointer'
    },
    uploadProgress : {
        flexGrow: 1,
    },
    border: {
        borderWidth: 2
    },
    linearColorPrimary : {
        backgroundColor: COLORS.LINARY_LOADING_MASK,
    },
    linearBarColorPrimary : {
        backgroundColor: COLORS.LINARY_LOADING_MASK_BAR,
    },
    textInput: {
        padding: theme.spacing(.5),
    }
});

const IMAGE_DISPLAY_WIDTH = 50;
const INITIAL_STATE = {
    message:null,
    question: '',
    dropZone:{},
    answers:[{isCorrect:false}, {isCorrect:false}, {isCorrect:false}],
    type: QUESTION_TYPE.MULTIPLE_CHOICE,
    numberOfAnswers: 1,
    saving: false,
    imageToUpdate: null,
    showImagePicker: false,
    dragAndMatchList: [{dropZone: {id:1}, draggable:{id:1}}, {dropZone: {id:2}, draggable:{id:2}}, {dropZone: {id:3}, draggable:{id:3}}],
};

class QuestionCreate extends React.Component {

    constructor(props) {
        super(props);
        var initState = {...INITIAL_STATE};
        if(props.questionObject){
            initState.type = props.questionObject.type;

            if(initState.type === QUESTION_TYPE.OPEN_ENDED ||initState.type == QUESTION_TYPE.MULTIPLE_CHOICE) {
                initState.question = props.questionObject.question;
                if(QUESTION_TYPE.MULTIPLE_CHOICE === initState.type){
                    initState.answers = props.questionObject.answers
                }
            } else if(initState.type === QUESTION_TYPE.DRAG_AND_MATCH){
                initState.dragAndMatchList = props.questionObject.dragAndMatchList;
            } else if(initState.type === QUESTION_TYPE.DRAG_AND_MATCH_CHOICE){
                initState.dropZone = props.questionObject.dropZone;
                initState.answers = props.questionObject.answers
            }
        }

        this.state = {...initState};
        this.handleClose = this.handleClose.bind(this);
        this.handleCreate = this.handleCreate.bind(this);
        this.onChange = this.onChange.bind(this);
        this.onCheckChange = this.onCheckChange.bind(this);
        this.onAnswerValueChange = this.onAnswerValueChange.bind(this);
        this.onAnswerCheckChange = this.onAnswerCheckChange.bind(this);
        this.addAnswer = this.addAnswer.bind(this);
        this.removeAnswer = this.removeAnswer.bind(this);
        this.addQuestionCallback = this.addQuestionCallback.bind(this);
        this.onImageSelected = this.onImageSelected.bind(this);
        this.dragAndMatchInValid = this.dragAndMatchInValid.bind(this);
        this.onDropZoneInfoChange = this.onDropZoneInfoChange.bind(this);
        this.dragAndMatchMultipleInValid = this.dragAndMatchMultipleInValid.bind(this);

    }

    // componentWillUpdate(nextProps) {
    //     if (nextProps.newQuestion && this.state.saving) {
    //         if(nextProps.newQuestion.createSuccess) {
    //             this.setState({saving:false});
    //             this.props.openSnack({open:true, messageText:'question added', variant:'success'});
    //             this.handleClose();
    //         } else if(nextProps.newQuestion.createFailed) {
    //             this.props.openSnack({open:true, messageText:nextProps.lesson.error.message, variant:'error'});
    //             this.setState({saving:false});
    //         }
    //     }
    // }

    handleClose = () => {
        this.setState({...INITIAL_STATE});
        this.props.close();
    };


    handleCreate = () => {
        debugger;
        this.setState({ 'saving': true });
        const { question, dropZone, type, answers, dragAndMatchList} = this.state;
        const { currentUserId, lessonId,dataPath} = this.props;
        var questionObject = {type:type, createdById:currentUserId};
        if(this.props.questionObject) {
            questionObject = {type:type, updatedById:currentUserId};
        }
        if(type === QUESTION_TYPE.OPEN_ENDED || type == QUESTION_TYPE.MULTIPLE_CHOICE) {
            questionObject.question  = question;
            if(QUESTION_TYPE.MULTIPLE_CHOICE === type){
                questionObject.answers = answers;
            }
        } else if(type === QUESTION_TYPE.DRAG_AND_MATCH){
            questionObject.dragAndMatchList = dragAndMatchList;
        } else if(type === QUESTION_TYPE.DRAG_AND_MATCH_CHOICE){
            questionObject.dropZone = dropZone;
            questionObject.answers = answers;
        }
        debugger;
        if(this.props.questionObject) {
            this.props.updateQuestion({path:dataPath, lessonId:lessonId, question:questionObject, questionId:this.props.questionObject.id, callback:this.addQuestionCallback});
        } else {
            this.props.addQuestion({path:dataPath, lessonId:lessonId, question:questionObject, callback:this.addQuestionCallback});
        }
    };

    addQuestionCallback(success) {
        this.setState({saving:false});
        if(success) {
            this.setState({...INITIAL_STATE});
            this.handleClose();
        }
    }
    onChange = prop => event => {
        this.setState({ [prop]: event.target.value });
    };
    onDropZoneInfoChange = prop =>  event => {
        debugger;
        const {dropZone} = this.state;
        dropZone[prop] = event.target.value;
        this.setState({dropZone: dropZone});
    };
    onDraggableInfoChange = (prop, index) =>  event => {
        debugger;
        const {answers, type, dragAndMatchList} = this.state;
        if(type === QUESTION_TYPE.DRAG_AND_MATCH) {
            dragAndMatchList[index].draggable[prop] = event.target.value;
            this.setState({dragAndMatchList: dragAndMatchList});
        } else if(type === QUESTION_TYPE.DRAG_AND_MATCH_CHOICE) {
            var draggable = answers[index];
            draggable[prop] = event.target.value;
            answers[index] = draggable;
            this.setState({answers: answers});
        }

    };
    onCheckChange = name => event => {
        this.setState({ [name]: event.target.checked });
    };

    onAnswerValueChange = index => event => {
        var answers = this.state.answers;
        answers[index].value = event.target.value;
        this.setState({answers:answers});
    };
    onAnswerCheckChange = index => event => {
        var answers = this.state.answers;
        answers[index].isCorrect = event.target.checked;
        this.setState({answers: answers});
    };
    addAnswer() {
        var answers = this.state.answers;
        answers[answers.length] = {value:'',isCorrect:false};
        this.setState({answers:answers});
    }
    addDragAndDrop() {
        var dragAndMatchList = this.state.dragAndMatchList;
        if(dragAndMatchList.length < 4 ) {
            const count = dragAndMatchList.length;
            dragAndMatchList.push({dropZone: {id: count}, draggable: {id: count}});
            this.setState({dragAndDropList: dragAndMatchList});
        }
    }

    dragAndMatchInValid(){
        var dragAndMatchList = this.state.dragAndMatchList;
        var isInValid = false;
        var count = 1;
        dragAndMatchList.forEach(dragAndMatch => {
            if(count < 3) {
                if (dragAndMatch.dropZone.imageLink === null || dragAndMatch.dropZone.imageLink === null) {
                    isInValid = true;
                }
            }  else {
                if (dragAndMatch.dropZone.imageLink !== null && dragAndMatch.dropZone.imageLink === null) {
                    isInValid = true;
                }
                if (dragAndMatch.dropZone.imageLink === null && dragAndMatch.dropZone.imageLink !== null) {
                    isInValid = true;
                }
            }
        })

        return isInValid;
    }
    dragAndMatchMultipleInValid(){
        var {dropZone, answers} = this.state;
        if(!dropZone.text && !dropZone.imageLink) {
            return true;
        }
        answers.forEach(answer => {
            if(answer.imageLink === null || answer.imageLink  === ''){
                return true;
            }
        })

        return false;
    }
    showImagePicker = (index, item) => {
        this.setState({imageToUpdate: {index:index, item:item}, showImagePicker:true});
    }
    onImageSelected = imageData => {
        const {imageToUpdate, dragAndMatchList, answers, dropZone, type} = this.state;

        if(imageToUpdate) {
            if(type === QUESTION_TYPE.DRAG_AND_MATCH) {
                let dragAndDrop = dragAndMatchList[imageToUpdate.index];
                dragAndDrop[imageToUpdate.item].imageLink = imageData.mediaLink;
                dragAndDrop[imageToUpdate.item].name = imageData.name;
                dragAndDrop[imageToUpdate.item].isSvg = imageData.isSvg;
                dragAndMatchList[imageToUpdate.index] = dragAndDrop;
                this.setState({showImagePicker:false, dragAndMatchList:dragAndMatchList})
            } else if(type === QUESTION_TYPE.DRAG_AND_MATCH_CHOICE){
                if(imageToUpdate.item == 'dropzone') {
                    dropZone.imageLink = imageData.mediaLink;
                    dropZone.name = imageData.name;
                    dropZone.isSvg = imageData.isSvg;
                    this.setState({showImagePicker: false, dropZone:dropZone});
                } else {
                    let draggable = answers[imageToUpdate.index];
                    draggable.imageLink = imageData.mediaLink;
                    draggable.name = imageData.name;
                    draggable.isSvg = imageData.isSvg;
                    answers[imageToUpdate.index] = draggable;
                    this.setState({showImagePicker: false, answers: answers})
                }
            }


        } else {
            this.setState({showImagePicker:false})
        }
    }

    onRemoveImageSelected = (index, item) => {
        const {dragAndMatchList, answers, dropZone, type} = this.state;

        if(type === QUESTION_TYPE.DRAG_AND_MATCH) {
            let dragAndDrop = dragAndMatchList[index];
            dragAndDrop[item].imageLink = null;
            dragAndDrop[item].name = "";
            dragAndDrop[item].isSvg = null;
            dragAndMatchList[index] = dragAndDrop;
            this.setState({showImagePicker:false, dragAndMatchList:dragAndMatchList})
        } else if(type === QUESTION_TYPE.DRAG_AND_MATCH_CHOICE){
            if(item == 'dropzone') {
                dropZone.imageLink = null;
                dropZone.name = "";
                dropZone.isSvg = null;
                this.setState({showImagePicker: false, dropZone:dropZone});
            } else {
                let draggable = answers[index];
                draggable.imageLink = null;
                draggable.name = "";
                draggable.isSvg = null;
                answers[index] = draggable;
                this.setState({showImagePicker: false, answers: answers})
            }
        }

    }
    removeDragAndDrop = index => event => {
        var dragAndMatchList = this.state.dragAndMatchList;
        if(this.state.dragAndMatchList.length < 3) {
            this.props.openSnack( {open:true, messageText:'at least 2 pairs need for drag and match', variant:'error'})
        } else {
            dragAndMatchList.splice(index, 1);
            this.setState({dragAndMatchList:dragAndMatchList});
        }

    }
    removeAnswer = index => event => {
        var answers = this.state.answers;
        if(this.state.type === QUESTION_TYPE.MULTIPLE_CHOICE  && answers.length === 2) {
            this.props.openSnack( {open:true, messageText:'2 choices need for multiple', variant:'error'})
        } else {
            answers.splice(index, 1);
            this.setState({answers:answers});
        }

    }
    answersInValid(){
        var answers = this.state.answers;
        var isInValid = false;
        answers.forEach(answer => {
            if(answer.value === null || answer.value === ''){
                isInValid = true;
                return true;
            }
        })

        return isInValid;
    }

    render() {
        const {
            message,
            saving,
            question,
            answers,
            dragAndMatchList,
            type,
            showImagePicker,
            dropZone
        } = this.state;
        const {t, classes, currentUser} = this.props;
        const isInvalid = type === '' || (type == QUESTION_TYPE.OPEN_ENDED &&  question === '' ) || (type === QUESTION_TYPE.MULTIPLE_CHOICE && (question === '' || this.answersInValid())) ||
            (type === QUESTION_TYPE.DRAG_AND_MATCH && this.dragAndMatchInValid()) || (type === QUESTION_TYPE.DRAG_AND_MATCH_CHOICE && this.dragAndMatchMultipleInValid()) || saving;
        const title = this.props.question ? t('application.update question') : t('application.add question');
        const disableChoiceRemove = answers === '' || answers.length < 3;
        return (
            <div align="center">
                <Dialog
                    open={this.props.open}
                    onClose={this.handleClose}
                    aria-labelledby="form-dialog-title"
                    fullWidth={true}
                    maxWidth={'lg'}
                >
                    <DialogTitle id="form-dialog-title">
                        <Typography variant="h6" className={classes.title}>
                            {title}
                        </Typography>
                        <IconButton aria-label="Close" className={classes.closeButton} onClick={this.handleClose}>
                            <Close />
                        </IconButton>
                    </DialogTitle>
                    <DialogContent dividers>
                        <div>
                            <Grid>
                                <Grid item>
                                    <FormControl className={classes.formControl}>
                                        <InputLabel htmlFor="type-select" required shrink={true}><Typography > {t('fields.type')}</Typography></InputLabel>
                                        <Select
                                            value={type}
                                            onChange={this.onChange('type')}
                                            inputProps={{
                                                name: 'typeId',
                                                id: 'type-select',
                                            }}
                                            native
                                        >

                                            <option value={QUESTION_TYPE.DRAG_AND_MATCH}>{t('drag and match')}</option>
                                            <option value={QUESTION_TYPE.DRAG_AND_MATCH_CHOICE}>{t('drag and match multiple choice')}</option>
                                            <option value={QUESTION_TYPE.MULTIPLE_CHOICE}>{t('multiple choice')}</option>
                                            <option value={QUESTION_TYPE.OPEN_ENDED}>{t('open ended')}</option>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </Grid>
                                {type && type === QUESTION_TYPE.DRAG_AND_MATCH &&
                                    <div>
                                        <Typography variant={"title"}>{t('add Transparent(PNG or SVG) images for drop container and draggable')}</Typography>
                                        <Grid container item spacing={2} >
                                            <Grid item xs={12} md={6}>
                                                <Paper>
                                                {dragAndMatchList.map((dragAndMatch, index) => (
                                                    <Grid item spacing={4}>
                                                        <Typography style={{paddingTop: 2}}>{t('pair count', {count: index + 1})}</Typography>
                                                        <List className={classes.root}>
                                                            <ListItem key={`dropzone-${index}`} dense>
                                                                <ListItemIcon>
                                                                    <IconButton edge="end" aria-label="edit"  className={classes.icon}  onClick={()=>this.showImagePicker(index, 'dropzone')}>
                                                                        <ImageSearch />
                                                                    </IconButton>
                                                                    {dragAndMatch.dropZone.imageLink &&
                                                                        <IconButton edge="end" aria-label="edit"
                                                                                    className={classes.iconRemove}
                                                                                    onClick={() => this.onRemoveImageSelected(index, 'dropzone')}>
                                                                            <CancelPresentation/>
                                                                        </IconButton>
                                                                    }
                                                                    {dragAndMatch.dropZone.imageLink &&
                                                                        <img src={dragAndMatch.dropZone.imageLink} alt={dragAndMatch.dropZone.name} width={IMAGE_DISPLAY_WIDTH}/>

                                                                    }

                                                                </ListItemIcon>
                                                                <ListItemText id={'dropZone' + index}>
                                                                    <TextField value={dragAndMatch.dropZone.name} required type="text"  fullWidth disabled={true} id={index} label={t('drop zone image')}/>
                                                                </ListItemText>
                                                            </ListItem>
                                                            <ListItem key={`draggable-${index}`} dense>
                                                                <ListItemIcon>
                                                                    <IconButton edge="end" aria-label="edit"  className={classes.icon}  onClick={()=>this.showImagePicker(index, 'draggable')}>
                                                                        <ImageSearch />
                                                                    </IconButton>
                                                                    {dragAndMatch.draggable.imageLink &&
                                                                        <IconButton edge="end" aria-label="edit"
                                                                                    className={classes.iconRemove}
                                                                                    onClick={() => this.onRemoveImageSelected(index, 'draggable')}>
                                                                            <CancelPresentation/>
                                                                        </IconButton>
                                                                    }
                                                                    {dragAndMatch.draggable.imageLink &&
                                                                        <img
                                                                            src={dragAndMatch.draggable.imageLink}
                                                                            alt={dragAndMatch.dropZone.name}
                                                                            width={IMAGE_DISPLAY_WIDTH}
                                                                        />
                                                                    }

                                                                </ListItemIcon>
                                                                <ListItemText id={'draggable' + index}>
                                                                    <TextField value={dragAndMatch.draggable.name} type="text" className={classes.textInput} disabled={true} id={index} label={t('draggable image')} />
                                                                    <TextField value={dragAndMatch.draggable.speechText} className={classes.textInput} type="text" id={'draggable-speech-text'} onChange={this.onDraggableInfoChange('speechText', index)} label={t('draggable speech text')}/>
                                                                </ListItemText>
                                                            </ListItem>
                                                        </List>
                                                        {index < dragAndMatchList.length && <Divider />}
                                                    </Grid>
                                                ))}
                                                    {dragAndMatchList.length < 3 && <IconButton className={classes.icon}  onClick={this.addDragAndDrop}><Add/></IconButton>}
                                                </Paper>
                                            </Grid>
                                            <Grid item xs={12} md={3}>
                                                <Paper>
                                                    {showImagePicker && <ImageGrid folderId={currentUser.id} onImageSelected={this.onImageSelected} path={QUESTION_IMAGE_BASE_PATH}/>}
                                                </Paper>
                                            </Grid>
                                        </Grid>
                                    </div>
                                }

                                {type && type === QUESTION_TYPE.DRAG_AND_MATCH_CHOICE &&
                                <div>
                                    <Typography variant={"title"}>{t('add Transparent(PNG or SVG) images for drop container and draggable')}</Typography>
                                    <Grid container item spacing={2} >
                                        <Grid item xs={12} md={6}>
                                            <Paper>
                                                <Grid item spacing={4}>
                                                    <Typography style={{paddingTop: 2}}>{t('add an image or just text for the drop zone')}</Typography>
                                                    <List className={classes.root}>
                                                        <ListItem key={`dropzone-image`} dense>
                                                            {dropZone.imageLink &&
                                                                <ListItemIcon>
                                                                    <img src={dropZone.imageLink} alt={dropZone.name} width={IMAGE_DISPLAY_WIDTH}/>
                                                                </ListItemIcon>
                                                            }
                                                            <ListItemText id={'dropZone-info-1'}>
                                                                <TextField value={dropZone.name} type="text" className={classes.textInput} disabled={true} id={'dropzone-image-name'} label={t('drop zone image')} />
                                                                <TextField value={dropZone.speechText} className={classes.textInput} type="text" id={'draggable-speech-text'} onChange={this.onDropZoneInfoChange('speechText')} label={t('speech text')}/>
                                                            </ListItemText>
                                                            <ListItemSecondaryAction>
                                                                <IconButton edge="end" aria-label="edit"  className={classes.icon}  onClick={()=>this.showImagePicker(0, 'dropzone')}>
                                                                    <ImageSearch />
                                                                </IconButton>
                                                                {dropZone.imageLink &&
                                                                <IconButton edge="end" aria-label="edit"
                                                                            className={classes.iconRemove}
                                                                            onClick={() => this.onRemoveImageSelected(0, 'dropzone')}>
                                                                    <CancelPresentation/>
                                                                </IconButton>
                                                                }
                                                            </ListItemSecondaryAction>
                                                        </ListItem>
                                                        <ListItem key={`dropzone-image-add`} dense>
                                                            <ListItemText id={'dropZone-info'}>
                                                                <TextField value={dropZone.text} type="text" id={'dropzone-text'} className={classes.textInput} onChange={this.onDropZoneInfoChange('text')} label={t('drop zone text')}/>
                                                                <TextField value={dropZone.textColor}  type="text" className={classes.textInput} onChange={this.onDropZoneInfoChange('textColor')} label={t('text color')} />
                                                                <TextField value={dropZone.bgColor}  type="text" className={classes.textInput} onChange={this.onDropZoneInfoChange('bgColor')} label={t('background color')} />
                                                            </ListItemText>
                                                        </ListItem>
                                                    </List>
                                                </Grid>
                                                <Grid item spacing={4}>
                                                    <Typography style={{paddingTop: 2}}>{t('draggables')}</Typography>
                                                    <List className={classes.root}>
                                                        {answers.map((draggable, index) => (
                                                                <div>
                                                                    <ListItem key={`draggable-${index}`} dense>
                                                                        <ListItemIcon>
                                                                            <Checkbox
                                                                                edge="start"
                                                                                checked={draggable.isCorrect === true}
                                                                                tabIndex={-1}
                                                                                disableRipple
                                                                                inputProps={t('field.is correct')}
                                                                                onClick={this.onAnswerCheckChange(index)}
                                                                            />
                                                                        </ListItemIcon>
                                                                        <ListItemText id={'draggable-text' + index}>
                                                                                <img src={draggable.imageLink}
                                                                                     alt={draggable.name} width={IMAGE_DISPLAY_WIDTH}/>
                                                                            <TextField value={draggable.name} className={classes.textInput} required type="text"  disabled={true} id={index} label={t('draggable image')} />
                                                                            <TextField value={draggable.speechText} className={classes.textInput} type="text" id={'draggable-speech-text'} onChange={this.onDraggableInfoChange('speechText', index)} label={t('speech text')}/>
                                                                        </ListItemText>
                                                                        <ListItemSecondaryAction>
                                                                            <IconButton edge="end" aria-label="edit"  className={classes.icon}  onClick={()=>this.showImagePicker(index, 'draggable', QUESTION_TYPE.DRAG_AND_MATCH_CHOICE)}>
                                                                                <ImageSearch />
                                                                            </IconButton>
                                                                            {draggable.imageLink &&
                                                                                <IconButton edge="end" aria-label="edit"
                                                                                            className={classes.iconRemove}
                                                                                            onClick={() => this.onRemoveImageSelected(index, 'draggable')}>
                                                                                    <CancelPresentation/>
                                                                                </IconButton>
                                                                            }
                                                                            <IconButton disabled={disableChoiceRemove} edge="end" className={classes.iconRemove}  aria-label="remove"  onClick={this.removeAnswer(index)}>
                                                                                <Remove />
                                                                            </IconButton>
                                                                        </ListItemSecondaryAction>
                                                                    </ListItem>
                                                                    {index < answers.length && <Divider />}
                                                                </div>
                                                        ))}
                                                    </List>
                                                </Grid>
                                                {answers.length < 5 && <IconButton className={classes.icon}  onClick={this.addAnswer}><Add/></IconButton>}
                                            </Paper>
                                        </Grid>
                                        <Grid item xs={12} md={3}>
                                            <Paper>
                                                {showImagePicker && <ImageGrid folderId={currentUser.id} onImageSelected={this.onImageSelected} path={QUESTION_IMAGE_BASE_PATH}/>

                                                }
                                            </Paper>
                                        </Grid>
                                    </Grid>
                                </div>
                                }

                                {type && (type === QUESTION_TYPE.MULTIPLE_CHOICE || type == QUESTION_TYPE.OPEN_ENDED) && <div>
                                    <Grid item>
                                        <TextField fullWidth inputProps={{maxLength: 200}} multiline rows="2" value={question} required type="text" onChange={this.onChange('question')} id="input-question" label={t('fields.question')} />
                                    </Grid>
                                    {type && type === QUESTION_TYPE.MULTIPLE_CHOICE &&
                                        <Grid item>
                                            <Typography variant={"title"}>{t('fields.answers')}</Typography>
                                            <Typography variant={"subtitle2"}>({t('multiple choice answer creation description')})</Typography>
                                            <List className={classes.root}>

                                            {answers.map((answer, index) => (

                                            <ListItem key={index} role={undefined} dense>
                                                <ListItemIcon>
                                                    <Checkbox
                                                        edge="start"
                                                        checked={answer.isCorrect === true}
                                                        tabIndex={-1}
                                                        disableRipple
                                                        inputProps={t('field.is correct')}
                                                        onClick={this.onAnswerCheckChange(index)}
                                                    />
                                                </ListItemIcon>
                                                <ListItemText id={'answer' + index} >
                                                    <TextField inputProps={{maxLength: 200}} fullWidth multiline rows="2" value={answer.value} required type="text" onChange={this.onAnswerValueChange(index)} id={index} label={t('fields.answer')} />
                                                </ListItemText>
                                                <ListItemSecondaryAction>
                                                    <IconButton disabled={disableChoiceRemove} edge="end" className={classes.iconRemove}  aria-label="remove"  onClick={this.removeAnswer(index)}>
                                                        <Remove />
                                                    </IconButton>
                                                </ListItemSecondaryAction>
                                            </ListItem>

                                                ))}
                                            </List>

                                            {/*{answers.map((answer, index) => (*/}
                                                    {/*<Grid xs={12} md={12} lg={12}>*/}
                                                        {/*<Grid xs={1} md={1} lg={1}>*/}
                                                            {/*<Checkbox*/}
                                                                {/*edge="start"*/}
                                                                {/*checked={answer.isCorrect === true}*/}
                                                                {/*tabIndex={-1}*/}
                                                                {/*disableRipple*/}
                                                                {/*inputProps={t('field.is correct')}*/}
                                                                {/*onClick={this.onAnswerCheckChange(index)}*/}
                                                            {/*/>*/}
                                                        {/*</Grid>*/}
                                                        {/*<Grid xs={10} md={10} lg={10}>*/}
                                                            {/*<TextField inputProps={{maxLength: 200}} fullWidth multiline rows="2" value={answer.value} required type="text" onChange={this.onAnswerValueChange(index)} id={'answer-value-'+index} label={t('fields.answer')} />*/}
                                                        {/*</Grid>*/}
                                                        {/*<Grid xs={1} md={1} lg={1}>*/}
                                                            {/*<IconButton disabled={disableChoiceRemove} edge="end" aria-label="remove" className={classes.icon}   onClick={this.removeAnswer(index)}>*/}
                                                                {/*<Remove />*/}
                                                            {/*</IconButton>*/}
                                                        {/*</Grid>*/}
                                                    {/*</Grid>*/}
                                                {/*))};*/}

                                            <IconButton onClick={this.addAnswer}><Add/></IconButton>
                                        </Grid>
                                    }
                                </div>}
                            </Grid>
                        </div>
                    </DialogContent>
                    {saving &&
                    <div className={classes.uploadProgress}>
                        <LinearProgress classes={{
                            colorPrimary: classes.linearColorPrimary,
                            barColorPrimary: classes.linearBarColorPrimary,
                        }}/>
                    </div>
                    }
                    <DialogActions>
                        <Button onClick={this.handleClose} color="primary">
                            {t('buttons.cancel')}
                        </Button>
                        <Button onClick={this.handleCreate} color="primary" disabled={isInvalid}>
                            {this.props.questionObject ? t('buttons.update') : t('buttons.create')}
                        </Button>
                    </DialogActions>
                    <Typography  className={classes.success}>
                        {message}
                    </Typography>
                </Dialog>
            </div>
        );
    }

}

const mapStateToProps = ({user, question}) => {
    return {newQuestion:question, currentUser:user};
};

export default compose (
    withStyles(styles),
    withI18n(),
    connect(mapStateToProps, {addQuestion, openSnack, updateQuestion})
)(QuestionCreate)
