import {isApprovedMemberOfOrganization} from "./functions";

export const canAssignLesson = (user, lesson) => {
    if (user && lesson && lesson.published) {
        //creator and owner
        if(user.id == lesson.createdById || user.id == lesson.ownerId) {
            return true;
        }

        //system admin
        if(user.isAdmin){
            return true;
        }

        //administrator or professor and lesson is private or in same org
        if((user.isAdministrator || user.isProfessor)
            && (!lesson.isPrivate || (isApprovedMemberOfOrganization(user.organizations, lesson.organizationId)))) {
            return true;
        }
    }
    return false;
}

export const canEditLesson = (user, lesson) => {
    if (user && lesson && !lesson.published ) {
        //creator and owner
        if(user.id == lesson.createdById || user.id == lesson.ownerId) {
            return true;
        }

        //system admin
        if(user.isAdmin){
           return true;
        }

        //administrator if lesson is in the same org
        if(user.isAdministrator && isApprovedMemberOfOrganization(user.organizations, lesson.organizationId)) {
            return true;
        }
    }
    return false;
}
