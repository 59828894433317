import {ADD_POSTS, FETCH_POSTS, REMOVE_POST, REMOVE_POSTS, UPDATE_POST} from "../constants/action-types";
import {combineArrays, removeObjectInArrayById, updateObjectInArrayById} from "../util/functions";

export default (state = {}, action) => {
    switch (action.type) {
        case ADD_POSTS:
            return {...state, [action.payload.id] : action.payload.top ?
                    combineArrays(action.payload.posts, state[action.payload.id])
                    : combineArrays(state[action.payload.id], action.payload.posts)};
        case FETCH_POSTS:
            return {...state, [action.payload.id] : action.payload.posts};
        case REMOVE_POSTS:
            return delete {...state,  [action.payload.id] : null};
        case REMOVE_POST:
            return {...state, [action.payload.id] : removeObjectInArrayById(state[action.payload.id], action.payload.postId)};
        case UPDATE_POST:
            debugger;
            return {...state, [action.payload.id] : updateObjectInArrayById(state[action.payload.id], action.payload.post)};
        default:
            return state;
    }
};